@font-face {
    font-family: 'Font Awesome 5 Brands-Regular-400';
    src: url('../fonts/Font\ Awesome\ 5\ Brands-Regular-400.otf');
}
@font-face {
    font-family: 'Font Awesome 5 Free-Regular-400';
    src: url('../fonts/Font\ Awesome\ 5\ Free-Regular-400.otf');
}
@font-face {
    font-family: 'Font Awesome 5 Free-Solid-900';
    src: url('../fonts/Font\ Awesome\ 5\ Free-Solid-900.otf');
}
@font-face {
    font-family: 'Lato-Black';
    src: url('../fonts/Lato-Black.ttf');
}
@font-face {
    font-family: 'Lato-BlackItalic';
    src: url('../fonts/Lato-BlackItalic.ttf');
}
@font-face {
    font-family: 'Lato-Bold';
    src: url('../fonts/Lato-Bold.ttf');
}
@font-face {
    font-family: 'Lato-BoldItalic';
    src: url('../fonts/Lato-BoldItalic.ttf');
}
@font-face {
    font-family: 'Lato-Italic';
    src: url('../fonts/Lato-Italic.ttf');
}
@font-face {
    font-family: 'Lato-Light';
    src: url('../fonts/Lato-Light.ttf');
}
@font-face {
    font-family: 'Lato-LightItalic';
    src: url('../fonts/Lato-LightItalic.ttf');
}
@font-face {
    font-family: 'Lato-Regular';
    src: url('../fonts/Lato-Regular.ttf');
}
@font-face {
    font-family: 'Lato-Thin';
    src: url('../fonts/Lato-Thin.ttf');
}
@font-face {
    font-family: 'Lato-ThinItalic';
    src: url('../fonts/Lato-ThinItalic.ttf');
}
