@media (min-width:768px){

}
@media (max-width:991px){

}
@media (max-width:767px){

}
@media
only screen and (max-width: 760px) {
}
@media only screen and (max-width: 1440px) and (min-width: 1024px){
.privacy-bg{
background-size: cover;
}
}
@media only screen and (max-width: 1515px) and (min-width: 1400px){
    .new-enterprise-banner-page{
        height: 450px;
    }
    .recruitment-process-right-card-heading {
        font-size: 18px;
    }
    .recruitment-process-section-full-width {
        height: 577px;
    }
    .recruitment-process-section-half-width {
        height: 577px;
    }

}
@media only screen and (max-width: 1412px) and (min-width: 1250px){
    .new-enterprise-banner-page{
        height: 400px;
    }
    .recruitment-process-section-full-width {
        height: 567px;
    }
    .recruitment-process-section-half-width {
        height: 567px;
    }
    .recruitment-process-section-right-card{
        margin-bottom: 15px;
    }
}
@media only screen and (max-width: 1383px) and (min-width: 1250px){
    .recruitment-process-section-full-width {
        height: 550px;
    }
    .recruitment-process-section-half-width {
        height: 550px;
    }
    .recruitment-process-right-card-heading {
        font-size: 16px;
    }
}
@media only screen and (max-width: 1335px) and (min-width: 1275px){
    .recruitment-process-section-full-width {
        height: 530px;
    }
    .recruitment-process-section-half-width {
        height: 530px;
    }
}
@media only screen and (max-width: 1290px) and (min-width: 1190px){
    .recruitment-process-section-full-width {
        height: 510px;
    }
    .recruitment-process-section-half-width {
        height: 510px;
    }
    .recruitment-process-section-right-whole-card{
        padding: 14px 0;
    }
    .recruitment-process-right-card-para {
        font-size: 14px;
    }
}
@media only screen and (max-width: 1250px) and (min-width: 1100px){
    .new-enterprise-banner-page{
        height: 350px;
    }
    .turnkey-system-card::after {
        content: '';
        background: url(../images/enterprise/turnkeycardbgpng.png);
        height: 142px;
        width: 100%;
        background-size: 131%;
    }
    .turnkey-system-card {
        height: 242px;
    }
    .privacy-bg {
        height: 1890px;
    }
}
@media only screen and (max-width: 1100px) and (min-width: 1024px){
    .new-enterprise-banner-page{
        height: 325px;
    }

}
@media only screen and (max-width: 1024px) and (min-width: 768px){
    .new-enterprise-banner-page {
        background: url(../images/enterprise/enterprisebannerbg.jpg);
        background-repeat: no-repeat;
        height: 244px;
        background-size: cover;
    }
    .jobseeker .tab-side-nav{
        display: block;
        position: fixed;
        width: 100%;
        top: 0;
    height: 100vh;
    z-index: 111;
    }
    .jobseeker .job-seeker-dashboard-banner-content {
        position: absolute;
        bottom: 0;
        left: 2.5%;
        width: 95%;
    }
    .jobseeker .find-job-p{
        width: auto !important;
    }
    .jobseeker .find_job h2{
        width: auto !important;
    }
    .custom-tab-flex{
        align-items: center;
        justify-content: space-between;
    }
    .jobseeker .dashboard-body-sec{
        width: 100%;
    }
    .invoice-module .invoice-module-menus h3{
        font-size: 14px;
        min-height: 38px;
    }
    .transaction-table{
        padding: 0 0 30px;
    }
    .user-preview-header .w-50{
        width: 100% !important;
    }
    .jobseeker .footer{
        padding: 0;
    }
    .header .nav-item .nav-link{
        font-size: 12px;
    }
    .login-page-card .contactus-left-card {
        width: 60%;
        top: 0;
    }
    .loginbg {
        height: 200px;
        padding: 15px 0 0;
    }
    .login-page-card.contactmaincard {
        padding: 40px 0;
    }
    .signup-whole-card .contactus-left-card{
        width: 45%;
    }
    .signup-whole-card ul.common-bulletin {
        width: 90%;
    }
    .applicants-card-exp-sec{
        display: inherit;
    }
    .applicants-name-job{
        text-align: center;
    }
    .applicants-card-header{
        padding-left: 0;
    }
    .applicants-profile-img{
        margin-right: 15px;
    }
    .applicant-profile-first-section{
        flex-direction: column;
    }
    .new-jobs-list{
        padding: 1.5rem;
    }
    .hiredemployer-section{
        flex-direction: column;
    }
    .hiredemployer-text{
        width: 100%;
    }
    .hiredemployer-img{
        margin: 0 auto;
    }
    .hire-employer-breadcumbs{
        align-items: center;
        justify-content: center;
    }
    .transaction-table-card-filter{
        flex-direction: column;
    }
    .transaction-table-card-filter-left {
        align-items: center;
        margin-bottom: 20px;
    }
    .employer-sidebar{
        display: block;
        position: fixed;
        width: 100% !important;
        top: 0;
    height: 100vh;
    z-index: 111;
    }
    .transaction-table-card{
        width: 95%;
    }
    .tab-worker-filter-section {
        margin: 15px 0 25px;
    }
    .current-hired-staff-list-header h4{
        font-size: 15px;
        margin-bottom: 0;
    }
    .current-hired-staff-list-header .custom-btn-style{
margin: 0 !important;
font-size: 14px;
    }

    .employer-banner-bg-header h2 {
        font-size: 18px;
    }
    .employer-banner-bg-header p {
        margin: 15px 0;
    }
    .employer-banner-bg-footer-links {
        font-size: 11px;
    }
    .employer-dashboard-main{
        width: 100%;
    }
    .tablet-menu-button{
        display: block;
    }
    .header {
        height: 120px !important;
    }
    .find-job .search-bar{
        width: 50% !important;
    }
    .header .navbar-expand-md{
        flex-direction: column;
    }
    .find-job-body .contents {
flex-direction: column;
    }
    .find-job-body .find-job-left-nav,.find-job-body .find-job-right-content {
        width: 100% !important;
    }
    .find-job-body .find-job-left-nav .white-bg{
        margin-bottom: 15px;
    }
    .find-job-body .find-job-left-nav{
        padding-right: 0 !important;
    }
    .find_job{
        width: 95%;
    }
    .jobseeker .footer-area,.task-container-sass .footer-area,.employer-footer .footer-area{
        width: 35%;
    }
    .employer-banner-bg-body input{
        font-size: 11px;
    }
    .body-sec{
        width: 95% !important;
    }
    .user-preview-left-header {
        width: 60% !important;
    }
    .up-user-name {
        width: 100% !important;
    }
    .recruitment-process-section-full-width {
        background: #D4D4D4;
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
    }
    .recruitment-process-section-half-width {
        width: 100%;
        height: 200px;
        background: url(../images/enterprise/enterpriseleftbg.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .recruitment-process-section-right-width {
        width: 100%;
    }
    .turnkey-system-section-content{
        flex-direction: column;
    }
    .turnkey-system-card::after {
        content: '';
        background: url(../images/enterprise/turnkeycardbgpng.png);
        height: 146px;
        width: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        position: absolute;
        bottom: 0;
        z-index: 0;
    }
    .turnkey-system-card {
        background: #FFFFFF;
        box-shadow: 0px 0px 9px rgb(0 0 0 / 25%);
        border-radius: 8px;
        height: 250px;
    }
    .turnkey-system-card-content h5 {
        font-weight: 900;
        font-size: 14px;
    }
    .turnkey-system-card-content p {
        font-size: 10px;
    }
    .privacy-bg {
        height: auto;
        background: url(../images/enterprise/privacybg.jpg);
        background-size: cover;
    }
}
@media (max-width:767px){
    .hiredemployer-section{
        flex-direction: column;
    }
    .hiredemployer-text{
        width: 100%;
    }
    .hiredemployer-img{
        margin: 0 auto;
    }
    .hire-employer-breadcumbs{
        align-items: center;
        justify-content: center;
    }
    .hired-text-links{
        flex-direction: column;
    }
    .hired-text-link1{
        width: 100%;
        margin-right: 0;
        margin-bottom: 15px;
    }
    .link1-span{
        margin-bottom: 0;
    }
    .circled-image{
        width: 11rem;
    }
    .tablet-menu-button{
        display: block;
    }
    .employer-sidebar{
        display: block;
        position: fixed;
        width: 100% !important;
        top: 0;
    height: 100vh;
    z-index: 111;
    }
    .employer-dashboard-main{
        width: 100% !important;
    }
    .employer-dashboard-main .row-eq-height{
        height: auto !important;
    }
    .employer-banner-bg{
        height: 530px !important;
    }
    .hired-staff-banner{
        height: 300px !important;
    }
    .hired-staff-search{
        width: 90%;
    }
    .jobseeker .dashboard-body-sec{
        width: 100%;
    }
    .worker-filter-section{
        margin-bottom: 25px;
    }
    .job-seeker-search1 {
        width: 95% !important;
        left: 8px !important;
        top: unset;
    }
    .invoice-module .invoice-module-menus{
        width: 100% !important;
        margin-bottom: 20px;
    }
    .body-sec {
        width: 90%;
    }
    .find-staff-icon-section{
        flex-direction: column;
    }
    .find-staff-one,.find-staff-two{
        width: 100% !important;
    }
    .applicant-profile-details{
        flex-direction: column;
    }
    .applicant-profile-first-section{
        flex-direction: column;
    }
    .applicant-profile-salary-audio-section{
        flex-direction: column;
        align-items: flex-start;
    }
    .applicant-profile-audio audio{
        width: 100%;
    }
    .transaction-page-banner{
        height: 300px;
    }
    .my-jobs-card-header form{
        flex-direction: column;
    }
    .my-jobs-card-header form .form-control{
        width: 100% !important;
    }
    .view-applicants-banner-text h1{
        text-align: center;
        line-height: 65px;
    }
    .applicants-card-exp-sec{
        flex-direction: column;
    }
    .applicants-exp{
        width: 100%;
    }
    .applicants-card-header{
        flex-direction: column;
    align-items: flex-start;
    padding: 0 15px;
    }
    .salary-rate p{
        border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    margin-left: 0;
    }
    .applicants-page-main {
        padding: 0;
        width: 100%;
    }
    .applicants-card{
        width: auto;
    }
    .applicant-profile-location-details {
        width: 100%;
    }
    .applicant-profile-download-section {
        display: flex;
    }
    .view-applicants-banner{
        height: 300px !important;
    }
    .view-applicants-banner-text p{
        text-align: center;
    }
    .invoice-module .nav{
        flex-wrap: wrap !important;
    }
    .jobseeker .font-h1 {
        font-size: 24px !important;
    }
    .jobseeker .h2 {
        font-size: 18px;
    }
    .jobseeker .custom-link-text {
        font-size: 15px;
    }
    .jobseeker .db-widgets-box {
        padding: 1.5rem;
    }
    .find-job .banner{
        text-align: center;
    }
    .find-job .search-bar{
        width: 95% !important;
    }
    .find-job-body .find-job-left-nav,.find-job-body .find-job-right-content{
        width: 100% !important;
        padding: 0 !important;
    }
    .jobseeker .body-sec {
        width: 90%;
    }
    .user-preview-header{
        flex-direction: column;
    }
    .user-preview-left-header{
        width: 100% !important;
        flex-direction: column;
    }
    /* .up-user-image::after{
        display: none;
    } */
    .find-job-body .find-job-left-nav .white-bg{
        margin-bottom: 30px;
    }
    .find_job .custom-tab-flex{
        flex-direction: column;
    }
    .find-job-p {
        width: 100% !important;
    }
    .find-job-sub-data .d-flex{
        flex-direction: column;
    }
    .find-job-body .contents{
        flex-direction: column;
    }
    .jobseeker .tab-side-nav{
        display: block;
        position: fixed;
        width: 100%;
        top: 0;
    height: 100vh;
    z-index: 111;
    }
    .login-page-card .contactus-left-card{
        top: 0;
        margin-bottom: 50px;
        width: 100%;
    }
    .signup-whole-card{
        flex-direction: column;
    }
    .loginbg{
        padding-top: 0;
        background-size: cover;
    }
    .turnkey-system-card::after{
        height: 143px;
        background-size: cover;
    }
    .turnkey-system-card-content p{
        font-size: 13px;
        width: 95%;
    }
    .turnkey-system-card-content{
        top: -11px;
    }
    .recruitment-process-section-half-width h3{
        font-size: 16px;
    }
    .header {
        height: auto !important;
    }
    .new-enterprise-banner-page {
        background: url(../images/enterprise/enterprisebannerbg.jpg);
        background-repeat: no-repeat;
        height: 180px;
        background-size: cover;
    }
    .login-li{
        border: 0;
    }
    .signup-li{
        padding-left: 0;
    }
    .jobseeker .header #collapsibleNavbar{
        display: flex;
        justify-content: center;
    }
    .employer-banner-bg-body input{
        font-size: 10px;
    }
    .header #collapsibleNavbar{
        /* display: flex; */
        justify-content: center;
    }
    .jobseeker .header .navbar-right{
        flex-direction: row;
    }
    .header .navbar-right{
        flex-direction: row;
    }
    .new-enterprise-banner-text h2 {
        font-size: 19px;
        line-height: normal;
    }
    .new-enterprise-banner-text p {
        font-weight: bold;
        font-size: 13px;
        line-height: 15px;
        font-family: "Lato-Bold";
        color: #634F4F;
        margin-top: 0px;
        width: 60%;
    }
    .recruitment-process-section-full-width {
        background: #D4D4D4;
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
    }
    .recruitment-process-section-half-width {
        width: 100%;
        height: 200px;
        background: url(../images/enterprise/enterpriseleftbg.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .recruitment-process-section-right-width {
        width: 100%;
    }
    .turnkey-system-section-content{
        flex-direction: column;
    }
    .recruitment-process-section-right-card {
        width: 90%;
        height: auto;
        background: #FFFFFF;
        box-shadow: 0px 0px 10px rgb(0 0 0 / 25%);
        border-radius: 70px;
        display: flex;
        align-items: center;
        margin-bottom: 30px;
        flex-direction: column;
        padding: 20px 0;
    }
    .recruitment-process-right-card-text {
        width: calc( 100% - 77px);
        padding: 25px 33px 25px 25px;
        position: relative;
        min-height: auto;
    }
    .recruitment-process-right-card-text::before {
        content: '';
        background: #C4C4C4;
        width: 300px;
        height: 1px;
        position: absolute;
        top: 14px;
        left: 0;
    }
    .turnkey-system-card{
        height: auto;
        width: 100%;
        margin-right: 0;
    }
    .turnkey-system-section-heading{
        width: 100%;
        margin-right: 0;
    }
    .privacy-bg-card {
        background: #FFFFFF;
        box-shadow: 0px 0px 8px rgb(0 0 0 / 40%);
        border-radius: 5px;
        width: 100%;
        padding: 15px;
        border-left: 5px solid #B32B7F;
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-bottom: 35px;
    }
    .privacy-bg{
        height: auto;
        background-size: cover;
    }
    .privacy-bg-main {
        width: 100%;
    }
    .privacy-bg-card-icon {
        margin-bottom: 20px;
    }
    .get-pricing-btn {
    margin-bottom: 50px;
    }
    .footer-area{
        flex-direction: column;
    }
    .footer-area-single-section {
        padding: 15px 0;
    }
    .footer2-area-content {
        align-items: center;
        flex-direction: column;
    }
    .footer2-area-left-text{
        margin-bottom: 20px;
        display: flex;
        flex-direction: column;
        text-align: center;
    }
    .footer2-area-left-text a{
        margin-left: 10px;
        color: white;
        font-size: 16px;
        text-decoration: none;
      }
    .footer2-area {
       padding: 20px 0;
        height: auto;
    }
}
@media (max-width:375px) and (min-width:320px) {
    .recruitment-process-right-card-text::before{
        width: 220px;
    }
}
ul{
    list-style: none;
    padding: 0;
}


/* 08/03/22 */

@media only screen and (min-width:1200px){
    /* footer area */
    .footer-area{
        width:60%;
        margin: auto;
    }
}
@media only screen and (min-width:768px) and (max-width:1199px){
    /* footer area */
    .footer-area{
        width:70% !important;
        margin: auto;
    }
}
@media only screen and (max-width:767px){
      /* footer area */
      .footer-area{
        flex-direction: row !important;
        flex-wrap: wrap;
        width: 100%;
    }
    .footer-area .footer-area-single-section{
        width: 50%;
    }
    .footer2-area-content{
        width: 90%;
        margin: auto;
    }
    .footer2-area-left-text {
        display: flex;
        flex-direction: column;
        text-align: center;
    }
    .footer2-area-left-text p,.footer2-area-left-text a{
        margin-bottom: 5px;
    }
    .footer2-area-left-text p:after{
        display: none;
    }
    /* employer/postAJOb  */
    .postajob-banner .pricing-banner-content h1{
        width: 100%;
        font-size: 30px;
    }
    .pricing-banner-content p {
        font-size: 16px;
    }
   
    header.employer_header {
        position: fixed;
        top: 0;
        z-index:999;
    }
    nav.navbar.navbar-expand-md{
        justify-content: center !important;
    }
    section.postajob-banner.transaction-page-banner.tools-page-banner.pricing-page-banner{
        margin-top: 60px;
        padding: 10px;
    }
    .hired-staff-search{
        bottom: -30px !important;
    }
    section.post-a-job.transaction-history-card .nav-link i{
        margin-right:.5rem !important
    }
    /* job-list-form */
    .job-list-form .form-group{
        width: 100%;
    }
    .my-jobs-card-content h1{
        font-size: 24px;
    }
    /* employer-dashboard */
    .employer-dashboard-main{
        margin-top: 60px;
    }
    /* unverified */
    .unverified-page{
        margin-top: 60px;
        padding: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    /* two step verification */
    .two-step-verification{
        margin-top: 80px;
        padding: unset !important;
    }
    .two-step-verification ul.nav.nav-pills li{
        width: 100%;
        text-align: center;
    }
    .two-step-verification .tab-content h3,.two-step-verification .tab-content h2{
        font-size: 24px !important;
    }
    .two-step-verification button.btn.btn-primary.btn-lg.mt-4{
        margin: 20px auto !important;
    }
    .view-applicants-banner-text h1{
        font-size: 2rem !important;
    }
    /* forgot-page,login-pge */
    .forgot-page,.loginbg{
        padding: 10px !important;
    }
    .forgot-page h2,.loginbg h2{
        font-size: 24px !important;
    }
    .forgot-page p,.loginbg p{
        font-size: 16px !important;
        line-height: 24px !important;
    }
    /* job-seeker dashboard */
    .jobseeker_header .nav-item{
        margin: 5px !important;
    }
    #after{
        display: none !important;
    }
    .job-seeker-search .search-bar.d-flex{
        flex-direction: column;
    }
    .job-seeker-search .search-bar.d-flex button{
        width: 100% !important;
    }
    header.jobseeker_header nav.navbar.navbar-expand-md{
        justify-content: space-between !important;
    }
    .job-dash table{
        display: table !important;
    }
    .job-dash thead th{
        color: #fff  !important;
    }
    .job-dash tbody,.job-dash thead{
        display: table-row-group !important;
    }
    .job-dash  thead tr{
        position: unset !important;
       
    }
    .job-dash .hired-staff-table-data th{
        color: #fff !important;
    }
    .job-dash  tr{
        overflow: auto;
        display: unset !important;
        display: table-row !important;
    }
    .job-dash  td, .job-dash  th{
       width: unset !important;
       display: table-cell !important;

    }
    .job-dash  td::before{
        background:transparent !important;
    }
    .job-dash .db-widgets-applicant-status h2{
        font-size: 20px;
    }
    .job-dash .d-flex.justify-content-between.align-items-center h2::after{
        display: none;
    }
    .job-dash .d-flex.justify-content-between.align-items-center{
        flex-direction: column;
    }
    .job-dash .btns{
        flex-direction: column;
    }
    .job-dash .failure-btn{
        margin-top: 5px;
    }
    .find-job-jobseeker-body .paination_link{
        width: 250px !important;
        overflow: auto;
    }
    .find-job-jobseeker-body .find_job h2 {
        font-size: 20px !important;
    }
    .find-job-jobseeker-body .job-skills{
        overflow: auto !important;
        text-overflow: unset !important;
    }
    /* learn-how-it works */
    .learn-how-it-works-alert{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
   .learn-how-it-works-alert p{
       width: 100% !important;
       text-align: justify;
   }
   .learn-how-it-works-alert .d-flex.align-items-center{
       justify-content: center;
       margin-top: 10px;
   }
   /* applied jobs */
   .applied-jobs .job-description-title.d-flex.justify-content-between{
       flex-direction: column;
   }
   .applied-jobs .job-description-title.d-flex.justify-content-between h2{
       border-bottom: 0 !important;
       margin-bottom: 5px;
   }
   .applied-jobs-pagination{
       width: 250px !important;
       margin: auto;
   }
   .applied-jobs-pagination ul.paination_link{
       width: 100%;
   }
   .applied-jobs .d-flex.jobs-section{
       width: 100%;
       margin: unset !important;
   }
   /* view-all-scheduled-events */
   .scheduled-events .table{
       display: table;
   }
   .scheduled-events .jobs-section{
       width:100% !important;
       margin: unset !important;
    
   }
   .scheduled-events .table tbody,.scheduled-events .table thead{
       display: table-row-group;
   }
   .scheduled-events .table tr{
       display: table-row;
   }
   .scheduled-events .table td,.scheduled-events .table th{
       display: table-cell;
       /* width: 200px !important; */
       min-width: 100px !important;
   }
   .scheduled-events td::before{
       content: '' !important;
       width: unset !important;
       padding: unset !important;
       background: transparent !important;
   }
   .scheduled-events thead tr{
       position: unset !important;
   }
   .interview-confirm{
       width: 100% !important;
   }
   .interview-confirm p.time.text-center {
       width: 100% !important;
       margin-left: 0 !important;
   }
   .schedule_interview_details.mt-4{
       width: 100% !important;
       margin:unset !important
   }
   .interview-confirm h1.text-center{
       font-size: 20px !important;
       margin-bottom: 20px;
   }
   .interview-confirm p{
       margin: 40px auto !important;
   }
   /* jobdescription */
   .job-description .job-seeker-row-section{
       flex-direction: column;
   }
   .job-description .job-seeker-row-section>div{
       border-right: 0;
       border-bottom: 1px;
   }
   .job-description .job-seeker-row-section>div{
       flex-direction: row;
   }
   .job-description .job-seeker-row-section{
       width: 100%;
      
   }
   .job-description  .job-seeker-job-offer-section{
       width: 100% !important;
   }
   .job-description .job-seeker-row-section h3{
       margin: unset;
       font-size: 16px;
       width: 50%;
   }
   .job-description .job-seeker-row-section p{
       font-size: 16px;
   }
   .job-description section.banner.d-flex.flex-column h1{
       font-size: 24px;
   }
   .job-description .applyjob-section{
       width: 100% !important;
       justify-content: center;
   }
   /* saved jobs */
   .saved-jobs .job-description-title.d-flex.justify-content-between.mb-3 h2{
       border: none !important;
       font-size: 18px;
   }
   .saved-jobs .job-description-title.d-flex.justify-content-between.mb-3{
       flex-direction: column;
   }
   .view-applicants-banner{
       height: 100px !important;
   }
   /* job offfer */
   .dashboard-body-sec.scheduled-events.job-offer select{
       margin: 10px auto;
   }
   .job-offer{
       min-height: unset !important;
   }
   /* view job */
   .job-offer-card-bg{
       flex-direction: column-reverse;
   }
   .job-offer-card-right-image {
    width: 100px;
    margin: 10px auto;
    height: 100px;
    border-radius: 50%;
}
.job-offer-card-right-image .img-fluid {
   
    border-radius: 50%;
}
.job-offer-card-right {
    width: 100% !important;
}

.job-offer-card-right-bottom{
    text-align: left !important;
}
.job-offer-card-right-bottom .active-box {
    margin: 10px 0;
}
.job-offer-btn-section .btn{
    width: auto !important;
    margin: 5px !important;
}
.job-offer-card-left{
    width: 100% !important;
    border: 0 !important;
}
.job-offer-card-left-content span{
    font-size: 16px !important;
    /* white-space: nowrap; */
}
.job-offer-card-left-content label{
    font-size: 16px !important;
    line-height: 22px !important;
    width: 50%;
}
.job-offer-banner{
    height: 150px !important;
}
.job-offer-banner h1{
    font-size: 24px !important;
}
.view-job p{
    font-size: 16px;
}
.view-job .job-offer-card-head-title{
    font-size: 20px !important;
}
/* chat */
.chat-container{
    flex-direction: column !important;
    padding: 15px;
}
.chat-container h1.font-weight-lighter {
    font-size: 20px;
}
.chat-container .chat-sidebar {
   
    max-width: 70%;
    width: 70%;
    margin: auto;
}
/* task-management/job/dash */
.head-task-job-dash{
    flex-direction: column;
}
.head-task-job-dash .hyphen{
    display: none !important;
}
.head-task-job-dash img{
    width: auto;
    margin: 10px auto;
}
.task-job-dash section{
    padding: 15px !important;
}
.task-job-dash .dashboard .name-text{
    font-size: 24px !important;
}
.task-job-dash p{
    width: 95% !important;
}
.task-footer{
    padding: 10px !important;
}
/* jobseeker/notificationsettings */
.notification-settings h3{
    font-size: 18px !important;
    
}
/* jobseeker/user-profile/preview */
.job-user-preview .up-user-image-preview::after{
    display: none !important; 
}
.user-preview-left-header{
    flex-direction: column;
}
.job-user-preview .up-user-image-preview{
    width: 100% !important;
}
.job-user-preview .up-user-name{
    width: 100% !important;
    border: 0 !important;
    padding: 0 !important;
}
.job-user-preview .user-preview-right-header{
    width: 100% !important;
    margin: 10px 0;
    
}
.job-user-preview .user-preview-right-header .d-flex.profile-visibility-div{
    justify-content: flex-start;

}
.job-user-preview .profile-visibility-div > div:nth-child(1){
    text-align: left !important;
    width: 70%;
}
.job-user-preview .user-preview-right-header a{
    margin: 10px 0 !important;
}
.job-user-preview .img-wrapper{
    margin: 10px auto;
}
.public-preview h1,.public-preview h2{
    font-size: 20px !important;
}
/* .user-preview-user-title h1{
    font-size: 20px !important;
} */
/* update-rate modal */
.update-rate-modal{
    width: 80% !important;
    min-width: 80% !important;
}
.update-rate-modal .modal-body{
    padding: 10px !important;
}
.update-rate-modal .rate-box{
    flex-direction: column !important;
}
.update-rate-modal .rate .rate-price .input-group {
    width: 80%;
    float: unset !important;
    margin: auto;

}
.update-rate-modal .job_type_select{
    margin-right: 5px;
    margin-top: 5px;
}
.public-preview .up-user-name{
    width: 100% !important;
    border: 0 !important;
}
/* ------------------------Employer-15/03/22-------------------------------------- */

/* employer--learn-how-it-works */
header.employer_header .learn-wrapper{
    flex-direction: column ;
}
header.employer_header .close-icon{
    display: flex;
   width: 100% !important;
   justify-content: start;
}
header.employer_header .learn-how-it-works-alert p{
   width: 100% !important;
   text-align: justify;
}
header.employer_header .learn-content{
    flex-direction: column;
    justify-content: center !important;
    align-items: center !important;
}
header.employer_header .learn-btn{
    margin-top: 10px;
}
.employer-dashboard-main table{
 display: table;
}
.employer-dashboard-main .table tbody,.employer-dashboard-main .table thead{
    display: table-row-group;
}
.employer-dashboard-main .table thead{
    color: #fff !important;
}
.employer-dashboard-main .table tr{
    display: table-row;
}
.employer-dashboard-main .table td,.employer-dashboard-main .table th{
    display: table-cell;
    /* width: 200px !important; */
    min-width: 100px !important;
}
.employer-dashboard-main td::before{
    content: '' !important;
    width: unset !important;
    padding: unset !important;
    background: transparent !important;
}
.employer-dashboard-main thead tr{
    position: unset !important;
}
.employer-dashboard-main .employer-banner-bg.white-bg-card{
    height: unset !important;
    min-height: 400px !important;
    padding: unset !important;
}
.employer-dashboard-main .employer-banner-bg-content{
    position: relative !important;
}
.employer-dashboard-main .employer-banner-bg-header h2{
    font-size: 20px !important;
}
.employer-dashboard-main .employer-banner-bg-footer-links{
    margin: 5px !important;
}
.staff-list-header{
    flex-direction: column;
}
.staff-list-header h4{
    font-size: 20px ;
}
/* employer post a job */
.employer-postajob{
    height: unset !important;
}
.employer-postajob h1{
    font-size: 20px !important;
    line-height: 30px !important;
}
/* applicant */
.applicant-profile-banner{
    height: 100px !important;
}
.applicant-page .applicant-profile-pic{
    width: 150px !important;
    height: 150px !important;
    margin: 0 auto !important;
}
.applicant-page .applicant-profile-image{
    margin: auto !important;
}
.applicant-page .applicant-profile-job-section{
    width: 100%;
}
.applicant-page .applicant-profile-job-section .applicant-profile-job-title{
    font-size: 20px !important;
}
.applicant-page .applicant-profile-job-title-area{
    justify-content: space-between;
}
.applicant-page .applicant-profile-job-details {
    width: 100% !important;
}
.applicant-page .applicant-profile-download-section{
    flex-direction: column;
}
.applicant-page .applicant-profile-download-section .btn,.applicant-profile-download-section a{
    /* margin: 5px auto !important; */
}
.applicant-page-skills .table{
    display: table;
}
.applicant-page-skills .table tbody,.applicant-page-skills .table thead{
    display: table-row-group;
}
.applicant-page-skills .table thead{
    color: #fff !important;
}
.applicant-page-skills .table tr{
    display: table-row;
}
.applicant-page-skills .table td,.applicant-page-skills .table th{
    display: table-cell;
    /* width: 200px !important; */
    min-width: 200px !important;
}
.applicant-page-skills td::before{
    content: '' !important;
    width: unset !important;
    padding: unset !important;
    background: transparent !important;
}
.applicant-page-skills thead tr{
    position: unset !important;
}
.applicant-page-skills .table th{
    color: #fff !important;
}
.applicant-page-skills .applicant-details-single-content{
    padding: unset !important;
}
.applicant-page-skills .applicant-details-single-content table > thead > tr > th{
    color: #fff !important;
}
.applicant-details-single-tags{
    padding: 10px !important;
    overflow: auto !important;
}
.applicant-profile-salary > label{
    font-size: 16px !important;
}
.applicant-page .employment-type-text{
    font-size: 16px !important;
}
.applicant-page .applicant-details-single-title,.applicant-page-skills .applicant-details-single-title {
    font-size: 20px !important;
}
.schedule-interview-modal{
    width: 70% !important;
    margin: auto !important;
    position: absolute !important;
}
/* virtual-staff-list */
.virtual-staff-list{
    margin-top: 60px !important;
    height: unset !important;
}
.virtual-staff-list .view-applicants-banner-text{
    font-size: 20px !important;
}
.virtual-staff-list .view-applicants-banner{
    height: unset !important;
}
.virtual-staff-list .table{
    display: table;
}
.virtual-staff-list .table tbody,.virtual-staff-list .table thead{
    display: table-row-group;
}
.virtual-staff-list .table thead{
    color: #fff !important;
}
.virtual-staff-list .table tr{
    display: table-row;
}
.virtual-staff-list .table td,.virtual-staff-list .table th{
    display: table-cell;
    /* width: 200px !important; */
    min-width: 200px !important;
}
.virtual-staff-list td::before{
    content: '' !important;
    width: unset !important;
    padding: unset !important;
    background: transparent !important;
}
.virtual-staff-list thead tr{
    position: unset !important;
}
.virtual-staff-list .table th{
    color: #fff !important;
}
.virtual-staff-list .new-jobs-list.white-bg{
    padding: 20px !important;
}
/*employer job list */

.employer-posted-job-list-page .job-list-right button{
    margin: 5px 0;
    padding: 5px !important;
}
.employer-posted-job-list-page .job-list{
    flex-direction: column;
}
.employer-posted-job-list-page .job-list-left{
    padding: 5px 0;
    width: 100% !important;
}
.employer-posted-job-list-page .job-list-right{
    width: 100% !important;
}
.employer-posted-job-list-page .my-jobs-section{
    padding: 15px !important;
}
/* view all applicants */
.employer-view-applicants-page{
    margin-top: 60px !important;
}
.employer-view-applicants-page .view-applicants-banner{
    height: unset !important;
}
.employer-view-applicants-page .my-jobs-card-header .form-group{
    width: auto !important;
}
.employer-view-applicants-page .my-jobs-card-header .search-btn.login-btn-section{
    width: auto !important;
}
.employer-view-applicants-page .row.row-eq-height{
    padding: 10px !important;
}
.employer-view-applicants-page .row.row-eq-height .col-lg-4{
    margin: 10px 0 !important;
}
.employer-view-applicants-page .applicants-card{
    height: 100% !important;
}
.employer-view-applicants-page .applicants-profile-img {
    width: 100px;
    height: 100px;
    margin: auto;
}
.employer-view-applicants-page p{
    text-align: unset !important;
}
/* send-job-offer */
.send-job-offer{
    margin-top: 60px;
    padding: unset !important;
}
.send-job-offer .container{
    width: 100%;
}
.send-job-offer .date-sec{
    flex-direction: column;
}
.send-job-offer .date-sec>div{
    width: 100% !important;
}
.send-job-offer .applicant-profile-main{
    width: 100% !important;
}
.send-job-offer .joboffer-input:disabled{
    width: 1.5rem !important;
}
/* virtual-coming-soon */
.virtual-coming-soon{
    padding: 10px;
}
.virtual-coming-soon .row{
    margin: unset !important;
}
.virtual-coming-soon-banner{
    height: unset !important;
}
.virtual-coming-soon-banner h1{
    font-size: 24px !important;
}
/* employer-enterprise */
.employer-enterprise{
    margin-top: 60px !important;
    display: flex;
    flex-direction: column;
}
.employer-enterprise>div{
    width: 100% !important;
    height: unset !important;
    padding: 20px !important;
    
}
.employer-enterprise>div h2{
    font-size: 24px !important;
}
.enterprise-row div{
    margin-bottom: 10px !important;
}
.employer-enterprise-first .internal-recruitment-card{
    height: unset !important;
}
.enterprise-faq-section h3{
    font-size: 24px !important;
}
.enterprise-how-started{
    height: unset !important;
}
.enterprise-how-started h3{
    font-size: 24px !important;
}
.enterprise-how-started .get-pricing-btn a{
    font-size: 16px;
    margin: auto;
}
.enterprise-privacy-img{
    margin: auto !important;
}
/* employer-how it works */
section.how-it-works-jobseeker-banner.how-it-works-employer-banner{
    background-size: cover !important;
}
.how-it-works-employer-banner h2{
    font-size: 24px !important;
    line-height: 30px !important;
}
.how-it-works-employer-banner p{
    font-size: 16px !important;
    line-height: 25px !important;
}
.emp-how-it-works-sec{
    flex-direction: column;
}
.emp-how-it-works-sec::after{
    display: none !important;
}
.emp-how-it-works-sec > div{
    width: 100% !important;
}
section.pricing-section.emp-how-it-works-pricing{
    flex-direction: column !important;
}
section.pricing-section.emp-how-it-works-pricing>div{
    width: 100% !important;
}
.emp-how-it-works-hiring .sorts-of-jobs-hiring-whole{
    flex-direction: column !important;
}
.emp-how-it-works-hiring .sorts-of-jobs-hiring-whole>div{
    width: 100% !important;
}
.how-it-works-faq .card-header {
   
    position: relative;
}
.how-it-works-faq .card-header a::after{
    position: absolute;
    right: 0px;
}
}

@media only screen and (max-width:1024px){
   /* temprorary sidebar css-11/03/22 */
    .tab-side-nav{
        display: none !important;
    }
    /* view-job */
    .job-offer-card-right-top{
        padding: 10px !important;
    }
    .job-offer-card-right-bottom {
        padding: 10px !important;
    }
    /* job-user-preview */
    .job-user-preview .up-user-image-preview{
       padding: 10px;
    }
    .job-user-preview .user-preview-left-header{
        width: 100% !important;
    }
    .user-preview-page{
        padding: 15px !important;
    }
    /* expertise level modal */
    #expertiselevelModal .div1,#expertiselevelModal .div2,#expertiselevelModal .div3{
        margin-bottom: 10px;
       
    }
    /* delete modal */
    #delete .modal-dialog{
        min-width:60% !important;
        margin: auto;
      }
      /* ----------------------employer/15/03/22------------------------------ */
      .side-nav.employer-sidebar{
        width: 250px !important;
    height: 100vh;
    transition: all .2s;
    position: relative;
    left: 0;
    }
    /* .side-nav.employer-sidebar{
        display: none;
    } */
    .toggle-menu{
        position: fixed;
        top: 20px;
        right: 20px;
        /* z-index: 1111 !important; */
    }
    

}
@media only screen and (max-width:576px){
    .job-description .job-seeker-row-section>div{
        flex-direction:column !important;
    }
    .job-description .job-seeker-row-section h3{
        width: 100% !important;
    }
    /* update-rate modal */
.update-rate-modal{
    width: 95% !important;
    min-width: 95% !important;
}
.update-rate-modal .modal-body{
    padding: 10px !important;
}
/* delete modal */
#delete .modal-dialog{
    min-width:90% !important;
    margin: auto;
  }
}

/* toggle-menu employer */
 @media only screen and (min-width:1024px){
.toggle-menu{
    display: none;
}
} 
@media only screen and (min-width:768px){
    .toggle-menu.d-flex.justify-content-end{
        display: none !important;
    }
}
/* 17-03-2022 */
@media (max-width:767px) and (min-width:320px) {
.custom-radio-btn-salary.flwrp{
    flex-wrap: wrap !important;
}

.post-a-job .tab-content .tab-pane .custom-filter-section.flwrp{
    flex-wrap: wrap !important;
}
.custom-checkbox-design.daysflex{
    display: block !important;
}
.dayalign .custom-checkbox-design .quiz_card_area.daysflex {
    display: flex !important;
    flex-wrap: wrap !important;
}
.dayalign .custom-checkbox-design .quiz_card_area{
width: 100% !important;
}
.stepfont-14{
    font-size: 14px !important;
    line-height: 16px !important;
}
.width-100{
    width: 100% !important;
}
.post-a-job .tab-content .tab-pane .post-a-job-questions{
    margin: 0% !important;
}
.post-a-job .tab-content .tab-pane .tabwork .post-a-job-questions {
    padding: 0 0px 0px !important;
}
.questions-options2-section .custom-filter-section1 input {
    width: 100% !important;
}
.addmoretxt{
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    margin-bottom: 20px;
}
.addmore{
    display: flex;
    justify-content: space-around;
    width: 100% !important;
     margin-left: 0% !important;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 80px !important;
}
.btm-12.questions-options2-section select {
   
    margin-bottom: 12px !important;
}

.btm-12.transaction-table-card-filter-right-btn .action1 {
    margin-top: 15px !important;
}
.questions-options-section.tabpad-0{
    padding:0 0px !important;
}
.btm-12.questions-options2-section {
    display: block;

}
.topm-12{
    margin-top: 12px !important;
}
.reviewwidth.review-page .custom-filter-section {
    width: 100% !important;

}
.postajob-banner .pricing-banner-content h1 {
    width: 100%;
    font-size: 22px !important;
    line-height: 26px !important;
}
.find-job-jobseeker-body .job-skills {
    overflow: auto !important;
    text-overflow: clip;
    white-space: nowrap !important;
}
.find-job-jobseeker-body .paination_link {
    white-space: nowrap !important;
    width: 100% !important;
    overflow: hidden !important;
}

.job-offer-card-content-section ul li{
    font-size: 14px !important;
    list-style: disc;
}
.table thead tr th {
    color: white;
    text-align: center;
    white-space: nowrap;
}
.chat-container .chat-sidebar {
    max-width: 100% !important;
    width: 100% !important;
   
}
.makeStyles-roots-6 {
    width: 100% !important;
  
  }
  .titlefont{
      font-size: 22px !important;
  }
  .manage-account .form-group-right {
    padding-left: 5px !important;
}
.manage-account .form-group-left {
    padding-right: 5px !important;
}
.billheader.billings-body-header {
    display: flex;
    justify-content: space-between;
    padding: 26px;
    flex-wrap: wrap;
}
.flexdirection.navbar-nav {
    display: flex;
    flex-direction: initial;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}
.transaction-table table thead tr {
    position: relative !important;
    top: 0 !important;
    left: 0 !important;
}
.transaction-table td::before{
    content: '' !important;
    width: unset !important;
    padding: unset !important;
    background: transparent !important;
}
.transaction-table {
    padding: 0px !important;
}
.invoice-receipt.invoicemrg{
    margin: 0px !important;
}
.receipt-table td::before{
    content: '' !important;
    width: unset !important;
    padding: unset !important;
    background: transparent !important;
}
.receipt-table > tr:nth-child(2) > td:nth-child(1) {
    width: 50%;
    padding: 10px;
    border-right: 1pt solid #aaa;
    border-bottom: 1pt solid #aaa;
    display: inline-block;
}
.receipt-table > tr:nth-child(2) > td:nth-child(2) {
    width: 50%;
    text-align: end;
    padding: 10px;
    border-bottom: 1pt solid #aaa;
    display: inline-block;
}
.receipt-table > tr:nth-child(3) > td:nth-child(1) {
    width: 50%;
    padding: 4px;
    border-right: 1pt solid #aaa;
    border-bottom: 1pt solid #aaa;
    display: inline-block;
}
.receipt-table > tr:nth-child(3) > td:nth-child(2) {
    width: 50%;
    text-align: end;
    padding: 4px;
    border-bottom: 1pt solid #aaa;
    display: inline-block;
}
.receipt-table > tr:nth-child(4) > td:nth-child(1) {
    width: 50%;
    padding: 4px;
    border-right: 1pt solid #aaa;
    border-bottom: 1pt solid #aaa;
    display: inline-block;
}
.receipt-table > tr:nth-child(4) > td:nth-child(2) {
    width: 50%;
    text-align: end;
    display: inline-block;
    padding: 4px;
    border-bottom: 1pt solid #aaa;
}
.receiptfooter{
    text-align-last: start !important;

}
.invoice-receipt .invoicemrg {
    margin: 0px !important;
}
.post-a-job .transaction-table-card-filter {
    padding: 0;
    margin-top: 12px !important;
}
.review-job-card-header .view-applicants-section.flexwrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap !important;
    flex-direction: initial !important;
}
.review-job-card-header.flexwrap {
    flex-wrap: wrap !important;
}
.payment-options-card-content.boxpadding{
    padding: 2px !important;
}
.card-div.flexwrap{
    flex-wrap:wrap !important ;
}

.job-description section.banner.d-flex.flex-column h1 {
    font-size: 16px !important;
    text-align: center !important;
}
.find-job .banner h1 {
   
    font-size: 16px !important;
    font-weight: 800 !important;
}
.find-job-para {
    font-size: 14px !important;
}
.applicant-details-single-tags.ardchip {
    padding: 2px !important;
    overflow: auto !important;
}

.search-workers-top p, .applicant-details-single-tags.cardchip .chip-container {
    width: auto;
    font-family: 'Lato-Regular';
    font-style: normal;
    line-height: 18px !important;
    background-color: #aaa;
    color: white;
    padding: 6px !important;
    border-radius: 8px;
    font-size: 12px;
    display: inline-block;
    align-items: center;
    margin-bottom: 0px !important;
    margin-right: 4px !important ;
    
}


.search-workers-top p, .tablescrollmb .chip-container {
    width: auto;
    font-family: 'Lato-Regular';
    font-style: normal;
    line-height: 18px !important;
    background-color: #aaa;
    color: white;
    padding: 6px !important;
    border-radius: 8px;
    font-size: 12px;
    display: inline-block;
    align-items: center;
    margin-bottom: 0px !important;
    margin-right: 4px !important;
}


.applicant-details-single-tags.cardchip  {
    display: flex;
    flex-wrap: inherit !important;
    width: 100%;
    white-space: nowrap;
    
    text-overflow: ellipsis;
}
.tablescrollmb{
    display: flex;
    flex-wrap: inherit !important;
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 3px !important;
    overflow: auto !important;
}
.tablescrollmb .chip-container {
    display: table-cell !important;
  
   
    border-left: 1px solid #e7dbdb !important;
}
.new-jobs-list table td:before {
    content: '' !important;
    width: unset !important;
    padding: unset !important;
    background: transparent !important;
}
.new-jobs-list table tbody tr {
    height: auto;
}


.invoicetabletable td:before {
    content: '' !important;
    width: unset !important;
    padding: unset !important;
    background: transparent !important;
    white-space: nowrap !important;
}
.invoicetabletable tbody tr {
    height: auto;
}


.invoicetabletable, thead, tbody, th, td, tr {
    display: revert;
}
.invoicetabletable,thead tr {
    position: initial !important;
    top: -9999px;
    left: -9999px;
}
.tableresponsive {
    display: block !important;
    width: 100% !important;
    overflow-x: auto !important;
    -webkit-overflow-scrolling: touch;
    white-space: nowrap;
}
.fnt-32.no-jobs {
       
        font-size: 20px !important;
        margin: 60px !important;
    
}

.side-nav ul li:last-child{
    display: block; 
  } 
  .no-invoice-div {

     margin: 0px !important; 
}
.applicant-profile-education-table{
    margin-top:75px;
}
.transaction-table-card-filter-left.wrap{
    flex-wrap: wrap !important;
}
.primary-yellow-filled-btn.mrt-10{
    margin-top: 12px;
}
.transaction-table-card-filter-left.wrap .form-group {
    
    margin-bottom: 1rem !important; 
}
#faq .card .card-header .btn-header-link {
    white-space: normal !important;
}
.view-applicants-banner {
    height: auto !important;
}
.applicant-page .applicant-profile-download-section {
    flex-direction: initial !important;
}
.header .navbar-right {
    align-items: flex-start !important;
}
.header .navbar-right {
    flex-direction: column !important;
}
.green{
   padding-top: 30px  !important;
   
 }
.fontsize-11{
    font-size: 11px !important;
}
.chat-header .messagecard1-leftrowdetails {
   
    flex-wrap: wrap !important;
}
.padd-top{
    padding-top: 0px !important;
  }

#advPayment table td:before {
    content: '' !important;
    width: unset !important;
    padding: unset !important;
    background: transparent !important;
}

#advPayment  table tbody tr {
    height: auto;
}

.applicant-profile-education-table > #advPayment > table > tbody > tr > td {
    border-right: 0px !important;
    /* border-bottom: 1px solid #eee; */
}
.flexwrp{
    flex-wrap: wrap !important;
}
.MuiTable-stickyHeader td:before {
    content: '' !important;
    width: unset !important;
    padding: unset !important;
    background: transparent !important;
}

.MuiTable-stickyHeader thead tr {
    position: sticky;
    top: -9999px;
    left: -9999px;
}
.mobres{
    display: none !important;
}
.mbresp{
    flex-wrap: nowrap;
    display: flex !important;
    flex-direction: column;
}
.admin-chg-pwd-btn {
    margin-top: 10px;
    width:auto !important;
}
.applicant-profile-download-section.applicationmbl{
    overflow: auto;
    white-space: nowrap;
    padding-bottom: 10px;
    justify-content: center;
}
.applicant-page .applicant-profile-download-section .btn{
    margin-right: 6px !important;
}
.schedule-interview-modal.mbmodal {
    width: 100% !important;
    margin: auto !important;
    position: absolute !important;
    height: fit-content;
    top: 50%;
}
.mt-55{
  display: none !important;
  }
  .mt-28{
    display: none !important;
  }
  .newheader{
    font-size: 18px !important;
      font-weight: 900;
  }

  .staffname-label-section {
 flex-wrap: wrap !important;
}
.amountdue-whole-section {
    flex-wrap: wrap !important;
}
.amountdue-section-left {
    flex-wrap: wrap !important;
}

.invoice-heading-section {
    flex-wrap: wrap !important;
}
.invoice-banner {
  
    height: 88px !important;
  
}
.weekly-invoice-heading {
    font-size: 30px !important;
    
}
.margintp-18{
    margin-top:18px;
}
.staffname-label > label {
   
    font-size: 14px !important;
  
    min-width: 97px!important;
}
.staffname-label > span {
    
    font-size: 14px !important;
   
}
.staffname-label {
    padding: 0px 8px;
}
.amountdue-whole-section {

    flex-wrap: wrap !important;
}
.amountdue-section-right > textarea {
   
    width: 250px !important;
  
}
.amount-due-salary-breakdown {
    width: 100% !important;

}
.amountdue-section-price {
  
    font-size: 19px !important;
   
}
.messagerowsection {
    top: 0;
    overflow: auto;
    height: auto !important;
    padding: 0px !important;
    display: flex;
    flex-direction: column;
}
.amountdue-section-left-text {
   
    
    font-size: 19px !important;
    min-width: 190px !important;
    line-height: 30px !important;
}
.mobbutn{
    display: block !important;
}
.salarybreakdown > span:nth-child(2) {
   
    margin: 3px 0px 10px 0px !important;
   
}
ul.navbar-right .sidebarmobile .tab-side-nav{
    display: block !important;
    position: relative !important;
}
.sidebarmobile {
    display: block !important;
    width: 100% !important;
}

ul.navbar-right .sidebarmobile .tab-side-nav{
    display: block !important;
    position: relative !important;
}

ul.navbar-right .sidebarmobile .tab-side-nav ul a{
    padding: 1.5rem 6rem 1.5rem 0.5rem !important;
}
.applicant-page-skills .table tbody, .applicant-page-skills .table thead {
    display: table-row-group;
    white-space: nowrap;
}

.side-nav {
   
    min-height: auto !important;
}
.jobseeker .tab-side-nav {
   
    height: auto !important;
    
} 
.mobcoll.collapse:not(.show) {
    display: none !important;
}
.employer-posted-job-list-page .job-list-right.flex-sear {
    
    flex-wrap: wrap !important;
}
.employer-posted-job-list-page .job-list-right.flex-sear .edit-btn{
    margin-bottom: 6px;
}
.employer-posted-job-list-page .job-list-right.flex-sear .edited-btn{
    margin-bottom: 12px;
}
} 
@media (max-width:992px) and (min-width:767px) {
    .amountdue-whole-section {
        flex-wrap: wrap !important;
    }

}
@media (max-width:1080px) and (min-width:768px) {
    .invoice-banner {
      
        height: 206px !important;
      
    }

}

@media (max-width:1136px) and (min-width:767px) {
    .mobres{
        display: none !important;
    }

}
@media (max-width:1314px) and (min-width:1025px) {

.employer-banner-bg-content {
 
    padding: 5px !important;
}
.side-nav ul a {
  
  
    padding: 1.2rem 0rem 1.2rem 1.2rem !important;
    font-size: 14px !important;
   
}

}
@media (max-width:1024px) and (min-width:768px) {

    .jobseeker .tab-side-nav {
        position: inherit !important;
        top: 0;
        height: 100vh;
        z-index: 111;
    }

    .side-nav {
        width: 55% !important;
        float: left;
        background: #fff;
        min-height: 100vh;
    }
    .tab-side-nav {
        display: block !important;
    }
    .side-nav ul a {
       
        padding: 1.5rem 0rem 1.5rem 0.8rem !important;
      
    }
    .employer-posted-job-list-page .job-list-right.flex-sear {
    
        flex-wrap: wrap !important;
    }
    .employer-posted-job-list-page .job-list-right.flex-sear .edit-btn{
        margin-bottom: 12px;
    }
    .employer-posted-job-list-page .job-list-right.flex-sear .edited-btn{
        margin-bottom: 12px;
    }
}


@media  (max-width:1024px) and (min-width:768px){
.toggle-menu.d-flex.justify-content-end {
    display: block !important;
}
.employer-sidebar {
    display: block;
    position: fixed;
    width: 100% !important;
    top: 0;
    height: 100vh;
    z-index: 111;
}
.tabresponsivefriendly{
    width: 90% !important;
}
.tabresponsiveclose{
    width: 4% !important;
}

}
@media  (max-width:1080px) and (min-width:320px){
.paination_link {
    width: auto !important;
}
}

@media (max-width:1160px) and (min-width:320px) {
  
    .widthhalf{
        width: 100% !important;
    }
    .flex-wrappop{
        flex-wrap: wrap !important;
    }
    .closepos {
        
        position: relative !important;
        z-index: 999 !important;
        top: -402px  !important;
    }
    .orposition{
        top: 49% !important;
    left: 48% !important;
    margin: 0 auto !important;
    position: absolute !important;
width: auto !important;
    }
    /* .topmodal.modal {
        top: 20% !important;
    } */
}


@media (max-width: 1160px) and (min-width: 481px){
    .orposition {
        top: 45% !important;
        left: 48% !important;
        margin: 0 auto !important;
        position: absolute !important;
        width: auto !important;
    }
}

/* tab responsive style check */

.job-seeker-bnr-img{
    background-size: cover !important;
}
.btn.btn-header-link.collapsed.re-collapse{
    white-space: normal!important;
}
@media (max-width:767px) {
    .notifi-res.nav-notification-options{
        z-index: 123!important;
    }
    
}
@media (min-width:320px) and (max-width:374px){
    .nav-notification-options.notifi-res{
       left: -2rem!important;
       max-width: 250px!important;
    } 
}
@media (min-width:768px)and (max-width:820px){
   .applicant-profile-education-table.table-responsive.job-offer-table-res  {
      
        max-width: 490px!important;
    }
}
@media (min-width:877px) and (max-width:1210px){
    .res-2-card {
        margin: 0px !important;
    }
}
@media (max-width:1200px){
    .res-2-card.apps {
        margin-top: 1rem !important;
    }
}
@media (max-width:728px){
    .res-2-card.apps {
        margin: 0px !important;
        margin-top: 1rem!important;
       
    }
}
@media (min-width:1181px) and (max-width:1210px){
    .res-2-card.apps{
        margin-right: 10px!important;
    }
}
@media (min-width:320px) and (max-width:375px) {
    .res-2-card.apps{
        padding: 1px!important;
    }
    
}
.time-title{
    text-align: center!important;
}
@media (min-width:320px) and (max-width:479px){
   .text-box-res {
        width: 230px!important;
        max-width: 100%!important;
        margin-left: 10px!important;
    }
}
@media (min-width:320px) and (max-width:500px){
    .tracker-details.track-card-res{
        width: 300px !important;
        max-width: 100%!important;
        margin-right: 0px!important;

    }
}
/* admin module responsive check */
@media (min-width:320px) and (max-width:740px){
    .chart_div.chart-res{
        flex-wrap: wrap!important;
    }
}
@media (min-width:1401px) and (max-width:1700px) {
    .chart_div.chart-res{
        flex-wrap: wrap!important;
    }
}
@media (min-width:768px) and (max-width:1400px){
    .chart-bar-res.chart_bar{
        width: 50%!important;
        max-width:100%!important;

    }
    .chart-pie-res.chart_pie{
        width: 20%!important;
        max-width:100%!important;
        height: 200px!important;
        
    }
}
@media(min-width:320px) and (max-width:760px){
    .unverified-table-res td::before{
        background: none!important;
    }
}
.unverified-table-res{
    overflow: scroll!important;
}
@media(min-width:320px) and (max-width:760px) {
    .table-tab-res td::before{

        background: none!important;
    }
} 
@media (min-width:320px) and (max-width:600px) {
    .table-tab-res ,table,thead{
        display: revert !important;
        position: relative!important;
    }
    .table-tab-res ,table,tbody,tr,td{
        display: revert !important;
       
    }
  
}
@media (min-width:320px) and (max-width:480px) {
    .input-res.docuchainSearchbox {
        width: 300px!important;
        max-width: 100%!important;
    }
    
}
.manag-table-res{
    overflow: scroll!important;
}
@media(min-width:320px) and (max-width:760px) {
    .manag-table-res td::before{

        background: none!important;
    }
}
@media (min-width:320px) and (max-width:600px) {
    .manag-table-res ,table,tbody,tr,td::before {
        content: none!important;
    }    
}
@media (min-width:320px) and (max-width:450px){
    .skill-btn{
        float: none!important;
        margin-top: 10px!important;
    }
}
@media (min-width:600px) and (max-width:760px) {
    .table-re-assemble ,table{
        display: revert !important;
    }
}
@media (min-width:320px) and (max-width:620px) {
    .managechat .extension1 .resize-input{
        overflow: scroll!important;
    }
}
@media (min-width:320px) and (max-width:490px) {
     .setting-select-type .resize-selct-type ,h3 {
        padding: 0px!important;
    }
    
}
@media (min-width:576px) and (max-width:991px){
    .new-inp.docuchainSearchbox.input-res {
        float: none!important;
    }
}
@media (min-width:768px) and (max-width:1200px) {
    .frst-fld.form-group.col-md-1.m-auto.w-100 {
        max-width: none!important;
        margin-bottom: 1rem!important;
    }
    
}
@media (min-width:844px) and (max-width:996px) {
    .lab-res.form-group.col-md-3 {
        flex: 1 0 24% !important;
    }
}
@media (min-width:768px) and (max-width:872px){
    .lab-res.form-group.col-md-3 {
        flex: 1 0 31% !important;
        max-width: 35% !important;
    }
}
/* jobseeker */

@media (min-width:768px) and (max-width:950px) {
    /* .side-re-size, ul, li, a{
        font-size: 14px !important;
    }
    .side-re-size, ul, li, a, span {
        font-size: 13px !important;
    } */
}
/* 30-05-2022 */
@media (min-width:768px) and (max-width:1450px){
    .emp-ban-resize.how-it-works-jobseeker-banner.how-it-works-employer-banner {
 background-size: cover!important;
    }
}

@media (min-width:576px) and (max-width:767px) {
    .fnt-resize{
        font-size: 14px!important;
    }
}
@media (min-width:576px) and (max-width:767px) {
    .f-tab-res{
        padding-right: 2px!important;
    }
    
}
@media (min-width:320px) and (max-width:412px) {
    .transaction-table.table-re-size {
        overflow: scroll!important;
    }
}
/* 09-06-2022 */

.job-off-res.message-group.paddingnone.d-flex.align-items-center.table-responsive{
    height: auto!important;
}
.hired-staff-table-data > tbody > tr > td.marfintable.app-rol-res{
    padding-left: 10px!important;
    padding-top: 15px!important;
}
