.v-staff-admin-container{

  .login-page{
    display:flex;
    height:100vh;
    width:100%;

  }

  .login-block{
    width:50%;
    background-image: url('../images/admin/image/bg2.jpg');
    background-size: cover;
    background-position: center;
  }
  .login-section{
    width:50%;
    background: #F3F2F8;
    display: flex;
    justify-content: center;
    align-items: center
  }
  .form-group{
    width: 100%;
    position: relative;
  }
  .log-sec{
    margin-top:-30px;
    width:350px;
    background: #fff;
    border-radius: 15px;
    box-shadow: 0 0 5px 0 #d8d8d8;
  }
  .log-sec h3{
    margin-bottom: 20px;
    font-size: 22px;
    font-family: 'Roboto', sans-serif;
    color: #fff;
    text-align: center;
    font-weight: bold;
    white-space: nowrap;
    background: #004C70;
    margin-top: 0;
    padding: 15px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;

  }
  .mob-img img{
    display: none;
  }
  .log-sec input{
    width: 100%;
    padding: 10px;
    background: transparent;
    border: 1px solid #A3A4A5;
    outline: none;
    padding-left: 10px;}

  .log-sec input:focus{
    border:2px solid #004C70;

  }
  .log-sec label{
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
    font-weight: bold;
  }

  .log-sec .form-group span{
    position: absolute;
    top: 40px;
    right: 13px;
    font-size: 18px;
    color: #004C70;
  }
  .row{
    margin:0 !important;
  }

  /* Check box design begin */
  .check-lab{
    font-weight: normal;
  }
  .cbx {
    margin: auto;
    -webkit-user-select: none;
    user-select: none;
    cursor: pointer;
  }
  .cbx span {
    display: inline-block;
    vertical-align: middle;
    transform: translate3d(0, 0, 0);
  }
  .cbx span:first-child {
    position: relative;
    width: 18px;
    height: 18px;
    border-radius: 3px;
    transform: scale(1);
    vertical-align: middle;
    border: 1px solid #9098A9;
    transition: all 0.2s ease;
  }
  .cbx span:first-child svg {
    position: absolute;
    top: 3px;
    left: 2px;
    fill: none;
    stroke: #FFFFFF;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-dasharray: 16px;
    stroke-dashoffset: 16px;
    transition: all 0.3s ease;
    transition-delay: 0.1s;
    transform: translate3d(0, 0, 0);
  }
  .cbx span:first-child:before {
    content: "";
    width: 100%;
    height: 100%;
    background: #004C70;
    display: block;
    transform: scale(0);
    opacity: 1;
    border-radius: 50%;
  }
  .cbx span:last-child {
    padding-left: 8px;
  }
  .cbx:hover span:first-child {
    border-color: #004C70;
  }

  .inp-cbx:checked + .cbx span:first-child {
    background: #004C70;
    border-color: #004C70;
    animation: wave 0.4s ease;
  }
  .inp-cbx:checked + .cbx span:first-child svg {
    stroke-dashoffset: 0;
  }
  .inp-cbx:checked + .cbx span:first-child:before {
    transform: scale(3.5);
    opacity: 0;
    transition: all 0.6s ease;
  }

  @keyframes wave {
    50% {
      transform: scale(0.9);
    }
  }


  /* Checkbox end */

  .sign-in-btn, .sign-in-btn:focus{
    margin-top: 20px;
    width:150px;
    background: #004C70;
    border:1px solid #004C70;

    color:#fff;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    letter-spacing: 1px;
    font-size: 16px;
    padding: 9px 15px;
  }
  .sign-in-btn:hover{
    color:#fff;
  }

  .custom-padding-20{
    padding: 20px;
  }
  .forgot-pwd {
    color: #004C70;
    text-align: right;
    display: block;
    text-decoration: underline;
  }
}
// new login css
.Log {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    // height: 90vh;
  background-color: #004C70;
}

.Log .log-card {
    background: #FFFFFF;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
    border-radius: 34px;
}

.Log .log-card .logo-bar {
    text-align: center;
}

.Log .log-card .logo-bar i {
    font-size: 4rem;
    color: #004C70;
    padding: 0.5rem;
}

.Log .log-card .logo-bar h2 {
    font-size: 24px;
  font-weight: 700;
    text-align: center;
    padding-bottom: 0rem;

    color: #004C70;
}

.Log .log-card .logo-bar p {
    font-size: 18px;
    text-align: center;
    padding-bottom: 0rem;
    color: #000000;

}

.Log .log-card .logo-bar span {
    // border-bottom: 2px solid #004C70;
    font-size: 18px;
    color: #004C70;

}

.Log .log-card .option {
    margin: 0.5rem 0rem;
    text-align: center;
    display: flex;
}
.logo-bar img{
  height: 50px;
}
.Log .log-card .option .opt span {
  font-size: 18px;
    width: 200px;
    display: flex;
    justify-content: center;
    color: #747474;
}

.Log .log-card .option .line {
    color: #747474 solid 2px;
    width: 100%;
}

.Log .log-card .inpt {
    text-align: center;
    margin-bottom: 1rem;
}
.text-underlin{
  border-bottom: 2px solid #008DCB;
}
.inpt img{
  width: 210px;
  margin-bottom: 10px;
  cursor: pointer;
}
.Log .log-card .inpt input {
  font-size: 18px;
    border: solid 1px #f7f7f7;
    // border-left: 3px solid #004C70;
    background-color: #f7f7f7;
    width: 85%;
    max-width: 100%;
    height: 44px;
    margin: auto;
    margin-bottom: 1rem;
    padding-left: 1rem;
}
.Log .log-card .inpt span {
    font-size: 19px;
}

.Log .log-card .inpt button {
    border: solid 1px #004C70;
    background-color: #004C70;
    color: white;
    width: 48%;
    height: 40px;
    border-radius: 20px;
    margin: 0.5rem 0rem;
    font-size: 18px;
    font-family: 'Lato-Regular';
}
.signup-jobseeker-btn{
  width: fit-content !important;
  padding: 10px 16px;
}
.Log .log-card .inpt p {
    font-size: 18px;
    text-align: center;
    color: #000000;
    margin-bottom: 0px;
}
.log-row{
  padding: 30px 0px;
}
.inpt label{
  display: none;
}
@media (min-width:320px) and (max-width:767px) {
  .Log .log-card .inpt input {
    border-left: 3px solid #004C70;
}
  .Log .log-card .inpt input {
      width: 100% !important;
  }

  .Log .log-card .option {
      margin: 1rem 0rem;
  }

  .Log .log-card .inpt button {
      width: 60%;
  }

  .Log .log-card .logo-bar i {
      font-size: 7rem;
      padding: 1rem;
  }

  .Log .log-card .logo-bar h2 {
      font-size: 40px;
      padding-bottom: 0rem;
  }

  .Log .log-card .logo-bar p {
      font-size: 24px;
      padding-bottom: 0rem;

  }

  .Log .log-card .logo-bar span {
      font-size: 24px;

  }


  .Log .log-card .option span {
      color: #747474;
      font-size: 24px;
  }

  .Log .log-card .inpt input {
      margin-bottom: 1rem;
  }



  .Log .log-card .inpt span {
      font-size: 24px;
      margin-bottom: 0.5rem;
  }



  .Log .log-card .inpt p {
      font-size: 24px;
  }

}

@media (min-width:320px) and (max-width:480px) {
  .Log .log-card .logo-bar i {
    font-size: 5.5rem;
    padding: 1rem;
    padding-top: 0px;
}
  .log-img-div{
    display: none;
  }
  .log-row{
    padding: 0px;
  }
  .Log .log-card {
      padding: 2rem;
      border-radius: 0px;
  }

  .Log .log-card .logo-bar h2 {
      font-size: 25px;
  }

  .Log .log-card .logo-bar p {
      font-size: 16px;
  }

  .Log .log-card .logo-bar img {

      width: 235px;
      max-width: 100%;
  }

  .Log .log-card .inpt input {
      width: 261px;
      max-width: 100%;
  }

  .Log .log-card .logo-bar p {
      font-size: 19px;
      padding-bottom: 1rem;

  }

  .Log .log-card .logo-bar span {
      font-size: 16px;

  }


  .Log .log-card .option span {
      font-size: 16px;
  }

  .Log .log-card .inpt {
      margin-bottom: 3rem;
  }

  .Log .log-card .inpt span {
      font-size: 16px;
  }



  .Log .log-card .inpt p {
      font-size: 16px;
  }

  .Log .log-card .option .opt {
      padding: 0rem 0.5rem;
  }

  .Log .log-card .option .line hr {
      color: #747474 solid 2px;
      width: 100px;
  }

  .Log .log-card .inpt button {
      border-radius: 30px;
      height: 40px;
  }
}
.logined-text{
  padding: 0px 6px 0px 6px !important;
  color:grey !important;
  font-size:20px !important;
}
.left-line{
  background:"#c4bcbc94" !important;
  width:7rem !important;
  height:1px !important;
}
.right-line{
  background:"#c4bcbc94" !important;
  width:7rem !important;
  height:1px !important;
}
@media (min-width:481px) and (max-width:761px) {
  .Log .log-card .option .opt {
      padding: 0rem 1rem;
      font-size: 16px;
  }

  .Log .log-card .option .line hr {
      color: #747474 solid 2px;
      width: 150px;
  }

}
@media (min-width:700px) and (max-width:850px) {
  .Log .log-card .inpt input{
    width: 70%;
  }
}
@media (min-width:320px) and (max-width:580px) {
  .logined-text{
    font-size: 20px !important;
  
  }
  .left-line{
    width:5rem !important;
  }
  .right-line{
    width:5rem !important;
  }
  .Log .log-card .option .opt span {
      width: 100px;
      display: flex;
      font-size: 16px;
      justify-content: center;
      padding: 0rem;
      margin: 0rem;

  }

  .Log .log-card .option .line hr {
      width: 70px;

  }
  .Log .log-card .inpt button {
    width: 55%;
}

}
@media (min-width:200px) and (max-width:321px) {
  .logined-text{
    font-size: 17px !important;
  
  }
  .contactus-left-card-input svg {
    right: 24px;
}
  .left-line{
    width:3.5rem !important;
  }
  .right-line{
    width:3.5rem !important;
  }
}
