@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@500&display=swap');
.MuiDialog-paperWidthSm {
  max-width: 1000px !important;
}

.tracker,
.tracker-sass {
  // background-image: url('../images/businessman-holding-hour-glass-signifies-importance-being-time.png');
  // background-repeat: no-repeat;
  // background-size: cover;
  background-color: #e5e5e5;
  min-height: 700px;
  .MuiDialog-paperWidthSm {
    max-width: 1000px !important;
  }
  .MuiInputLabel-animated {
    color: black !important;
  }
  .MuiInputBase-input {
    color: #ffffff !important;
  }
  .MuiSelect-select.MuiSelect-select {
    color: #33444d !important;
  }
  .MuiFormControl-marginNormal {
    margin-top: 0px !important;
  }

  .MuiBreadcrumbs-separator {
    color: black !important;
  }
  .MuiBox-root-17 {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
  .MuiOutlinedInput-root {
    border: 1px solid black !important;
  }

  .MuiInputLabel-outlined.MuiInputLabel-shrink {
    background: #ffffff;
    color: black !important;
    padding: 5px;
  }
  .MuiFormHelperText-root.Mui-error {
    font-size: 15px;
  }

  .tracker-details {
    // padding:15px;
    .inner-details-card {
      padding-left: 30px;
      padding-top: 30px;
      padding-bottom: 30px;
      padding-right: 30px;
      border-radius: 10px;
      margin-right: 30px;
      background-color: #ffffff;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      min-height: 383px;
    }
    div {
      .section {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
        p {
          font-size: 18px;
          color: #33444d;
          width: 170px;
          font-weight: 600;
        }
        .seperate-comma {
          width: 20px !important;
        }
      }
    }
  }

  .trackerIntstallation {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .threecards {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      .downloadcards {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        background: #f2f2f2;
        min-height: 250px;
        padding: 20px;
        margin: 0px 10px;
        .btnsClick{
          background:transparent;
          border:none;
          font-weight: 700;
        }
        .imagediv {
          min-height: 100px;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            width: 100px;
          }
        }
        .titles {
          font-size: 18px;
          color:#33444d;
          font-weight: 700;
        }
      }
    }
  }
  // td.MuiTableCell-root.MuiTableCell-body.MuiTableCell-alignRight{
  //   width:200px !important;
  // }
  // td.MuiTableCell-root.MuiTableCell-body{
  //   width:250px !important;
  // }
  .apps {
    /* background-color: #0e4d92; */
    background-color: #0093d1;
    margin: 0 auto;
    // min-width: 340px;
    // min-height: 500px;
    position: relative;
    border-radius: 10px;
    // margin:0px !important;
    // margin:"10px"
    /* margin-left: 10px;*/
    margin-right: 30px;
    padding: 30px;
  }
  .apps:last-child {
    margin-right: 0px;
  }

  h4 {
    color: white;
    text-align: center;
    // letter-spacing: 1.2px;
    font-weight: 600;
  }
  .textField {
    color: #ffffff !important;
  }
  p {
    font-size: 24px;
    color: #555555;
    font-weight: 600;
  }

  .stopwatch-card {
    // position: absolute;
    text-align: center;
    background-color: white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    // width: 299px;
    // height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    // top: 270px;
    // left: 50%;
    // transform: translate(-50%, -50%);
    flex-direction: column;
    padding: 20px;
    margin-top: 20px;
  }
  .MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.MuiPaper-elevation24.MuiPaper-rounded {
    max-width: 900px;
  }

  button {
    outline: none;
    // background: transparent;
    // border: 1px solid blue;
    padding: 5px 10px;
    border-radius: 7px;
    // color: blue;
    cursor: pointer;
  }

  .buttons {
    display: flex;
    justify-content: space-evenly;
    width: 150px;
    margin: 0 auto;
    margin-top: 5px;
  }
}
