.timesheet-container-sass{

  .wrapper {
    background:#EFEFEF;
    box-shadow: 1px 1px 10px #999;
    margin: auto;
    text-align: center;
    position: relative;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    margin-bottom: 20px !important;
    width: 1240px;
    /* padding-top: 5px; */
  }
  /* p{
      margin-bottom: 0px !important;
      font-size:19px
  } */
  .wrappers {
    background:#EFEFEF;
    box-shadow: 1px 1px 10px #999;
    margin: auto;
    text-align: center;
    position: relative;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    margin-bottom: 20px !important;
    width: 1240px;
    padding-top: 5px;
    display:flex;
    padding-bottom: 5px;

  }
  .scrolls {
    overflow-x: scroll;
    overflow-y: hidden;
    height: 265px;
    white-space:nowrap;
    display:flex;
    flex-direction:column;
  }
  .scroll-body{
    display:flex;

  }
  .title{
    background-color:green;
    height:40px;
    width:100%;
    position:static;
    display:flex;
    justify-content:center;
    align-items:center;
  }
  .title p{
    color:#ffffff;
  }
  .start{
    height:max-content;
    width:50px;
  }
  .starts{
    display:flex;
    justify-content:center;
    align-items:center;
  }
  .imageDiv img {
    box-shadow: 1px 1px 10px #999;
    margin: 2px;
    max-height: 50px;
    cursor: pointer;
    display:inline-block;
    *display:inline;
    *zoom:1;
    vertical-align:top;
  }
}
