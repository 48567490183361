body {
  font-family: 'Lato-Regular' !important;
  margin: 0;
  background: #f8f6f6 !important;
}
.text-bold{
  font-weight: bold;
}
.blue-color{
  color : #004C70;
  font-weight: bold !important;
}
.bg-blue{
  background-color : #004C70;
  color: white;
}
.sidebar-text p{
  color: #DBDF1E;
  text-decoration: none;

}
.sidebar-text span{
  color: white;
  font-size: 35px;
}
.sidebar-text a{
  color: #DBDF1E;
  font-size: 24px;
}
.activationbtn {
  border-radius: 3px;
  color: rgb(255, 255, 255);
  font-size: 15px;
  background: rgb(21, 142, 255);
  padding: 10px 20px;
  text-decoration: none;
  width: 300px;
  margin: 10px auto 0;
  cursor: pointer;
  display: block;
  text-align: center;
}

.text-black {
  color: black !important
}

.activationbtn .loading-button {
  display: flex;
  align-self: center;
  margin: auto;
}

/* .navbar-brand img{
width: 153px;
height: 23px;
} */
.navbar-brand img {
  width: 161px;
  height: 35px;
}

.jobseeker.header {
  height: 71px !important;
}

.header {
  height: 71px !important;
  width: 100%;
  background: #fff;
  display: flex;
  align-items: center;
}

.jobseeker_header {
  height: auto !important;
  width: 100%;
  background: #fff;
  align-items: center;
}

.employer_header {
  height: auto !important;
  width: 100%;
  background: #fff;
  align-items: center;
  border-bottom: 1px solid grey;
}

.header .nav-item .nav-link {
  font-family: Lato-bold;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
  color: #004c70;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.header #collapsibleNavbar {
  justify-content: flex-end;
  align-items: center;
}

.jobseeker_header #collapsibleNavbar {
  justify-content: flex-end;
  align-items: center;
}

.employer_header #collapsibleNavbar {
  justify-content: flex-end;
  align-items: center;
}

.header .nav-item {
  margin: 0 5px;
}

.jobseeker_header .nav-item {
  margin: 0 5px;
}

.employer_header .nav-item {
  margin: 0 5px;
}

.header .navbar-right form {
  position: relative;
}

.header .navbar-right form .form-control {
  width: 300px;
  height: 36px;
  border-radius: 18px;
  background-color: #f1f9ff;
  border: 1px solid #e2eae2;
  padding-left: 40px;
}

.Search-btn {
  background: transparent;
  border: 0;
  position: absolute;
  left: 7px;
  top: 7px;
  color: #696969;
  font-size: 14px;
}

.header .navbar-right {
  align-items: baseline;
}

.jobseeker_header .navbar-right {
  align-items: baseline;
}

.employer_header .navbar-right {
  align-items: baseline;
}

.login-text {
  font-family: Lato-bold;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff !important;
  background: #004c70;
  border-radius: 30px;
  text-transform: uppercase;
  padding: 10px;
  cursor: pointer;
}

.new-homepage-section2-card-icon img {
  width: 70px;
}

.login-text:hover {
  color: #ffffff;
}

.login-li {
  padding: 10px 0px 10px 0px;
  border-right: 2px solid #a69e9e;
}
.login-li a{
  background-color:transparent !important;
  color: #004C70 !important;
}

.login-text svg {
  margin-bottom: 4px;
}


.signup-text {
  width: 98px;
  height: 36px;
  border: 1px solid #0093d1;
  box-sizing: border-box;
  border-radius: 30px;
  font-family: Lato-bold;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: #004C70 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  cursor: pointer;
}
.signup-li a{
  border-radius: 5px;
  border-color: #333;
}
.signup-text:hover {
  color: #0093d1;
}

.signup-text svg {
  margin-left: 3px;
}

.homepage-banner {
  background: url('../images/homepage/jobpositionbanner.jpg');
  background-repeat: no-repeat;
  height: 316px;
  width: 100%;
  background-size: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .banner-text h2 {
  font-size: 33px;
  color: #ffffff;
  font-weight: 800;
  text-align: center;
  font-family: "Lato-Black";
  margin-bottom: 25px;
} */
/* .banner-text h2 > span {
  font-size: 33px;
  color: #ffcc00;
  font-weight: 800;
  text-align: center;
  font-family: "Lato-Black";
}
.banner-text p {
  font-size: 18px;
  line-height: 33px;
  color: #bdbdbd;
  font-weight: 800;
  text-align: center;
  font-family: "Lato-Black";
  margin-bottom: 5px;
} */
.footer {
  /* background: #004c70; */
  width: 100%;
  background-image: url('https://i.stack.imgur.com/dZnzJ.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  /* position: absolute; */
  /* bottom: 0; */
}

.footer-area {
  display: flex;
  width: 50%;
  justify-content: space-between;
  padding-top: 30px;
}

.footer2-area {
  width: 100%;
  min-height: 72px;
  background: #0093d1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer2-area-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-area-single-section h5 {
  font-family: 'Lato-bold';
  margin-bottom: 15px;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: #f4d00c;
}

.footer-area-single-section span {
  background: transparent;
  border-top: 3px solid #f4d00c;
  padding: 0px 20px;
}

.footer-area-single-section ul li a {
  font-family: Lato-Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
  margin-bottom: 15px;
  display: block;
}

.footer-border {
  border-top: 1.5pt solid #2e88b2;
}

.footer2-area-left-text {
  font-family: Lato-bold;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: #ffffff;
  display: flex;
}

.footer2-area-left-text a {
  margin-left: 10px;
  color: white;
  font-size: 16px;
}

.footer2-area-right svg {
  font-size: 26px;
  color: #fff;
  margin-right: 10px;
}

/*-------HIRING CSS-----------*/
.hiring-section {
  background: #f8f8f8;
  width: 100%;
  padding: 40px 0 15px;
}

.hiring-section-heading h2 {
  font-size: 33px;
  line-height: 114px;
  color: #004c70;
  font-weight: 800;
  text-align: center;
  font-family: 'Lato-Black';
}

.hiring-section-search form .form-control {
  width: 788px;
  height: 36px;
  border-radius: 18px;
  background-color: #f1f9ff;
  border: 1px solid #e2eae2;
  padding-left: 15px;
  font-size: 14px;
  color: #696969;
  font-weight: 400;
}

.hiring-section-search .form-inline {
  position: relative;
  width: 788px;
  height: 36px;
  margin: 0 auto 48px;
}

.hiring-section-search .Search-btn {
  left: unset;
  right: 25px;
}

.hiring-section-index-area h3 {
  font-size: 33px;
  line-height: normal;
  color: #0095d3;
  font-weight: 800;
  font-family: 'Lato-Black';
  margin-bottom: 15px;
}

.hiring-section-index-names {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}

.hiring-section-index-individual-names {
  display: flex;
  flex-direction: column;
  width: 33%;
}

.hiring-section-index-individual-names p {
  font-size: 15px;
  line-height: 25px;
  color: #818181;
  font-weight: 400;
  margin-bottom: 10px;
}

.hiring-section-index-area {
  width: 75%;
  margin: 0 200px 30px;
}

.talk-about-hiring-team {
  background: #f8f8f8;
  width: 100%;
}

.talk-about-hiring-team-section {
  display: flex;
  justify-content: space-between;
  padding: 40px 30px 40px;
  align-items: center;
}

.talk-about-hiring-section-left-area h4 {
  font-size: 16px;
  line-height: 25px;
  color: #9a9a9a;
  font-weight: 400;
  margin-bottom: 15px;
}

.talk-about-hiring-section-left-area h3 {
  font-size: 33px;
  color: #004c70;
  font-weight: 800;
  font-family: 'Lato-Black';
  margin-bottom: 25px;
}

.timing-icon svg {
  font-size: 16px;
  line-height: 25px;
  color: #0095d3;
}

.timing-icon span {
  font-size: 15px;
  line-height: 25px;
  color: #0095d3;
  font-weight: 800;
  font-family: 'Lato-Black';
  margin-left: 15px;
}

.yellow-text {
  font-size: 20px;
  line-height: 25px;
  color: #ffcc00;
  font-weight: 400;
  margin-top: 15px;
}

.calendar-card {
  width: 550px;
  height: 583px;
  border-radius: 10px;
  background-color: #ffffff;
  border: 2px solid #f2f2f2;
  display: flex;
  justify-content: center;
  align-items: center;
}

/*-----ENTERPRISE PAGE-----*/
.banner-yellow-text {
  font-size: 18px;
  line-height: 33px;
  color: #ffcc00;
  font-weight: 800;
  text-align: center;
  font-family: 'Lato-Black';
  margin-bottom: 5px;
}

.enterprise-section2-single-card {
  background: #fff;
  box-shadow: 0 0 4px 0 #696969;
  border-radius: 8px;
  border-bottom: 2.5pt solid #0095d3;
  padding: 20px;
  margin-bottom: 25px;
}

.enterprise-icon {
  background: #fff;
  box-shadow: 0 0 4px 0 #696969;
  height: 84px;
  width: 84px;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  border-radius: 50%;
  align-items: center;
}

.enterprise-icon img {
  width: 56px;
  height: 56px;
  padding: 5px;
}

.enterprise-section2-single-card h4 {
  font-size: 18px;
  line-height: 36px;
  color: #0095d3;
  font-weight: 800;
  font-family: 'Lato-Black';
  margin: 15px 0;
  text-align: center;
}

.enterprise-section2-single-card p {
  font-size: 15px;
  line-height: 25px;
  color: #818181;
  font-weight: 400;
  text-align: center;
  min-height: 50px;
}

.enterprise-whole-section {
  padding: 10px 0 40px;
}

.enterprise-whole-section .hiring-section-heading p {
  text-align: center;
  margin-bottom: 30px;
}

.enterprise-banner {
  background: url('../images/homepage/banner1.png');
  background-repeat: no-repeat;
  height: 630px;
  width: 100%;
  background-size: 100%;
}

.enterprise-section3-single ul li {
  display: flex;
  font-size: 15px;
  line-height: 25px;
  color: #004c70;
  font-weight: 400;
}

.enterprise-section3-single ul li span svg {
  color: #00d31e;
  margin-right: 15px;
}

.enterprise-section3-single h5 {
  font-size: 25px;
  line-height: normal;
  color: #ffcc00;
  font-weight: 800;
  font-family: 'Lato-Black';
}

.enterprise-section3-single p {
  font-size: 15px;
  line-height: 25px;
  color: #818181;
  font-weight: 400;
}

.blue-color-text {
  color: #0093d1 !important;
}

.what-does-it-cost-section {
  background: #0093d1;
}

.what-does-it-cost-section h5 {
  color: #fff;
}

.what-does-it-cost-section p {
  color: #fff;
}

.what-does-it-cost-section .blue-color-text {
  color: #fff !important;
}

.what-does-it-cost-section ul li {
  color: #fff;
}

.what-does-it-cost-section .hiring-section-heading h2 {
  color: #fff;
}

.what-does-it-cost-section .enterprise-section2-single-card img {
  width: 80%;
  display: block;
  margin: 0 auto;
  height: 309px;
}

.enterprise-section3-single {
  padding-top: 30px;
}

.what-does-it-cost-section .enterprise-section2-single-card {
  margin-bottom: 50px;
}

/* Login Page */
.login-main-body {
  width: 100%;
  height: auto;
  background-color: #e7f6fd;
}

.login-bg-section {
  width: 100%;
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-card {
  width: 90%;
  height: 500px;
  background-color: white;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
}

.login-card .login-card-left {
  width: 50%;
  height: 100%;
  background-image: url('../images/enterprise/login.jpg');
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
  z-index: 3;
}

.login-card .login-card-left::after {
  width: 100%;
  height: 100%;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.8;
  background-color: #004c70;
  mix-blend-mode: multiply;
}

.login-card .login-card-right {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-card-right .login-card-main {
  width: 70%;
  height: 350px;
  border-radius: 10px;
  background-color: #004c70;
  padding: 15px;
}

.login-card-main h2 {
  font-family: Lato-bold;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  background: #004c70;
  border-radius: 30px;
  text-transform: uppercase;
  padding: 10px;
}

.footer-area-single-section {
  padding: 50px 0;
}

.header .dropdown-toggle::after {
  display: none;
}

.header .nav-item .nav-link svg {
  position: relative;
  left: 8px;
}

.primary-yellow-filled-btn {
  background: #f4d00c;
  border-radius: 30px;
  width: 148px;
  height: 34px;
  font-size: 16px;
  line-height: 19px;
  font-family: 'Lato-Bold';
  color: #004c70;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
  border: 1pt solid #f4d00c;
}

.primary-yellow-outline-btn {
  background: transparent;
  border-radius: 30px;
  width: 148px;
  height: 34px;
  font-size: 16px;
  line-height: 19px;
  font-family: 'Lato-Bold';
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
  border: 1pt solid #f4d00c;
}

.new-home-page-banner .banner-text h2 {
  font-family: 'Lato-Black';
  font-size: 48px;
  line-height: 50px;
  color: #ffffff;
  width: 80%;
  margin-bottom: 20px;
}

.banner-text-button {
  display: flex;
}

.new-homepage-section2-card {
  background: #0093d1;
  border-radius: 10px;
  border-bottom: 6pt solid #004c70;
  height: 163px;
  width: 100%;
  margin-bottom: 25px;
  padding: 35px;
  display: flex;
  align-items: center;
}

.new-homepage-section2-card p {
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  font-family: 'Lato-Bold';
  color: #ffffff;
  margin: 0;
}

.new-homepage-section2-card p>span {
  color: #f4d00c;
}

.new-homepage-section2-card-icon {
  display: flex;
  justify-content: center;
  position: relative;
  top: 25px;
}

.new-homepage-section2-card-section {
  position: relative;
  margin-top: -90px;
  padding: 0 0 54px;
}

.find-talented-filipano {
  padding: 40px 0 55px;
}

.common-headingtwo {
  font-weight: 900;
  font-size: 26px;
  line-height: 31px;
  text-align: left;
  color: #004c70;
  font-family: 'Lato-Black';
  margin-bottom: 10px;
}

.common-heading {
  font-weight: 900;
  font-size: 26px;
  line-height: 31px;
  text-transform: uppercase;
  text-align: center;
  color: #004c70;
  font-family: 'Lato-Black';
  margin-bottom: 10px;
}

.find-talented-filipano p {
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  text-transform: uppercase;
  color: #494949;
  margin-bottom: 35px;
}

.common-border {
  background: #004c70;
  border: 0;
  width: 45px;
  height: 4px;
  text-align: center;
  margin: 0 auto 40px;
}

.find-talented-filipano-card {
  background: #ffffff;
  box-shadow: 0px 0px 9px rgba(122, 122, 122, 0.25);
  border-radius: 10px;
  padding: 25px 15px 26px;
  min-height: 334px;
}

.find-talented-filipano-card h4 {
  font-size: 18px;
  line-height: 22px;
  text-transform: uppercase;
  font-family: 'Lato-Bold';
  color: #004c70;
  margin-bottom: 30px;
}

.find-talented-filipano-line {
  width: 45px;
  height: 4px;
  background: #004c70;
  border: 0;
  margin: 30px 0 26px;
}

.find-talented-filipano-card p {
  font-size: 14px;
  line-height: 17px;
  text-align: left;
  color: #494949;
  text-transform: inherit;
  margin-bottom: 0;
}

.most-recent-section {
  background: url('../images/homepage/jobseekerbg.jpg');
  background-size: 100%;
  height: 1027px;
  width: 100%;
  background-repeat: no-repeat;
  padding: 50px 0;
}

.home-page-carousel-card {
  background: linear-gradient(270deg, #ffffff 0%, #ffffff 97.75%);
  box-shadow: 0px 0px 9px rgba(122, 122, 122, 0.25);
  border-radius: 10px;
  display: flex;
  border-left: 6pt solid #004c70;
  padding: 17px 25px;
  margin-bottom: 40px;
}

.home-page-carousel-card:hover {
  border: 1px solid #00838d;
  box-shadow: 0px 0px 999px 999px rgba(255, 255, 255, 0.5);
  z-index: 500;
}

.home-page-carousel-card-left {
  margin-right: 27px;
}

.home-page-carousel-card-left img {
  width: 107px;
  height: 107px;
  border-radius: 50%;
  margin-bottom: 17px;
}

.part-time-text {
  background: #0093d1;
  width: 76px;
  height: 21px;
  font-size: 13px;
  line-height: 16px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  margin: 0 auto;
  text-align: center;
}

.home-page-carousel-card-right {}

.home-page-carousel-card-right h4 {
  font-weight: 900;
  font-size: 22px;
  line-height: 26px;
  text-transform: uppercase;
  font-family: 'Lato-Black';
  color: #004c70;
  margin-bottom: 0;
}

.years-of-exp-text {
  font-size: 15px;
  line-height: 18px;
  text-transform: uppercase;
  margin-bottom: 10px;
  color: #494949;
}

.years-of-exp-text span {
  color: #f4d00c;
  font-family: 'Lato-Black';
}

.price-text {}

.price-text sup {
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
  font-family: 'Lato-Black';
  color: #494949;
  top: -5px;
}

.price-text label {
  font-size: 24px;
  line-height: 29px;
  text-transform: uppercase;
  font-family: 'Lato-Black';
  color: #0093d1;
}

.price-text span {
  font-size: 13px;
  line-height: 15.6px;
  color: #494949;
  text-transform: uppercase;
}

.margin-r-80 {
  margin-right: 80px;
  margin-left: 20px;
}

.margin-l-80 {
  margin-left: 80px;
  margin-right: 20px;
}

.most-recent-section .find-talented-filipano-line {
  margin: 20px 0;
}

.most-recent-section .carousel-indicators .active {
  background: #004c70;
}

.most-recent-section .carousel-indicators li {
  height: 18px;
  width: 18px;
  border-radius: 25px;
  border: 1pt solid #004c70;
}

.most-recent-section .carousel-item {
  margin-bottom: 80px;
}

.most-recent-section .carousel-control-prev {
  left: 36%;
  top: unset;
  bottom: 2px;
  opacity: 1;
  z-index: 16;
}

.most-recent-section .carousel-control-next {
  right: 36%;
  top: unset;
  bottom: 2px;
  opacity: 1;
  z-index: 16;
}

.browse-btn-section {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

.commonbtn2 {
  background: #004c70;
  border-radius: 30px;
  font-size: 16px;
  line-height: 19px;
  font-family: 'Lato-Bold';
  color: #ffffff;
  width: 154px;
  height: 49px;
  border: 1pt solid #004c70;
}

.heading-bg {
  height: 78px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.pay-your-staff-section {
  background: #004c70;
  padding: 40px 0;
}

.pay-your-staff-section p {
  font-weight: bold;
  font-size: 18px;
  line-height: 29px;
  font-family: 'Lato-Bold';
  text-align: center;
  color: #fff;
}

.pay-your-staff-bg-card {
  background: #fff;
  box-shadow: 0 0px 2px 5px rgb(0 0 0 / 10%);
  border-radius: 5px;
  position: relative;
  margin: 50px 30px;
  padding: 20px 30px;
  border-left: 6pt solid #0093d4;
  display: flex;
  width: 100%;
}

.pay-your-staff-bg-card::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 60px 60px 0 0;
  border-color: #0093d1 transparent transparent transparent;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 3;
}

.pay-your-staff-bg-card::after {
  content: '\f155';
  font-family: 'Font Awesome 5 Free-Solid-900';
  font-weight: 900;
  color: #fff;
  display: block;
  position: absolute;
  font-size: 20px;
  left: 10px;
  top: 10px;
  z-index: 4;
}

.heading-bg h3 {
  margin: 0;
  color: #fff;
}

.pay-your-staff-bg-card .common-heading {
  text-align: left;
}

.pay-your-staff-bg-card .common-border {
  text-align: left;
  margin: 20px 0;
}

.pay-your-staff-bg-card .common-bulletin {
  width: 100%;
}

.pay-your-staff-bg-card .common-bulletin li span svg path {
  fill: #494949;
}

.pay-your-staff-bg-card .common-bulletin li label {
  color: #494949;
}

.pay-your-staff-bg-card-content {
  width: 60%;
  padding: 20px 10px;
}

.pay-your-staff-bg-card-content2 {
  width: 35%;
  margin: 12px 0 0 auto;
}

.full-bg {
  display: flex;
}

.full-bg-left {
  position: relative;
  background: #004c70;
  height: 497px;
  width: 50%;
  padding: 24px 65px 0 80px;
}

.full-bg-right {
  position: relative;
  background: #06668e;
  height: 497px;
  width: 50%;
  padding: 24px 65px 0 80px;
}

.full-bg-left .common-heading,
.full-bg-right .common-heading {
  color: #ffffff;
  line-height: 31px;
}

.full-bg-left .common-border,
.full-bg-right .common-border {
  background: #fff;
  margin-top: 30px;
}

.common-bulletin li {
  display: flex;
  margin-bottom: 10px;
}

.common-bulletin li span {
  margin-right: 19px;
}

.common-bulletin li label {
  font-size: 16px;
  line-height: 19px;
  font-family: 'Lato-Bold';
  color: #f4d00c;
}

.bottom-img {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.bottom-img img {
  width: 100%;
}

.phone_contain_div {
  position: relative;
}

.phone_contain_div .image_div {
  position: absolute;
  border: thin #000 solid;
  top: 16%;
  bottom: 0;
  left: 12.7%;
  right: 0;
  width: 46%;
  height: 64.4%;
  overflow: hidden;
}

.phone_contain_div .image_div img {
  max-width: 100%;
  max-height: 100%;
  box-sizing: border-box;
  overflow: hidden;
  transition: all 0.2s;
}

.image_click>.image.active {
  color: red;
}

.image_div img {
  position: absolute;
  left: 100%;
  top: 0;
}

.hire-a-pro-section {
  background: #f8f6f6;
  padding: 50px 0;
}

.hire-a-pro-section .common-heading {
  text-align: left;
}

.hire-a-pro-section .common-border {
  margin: 0;
}

.hire-a-pro-list {
  margin-top: 40px;
}

.hire-a-pro-list p {
  color: #06668e;
  font-weight: bold;
  font-family: 'Lato-Black';
  font-size: 25px;
  margin-bottom: 15px;
  cursor: pointer;
}

.hire-a-pro-list_button_active {
  color: #78b9d4 !important;
}

.hire-a-pro-list-slider .carousel-item img {
  width: 100%;
}

.hire-a-pro-list-slider {}

.hire-a-pro-list-slider-list {
  display: flex;
  flex-wrap: wrap;
  margin: 15px 0;
}

.hire-a-pro-list-slider-list-single {
  background: #f4d00c;
  border-radius: 5px;
  font-size: 16px;
  line-height: 19px;
  padding: 8px 17px;
  color: #004c70;
  font-family: 'Lato-Bold';
  margin: 0 15px 15px 0;
  cursor: pointer;
}

/*----enterprise-page-design-----*/
.new-enterprise-banner-page {
  background: url('../images/enterprise/enterprisebannerbg.jpg');
  background-repeat: no-repeat;
  height: 484px;
  width: 100%;
  background-size: 100%;
  display: flex;
  align-items: center;
}

.new-enterprise-banner-text h2 {
  font-size: 40px;
  line-height: 48px;
  font-family: 'Lato-Black';
  color: #8d1e1e;
}

.new-enterprise-banner-text p {
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  font-family: 'Lato-Bold';
  color: #634f4f;
  margin-top: 24px;
  width: 45%;
}

.recruitment-process-section-full-width {
  background: #d4d4d4;
  width: 100%;
  height: 588px;
  display: flex;
}

.recruitment-process-section-half-width {
  width: 50%;
  height: 588px;
  background: url('../images/enterprise/enterpriseleftbg.jpg');
  background-repeat: no-repeat;
  background-size: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.recruitment-process-section-half-width h3 {
  font-weight: 900;
  font-size: 34px;
  line-height: 41px;
  text-align: center;
  text-transform: uppercase;
  font-family: 'Lato-Black';
  color: #b32b7f;
  background: #c4d8fe;
  height: 70px;
  width: 75%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.recruitment-process-section-right-width {
  width: 50%;
}

.recruitment-process-section-right-whole-card {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 30px 0;
  align-items: center;
}

.recruitment-process-section-right-card {
  width: 80%;
  height: 156px;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  border-radius: 70px;
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.process-section-right-card-icons img {
  width: 73px;
}

.process-section-right-card-icons {
  margin: 0 30px;
}

.recruitment-process-right-card-text {
  width: calc(100% - 77px);
  padding: 25px 33px 25px 25px;
  position: relative;
  min-height: 157.6px;
}

.recruitment-process-right-card-text::before {
  content: '';
  background: #c4c4c4;
  width: 1px;
  height: 128px;
  position: absolute;
  top: 14px;
  left: 0;
}

.recruitment-process-right-card-heading {
  font-size: 20px;
  line-height: 24px;
  text-transform: uppercase;
  margin-bottom: 10px;
  color: #8d1e1e;
  font-family: 'Lato-Bold';
}

.recruitment-process-right-card-para {
  font-size: 16px;
  line-height: 19px;

  color: #634f4f;
}

.turnkey-system-section-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 33px;
}

.turnkey-system-section-heading {
  font-size: 24px;
  line-height: 29px;
  text-transform: uppercase;
  font-family: 'Lato-Black';
  color: #b32b7f;
  width: 90%;
  margin-right: 80px;
}

.turnkey-system-section-para {
  font-size: 18px;
  line-height: 22px;
  text-transform: uppercase;
  color: #634f4f;
}

.turnkey-system-section {
  padding: 50px 0 0;
}

.turnkey-system-card {
  background: #ffffff;
  box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  height: 231px;
  position: relative;
  width: 32%;
  margin-right: 2%;
  transition: 0.5s ease;
  margin-bottom: 43px;
}

.turnkey-system-card:last-child,
.turnkey-system-card:nth-child(3) {
  margin-right: 0;
}

.turnkey-system-card::after {
  content: '';
  background: url('../images/enterprise/turnkeycardbgpng.png');
  height: 137px;
  width: 100%;
  background-size: 100%;
  background-repeat: no-repeat;
  position: absolute;
  bottom: 0;
  z-index: 0;
}

.turnkey-system-card-content {
  position: relative;
  z-index: 1;
}

.turnkey-system-card-icon {
  display: flex;
  justify-content: center;
  padding: 15px 0 61px;
}

.turnkey-system-card-icon img {
  width: 61px;
}

.turnkey-system-card-content {
  text-align: center;
}

.turnkey-system-card-content h5 {
  font-weight: 900;
  font-size: 18px;
  line-height: 22px;
  font-family: 'Lato-Black';
  text-align: center;

  color: #ffffff;
}

.turnkey-system-card-content p {
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  width: 80%;
  margin: 0 auto;
  color: #ffffff;
}

.turnkey-system-card-content::after {
  content: '';
  position: absolute;
  right: 46px;
  top: -40px;
  background: url('../images/enterprise/recruitmentopacity.png');
  width: 61px;
  height: 61px;
  background-size: 100%;
}

.turnkey-system-card-content.client-bg::after {
  background: url('../images/enterprise/clientopacity.png');
  width: 61px;
  height: 61px;
  background-size: 100%;
}

.turnkey-system-card-content.legal-bg::after {
  background: url('../images/enterprise/legalopacity.png');
  width: 61px;
  height: 61px;
  background-size: 100%;
}

.turnkey-system-card-content.simple-bg::after {
  background: url('../images/enterprise/simpleopacity.png');
  width: 61px;
  height: 61px;
  background-size: 100%;
}

.turnkey-system-card-content.staffing-bg::after {
  background: url('../images/enterprise/staffopacity.png');
  width: 61px;
  height: 61px;
  background-size: 100%;
}

.turnkey-system-card-content.peace-bg::after {
  background: url('../images/enterprise/peaceopacity.png');
  width: 61px;
  height: 61px;
  background-size: 100%;
}

.turnkey-system-card:hover {
  background: #b32b7f;
  transition: 0.5s ease;
}

.hover-img {
  display: none;
}

.turnkey-system-card:hover .normal-img {
  display: none;
}

.turnkey-system-card:hover .hover-img {
  display: block;
}

.turnkey-system-card-area {
  display: flex;
  flex-wrap: wrap;
}

.privacy-bg {
  height: 1835px;
  background: url('../images/enterprise/privacybg.jpg');
  background-size: 100%;
  background-repeat: no-repeat;
  width: 100%;
  padding: 43px 0;
}

.privacy-bg-main {
  width: 90%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0 auto;
}

.privacy-bg-card {
  background: #ffffff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.4);
  border-radius: 5px;
  width: 100%;
  padding: 15px 0;
  border-left: 5px solid #b32b7f;
  display: flex;
  align-items: center;
  padding-right: 30px;
  margin-bottom: 35px;
}

.privacy-bg-card-icon img {
  width: 72px;
  margin: 0 53px;
}

.privacy-bg-card-content h3 {
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  font-family: 'Lato-Bold';
  color: #8d1e1e;
  margin-bottom: 13px;
}

.privacy-bg-card-content p {
  font-size: 16px;
  line-height: 19px;
  color: #634f4f;
  margin-bottom: 10px;
}

.privacy-bg-card-content ul {
  margin: 0;
}

.privacy-bg-card-content ul li span {
  margin-left: 10px;
  width: 96%;
}

.privacy-bg-card-content ul li {
  display: flex;
  margin-bottom: 10px;
  font-size: 13px;
  line-height: 16px;

  color: #b32b7f;
}

.custom-bulletin-area {
  display: flex;
}

.custom-bulletin-area ul {
  width: 50%;
}

.privacy-bg-card-content {
  width: 100%;
}

.how-get-started {
  background: linear-gradient(180deg, #b32b7f 0%, #661549 100%);
  width: 100%;
  padding: 66px 0;
}

.get-started-img img {
  width: 100%;
}

.get-started-content h3 {
  font-weight: 900;
  font-size: 34px;
  line-height: 41px;
  text-transform: uppercase;
  font-family: 'Lato-Black';
  color: #ffffff;
  margin-bottom: 12px;
}

.get-started-content p {
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 15px;
  color: #ffffff;
  width: 90%;
}

.get-pricing-btn a {
  background: #c4d8fe;
  border-radius: 8px;
  font-size: 24px;
  line-height: 29px;
  color: #b32b7f;
  width: 204px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Lato-Black';
  margin-top: 35px;
}

ul.common-bulletin {
  width: 70%;
  margin: 0 auto;
}

/* internal enterprise page */
.internal-enterprise-banner-page {
  display: flex;
}

.internal-enterprise-banner-left {
  width: 50%;
  height: 466px;
  background: #8157da;
  display: flex;
  justify-content: center;
  align-items: center;
}

.internal-enterprise-banner-right {
  background: url('../images/enterprise/new_enterprise_banner.jpg');
  background-repeat: no-repeat;
  height: 466px;
  width: 50%;
  background-size: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.internal-enterprise-banner-left h2 {
  font-weight: 900;
  font-size: 40px;
  line-height: 48px;
  text-align: center;
  color: #ffffff;
  font-family: 'Lato-Black';
  margin: 0;
}

.internal-enterprise-banner-right p {
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  font-family: 'Lato-Bold';
  color: #ffffff;
  width: 75%;
  margin: 0;
}

.internal-recruitment-card {
  background: #ded5f0;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  height: 361px;
  padding: 18px 22px 16px;
}

.internal-recruitment-process {
  padding: 50px 0;
  background: #eeeeee;
}

.internal-recruitment-heading h3 {
  font-weight: 900;
  font-size: 26px;
  line-height: 31px;
  text-align: center;
  text-transform: uppercase;
  font-family: 'Lato-Black';
  color: #8157da;
  margin-bottom: 10px;
}

.internal-recruitment-heading hr {
  width: 45px;
  height: 4px;
  background: #8157da;
  text-align: center;
  margin: 0 auto 50px;
}

.internal-recruitment-icon-text {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 15px;
}

.internal-recruitment-icon img {
  width: 81px;
}

.internal-recruitment-text {
  font-weight: 900;
  font-size: 45px;
  line-height: 54px;
  letter-spacing: 0.1em;
  -webkit-text-stroke: 1pt #0a2855;
  color: transparent;
}

.internal-recruitment-content h4 {
  font-size: 20px;
  line-height: 24px;
  text-transform: uppercase;
  font-family: 'Lato-Bold';
  color: #0a2855;
  margin-bottom: 14px;
}

.internal-recruitment-content p {
  font-size: 18px;
  line-height: 22px;

  color: #0a2855;
}

.turnkey-section {
  background: #f8f6f6;
}

.internal-recruitment-heading p {
  margin-bottom: 40px;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  text-transform: uppercase;

  color: #494949;
}

.turnkey-card-img {
  height: 195px;
  background: url('../images/enterprise/recruitmentbg.jpg');
  width: 100%;
  background-repeat: no-repeat;
  background-size: 100%;
  position: relative;
}

.clientbgsection {
  height: 195px;
  background: url('../images/enterprise/clientbg.jpg');
  width: 100%;
  background-repeat: no-repeat;
  background-size: 100%;
  position: relative;
}

.legalbgsection {
  height: 195px;
  background: url('../images/enterprise/legalbg.jpg');
  width: 100%;
  background-repeat: no-repeat;
  background-size: 100%;
  position: relative;
}

.simplebgsection {
  height: 195px;
  background: url('../images/enterprise/simplebg.jpg');
  width: 100%;
  background-repeat: no-repeat;
  background-size: 100%;
  position: relative;
}

.staffbgsection {
  height: 195px;
  background: url('../images/enterprise/staffbg.jpg');
  width: 100%;
  background-repeat: no-repeat;
  background-size: 100%;
  position: relative;
}

.peacebgsection {
  height: 195px;
  background: url('../images/enterprise/peacebg.jpg');
  width: 100%;
  background-repeat: no-repeat;
  background-size: 100%;
  position: relative;
}

.turnkey-card-icon {
  position: absolute;
  width: 60px;
  height: 60px;
  background: #8157da;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 9px;
  top: 9px;
}

.turnkey-card-icon img {
  width: 40px;
}

.turnkey-card {
  height: 410px;
  background: #ffffff;
  box-shadow: 0px 0px 6px #544f5f;
  border-radius: 10px;
  padding: 20px 14px;
  margin-bottom: 40px;
}

.turnkey-card-img {
  margin-bottom: 29px;
}

.turnkey-card-content h4 {
  font-weight: 900;
  font-size: 18px;
  line-height: 22px;

  text-align: center;
  margin-bottom: 29px;
  color: #8157da;
}

.turnkey-card-content p {
  font-size: 16px;
  line-height: 19px;
  text-align: center;

  color: #494949;
}

.new-enterprise-privacy-bg {
  background: rgba(129, 87, 218, 0.2);
  width: 100%;
  padding: 70px 0;
}

#faq .card {
  margin-bottom: 0;
  border: 0;
  background: transparent;
  border-bottom: 1pt solid #d8d8d8;
  border-radius: 0;
}

#faq .card .card-header {
  border: 0;
  border-radius: 0;
  padding: 0;
  background: transparent;
}

#faq .card .card-header .btn-header-link {
  font-weight: 900;
  font-size: 15px;
  line-height: 22px;
  text-transform: uppercase;
  font-family: "Lato-Black";
  color: #0a2855;
  display: block;
  text-align: left;
  background: transparent;
  padding: 14px 0;
}

#faq .card .card-header .btn-header-link:after {
  content: '\f107';
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  float: right;
}

#faq .card .card-header .btn-header-link.collapsed {
  background: transparent;
  color: #0a2855;
}

#faq .card .card-header .btn-header-link.collapsed:after {
  content: '\f107';
}

#faq .card-body {
  padding: 0;
}

#faq .card .collapsing {
  background: transparent;
  line-height: 30px;
}

#faq .card .collapse {
  border: 0;
}

#faq .card .collapse.show {
  background: transparent;
  line-height: 30px;
  color: #222;
}

#faq .card-body p {
  font-size: 16px;
  line-height: normal;
  margin-bottom: 10px;
  color: #0a2855;
  font-family: "Lato-Regular";
  text-align: left;
}

#faq .card-body ul li svg {}

#faq .card-body ul li span {
  font-size: 12px;
  line-height: 14px;
  color: #0a2855;
  margin-left: 10px;
  width: 90%;
}

#faq .btn:focus,
#faq .btn.focus {
  outline: 0;
  box-shadow: none;
}

.privacyimg-area {
  margin-left: 70px;
}

#faq .card-body ul li {
  display: flex;
  margin-bottom: 10px;
}

.how-get-started-bg {
  background: url('../images/enterprise/how-to-get-started-bg.jpg');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top right;
  width: 100%;
  height: 751px;
}

.how-get-started-bg .get-started-content h3 {
  color: #8157da;
  text-align: left;
  margin-bottom: 30px;
}

.how-get-started-bg .get-started-content ul li {
  display: flex;
  margin-bottom: 15px;
}

.how-get-started-bg .get-started-content ul li span {
  font-size: 18px;
  line-height: 22px;
  width: 90%;
  color: #0a2855;
  margin-left: 10px;
}

.how-get-started-bg .get-started-content {
  padding-top: 40px;
}

.how-get-started-bg .get-started-content .get-pricing-btn a {
  background: #8157da;
  color: #fff;
  border-radius: 25px;
  margin-top: 45px;
}

/* Login Page */
.login-main-body {
  width: 100%;
  height: auto;
  background-color: #e7f6fd;
}

.login-bg-section {
  width: 100%;
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('../images/login/loginTech.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  z-index: 3;
}

.login-bg-section::after {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #004c70;
  mix-blend-mode: multiply;
  opacity: 0.99;
}

.signup-bg-section {
  background-image: url('../images/login/signup.jpg');
}

.pwd-bg-section {
  background-image: url('../images/login/forgotPwdMain.jpg');
}

.signUp-main-bg {
  background-image: url('../images/login/hiring.jpg');
}

.change-pwd-bg-section {
  background-image: url('../images/login/forgotPwd1.png');
}

/* .login-bg-section {
  width: 100%;
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
} */
.login-card {
  width: 90%;
  height: 500px;
  background-color: white;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
}

.login-card .login-card-left {
  width: 50%;
  height: 100%;
  background-image: url('../images/enterprise/login.jpg');
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
  z-index: 3;
}

.login-card .login-card-left::after {
  width: 100%;
  height: 100%;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.8;
  background-color: #004c70;
  mix-blend-mode: multiply;
}

.login-card .login-card-right {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-card-main {
  width: 55%;
  height: auto;
  border-radius: 20px;
  background-color: white;
  padding: 20px 25px;
  z-index: 3;
  display: flex;
  justify-content: space-between;
}

.pwd-card {
  width: 30%;
  display: block;
  padding: 20px 45px;
}

.signUp-card {
  width: 35%;
  margin: 10px 20px;
  display: block;
}

.pwd-card h4 {
  font-family: Lato-bold;
  font-style: normal;
  font-weight: bold;
  line-height: 30px;
  color: black;
  border-radius: 30px;
  text-transform: uppercase;
  text-align: center;
  padding: 5px 0px;
}

.login-email-sec {
  width: 60%;
  height: 100%;
  padding-right: 20px;
  border-right: 1px solid grey;
}

/* .login-card-right .login-card-main {
  width: 80%;
  height: 380px;
  border-radius: 10px;
  background-color: #004c70;
  padding: 30px 25px;
} */
.login-card-main h3 {
  font-family: Lato-bold;
  font-style: normal;
  font-weight: bold;
  line-height: 30px;
  color: black;
  border-radius: 30px;
  text-transform: uppercase;
  text-align: center;
  padding: 5px 0px;
}

.custom-form-group input {
  width: 100%;
  padding: 14px 10px;
  margin-bottom: 10px;
  font-family: Lato-bold;
  font-style: normal;
  line-height: 25px;
  color: #004c70;
  font-size: 16px;
  position: relative;
  border: none;
  border-bottom: 1px solid grey;
}

.custom-form-group-signUp {
  padding: 10px 30px;
}

.custom-form-group input:focus {
  outline: 0;
}

.custom-form-group input::placeholder {
  color: hsl(199, 30%, 48%);
  font-size: 14px;
  font-family: Lato-light;
  opacity: 1;
  /* Firefox */
}

.custom-form-group input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #004c70;
  font-family: Lato-light;
  font-size: 14px;
}

.custom-form-group input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #004c70;
  font-family: Lato-light;
  font-size: 14px;
}

.custom-form-group {
  position: relative;
}

.custom-form-group svg {
  position: absolute;
  top: 15px;
  right: 14px;
}

.login-btn-section {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-btn-section button {
  padding: 8px 20px;
  background-color: #f4d00c;
  font-family: Lato-bold;
  font-style: normal;
  line-height: 25px;
  color: #004c70;
  font-size: 16px;
  text-transform: uppercase;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
}

.login-btn-section button:active {
  background-color: #f4d00c;
  color: #004c70;
}

.login-btn-section button:focus {
  background-color: #f4d00c;
  color: #004c70;
}

.login-btn-section svg {
  width: 30px;
  height: 25px;
}

.signUp-btn svg {
  width: 22px;
  height: 22px;
}

.login-btn-section button:hover {
  color: #004c70;
  background-color: #f4d00c;
}

.login-link-details {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 15px 0px;
}

.login-link-details .signUp {
  font-family: Lato-light;
  font-style: normal;
  line-height: 20px;
  color: #004c70;
  font-size: 12px;
}

.login-link-details .forgetPwd {
  font-family: Lato-light;
  font-style: normal;
  line-height: 15px;
  color: #004c70;
  font-size: 12px;
}

.login-link-details a span {
  text-decoration: underline;
  color: #004c70;
  font-family: Lato-bold;
  letter-spacing: 1px;
  text-transform: capitalize;
}

.login-checkbox-section {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.custom-checkbox label {
  font-family: Lato-regular;
  font-style: normal;
  line-height: 20px;
  color: #004c70;
  font-size: 14px;
}

.login-social-sec {
  width: 40%;
  height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.login-social-sec button {
  width: 220px;
  height: 40px;
  font-family: Lato-bold;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  background: #004c70;
  margin: 10px 0px;
}

.login-social-sec button:hover {
  color: #ffffff;
}

.agree-terms {
  width: 100%;
  margin-bottom: 10px;
  padding: 0px 30px;
}

.agree-terms .custom-checkbox input {
  margin-right: 10px;
}

.agree-terms .custom-checkbox label {
  font-size: 12px;
}

.login-social-sec button svg {
  margin-right: 10px;
}

.login-social-sec button {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.employer-section {
  width: 100%;
  display: block;
}

.employer-section h3,
.jobSeeker-section h3 {
  font-family: Lato-bold;
  font-style: normal;
  font-weight: bold;
  line-height: 30px;
  color: black;
  border-radius: 30px;
  text-transform: uppercase;
  text-align: center;
  padding: 5px 0px;
}

.signUp-main {
  display: flex;
  justify-content: space-evenly;
}

.employer-main {
  display: flex;
  justify-content: space-between;
}

.employer-img img {
  width: 150px;
  height: 180px;
}

.employer-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.employer-content ul li {
  font-family: Lato-regular;
  font-style: normal;
  line-height: 30px;
  color: #004c70;
  text-transform: capitalize;
}

.employer-content ul li b {
  color: black;
}

/* Employer ==> how it works? */

.employer-how-it-works-main {
  width: 100%;
  height: auto;
}

.job-post-option-section {
  width: 100%;
  height: auto;
}

.option1 {
  width: 100%;
  height: 650px;
  background-color: #e7f6fd;
  display: flex;
  justify-content: space-between;
}

.option2 {
  width: 100%;
  height: 800px;
}

.option1 .content-left {
  width: 50%;
  height: 100%;
  padding: 0px 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.option1 .video-right {
  width: 50%;
  height: 100%;
  background-color: rgba(0, 76, 112, 0.2);
}

.content-left h2 {
  padding: 10px;
  text-align: center;
  font-family: Lato-bold;
  font-style: normal;
  line-height: 30px;
  color: #f4d00c;
  text-transform: capitalize;
}

.content-left h2 span {
  color: #004c70;
}

.option1 .content-left ul {
  list-style: symbols(peace);
  padding-left: 20px;
}

.option1 .content-left ul li {
  font-family: Lato-regular;
  font-style: normal;
  line-height: 30px;
  color: #004c70;
  font-size: 18px;
  padding: 10px 0px;
}

.video-right {
  display: flex;
  justify-content: center;
  align-items: center;
}

.option-heading {
  width: 100%;
  height: 250px;
  background-color: #004c70;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('../images/how-it-works-employer/hiring-banner.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.option-heading::after {
  width: 100%;
  height: 100%;
  background-color: #004c70;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.5;
  mix-blend-mode: multiply;
}

.option-heading h1 {
  font-family: Lato-regular;
  font-style: normal;
  line-height: 60px;
  color: white;
  text-align: center;
  z-index: 3;
}

.main-heading {
  z-index: 3;
}

/* how-it-works-jobseeker */

.how-it-works-jobseeker-banner {
  height: 400px;
  background: url("../images/how-it-works-jobseeker/bannerbg.jpg");
  width: 100%;
  background-repeat: no-repeat;
  background-size: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.how-it-works-jobseeker-banner .banner-text {
  text-align: center;
  color: #fff;
}

.how-it-works-jobseeker-banner .banner-text h2 {
  font-size: 40px;
  line-height: 48px;
  font-family: "Lato-Black";
  color: #fff;
}

.how-it-works-jobseeker-banner .banner-text p {
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  font-family: "Lato-Bold";
}

.how-it-works-jobseeker-card {
  background: #004c70;
  box-shadow: 0px 0px 9px rgb(122 122 122 / 25%);
  border-radius: 10px;
  padding: 25px 15px 26px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.how-it-works-jobseeker-card-icon {
  width: 48px;
  height: 48px;
  background: #fff;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
}

.how-it-works-jobseeker-card-icon i {
  font-size: 26px;
  color: #0093d1;
}

.how-it-works-employer-section .how-it-works-jobseeker-card-text {
  font-size: 22px;
  font-family: "Lato-Black";
  color: #004c70;
}

.sorts-of-jobs-hiring-single-svg i {
  font-size: 40px;
  color: #fff;
  margin-bottom: 15px;
}

.sorts-of-jobs-hiring-single-icon h4 {
  font-family: "Lato-bold";
  font-style: normal;
  color: #fff;
  line-height: 25px;
}

.how-it-works-jobseeker-card-text {
  font-size: 22px;
  font-family: "Lato-Black";
  color: #fff;
}

.faq-section {
  background: #e8e8e8;
  padding: 50px 0;
}

#faq .card-body ul li span a {
  color: #0a2855;
}

/*---About us---*/
.about-us-list-view {
  list-style: disc;
  margin-top: 20px;
  padding-left: 20px;
}

.about-us-list-view li {
  display: list-item;
}

.aboutuspagebg {
  width: 100%;
  height: 450px;
  background: url('../images/about/abouttoparea.jpg');
  background-size: cover;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  padding: 100px 0 0;
  position: relative;
}

.aboutuspagebg .banner-text {
  color: #fff;
  text-align: center;
}

.aboutus-founder-content-para p {
  font-size: 16px;
  margin-bottom: 8px;
}

.aboutuspagebg .banner-text h2 {
  font-size: 40px;
  line-height: 48px;
  font-family: 'Lato-Black';
  color: #fff;
}

.aboutus-founder-content {
  position: absolute;
  background: #0093d4;
  width: 50%;
  color: #fff;
  padding: 10px;
  bottom: 0;
  border-top: 6pt solid #004c70;
}

.aboutus-section {
  background: url('../images/homepage/jobseekerbg.jpg');
  background-size: cover;
  height: auto;
}

.aboutus-section .pay-your-staff-bg-card {
  background: #0093d4;
  box-shadow: none;
  border-left: 6pt solid #004c70;
  align-items: center;
}

.aboutus-section .pay-your-staff-section-bg .pay-your-staff-bg-card::before,
.aboutus-section .pay-your-staff-section-bg .pay-your-staff-bg-card::after {
  content: none;
}

.aboutus-section .pay-your-staff-bg-card .common-heading {
  color: #fff;
}

.aboutus-section .pay-your-staff-bg-card .common-border {
  background: #fff;
}

.aboutus-section .pay-your-staff-bg-card .common-bulletin li label {
  color: #fff;
}

.aboutus-section .pay-your-staff-bg-card .common-bulletin li span svg path {
  fill: #fff;
}

.aboutus-section-icon {
  background: #fff;
  display: flex;
  width: 100px;
  height: 100px;
  padding: 15px;
  border-radius: 10px;
  margin: 0 0 0 auto;
}

.aboutus-section .pay-your-staff-bg-card-content {
  width: 85%;
}

.aboutus-section .pay-your-staff-bg-card-content2 {
  width: 15%;
}

.aboutus-section .pay-your-staff-bg-card .common-bulletin li label>a {
  color: #fff;
  text-decoration: underline !important;
}

.last-thoughts-text p {
  margin-bottom: 10px;
  color: #fff;
}

.last-thoughts-text p>i>a {
  color: #fff;
  text-decoration: underline !important;
}

.aboutuspagesectionbg {
  padding: 50px 0;
  background: #0093d1;
}

.aboutuspagesectionbg .common-heading {
  color: #fff;
}

.aboutuspagesectionbg .common-border {
  background: #fff;
}

.common-para {
  color: #fff;
  text-align: center;
}

.aboutcompanytext p {
  color: #fff;
}

.aboutcompanyimg img {
  width: 20%;
  margin-bottom: 30px;
}

.aboutcompanyimg {
  display: flex;
  justify-content: center;
}

.aboutcompanytext2 h5 {
  color: #fff;
}

.aboutcompanytext2 p,
.aboutcompanytext2 p a {
  color: #fff;
}

/*---Privacy---*/
.privacypagebg {
  background: url('../images/privacy/privacybanner.jpg');
  background-repeat: no-repeat;
  height: 300px;
  width: 100%;
  background-size: 100%;
  flex-direction: column;
  justify-content: center;
}

.privacypagebg .banner-text h2 {
  font-size: 40px;
  line-height: 48px;
  font-family: 'Lato-Black';
  color: #fff;
}

.privacypagebg .banner-text p {
  font-weight: bold;
  font-size: 18px;
  line-height: 29px;
  font-family: 'Lato-Bold';
}

.termsusebg {
  background: url('../images/privacy/privacybanner.jpg');
  background-repeat: no-repeat;
  height: 300px;
  width: 100%;
  background-size: 100%;
  flex-direction: column;
  justify-content: center;
}

.contactbg {
  background: url('../images/privacy/contactbanner.jpg');
  background-repeat: no-repeat;
  height: 300px;
  width: 100%;
  background-size: 100%;
  flex-direction: column;
  justify-content: center;
}

.termsusebg p {
  width: 80%;
  margin: 0 auto;
}

/* .privacypagebg .internal-enterprise-banner-right {
  background: url(../images/privacy/privacybanner.jpg);
  background-repeat: no-repeat;
  height: 466px;
  width: 100%;
  background-size: 100%;
  flex-direction: column;
} */
.privacypagebg .banner-text {
  color: #fff;
  text-align: center;
  width: 100%;
}

.privacypagebg p {
  color: #fff;
  text-align: center;
}

.privacypagebg .internal-enterprise-banner-right p {
  margin-bottom: 30px;
}

.privacypolicysection {
  padding: 50px 0;
  background: url('../images/homepage/jobseekerbg.jpg');
  background-size: cover;
  height: auto;
}

.privacypolicysectioncard {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 0 4px 0 #aaa;
  padding: 20px;
  margin-bottom: 30px;
}

.privacycardheading {
  color: #004c70;
  font-size: 24px;
  font-family: 'Lato-Black';
}

.privacycardline {
  border-top: 1pt solid #494949;
}

.privacycardpara {
  color: #494949;
  font-size: 16px;
  margin-bottom: 25px;
}

.privacypolicysectioncard-details ul li {
  margin-bottom: 10px;
  list-style: auto;
}

.privacypolicysectioncard-details ul {
  margin-left: 30px;
}

/*---employer---*/
.employer-sidebar-left {
  width: 280px;
  background: #0093d1;
  height: 900px;
  position: relative;
}

.employer-pro-pic {
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  margin: 20px auto;
  position: relative;
}

.employer-pro-pic img {
  border-radius: 50%;
  border: 1pt solid #004c70;
}

.employer-sidebar-section {
  display: flex;
}

.employer-pro-name {
  display: flex;
  justify-content: center;
  color: #f4d00c;
  font-family: 'Lato-Black';
}

/* .employer-sidebar-left::after{
content: "";
background-image: url("../images/employermodule/sidebar_bottom.png");
height: 170px;
width: 100%;
background-repeat: no-repeat;
background-size: 100%;
position: absolute;
bottom: 0;
} */
.certificate-badge {
  position: absolute;
  bottom: -24px;
}

.certificate-badge svg g>path {
  fill: #f4d00c;
}

.employer-sidebar-left ul {
  padding: 30px 7px;
}

.employer-sidebar-left ul li {
  margin-bottom: 5px;
  padding: 10px;
}

.employer-sidebar-left ul li a {
  color: #fff;
}

.employer-sidebar-left ul li a>svg {
  margin-right: 15px;
}

.dashboard-active {
  background: #004c70;
  border-radius: 5px;
}

.employer-sidebar-content-right {
  width: calc(100% - 280px);
  padding-left: 30px;
  padding-top: 30px;

  padding-right: 30px;
}

.employer-sidebar-right-heading .common-heading {
  text-align: left;
}

.employer-sidebar-right-heading .common-border {
  margin: 0;
}

.employer-sidebar-right-heading .common-para {
  text-align: left;
  color: #494949;
  margin: 20px 0;
}

.employer-sidebar-right-card {
  background: #fff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.25);
}

.employer-sidebar-right-card .nav-link span img {
  width: 24px;
  margin-right: 15px;
}

.employer-sidebar-right-card .nav-pills {
  width: 100%;
  background: #004c70;
}

.employer-sidebar-right-card .nav-pills .nav-link.active,
.employer-sidebar-right-card .nav-pills .show>.nav-link {
  color: #fff;
  background-color: #0093d1;
  border-radius: 0;
}

.employer-sidebar-right-card .nav-pills .nav-link {
  color: #fff;
}

.employer-sidebar-right-card .tab-content {
  background: #eeee;
}

.dashboardtabsection-left {
  width: 50%;
}

.dashboardtabsection-right {
  width: 50%;
}

.dashboardtabsection {
  display: flex;
  background: #0093d1;
  padding: 20px;
  align-items: center;
}

.dashboardtabsection-right img {
  width: 70%;
}

.dashboardtabsection-left h3 {
  text-align: left;
}

.dashboard-banner-section {
  background: url('../images/employermodule/dashboardbannerbg.jpg');
  background-repeat: no-repeat;
  height: 300px;
  width: 100%;
  background-size: cover;
  padding: 65px 30px;
}

.dashboard-banner-right h4 {
  line-height: 25px;
  font-family: 'Lato-bold';
  font-style: normal;
  color: #ffff;
  font-size: 28px;
}

.dashboard-banner-right p {
  color: #fff;
}

.dashboard-banner-right p>a {
  color: #f4d00c;
}

.dashboard-banner-right button {
  width: 180px;
}

.dashboard-banner-left {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* post a job */

.post-job-main {
  width: 100%;
  height: auto;
}

.post-job-banner {
  width: 100%;
  height: 220px;
  background-image: url('../images/post-A-job-employer/post-job-banner-bg.jpg');
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: center;
}

.post-job-banner::after {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #004c70;
  opacity: 0.5;
  mix-blend-mode: multiply;
}

.post-job-banner-content {
  width: 60%;
  height: auto;
  z-index: 3;
}

.post-job-banner-content h1 {
  font-family: Lato-regular;
  font-style: normal;
  line-height: 60px;
  color: white;
  text-align: center;
  z-index: 3;
}

.post-job-banner-content p {
  font-family: Lato-bold;
  font-style: normal;
  line-height: 20px;
  color: #f4d00c;
  text-align: center;
  z-index: 3;
  margin-bottom: 0px;
  font-size: 18px;
}

.post-job-section {
  width: 100%;
  height: auto;
  background-color: rgba(231, 246, 253, 0.2);
}

.post-job-form-section {
  width: 100%;
  height: auto;
  padding: 30px 0px;
}

.custom-job-post-form-group {
  width: 100%;
  margin-bottom: 15px;
  position: relative;
}

.custom-job-post-form-group label {
  font-family: Lato-bold;
  font-style: normal;
  line-height: 20px;
  color: #004c70;
  text-align: center;
  font-size: 18px;
  margin: 5px 0px;
}

.custom-job-post-form-group input {
  width: 100%;
  padding: 10px;
  border: 1px solid #f4d00c;
  font-family: Lato-bold;
  font-style: normal;
  line-height: 20px;
  color: #004c70;
  font-size: 18px;
  margin: 10px 0px;
}

.custom-job-post-form-group input:focus {
  outline: 0;
}

.custom-job-post-form-group input::placeholder {
  color: hsl(199, 30%, 48%);
  font-size: 14px;
  font-family: Lato-light;
  opacity: 1;
  /* Firefox */
}

.custom-job-post-form-group input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #004c70;
  font-family: Lato-light;
  font-size: 14px;
}

.custom-job-post-form-group input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #004c70;
  font-family: Lato-light;
  font-size: 14px;
}

.custom-job-post-form-group span {
  font-family: Lato-regular;
  font-style: normal;
  line-height: 20px;
  color: #0093d1;
  font-size: 15px;
  margin: 5px 0px;
}

.custom-job-post-form-group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #f4d00c;
  font-family: Lato-bold;
  font-style: normal;
  line-height: 20px;
  color: #004c70;
  font-size: 18px;
  margin: 10px 0px;
}

.custom-job-post-form-group textarea:focus {
  outline: 0;
}

.custom-job-post-form-group p {
  font-family: Lato-light;
  font-style: normal;
  line-height: 20px;
  color: #004c70;
  font-size: 15px;
  margin: 5px 0px;
}

.custom-job-post-form-group ul li {
  font-family: Lato-regular;
  font-style: normal;
  line-height: 20px;
  color: #0093d1;
  font-size: 15px;
  margin: 5px 0px;
}

.custom-job-post-form-group select {
  width: 100%;
  padding: 10px;
  border: 1px solid #f4d00c;
  font-family: Lato-bold;
  font-style: normal;
  line-height: 20px;
  color: #004c70;
  font-size: 18px;
  margin: 10px 0px;
}

.custom-job-post-form-group .select-small {
  width: 30%;
}

.form-inline .select-small {
  margin-top: 0px;
}

.custom-job-post-form-group .input-small {
  width: 285px;
}

.custom-job-post-form-group .input-group-text {
  background-color: inherit;
  border: 1px solid #f4d00c;
}

.custom-job-post-form-group .input-group-append {
  width: auto;
  height: 52px;
  margin-top: 5px;
}

.form-inline {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.custom-job-post-form-group h3 {
  font-family: Lato-bold;
  font-style: normal;
  line-height: 30px;
  color: #004c70;
  margin: 10px 0px;
}

.custom-job-post-form-group .custom-checkbox-main button {
  width: 100%;
  background-color: #004c70;
  margin: 10px 0px;
  padding: 10px;
  font-family: Lato-bold;
  font-style: normal;
  line-height: 30px;
  color: #f4d00c;
  font-size: 22px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.custom-checkbox-main .form-custom-checkbox input {
  width: auto;
  margin: 10px 10px 15px 10px;
}

.book-footer-section {
  width: 100%;
  height: 400px;
  /* margin-bottom: 20px; */
}

.book-img-section {
  width: 100%;
  height: 100%;
  position: relative;
  background-image: url('../images/post-A-job-employer/book-footer.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.book-content {
  width: 50%;
  height: auto;
}

.book-img-section h4 {
  font-family: Lato-bold;
  font-style: normal;
  line-height: 30px;
  color: white;
  text-transform: uppercase;
}

.book-img-section p {
  font-family: Lato-regular;
  font-style: normal;
  line-height: 25px;
  color: white;
  margin-top: 20px;
}

/* Bookmarked workers */

.bookmarked-workers-page {
  width: 100%;
  height: auto;
  margin-bottom: 50px;
}

.bookmark-banner {
  width: 100%;
  height: 280px;
  background-image: url('../images/bookmarkedWorkers-employer/bookmarked-workers-banner.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bookmark-banner::after {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #004c70;
  opacity: 0.5;
  mix-blend-mode: multiply;
}

.banner-content {
  width: 100%;
  height: auto;
  z-index: 3;
}

.banner-content h2,
.banner-content h3 {
  font-family: Lato-bold;
  font-style: normal;
  line-height: 30px;
  color: white;
  text-align: center;
  margin: 20px 0px;
}

.banner-content p {
  font-family: Lato-bold;
  font-style: normal;
  line-height: 30px;
  color: #f4d00c;
  text-align: center;
  margin-top: 20px;
}

.worker-filter-section {
  width: 100%;
  height: 160px;
  background-color: #e7f6fd;
  padding: 5px 150px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.custom-filter-section label {
  font-family: Lato-bold;
  font-style: normal;
  line-height: 20px;
  color: #004c70;
  /* width: 100%; */
  /* text-align: center; */
}

.custom-filter-section input {
  width: 200px;
  padding: 5px;
  border: 1px solid #f4d00c;
  color: black;
  font-family: Lato-bold;
  font-style: normal;
  background: white !important;
}

.custom-filter-section input:focus {
  outline: 0;
}

.custom-filter-section input::placeholder {
  color: #004c70;
  font-size: 14px;
  font-family: Lato-light;
  /* Firefox */
}

.custom-filter-section input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #004c70;
  font-family: Lato-light;
  font-size: 14px;
}

.custom-form-group input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #004c70;
  font-family: Lato-light;
  font-size: 14px;
}

.worker-filter-section form {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.custom-filter-section select {
  width: 120px;
  padding: 5px;
  border: 1px solid #f4d00c;
  color: #004c70;
  font-family: Lato-bold;
  font-style: normal;
  margin:0 20px;
}

.salaryRange {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.salaryRange span {
  padding: 0px 10px;
}

.custom-filter-section select:focus {
  outline: 0;
}

.custom-filter-section input[type='checkbox'] {
  width: 20px;
  margin: 0px 5px;
}

.filter-checkbox {
  display: flex;
  flex-direction: row;
  margin-top: 12px;
}

.search-btn button {
  background-color: #0093d1;
  color: white;
}

.search-btn {
  width: auto;
  display: block;
  margin-right: 0px;
}

.search-clear-btn {
  margin-right: 0px;
  width: auto;
  display: block;
}

.search-btn button:active {
  background-color: #0093d1;
  color: white;
}

.search-btn button:focus {
  background-color: #0093d1;
  color: white;
}

.search-btn button:hover {
  background-color: #0093d1;
  color: white;
}

.bookmarked-workers-section {
  width: 100%;
  height: 950px;
  overflow: auto;
  padding: 40px 0px;
}

.workers-card-section {
  width: 100%;
  height: 350px;
}

.filter-btn-section {
  width: 100%;
  background-color: #e7f6fd;
  display: flex;
  justify-content: center;
  align-items: center;
}

.filter-btn-sub {
  width: 30%;
  display: flex;
  justify-content: space-evenly;
  margin-top: 30px;
}

/* sudarsun */
.margin-l-80 {
  margin-left: 80px;
  margin-right: 20px;
}

.home-page-carousel-card {
  background: linear-gradient(270deg, #ffffff 0%, #ffffff 97.75%);
  box-shadow: 0px 0px 9px rgba(122, 122, 122, 0.25);
  border-radius: 10px;
  display: flex;
  border-left: 6pt solid #004c70;
  padding: 17px 25px;
  margin-bottom: 40px;
}

.home-page-carousel-card-left {
  margin-right: 27px;
}

.home-page-carousel-card-left img {
  width: 107px;
  height: 107px;
  border-radius: 50%;
  margin-bottom: 17px;
}

.home-page-carousel-card-right h4 {
  font-weight: 900;
  font-size: 22px;
  line-height: 26px;
  text-transform: uppercase;
  font-family: 'Lato-Black';
  color: #004c70;
  margin-bottom: 0;
}

.years-of-exp-text {
  font-size: 15px;
  line-height: 18px;
  text-transform: uppercase;
  margin-bottom: 10px;
  color: #494949;
}

.years-of-exp-text span {
  color: #f4d00c;
  font-family: 'Lato-Black';
}

.price-text sup {
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
  font-family: 'Lato-Black';
  color: #494949;
  top: -5px;
}

.price-text label {
  font-size: 24px;
  line-height: 29px;
  text-transform: uppercase;
  font-family: 'Lato-Black';
  color: #0093d1;
}

.price-text span {
  font-size: 13px;
  line-height: 15.6px;
  color: #494949;
  text-transform: uppercase;
}

.bookmark-card {
  width: 100%;
  height: 300px;
  background-image: url('../images/bookmarkedWorkers-employer/card-banner.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  display: flex;
  border-left: 8px solid #f4d00c;
}

/* find a staff */

.bookmarked-workers-page {
  width: 100%;
  height: auto;
  padding: 30px 0px;
  background: #fafafa;
}

.bookmarked-workers-custom-container {
  width: 90%;
  margin: auto;
}

.worker-filter-section {
  width: 100%;
  height: auto;
  padding: 10px 25px;
  background: rgba(0, 76, 112, 0.1);
}

.worker-filter-section-sub {
  width: 100%;
  height: 100%;
  margin: auto;
  margin-top: 10px;
  padding: 5px 35px;
}

.custom-salary-range {
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-salary-range .slider {
  padding: 0px;
}

.custom-salary-range span {
  font-family: Lato-bold;
  font-style: normal;
  line-height: 20px;
  color: #004c70;
  text-align: center;
  font-size: 15px;
  padding: 0px 10px;
}

.custom-filter-section {
  width: 100%;
 
}

.custom-filter-section a {
  width: 100%;
  font-family: Lato-bold;
  font-style: normal;
  line-height: 28px;
  color: #000;
  text-align: center;
  font-size: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.search-workers-top {
  width: 100%;
  min-height: 80px;
  height: auto;
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
}

.search-workers-top p,
.chip-container {
  width: auto;
  font-family: 'Lato-Regular';
  font-style: normal;
  line-height: 20px;
  background-color: #aaa;
  color: white;
  padding: 5px 8px;
  border-radius: 30px;
  margin-right: 10px;
  font-size: 14px;
  display: flex;
  align-items: center;
  margin-bottom: 0.6rem;
}

.chip-container {
  display: inline-block;
  padding: 7px 15px;
}

.chip-container .close-button {
  padding-left: 7px;
  font-size: 18px;
  vertical-align: bottom;
  cursor: pointer;
}

.chip-container.active {
  background-color: #0093d1;
}

.search-workers-top p span,
.chip-container span {
  font-family: 'Lato-Bold';
  font-size: 20px;
  margin-left: 10px;
}

.search-workers-top .clear-filter-btn {
  font-family: 'Lato-Bold';
  text-transform: capitalize;
  background-color: transparent;
  border: none;
  width: 100px;
  height: 30px;
  color: #004c70;
}

.search-workers-top .clear-filter-btn:focus {
  outline: 0;
}

.custom-filter-section a:focus {
  border: none;
  outline: 0;
}

.custom-filter-section .collapse {
  margin-top: 10px;
}

.custom-filter-section .filter-menu label {
  font-family: Lato-bold;
  font-style: normal;
  line-height: 23px;
  color: grey;
  text-align: center;
  font-size: 14px;
  position: relative;
  cursor: pointer;
}

.filter-menu-input li a {
  font-family: Lato-bold;
  font-style: normal;
  line-height: 23px;
  color: grey;
  text-align: center;
  font-size: 14px;
}

.filter-menu-input input {
  display: block !important;
}

/* style checkbox */
.filter-menu input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.filter-menu label:before {
  content: '';
  -webkit-appearance: none;
  background-color: transparent;
  border: 2px solid #004c70;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 5px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
}

.filter-menu input:checked+label:after {
  content: '';
  display: block;
  position: absolute;
  top: 6px !important;
  left: 7px !important;
  width: 6px !important;
  height: 14px !important;
  border: solid #004c70;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

/* styled checkbox */
.worker-filter-section h5 {
  font-family: Lato-bold;
  font-style: normal;
  line-height: 23px;
  color: #004c70;
}

.custom-filter-section .filter-menu {
  /* margin-left: 20px; */
}

.custom-filter-section .filter-menu label:hover {
  color: #004c70;
}

.custom-filter-section input {
  width: auto;
  padding: 5px;
  border: 1px solid #f4d00c;
  color: #004c70;
  font-family: Lato-bold;
  font-style: normal;
  /* margin: 5px 20px; */
}

.custom-filter-section input:focus {
  outline: 0;
}

.custom-filter-section input::placeholder {
  color: #004c70;
  font-size: 14px;
  font-family: Lato-light;
  /* Firefox */
}

.custom-filter-section input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #004c70;
  font-family: Lato-light;
  font-size: 14px;
}

.custom-form-group input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #004c70;
  font-family: Lato-light;
  font-size: 14px;
}

.worker-filter-section form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.custom-filter-section select {
  width: 220px;
  padding: 5px;
  border: 1px solid #f4d00c;
  color: #004c70;
  font-family: Lato-bold;
  font-style: normal;
  background: gainsboro;
}

.search-btn-margin {
  margin-top: 30px;
}

.custom-filter-section select:focus {
  outline: 0;
}

.custom-filter-section input[type='checkbox'] {
  width: 20px;
  margin: 0px 5px;
}

.post-job-button {
  background-color: #004c70;
  height: 40px;
  width: 130px;
  border-radius: 4px
}

.search-btn button {
  background-color: #0093d1;
  color: white;
}

.search-btn {
  width: auto;
  display: block;
  margin-right: 0px;
}

.search-clear-btn {
  margin-right: 0px;
  width: auto;
  display: block;
}

.search-btn button:active {
  background-color: #0093d1;
  color: white;
}

.search-btn button:focus {
  background-color: #0093d1;
  color: white;
}

.search-btn button:hover {
  background-color: #0093d1;
  color: white;
}

.bookmarked-workers-section {
  width: 100%;
  height: auto;
  padding: 0;
}

.filter-btn-section {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.filter-btn-sub {
  width: 30%;
  display: flex;
  justify-content: space-evenly;
  margin-top: 30px;
}

.margin-l-80 {
  margin-left: 80px;
  margin-right: 20px;
}

.home-page-carousel-card {
  background: linear-gradient(270deg, #ffffff 0%, #ffffff 97.75%);
  box-shadow: 0px 0px 9px rgba(122, 122, 122, 0.25);
  border-radius: 10px;
  display: flex;
  border-left: 6pt solid #004c70;
  padding: 17px 25px;
  margin-bottom: 40px;
}

.bookmark-card-profile {
  width: 100%;
  height: 90px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.bookmark-card-profile img {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  /* margin-bottom: 17px; */
}

.bookmark-card-profile p {
  font-weight: bold;
  line-height: 26px;
  font-family: 'Lato-Bold';
  color: #000;
  padding: 25px 10px;
  width: max-content;
}

.bookmark-card-name-work-details h5 {
  font-weight: bold;
  line-height: 26px;
  text-transform: capitalize;
  font-family: 'Lato-Bold';
  color: #004c70;
  margin-bottom: 0px;
  text-align: left;
}

.bookmark-card-header {
  display: flex;
  justify-content: space-between;
}

.bookmark-card-name-work-details {
  width: 100%;
  height: auto;
}

.bookmark-card-name-work-details P {
  line-height: 25px;
  font-family: 'Lato-regular';
  color: #000;
  text-align: left;
  margin-bottom: 0px;
}

.bookmark-card-name-work-details span {
  font-family: 'Lato-regular';
  line-height: 25px;
  color: grey;
  padding: 5px 10px;
}

.bookmark-card-name-work-details span.heart-icon {
  border: 1px solid #f4d00c;
  border-radius: 50%;
  padding: 4px 6px;
}

.work-expertise {
  width: 100%;
  display: flex;
  align-items: center;
  margin: 5px 0px;
  flex-wrap: wrap;
}

.work-expertise span {
  padding: 0px 10px;
  border: 1px solid #f4d00c;
  border-radius: 25px;
  font-family: 'Lato-black';
  color: #004c70;
  text-align: center;
  font-size: 12px;
  margin: 5px;
}

.work-details-inperson {
  width: 100%;
  height: auto;
  padding: 10px 15px;
  padding-left: 0px;
}

.work-details-inperson p {
  font-family: 'Lato-Regular';
  font-weight: 500;
  color: grey;
  text-align: left;
  font-size: 13px;
}

.years-of-exp-text {
  font-size: 15px;
  line-height: 18px;
  text-transform: uppercase;
  margin-bottom: 10px;
  color: #494949;
}

.years-of-exp-text span {
  color: #f4d00c;
  font-family: 'Lato-Black';
}

.price-text sup {
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
  font-family: 'Lato-Black';
  color: #494949;
  top: -5px;
}

.price-text label {
  font-size: 24px;
  line-height: 29px;
  text-transform: uppercase;
  font-family: 'Lato-Black';
  color: #0093d1;
}

.price-text span {
  font-size: 13px;
  line-height: 15.6px;
  color: #494949;
  text-transform: uppercase;
}

.bookmark-card {
  width: 100%;
  height: auto;
  min-height: 250px;
  position: relative;
  border-left: none;
  display: flex;
  flex-direction: column;
}

.pagination {
  width: 100%;
  height: 100px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination .page-prev-next-icon {
  padding: 0px 6px;
  color: #004c70;
  font-size: 28px;
}

.pagination .page-prev-next-icon:hover {
  background-color: transparent;
  border-radius: 0px;
}

.pagination p {
  color: #004c70;
  font-weight: bold;
  font-size: 18px;
  padding: 5px 15px;
}

.pagination p:hover {
  background-color: #f4d00c;
  border-radius: 50%;
}

.pagination p.active {
  background-color: #f4d00c;
  border-radius: 50%;
}

/* sudarsun */
.margin-l-80 {
  margin-left: 80px;
  margin-right: 20px;
}

.home-page-carousel-card {
  background: linear-gradient(270deg, #ffffff 0%, #ffffff 97.75%);
  box-shadow: 0px 0px 9px rgba(122, 122, 122, 0.25);
  border-radius: 10px;
  display: flex;
  border-left: 6pt solid #004c70;
  padding: 17px 25px;
  margin-bottom: 40px;
}

.home-page-carousel-card-left {
  margin-right: 27px;
}

.home-page-carousel-card-left img {
  width: 107px;
  height: 107px;
  border-radius: 50%;
  margin-bottom: 17px;
}

.home-page-carousel-card-right h4 {
  font-weight: 900;
  font-size: 22px;
  line-height: 26px;
  text-transform: uppercase;
  font-family: 'Lato-Black';
  color: #004c70;
  margin-bottom: 0;
}

.years-of-exp-text {
  font-size: 15px;
  line-height: 18px;
  text-transform: uppercase;
  margin-bottom: 10px;
  color: #494949;
}

.years-of-exp-text span {
  color: #f4d00c;
  font-family: 'Lato-Black';
}

.price-text sup {
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
  font-family: 'Lato-Black';
  color: #494949;
  top: -5px;
}

.price-text label {
  font-size: 24px;
  line-height: 29px;
  text-transform: uppercase;
  font-family: 'Lato-Black';
  color: #0093d1;
}

.price-text span {
  font-size: 13px;
  line-height: 15.6px;
  color: #494949;
  text-transform: uppercase;
}

.worker-title {
  display: flex;
  flex-direction: column;
  padding: 15px 0px;
}

.worker-title p {
  line-height: 20px;
  font-family: 'Lato-Black';
  color: #f4d00c;
}

.worker-title span {
  line-height: 20px;
  font-family: 'Lato-Black';
  color: #ffffff;
  padding-left: 10px;
}

.worker-details {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.expected-salary p {
  line-height: 20px;
  font-family: 'Lato-Black';
  color: #004c70;
  margin-bottom: 0px;
  text-align: center;
}

.expected-salary span {
  line-height: 20px;
  font-family: 'Lato-Black';
  color: #0093d1;
  padding: 0px 10px;
  text-align: center;
}

.worker-words {
  width: 80%;
  margin: auto;
  padding: 5px 0px 10px 40px;
}

.worker-detail-right {
  width: 820px;
  padding: 0px 30px;
}

.worker-star-rating {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 15px 0px;
}

.star-rating .fa-star.checked {
  color: #004c70;
}

.star-rating p {
  margin-bottom: 0px;
}

.worker-words p {
  line-height: 20px;
  font-family: 'Lato-regular';
  color: #004c70;
  font-size: 14px;
}

.worker-detail-right hr {
  background-color: #f4d00c;
}

.worker-pagination-section {
  width: 100%;
  height: 100px;
  background-color: #e7f6fd;
  display: flex;
  justify-content: center;
  align-items: center;
}

.worker-pagination-section a {
  padding: 0px 5px;
  line-height: 20px;
  font-family: 'Lato-bold';
  color: #004c70;
  font-size: 14px;
}

.worker-pagination-section .page-prev-next-icon {
  border: 1px solid #f4d00c;
  border-radius: 50%;
  padding: 0px 6px;
  color: #004c70;
  background-color: #f4d00c;
  font-size: 28px;
  padding-bottom: 5px;
}

/* virtual staff academy */

.staff-academy-page {
  width: 100%;
  height: auto;
}

.book-offer-section {
  width: 100%;
  height: 500px;
  background-color: rgba(231, 246, 253, 0.4);
  background-image: url('../images/virtual-staff-academy/book-bg.png');
  background-position: right;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  justify-content: space-between;
  z-index: 3;
}

.book-offer-section::after {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-color: #004c70;
  opacity: 0.8;
  mix-blend-mode: multiply;
}

.book-offer-section-left {
  width: calc(100% - 400px);
  height: 100%;
  padding: 20px;
  z-index: 3;
}

.book-offer-section-right {
  width: 400px;
  height: 100%;
  padding-right: 60px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 3;
}

.book-offer-section-right img {
  width: 330px;
  height: 420px;
}

.book-offer-section-left h3,
.book-offer-section-left h2 {
  line-height: 25px;
  font-family: 'Lato-bold';
  color: #ffff;
  font-style: normal;
  text-align: center;
  padding: 10px 0px;
}

.book-offer-list {
  width: 780px;
  display: flex;
  justify-content: space-evenly;
  padding: 10px 0px;
}

.book-offer-list ul li {
  line-height: 25px;
  font-family: 'Lato-bold';
  color: #ffff;
  font-style: normal;
  padding: 3px 0px;
}

.book-offer-list ul {
  margin-left: 100px;
}

.book-offer-price {
  width: 60%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.book-offer-price h1 span {
  line-height: 25px;
  font-family: 'Lato-bold';
  font-style: normal;
  color: #ffff;
  padding: 15px 20px;
  text-decoration: line-through 5px red;
}

.book-offer-price h1 {
  line-height: 25px;
  font-family: 'Lato-bold';
  font-style: normal;
  color: #f4d00c;
}

.book-offer-btn {
  margin: 20px 0px;
}

.book-offer-btn button svg {
  margin: 0px 10px;
}

.training-material-section {
  width: 100%;
  height: auto;
  background-color: #004c70;
  padding: 30px 0px;
}

.training-material-header {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.training-material-header h1,
.training-material-header h4 {
  line-height: 25px;
  font-family: 'Lato-bold';
  font-style: normal;
  color: #ffff;
  padding: 8px 0px;
}

.training-material-header h3 {
  line-height: 25px;
  font-family: 'Lato-bold';
  font-style: normal;
  color: #f4d00c;
  padding: 8px 0px;
}

.training-didUKnow-section {
  width: 100%;
  height: 700px;
  background-color: #e7f6fd;
  padding: 30px 0px;
}

.training-didUKnow-section h1 {
  line-height: 50px;
  font-family: 'Lato-bold';
  font-style: normal;
  color: #004c70;
  padding: 10px 0px;
  text-align: center;
}

.trained-staff-section {
  width: 83%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 30px;
}

.trained-staff-section-left {
  width: 50%;
  display: flex;
  justify-content: space-between;
}

.animation-img-left h3 {
  font-family: 'Lato-bold';
  font-style: normal;
  color: #004c70;
  padding: 10px 0px;
  text-align: center;
}

.animation-img-right h3 {
  font-family: 'Lato-bold';
  font-style: normal;
  color: #004c70;
  padding: 10px 0px;
  text-align: center;
}

.animation-img-left .questionmark-animation {
  width: 100%;
  height: 125px;
  background-image: url('../images/virtual-staff-academy/three-question-mark.png');
  background-position: 80px 0px;
  background-repeat: repeat-y;
  animation-name: questionMark;
  animation-duration: 30s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-direction: normal;
}

@keyframes questionMark {
  0% {
    background-position: 80px 0px;
  }

  100% {
    background-position: 80px -2000px;
  }
}

.animation-img-right img {
  animation-name: horizontalRotateImg;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-direction: alternate;
}

@keyframes horizontalRotateImg {
  0% {
    transform: rotateY(0deg);
  }

  25% {
    transform: rotateY(0deg);
  }

  50% {
    transform: rotateY(180deg);
  }

  100% {
    transform: rotateY(180deg);
  }
}

.animation-img-right .bulb-animation {
  width: 100%;
  height: 125px;
  background-image: url('../images/virtual-staff-academy/bulb.png');
  background-position: 80px 0px;
  background-repeat: repeat-y;
  animation-name: upwardBulbImg;
  animation-duration: 30s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-direction: normal;
  animation-delay: 0s;
}

@keyframes upwardBulbImg {
  0% {
    background-position: 80px 0px;
  }

  100% {
    background-position: 80px -2000px;
  }
}

.trained-staff-section-right {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.didUKnow-card {
  width: 80%;
  height: 316px;
  background-color: #ffff;
  box-shadow: 0 0 4px 0 #696969;
  border-radius: 8px;
  border-left: 5.5pt solid #f4d00c;
  padding: 20px;
  margin-bottom: 25px;
  background-image: url('../images/virtual-staff-academy/know.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  z-index: 3;
}

.didUKnow-card::after {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #004c70;
  opacity: 0.9;
  mix-blend-mode: multiply;
  z-index: 2;
}

.didUKnow-card-main {
  z-index: 3;
  position: inherit;
}

.didUKnow-card .card-header h2,
.didUKnow-card .card-header h5 {
  font-family: 'Lato-bold';
  font-style: normal;
  color: white;
  padding: 5px 0px;
  z-index: 3;
}

.card-header ul li {
  font-family: 'Lato-bold';
  font-style: normal;
  color: white;
  z-index: 3;
}

.training-subject-list {
  width: 95%;
  margin: auto;
  padding: 30px 20px;
}

.subject-card {
  width: 100%;
  height: 386px;
  background-color: #ffff;
  box-shadow: 0 0 4px 0 #696969;
  border-radius: 8px;
  border-left: 5.5pt solid #f4d00c;
  margin: 25px 0px;
  margin-right: 10px;
}

.subject-card-img {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.subject-card-img img {
  width: 122px;
  height: 130px;
}

.word-press-img img {
  width: 116px;
  height: 112px;
}

.phill-press-img img {
  width: 116px;
  height: 112px;
}

.subject-card-img h5 {
  font-family: 'Lato-bold';
  font-style: normal;
  color: #004c70;
  padding: 10px 0px;
  line-height: 40px;
}

.subject-card hr {
  margin-top: 0px;
  margin-bottom: 10px;
}

.subject-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.subject-card p {
  font-family: 'Lato-regular';
  font-style: normal;
  color: #004c70;
  line-height: 15px;
  text-align: center;
  margin-bottom: 5px;
}

.subject-card p span {
  font-weight: bold;
}

.subject-content ul {
  padding: 10px 0px;
}

.subject-content ul li {
  font-family: 'Lato-regular';
  font-style: normal;
  color: #004c70;
  line-height: 15px;
  padding: 3px 10px;
}

.subject-content .underline {
  width: 80px;
  height: 3px;
  text-align: center;
  background-color: #f4d00c;
}

.FAQ-section {
  width: 100%;
  height: auto;
  background-color: #e7f6fd;
  padding: 50px 0px;
}

.FAQ-content {
  width: 50%;
  margin: auto;
}

.FAQ-content h1 {
  font-family: 'Lato-bold';
  font-style: normal;
  font-weight: bold;
  color: #004c70;
  line-height: 40px;
  padding: 10px 0px;
  text-align: center;
}

.FAQ-content h3 {
  font-family: 'Lato-bold';
  font-style: normal;
  font-weight: bold;
  color: #004c70;
  padding: 5px 0px;
  text-align: left;
}

.FAQ-content p {
  font-family: 'Lato-bold';
  font-style: normal;
  font-weight: bold;
  color: #004c70;
  line-height: 40px;
}

.FAQ-underline {
  width: 100%;
  height: 2px;
  text-align: center;
  background-color: #f4d00c;
}

.FAQ-content h3.big-title {
  line-height: 37px;
}

.subject-card-bottom-section {
  position: absolute;
  bottom: 50px;
  left: 25%;
}

/*-----CONTACT US ------*/
.contactus-left-card,
.contactus-right-card {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 0 4px 0 #aaa;
  padding: 20px;
  margin-bottom: 0;
}

.contactmaincard {
  padding: 50px 0;
  background: #0093d1;
}

.contactus-left-card-input label {
  font-size: 16px;
  font-family: 'Lato-Black';
  color: #004c70;
}

.contactus-left-card-input input,
.contactus-left-card-input textarea {
  background: #f2f2f2;
  border: 1pt solid #004c70;
}

.contactus-right-card {
  background: url('../images/privacy/contactimg.jpg');
  height: 530px;
  width: 100%;
  background-size: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.captcha-center {
  display: flex;
  justify-content: center;
}

.contactus-right-card-icon {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 0 4px 0 #aaa;
  padding: 20px;
  width: 50%;
  margin-bottom: 20px;
  border-bottom: 5pt solid rgb(0, 76, 112);
}

.contactus-right-card-icon svg {
  background: rgb(0, 76, 112);
  width: 60px !important;
  height: 60px;
  padding: 15px;
  display: flex;
  margin: 0 auto;
  border-radius: 50%;
  color: rgb(244, 208, 12);
  margin-bottom: 15px;
}

.contactus-right-card-icon span {
  text-align: center;
  width: 100%;
  display: block;
}

/*------how it works employer----*/
.sorts-of-jobs-hiring-whole {
  display: flex;
}

.sorts-of-jobs-hiring-single {
  width: 33.4%;
  text-align: center;
  padding: 20px;
}

.sorts-of-jobs-hiring-single-bg1 {
  background: #0093d1;
}

.sorts-of-jobs-hiring-single-bg2 {
  background: #49bdee;
}

.sorts-of-jobs-hiring-single-bg3 {
  background: #2fa4d5;
}

.sorts-of-jobs-hiring-single-icon p {
  width: 80%;
  margin: 0 auto;
  color: #fff;
}

.sorts-of-jobs-hiring-single-svg svg {
  font-size: 40px;
  color: #fff;
  margin-bottom: 15px;
}

.how-it-works-employer-card {
  flex-direction: column;
}

.how-it-works-employer-card-para {
  font-size: 14px;
  color: #fff;
}

.how-it-works-employer-card .how-it-works-jobseeker-card-icon {
  margin-right: 0;
  margin-bottom: 15px;
}

.how-it-works-employer-card-step2 {
  margin-top: 20px;
}

.how-it-works-employer-card-step3 {
  margin-top: 50px;
}

.how-it-works-employer-card-step4 {
  margin-top: 80px;
}

.monitor-pay-section .common-border {
  background: #fff;
}

.monitor-pay-section-whole {
  width: 100%;
  display: flex;
  position: relative;
}

.monitor-pay-section-whole::after {
  content: '';
  border-top: 1.5pt dashed #fff;
  width: 75%;
  position: absolute;
  top: 14%;
  left: 10%;
  z-index: 0;
}

.monitor-pay-section-single {
  width: 31%;
  margin-right: 2%;
  z-index: 11;
}

.monitor-pay-section-single img {
  width: 50%;
  margin: 0 auto;
  display: block;
}

.monitor-pay-section-single:last-child {
  margin-right: 0;
}

.monitor-pay-section-single p {
  color: #fff;
  font-size: 15px;
  margin: 15px 0;
  text-align: center;
}

.monitor-pay-section-single h4 {
  color: #fff;
  margin: 15px 0;
  text-align: center;
  font-family: 'Lato-Black';
}

.pricing-section {
  display: flex;
  width: 100%;
  background: #d5d5d5;
}

.pricing-section-left {
  width: 50%;
  padding: 40px;
}

.pricing-section-right {
  width: 50%;
  padding: 40px;
  position: relative;
}

.pricing-section-right img {
  width: 70%;
  display: block;
  margin: 0 auto;
  z-index: 11;
  position: relative;
}

.pricing-section-left-content h3 {
  color: #004c70;
  font-family: 'Lato-Black';
  text-align: left;
  margin-top: 0;
}

.pricing-section-left-content p {
  color: #494949;
}

.pricing-section-right::after {
  content: '';
  background: #004c70;
  height: 90%;
  width: 63%;
  position: absolute;
  top: 5%;
  left: 17%;
}

/*----LOGIN----*/
.loginbg {
  background: url('../images/login/loginbanner.jpg');
  background-repeat: no-repeat;
  height: 300px;
  width: 100%;
  background-size: 100%;
  flex-direction: column;
  justify-content: center;
}

.contactus-left-card-input {
  position: relative;
}

.contactus-left-card-input svg {
  position: absolute;
  right: 12%;
  top: 10px;
}

.form-control.is-invalid {
  background-image: none !important;
}

.login-page-card .contactus-left-card {
  width: 40%;
  margin: 0 auto;
  position: relative;
  top: -95px;
  padding: 0;
  border-bottom: 5pt solid rgb(0, 76, 112);
}

.login-user-icon {
  background: #cfcfcf;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding: 10px;
}

.login-page-card form {
  padding: 20px;
}

.login-heading {
  font-size: 22px;
  color: rgb(0, 76, 112);
  font-family: 'Lato-Black';
  margin-left: 15px;
}

.login-page-card.contactmaincard {
  padding: 50px 0 0;
  background: url('../images/homepage/jobseekerbg.jpg');
  background-size: cover;
  height: auto;
}

.forgot-pwd-section {
  text-align: right;
}

.forgot-pwd-section a {
  color: #0093d1;
  font-size: 16px;
  text-decoration: underline !important;
}

.custom-login-btn .primary-yellow-filled-btn {
  height: 40px;
}

.custom-login-btn {
  display: flex;
  justify-content: center;
}

.register-text-section {
  text-align: center;
  margin: 30px 0 10px;
}

.signup-whole-card {
  display: flex;
}

.signup-whole-card .contactus-left-card {
  background: rgb(0, 76, 112);
  border-bottom: 5pt solid #f4d00c;
}

.signup-whole-card .contactus-left-card .login-heading {
  color: #fff;
  padding: 15px;
  text-align: center;
  margin: 0;
}

.signup-whole-card .login-right-card {
  background: #0093d1;
}

.signup-icon {
  background: #fff;
  width: 130px;
  height: 130px;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  padding: 24px;
  border-radius: 50%;
  margin-bottom: 30px;
}

.signup-whole-card .contactus-left-card-input svg {
  position: unset;
}

.signup-whole-card ul.common-bulletin {
  width: 70%;
  margin: 0 0 30px auto;
}

.signup-employer-bg {
  background: url('../images/login/signupbanner.jpg');
  background-repeat: no-repeat;
  height: 300px;
  width: 100%;
  background-size: 100%;
  flex-direction: column;
  justify-content: center;
}

.signup-employer-card .login-user-icon {
  background: #004c70;
}

.signup-employer-card .login-user-icon svg>path {
  fill: #fff;
}

.signup-employer-card .login-user-icon .login-heading {
  color: #fff;
}

.terms-text {
  text-align: center;
  font-size: 14px;
}

.custom-signup-btn button {
  width: 240px;
}

.signup-jobseeker-card .login-user-icon {
  background: #0093d1;
}

.signup-jobseeker-bg {
  background: url('../images/login/signupbanner1.jpg');
  background-repeat: no-repeat;
  height: 300px;
  width: 100%;
  background-size: 100%;
  flex-direction: column;
  justify-content: center;
}

.new-home-page-banner .banner-text p {
  color: #fff;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
}

.signup-card-details.common-bulletin {
  list-style: disc !important;
}

.signup-card-details.common-bulletin li {
  display: list-item;
  margin-bottom: 10px;
  color: #f4d00c;
}

.find-talented-filipano-card-icon {
  min-height: 110px;
}

/* Pricing Page */

.pricing-page-main {
  width: 100%;
  height: auto;
}

.pricing-page-banner {
  width: 100%;
  height: 220px;
  background-image: url(../images/pricing/pricing-banner.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  z-index: 3;
}

.pricing-page-banner::after {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-color: #004c70;
  mix-blend-mode: multiply;
  opacity: 0.7;
  z-index: 2;
}

.pricing-banner-content {
  z-index: 3;
  position: relative;
  width: 100%;
  height: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.pricing-banner-content h1 {
  font-family: 'Lato-bold';
  font-style: normal;
  font-weight: bold;
  color: #ffff;
  line-height: 40px;
}

.pricing-banner-content p {
  font-family: 'Lato-bold';
  font-style: normal;
  font-weight: bold;
  color: #f4d00c;
  line-height: 40px;
  font-size: 20px;
}

.pricing-page-content {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
}

.pricing-page-left {
  width: 50%;
  height: auto;
  background-color: #e7f6fd;
  padding: 20px;
}

.pricing-page-left h2 {
  font-family: 'Lato-bold';
  font-style: normal;
  font-weight: bold;
  color: #004c70;
  line-height: 40px;
}

.pricing-page-left img {
  border-radius: 50%;
  width: 100px;
  height: 100px;
}

.pricing-page-left h6 {
  font-family: 'Lato-bold';
  font-style: normal;
  font-weight: bold;
  color: #004c70;
  line-height: 20px;
  width: 70%;
  margin: auto;
}

.pricing-page-left-img {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pricing-CEO-sign {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}

.pricing-CEO-sign .pricing-logo-img {
  border-radius: 0%;
  width: 280px;
  height: 60px;
  margin-top: 10px;
}

.pricing-CEO-sign .CEO-sign-img {
  width: 280px;
  height: 60px;
  margin-top: 20px;
}

.pricing-CEO-sign h3 {
  font-family: 'Lato-bold';
  font-style: normal;
  font-weight: bold;
  color: #004c70;
  line-height: 20px;
}

.pricing-page-right {
  width: 50%;
  height: auto;
  background-color: #004c70;
  padding: 20px;
}

.pricing-page-right .tab-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pricing-page-right p {
  font-family: 'Lato-bold';
  font-style: normal;
  font-weight: bold;
  color: #ffff;
  line-height: 20px;
}

.pricing-card {
  width: 300px;
  height: 530px;
  background-color: white;
  margin: 20px 0px;
  border-top-right-radius: 100px;
  border-bottom-left-radius: 100px;
  position: relative;
}

.pricing-card h4 {
  font-family: 'Lato-bold';
  font-style: normal;
  font-weight: bold;
  color: #004c70;
  line-height: 30px;
  text-align: center;
  padding: 10px 0px;
}

.pricing-card p {
  font-family: 'Lato-regular';
  font-style: normal;
  font-weight: bold;
  color: #004c70;
  line-height: 20px;
  text-align: center;
}

.pricing-card .price-tag {
  width: 200px;
  height: 80px;
  background-color: #f4d00c;
  border-top-left-radius: 40px;
  border-bottom-right-radius: 40px;
  position: absolute;
  left: -94px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.price-tag h1 {
  font-family: 'Lato-bold';
  font-style: normal;
  font-weight: bold;
  color: #004c70;
  line-height: 20px;
  text-align: center;
}

.price-tag h1 span {
  font-size: 20px;
}

.pricing-btn {
  width: 90%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-top: 27px;
}

.pricing-offer-list {
  padding: 30px 10px 0px 20px;
}

.pricing-offer-list li {
  font-family: 'Lato-regular';
  font-style: normal;
  color: #004c70;
  line-height: 25px;
}

.pricing-card p.unlimited {
  font-family: 'Lato-regular';
  font-style: normal;
  font-weight: bold;
  color: #004c70;
  line-height: 20px;
  text-align: center;
}

.pricing-page-right .nav-tabs {
  width: 90%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: none;
}

.pricing-page-right .nav-tabs li {
  background-color: rgba(244, 208, 12, 0.9);
}

.pricing-page-right .nav-tabs li a {
  font-family: 'Lato-bold';
  font-weight: bold;
  color: #004c70;
  line-height: 25px;
  font-size: 18px;
}

.pricing-page-right .nav-tabs li a:hover {
  border: 1px solid #004c70;
}

.cashback-banner {
  width: 100%;
  height: 250px;
  margin-top: 20px;
}

.cashback-banner img {
  width: 100%;
  height: 100%;
}

.enterprise-solution-section {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
}

.enterprise-solution-card-left {
  width: 50%;
  height: auto;
  background-color: #004c70;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
  position: relative;
}

.enterprise-solution-video-right {
  width: 50%;
  height: auto;
  background-color: #e7f6fd;
  padding: 30px;
}

.enterprise-solution-card {
  width: 70%;
  height: 550px;
  background-color: white;
  background-image: url(../images/pricing/card-bg.png);
  background-position: top;
  background-repeat: no-repeat;
  background-size: contain;
}

.enterprise-solution-card h3 {
  font-family: 'Lato-bold';
  font-weight: bold;
  color: #004c70;
  line-height: 25px;
  text-align: center;
  padding-left: 5px;
}

.enterprise-solution-card h5 {
  font-family: 'Lato-bold';
  font-weight: bold;
  color: #004c70;
  line-height: 25px;
  text-align: center;
  background-color: rgb(244, 208, 12);
}

.learn-more-btn {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.enterprise-solution-card ul {
  padding: 20px 0px;
  width: 85%;
  margin: auto;
}

.enterprise-solution-card ul li {
  font-family: 'Lato-regular';
  font-style: normal;
  color: #004c70;
  line-height: 25px;
}

.new-label {
  width: 100px;
  height: 60px;
  background-image: url(../images/pricing/new.jpg);
  background-position: right;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  top: 29px;
  left: 122px;
}

.enterprise-solution-video-right {
  display: flex;
  justify-content: center;
  align-items: center;
}

.enterprise-solution-video-right img {
  width: 80%;
  height: 450px;
}

.speechBubble-box {
  width: 30%;
  height: 300px;
  margin: auto;
  position: relative;
  display: inline-block;
}

.dialog-1 {
  height: 260px;
  width: 95%;
  background-color: #f4d00c;
  position: absolute;
  border-radius: 10%;
}

.left-point {
  width: 0;
  height: 0;
  border-left: 2vh solid transparent;
  border-right: 2vh solid transparent;
  border-top: 10vh solid #f4d00c;
  position: absolute;
  bottom: -33px;
  left: 5%;
  transform: rotate(23deg);
}

.testimonial-section {
  width: 100%;
  height: auto;
  background-color: rgba(0, 149, 211);
}

.QA-session {
  width: 90%;
  margin: auto;
  padding: 20px 0px;
}

.QA-session h3,
.QA-session h2,
.QA-session h4 {
  font-family: 'Lato-bold';
  font-style: normal;
  color: #fff;
  line-height: 25px;
}

.QA-session h4 {
  font-family: 'Lato-bold';
  font-style: normal;
  color: #004c70;
  line-height: 25px;
  font-weight: bolder;
  padding: 15px 0px;
  background-color: #f4d00c;
}

.QA-session p a {
  font-family: 'Lato-bold';
  font-style: normal;
  color: #ffff;
  line-height: 33px;
  font-weight: bolder;
  padding: 10px;
  margin-top: 15px;
  background-color: #004c70;
}

.QA-session p {
  font-family: 'Lato-bold';
  font-style: normal;
  color: #ffff;
  line-height: 25px;
  font-weight: bolder;
  padding: 10px;
  margin-top: 10px;
}

.QA-session ul li {
  font-family: 'Lato-bold';
  font-style: normal;
  color: #ffff;
  line-height: 25px;
  font-weight: bolder;
  margin-top: 10px;
}

.QA-session span {
  font-family: 'Lato-bold';
  font-style: normal;
  color: #ffff;
  line-height: 25px;
  font-weight: bolder;
  margin-top: 10px;
  text-decoration: underline;
}

.testimonial-part {
  width: 100%;
  margin: auto;
  background-color: #004c70;
  padding-top: 30px;
}

.testimonial-part h2 {
  font-family: 'Lato-bold';
  font-style: normal;
  color: #ffff;
  line-height: 25px;
  font-weight: bolder;
  margin-top: 10px;
  text-align: center;
}

.testimonial-part p {
  font-family: 'Lato-bold';
  font-style: normal;
  color: #ffff;
  line-height: 25px;
  font-weight: bolder;
  margin-top: 10px;
  text-align: center;
}

.dialog-1 svg {
  font-size: 43px;
  margin-left: 10px;
  margin-top: -15px;
  z-index: 3;
}

.dialog-1 blockquote {
  width: 80%;
  margin: auto;
  padding: 20px 0px;
  position: absolute;
  bottom: 42px;
  font-family: 'Lato-bold';
  font-style: normal;
  color: #004c70;
}

.dialog-1 .quote-link {
  width: 100%;
  height: 90px;
  position: absolute;
  top: 0;
  background-color: #ffff;
  border-top-right-radius: 31px;
  border-top-left-radius: 31px;
}

.quote-link p {
  font-family: 'Lato-bold';
  font-style: normal;
  color: #004c70;
  line-height: 20px;
  font-weight: bolder;
  margin-bottom: 0px;
  text-align: center;
}

.quote-link a {
  width: 100%;
  font-family: 'Lato-bold';
  font-style: normal;
  line-height: 25px;
  font-weight: bolder;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.quote-section {
  display: flex;
  justify-content: center;
  align-items: center;
}

.speech-section {
  width: 90%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
}

.testimonial-bottom-section {
  width: 60%;
  margin: auto;
  height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.testimonial-bottom-section h6 {
  font-family: 'Lato-bold';
  font-style: normal;
  color: #ffff;
  line-height: 25px;
  text-align: center;
}

.testimonial-bottom-section h6 span {
  color: #f4d00c;
  text-decoration: underline;
}

.faq-section-sub {
  width: 100%;
  min-height: 200px;
  padding: 20px 0px;
}

.faq-ques-section {
  width: 100%;
  height: 100px;
  background: #004c70;
  border-left: 5px solid #f4d00c;
  padding: 20px;
}

.faq-ques-Q {
  font-family: 'Lato';
  font-style: normal;
  font-weight: bold;
  font-size: 70px;
  line-height: 82px;
  color: #f4d00c;
  margin-right: 30px;
}

.faq-ques {
  font-family: 'Lato-Bold';
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 35px;
  color: #f4d00c;
}

.faq-ans-section {
  background: #f2f2f2;
  width: 100%;
  min-height: 80px;
  border-left: 5px solid #004c70;
  padding: 20px;
}

.faq-ans-A {
  font-family: 'Lato';
  font-style: normal;
  font-weight: bold;
  font-size: 70px;
  line-height: 82px;
  color: #004c70;
  margin-right: 30px;
}

.faq-ans {
  font-family: 'Lato-Regular';
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  color: #004c70;
}

.faq-ans span {
  color: #004c70;
  text-decoration: underline;
}

/* Employer - manage jobs */

.employer-posted-job-list-page {
  width: 100%;
  height: auto;
  background-color: #f2f2f2;
  padding: 30px 0px;
}

.my-jobs-section {
  width: 100%;
  height: auto;
  padding: 30px;
}

.my-jobs-card {
  width: 100%;
  height: auto;
  padding: 20px 0px;
  background-color: white;
  border-radius: 5px;
  padding-top: 0px;
}

.my-jobs-card-header {
  width: 100%;
  border-bottom: 1px solid #004c70;
  margin-bottom: 20px;
}

.my-jobs-card-header form .form-control {
  width: 300px;
  margin-right: 15px;
}

.my-jobs-card-header form .form-control:focus {
  outline: #004c70;
}

.my-jobs-card-content {
  width: 100%;
  height: auto;
}

.job-list {
  width: 100%;
  min-height: 100px;
  border-bottom: 1px solid #004c70;
  padding: 10px;
  display: flex;
  justify-content: space-between;
}

.job-list-left {
  width: 65%;
  height: 100%;
  padding: 0px 20px;
  display: flex;
  justify-content: space-between;
}

.job-list-left h6 {
  line-height: 25px;
  font-family: 'Lato-Bold';
  font-size: 17px;
  font-style: normal;
  text-transform: capitalize;
}

.job-list-left p {
  font-family: 'Lato-Regular';
  color: grey;
}

.job-list-left p span {
  font-family: 'Lato-Regular';
  color: #000;
}

.job-list-right {
  width: 35%;
  height: 100%;
  padding: 10px 20px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.job-list-right .edit-btn i {
  margin-left: 7px;
  color: black;
}

.job-list-right .edited-btn i {
  margin-left: 7px;
  color: black;
}

.job-list-right .edit-btn a {
  font-size: 20px;
  background: #FFFFFF;
border: 1px solid #004C70;
border-radius: 10px;
color: #004C70;
  padding: 10px 15px;
  font-family: 'Lato-bold';
  line-height: 20px;
}

.job-list-right .edited-btn a{
  font-size: 20px;
  line-height: 29px;
  color: #004C70 !important;
  background: #FFFFFF;
  border: 1px solid #004C70;
  border-radius: 10px;
  padding: 8px 12px;
  font-family: 'Lato-bold';
}
.job_select_tag{
  background-color: #D9D9D9 !important;
  border-radius: 10px;
  width: 200px !important;
}
.job-list-left-title,
.job-list-left-mail {
  width: 50%;
  margin: 0px 10px;
}

.job-list-left-mail .active-status {
  font-family: 'Lato-bold';
  color: green;
  padding: 0px 5px;
}

.job-list-left-mail .inactive-status {
  font-family: 'Lato-bold';
  color: red;
  padding: 0px 5px;
}

.view-applicants-sub p {
  font-family: 'Lato-Regular';
  line-height: 25px;
  font-style: normal;
  color: grey;
  font-size: 16px;
  margin-bottom: 0.5rem;
}

.view-applicants-sub p span {
  /* margin: 0px 10px; */
  font-family: 'Lato-Bold';
  color: #000;
}

.job-list-right .delete-btn a {
  background-color: red;
  color: white;
  padding: 10px 15px;
}


.my-jobs-card-header form {
  width: 100%;
  padding: 10px 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.my-jobs-card-header .employer-job-filter-btn {
  background-color: #f4d00c;
  color: #004c70;
  font-family: 'Lato-bold';
  line-height: 25px;
  font-style: normal;
  font-weight: bold;
  margin-left: 10px;
  padding: 5px 20px;
}

.my-jobs-card-header form select.form-control {
  width: 180px;
  margin-right: 15px;
  font-size: 15px;
  font-family: 'Lato-Regular';
}

/* employer dashboard */
.employer-dashboard-navbar .nav-item .nav-link {
  text-transform: capitalize;
  padding: 10px 0px;
}

.employer-dashboard-navbar .nav-item {
  margin: 0px 7px;
}

.employer-dashboard-navbar .profile-dropdown {
  padding-right: 15px;
}

.employer-login-profile {
  width: 30px;
  height: 30px;
  border: 1px solid grey;
  border-radius: 50%;
}

.active-nav-item {
  border-bottom: 3px solid #f4d00c;
}

.employer-dashboard-navbar .dropdown .dropdown-menu .dropdown-item {
  font-size: 15px;
  font-family: 'Lato-Regular';
  line-height: 25px;
}

.navbar-expand-md .navbar-nav .profile-dropdown-menu {
  position: absolute;
  left: -68px;
}

.dropdown-bubble .profile-dropdown-menu:before,
.dropdown-bubble .profile-dropdown-menu:after {
  position: absolute;
  left: 4.5em !important;
}

/* speech bubble type dropdown */
/* .dropdown-bubble .dropdown-menu {
  margin-top: 1em;
} */

.dropdown-bubble .dropdown-menu:before,
.dropdown-bubble .dropdown-menu:after {
  content: ' ';
  display: block;
  border-style: solid;
  border-width: 0 0.5em 0.8em 0.5em;
  border-color: transparent;
  position: absolute;
  left: 1em;
}

.dropdown-bubble .dropdown-menu:before {
  top: -0.8em;
  border-bottom-color: rgba(0, 0, 0, 0.5);
}

.dropdown-bubble .dropdown-menu:after {
  top: -0.7em;
  border-bottom-color: #fff;
}

/* speech bubble type dropdown */

.employer-dashboard-page {
  width: 100%;
  height: auto;
  padding: 0 0px;
  background-color: aliceblue;
  display: flex;
}

.left-dashboard-sidebar {
  width: 300px;
  min-height: 100vh;
  background: #fff;
}

.left-dashboard-sidebar ul li a:hover {
  background-color: #00000021;
  color: #fff;
}

.left-dashboard-sidebar ul li a {
  color: #004c70;
  font-weight: bold;
  font-size: 18px;
  display: flex;
  padding: 15px;
}

.employer-active {
  background: #dbdbdb !important;
  border-right: 3pt solid #0093d1;
}

.employer-active a {
  color: #004c70 !important;
}

.employer-active-submenu {
  background: #dbdbdb !important;
  border-right: 3pt solid #0093d1;
}

.employer-active-submenu {
  color: black !important
}

.employer-dashboard-main {
  width: calc(100% - 210px);
  height: auto;
  padding: 25px;
}

.employer-dashboard-card-sm {
  width: 100%;
  height: 200px;
  padding: 30px 0px;
  margin: 30px 0px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.employer-dashboard-card-sm:hover {
  background-color: aliceblue;
}

.employer-dashboard-card-sm h5 {
  font-family: 'Lato-Bold';
  font-style: normal;
  line-height: 20px;
  color: grey;
  text-align: center;
}

.employer-dashboard-card-sm span {
  color: #004c70;
  padding: 3px 10px;
  background-color: #dbdbdb;
  border-radius: 50%;
  margin-bottom: 20px;
  font-size: 25px;
}

.employer-dashboard-card-sm p {
  font-family: 'Lato-Bold';
  font-style: normal;
  font-size: 22px;
  line-height: 20px;
  color: #004c70;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 0px;
}

.employer-dashboard-card-sm p span {
  font-size: 14px;
  color: grey;
  border: none;
  background-color: transparent;
}

.current-hired-staff-list-main {
  width: 48%;
  height: auto;
  padding: 20px;
  margin: 30px 0px;
  background-color: white;
  border-radius: 5px;
}

.current-hired-staff-list-header {
  width: 100%;
  padding: 20px 0px;
  border-bottom: 1px solid grey;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.current-hired-staff-list-main h4 {
  font-family: 'Lato-Regular';
  font-style: normal;
  line-height: 25px;
  color: #004c70;
  text-align: left;
  text-transform: capitalize;
}

.current-hired-staff-list {
  width: 100%;
  height: auto;
  padding-left: 20px;
  padding-right: 30px;
  border-bottom: 1px solid grey;
  margin-bottom: 10px;
}

.current-hired-staff-list ul {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.current-hired-staff-list ul li {
  width: 28%;
  font-family: 'Lato-Regular';
  font-style: normal;
  line-height: 25px;
  color: #000;
  text-transform: capitalize;
  display: flex;
  flex-direction: column;
  font-size: 15px;
  justify-content: center;
}

.current-hired-staff-list ul .custom-w-15 {
  width: 5%;
}

.hired-staff-time-log-section {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.current-hired-staff-list ul li a {
  color: #004c70;
  font-size: 18px;
}

.current-hired-staff-list ul li span {
  color: grey;
}

/* Employer - manage jobs */
.employer-sidebar.side-nav ul a {
  display: flex;
  align-items: center;
  color: white;
  /* margin : 2.5rem auto; */
  padding: 1rem 0rem 1rem 1.5rem;
  font-size: 16px !important;
  font-weight: bold;
  position: relative;
}
.employer-sidebar.side-nav .fadeIn .cursor-pointer a{
  padding: 0.4rem 0rem 0.4rem 1.2rem !important;
}
.post-job {
  font-size: 15px;
  color: #004C70;
  background: transparent;
  border: 1px solid #078AD3;
  border-radius: 6px;
  border: 1px solid #004C70;
}
.post-job .icon-div{
  background: #004C70;
  padding: 10px;
  /* margin: auto; */
  border-radius: 6px;
}
.post-job i {
  background: #0093d1;
  color: white;
}

.employer-sidebar.side-nav ul a p {
  margin-bottom: 0;
  margin-left: 2.5rem;
  font-size: 1.6rem;
}

.employer-posted-job-list-page {
  width: 100%;
  height: auto;
  background-color: white;
  padding: 30px 0px;
}

.employer-sidebar.side-nav .nav-item .nav-link i,
.employer-sidebar.side-nav .nav-item .nav-link svg {
  font-size: 0.3rem;
}

.employer-sidebar.side-nav ul a i,
.employer-sidebar.side-nav ul a svg {
  width: 2rem !important;
  font-size: 1.4rem;
}

.my-jobs-section {
  width: 100%;
  height: auto;
  padding: 30px;
}

.my-jobs-card {
  width: 90%;
  margin:auto;
  height: auto;
  background-color: white;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
  border-radius: 34px;
  padding: 30px 20px;
}

.my-jobs-card-header {
  width: 100%;
  border-bottom: 1px solid #004c70;
  margin-bottom: 20px;
}

.my-jobs-card-header form .form-control {
  width: 300px;
  margin-right: 15px;
  background: #D8D8D8;
}

.my-jobs-card-header form .form-control:focus {
  outline: #004c70;
}

.my-jobs-card-content {
  width: 100%;
  height: auto;
  
}

.job-list {
  width: 100%;
  min-height: 100px;
  border-bottom: 1px solid #004c70;
  padding: 10px;
  display: flex;
  justify-content: space-between;
}
.job-search-btn{
  background: #004C70 !important;
border-radius: 10px !important;
}
.job-list-left {
  width: 65%;
  height: 100%;
  padding: 0px 20px;
  display: flex;
  justify-content: space-between;
}

.job-list-left h6 {
  line-height: 25px;
  font-family: 'Lato-Bold';
  font-size: 24px;
  font-style: normal;
  text-transform: capitalize;
}

.job-list-left p {
  font-family: 'Lato-Regular';
  color: #3E3E3E;
    font-size: 17px;
}

.job-list-left p span {
  font-family: 'Lato-Regular';
  color: #000;
}
.job_count{
  color: #004C70 !important;
}
.job-list-right {
  width: 35%;
  height: 100%;
  padding: 10px 20px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.job-list-right .edit-btn a {
  background: #FFFFFF;
border: 1px solid #004C70;
border-radius: 10px;
color: #004C70;
  padding: 8px 12px;
  font-family: 'Lato-bold';
  line-height: 20px;
}

.job-list-left-title .emp img {
  height: 70px;
  width: 70px;
  border-radius: 50%;
}

.bg-green {
  background: green !important;
  color: white !important;
}

.bg-grey {
  background: grey !important;
  color: white !important;
}

.job-list-left-title .emp div {

  line-height: 1px;
  margin-left: 10px;
}

.job-list-left-title .emp div h4 {
  white-space: nowrap;
}

.job-list-left-title .emp div p {
  font-size: 12px;
}

.job-list-left-title,
.job-list-left-mail {
  width: 50%;
  margin: 0px 10px;
}

.job-list-left-mail .active-status {
  font-family: 'Lato-bold';
  color: green;
  padding: 0px 5px;
}

.job-list-left-mail .inactive-status {
  font-family: 'Lato-bold';
  color: red;
  padding: 0px 5px;
}
.job_list_left{
  width: 48%;
}
/* .job_list_right{
  width: 50%;
} */
/* .job_list_right button{
  width: 25%;
} */
.green_button{
  margin-top: 4px;
    border: 1px solid green;
    color: green;
    padding: 8px 12px;
    line-height: 20px;
    height: 3.5rem;
}
.view-applicants-sub p {
  font-family: 'Lato-Regular';
  line-height: 25px;
  font-style: normal;
  color: grey;
  font-size: 16px;
  margin-bottom: 0.5rem;
}

.view-applicants-sub p span {
  /* margin: 0px 10px; */
  font-family: 'Lato-Bold';
  color: #000;
}

.job-list-right .delete-btn a {
  background-color: red;
  color: white;
  padding: 10px 15px;
}

.my-jobs-head {
    font-weight: 700;
    font-size: 56px;
    line-height: 67px;
    color: #004C70;
    background-color: transparent;
    text-align: center;
    margin-bottom: 30px;
    margin-top: 30px;
}

.my-jobs-card-header form {
  width: 100%;
  padding: 10px 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.my-jobs-card-header .employer-job-filter-btn {
  background-color: #f4d00c;
  color: #004c70;
  font-family: 'Lato-bold';
  line-height: 25px;
  font-style: normal;
  font-weight: bold;
  margin-left: 10px;
  padding: 5px 20px;
}

.my-jobs-card-header form select.form-control {
  width: 180px;
  margin-right: 15px;
  font-size: 15px;
  font-family: 'Lato-Regular';
}

/* review job */

.review-job-page {
  width: 100%;
  height: auto;
  padding: 30px 0px;
  background-color: #f2f2f2;
}

.review-job-page-card {
  width: 100%;
  height: auto;
  background-color: white;
  border-radius: 5px;
  padding-bottom: 30px;
}

.review-job-card-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 30px;
  /* padding-top: 40px; */
  padding-bottom: 20px;
  border-bottom: 1px solid grey;
}

.review-job-page-card h4 {
  padding-top: 30px;
  font-family: 'Lato-Bold';
  line-height: 25px;
  font-style: normal;
  font-weight: bold;
  color: #004c70;
  text-align: center;
}

.review-job-card-body {
  width: calc(100% - 60px);
  height: auto;
  margin: 30px;
  background: aliceblue;
  border: 1pt solid #000;
}

.review-job-section {
  width: 100%;
  height: auto;
  /* padding-bottom: 20px; */
}

.review-job-section h6 {
  font-family: 'Lato-Bold';
  line-height: 25px;
  font-style: normal;
  font-weight: bold;
  background-color: #004c70;
  color: #f4d00c;
  text-transform: capitalize;
  padding: 5px;
}

.review-job-section p {
  font-family: 'Lato-Regular';
  line-height: 25px;
  font-style: normal;
  color: grey;
  margin-left: 20px;
}

.review-job-section p span {
  font-family: 'Lato-Bold';
  font-weight: bold;
}

.review-job-section span {
  font-family: 'Lato-Bold';
  font-weight: bold;
}

.review-job-section p.user_desc {
  margin: auto;
  padding: 10px;
}

.review-job-section p.user_desc p {
  margin: auto;
}

.review-job-card-header .view-applicants-section {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.view-applicants-section p {
  font-family: 'Lato-Regular';
  line-height: 25px;
  font-style: normal;
  color: #004c70;
  font-size: 14px;
  margin-bottom: 0px;
}

.view-applicants-section span {
  /* padding: 5px 15px; */
  /* margin: 0px 10px; */
}

.view-applicants-section .view-applicants-btn {
  background: #0093d1;
  border-radius: 5px;
}

.view-applicants-section .view-applicants-btn a {
  color: white;
  font-family: 'Lato-Regular';
  line-height: 25px;
  font-style: normal;
}

.view-job-company-section {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.view-job-company-section img {
  width: 55px;
  height: 60px;
}

.view-job-company-section div h6 {
  font-family: 'Lato-Bold';
  font-weight: bold;
  color: #004c70;
  line-height: 25px;
  padding-top: 20px;
  margin-left: 20px;
  margin-bottom: 0px;
  text-transform: capitalize;
}

.view-job-company-section div p {
  font-family: 'Lato-Regular';
  font-style: normal;
  font-size: 15px;
  font-weight: 500;
  color: grey;
  line-height: 25px;
  text-transform: capitalize;
  margin-left: 20px;
}

.view-applicants-section .view-applicants-sub {
  margin: 0px 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.custom-green-text {
  color: green !important;
  font-weight: bold;
}

.custom-red-text {
  color: red !important;
  font-weight: bold;
}

.view-applicants-section .view-applicants-sub p {
  font-family: 'Lato-Bold';
  font-style: normal;
  font-size: 15px;
  text-transform: capitalize;
  font-weight: bold;
  color: #004c70;
}

.view-applicants-section .view-applicants-sub span {
  font-family: 'Lato-Regular';
  font-style: normal;
  font-size: 15px;
  text-transform: capitalize;
  color: #004c70;
}

/* employer profile */

.employer-profile-page {
  width: 100%;
  height: auto;
}

.employer-profile-main {
  width: 100%;
  height: auto;
  background: #fff;
  margin: 25px 0;
  border-radius: 10px;
}

.employer-profile-left {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.edit-profile-card {
  width: 100%;
  background: #fff;
  margin: 15px 0;
  border-radius: 10px;
}

.edit-profile-card1 {
  width: 100%;
  border-top-right-radius: 280px;
  position: relative;
}

.edit-profile-card2 {
  width: 100%;
  position: relative;
}

.edit-profile-card-header {
  width: 100%;
  height: auto;
  padding: 10px 20px;
}

.edit-profile-card-header h2 {
  font-family: 'Lato-Bold';
  font-style: normal;
  font-weight: bold;
  line-height: 35px;
  color: #004c70;
  border-bottom: 1.5pt solid #e2e2e2;
  padding-bottom: 5px;
  font-size: 1.5rem;
}

.edit-profile-card-content {
  width: 100%;
  height: auto;
  margin: auto;
  padding: 15px 25px;
}

.edit-profile-card-content form label {
  font-family: 'Lato-Bold';
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
  color: #004c70;
}

.up-userimage {
  width: 100%;
  margin: 0 auto;
  position: relative;
  text-align: center;
}

.up-userimage::after {
  content: '';
  position: absolute;
  top: 0;
  right: -15px;
  height: 100%;
  width: 2px;
  background: #acacac;
}

.up-user-image {
  width: 100%;
  margin: 0 auto;
  position: relative;
}

/* .up-user-image::after {
  content: '';
  position: absolute;
  top: 0;
  right: -15px;
  height: 100%;
  width: 2px;
  background: #acacac;
} */
.profile-full-name {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 36px;
  color: #004c70;
  text-transform: capitalize;
}

.edit-profile-card-content form .form-control {
  font-family: 'Lato-Bold';
  font-style: normal;
  font-size: 16px;
  line-height: 35px;
  color: #004c70;
  margin-bottom: 20px;
  border: 1pt solid #004c70;
  background: #eee;
}

.edit-profile-card-content form .form-control::placeholder {
  font-size: 14px;
}

.edit-profile-btn-section {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.edit-profile-btn-section .login-btn-section {
  width: auto;
}

.edit-profile-btn-section .login-btn-section button {
  font-size: 13px;
  line-height: 20px;
}

/* .react-switch-bg {
  background: #004c70 !important;
} */


.edit-profile-payment {
  height: 100%;
  display: flex;
  align-items: center;
}

.edit-payment-status button>i {
 
  padding-right: 10px;
}

.edit-payment-status {
  display: flex;
  margin-bottom: 10px;
  align-items: center;
}

.edit-payment-status h5 {
  color: white;
}

.edit-payment-status label {
  font-size: 18px;
  font-weight: bolder;
  width: 10rem;
  color: #004c70;
  margin-bottom: 0;
}

.payment-pending-button {
  width: 5rem;
  border: none;
  background: yellow;
  border-radius: 21px;
}

.edit-profile-payment p {
  color: #004c70;
  font-size: 20px;
}

/* .change-pwd-card {
  background-color: #004c70;
}
.change-pwd-card1 {
  background-color: #0093d1;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 280px;
}
.change-pwd-card3{
  background-color: #0093d1;
  border-top-right-radius: 168px;

} */
.changepwd-card-content form input {
  margin-bottom: 40px !important;
}

.bookmarked-list-card {
  border: 0;
  width: 32%;
  height: auto;
  display: block;
  padding: 0;
}

.bookmarked-list-card-profile {
  justify-content: center;
  position: relative;
  top: 0;
}

.bookmarked-list-card-profile img {
  border-radius: 10px;
  margin-right: 0;
  position: absolute;
  top: -20px;
  margin-bottom: 0;
}

.bookmarked-list-card-name {
  margin-top: 0;
}

.bookmarked-list-card-like {
  background: #0093d1;
  height: 80px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}

.bookmarked-list-card-like svg>path {
  fill: #f4d00c;
}

.bookmarked-list-card-like svg {
  font-size: 30px;
}

.bookmarked-list-card-like p {
  color: #fff;
  margin-bottom: 0;
  font-family: 'Lato-Black';
  font-size: 24px;
}

/* view applicants */

.view-applicants-page {
  width: 100%;
  height: auto;
  background: #f2f2f2;
}

.view-applicants-main {
  width: 100%;
  height: auto;
}

.view-applicants-banner {
  width: 100%;
  height: 320px;
  position: relative;
  background-image: url('../images/employermodule/job-applicants.jpg');
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 3;
}

.view-applicants-banner::after {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #004c70;
  opacity: 0.5;
  mix-blend-mode: multiply;
  z-index: 2;
}

.view-applicants-banner-text {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
  position: relative;
  flex-direction: column;
  padding-top: 15px;
}

.view-applicants-banner-text p {
  color: white;
  font-size: 20px;
}

.view-applicants-banner-text h1 {
  font-family: 'Lato-Bold';
  font-style: normal;
  text-transform: capitalize;
  line-height: 20px;
  color: #ffff;
  padding-bottom: 20px;
  z-index: 3;
  font-size: 4rem;
  font-weight: bold;
}

.applicants-page-main {
  padding: 35px;
}

.applicants-card {
  width: 100%;
  height: 90%;
  background: white;
  border-radius: 10px;
  padding: 0 0 20px 0;
  margin: 20px 10px;
}

.applicants-card-header {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
  /* border-bottom: 1px solid grey; */
}

.applicants-profile-img {
  width: 23%;
  margin: 6px;
}

.applicants-profile-img img {
  width: 100%;
  /* height: 80px; */
  border-radius: 0;
  object-fit: cover;
  border-radius: 50%;
}

.applicants-name-job {
  display: flex;
  flex-direction: column;
  width: 73%;
  margin-top: 13px;
}

.applicants-name-job h5 {
  font-family: 'Lato-Bold';
  font-style: normal;
  text-transform: capitalize;
  line-height: 25px;
  color: #004c70;
}

.applicants-name-job p {
  font-style: normal;
  text-transform: capitalize;
  line-height: 20px;
  color: #000000;
  border-radius: 20px;
  width: fit-content;
  margin-bottom: 10px;
  font-size: 15px;
}

.salary-rate p {
  font-weight: bold;
  line-height: 26px;
  font-family: 'Lato-Bold';
  background-color: #004c70;
  color: #f4d00c;
  padding: 5px 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  /* margin-left: 20px; */
  font-size: 12px;
  text-align: center;
}

.salary-rate {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
}

.applicants-card-body {
  width: 100%;
  height: auto;
  padding-top: 15px;
}

.applicants-card-exp-sec {
  width: 100%;
  height: auto;
  display: flex;
  padding-left: 20px;
  border-bottom: 1px solid grey;
}

.applicants-exp {
  width: 50%;
}

.applicants-exp h6 {
  font-family: 'Lato-Bold';
  font-style: normal;
  text-transform: capitalize;
  line-height: 25px;
  color: #004c70;
  margin-bottom: 5px;
}

.applicants-exp p {
  font-family: 'Lato-Bold';
  font-style: normal;
  text-transform: capitalize;
  line-height: 25px;
  color: #000;
}

.mail-lowercase p {
  text-transform: lowercase;
}

.applicants-contacts {
  width: 100%;
  height: auto;
  padding: 10px 20px;
}

.applicants-contacts .applicants-exp {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.applicants-contacts .applicants-exp a>span>svg {
  color: #004c70;
  font-size: 26px;
  margin-right: 15px;
}

.applicants-contacts .applicants-exp a>button {
  padding: 0;
  font-size: 18px;
  text-align: left;
}

.applicants-contacts .applicants-exp a {
  display: flex;
  align-items: center;
  width: 45%;
}

.applicants-profile-btn button a {
  width: fit-content;
  height: fit-content;
  border-radius: 20px;
  font-weight: 800;
  font-size: 14px;
  /* font-family: 'Lato-black'; */
  border: 1px solid #004c70;
  color: #004c70;
  padding: 10px 20px;
}

.applicants-profile-btn {
  width: fit-content;
  height: fit-content;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5% 3% 0 3%;
}

.send_offer {
  border: none;
  background: #26B523;
  color: white;
  padding: 7px 11px;
  border-radius: 10px;
  font-weight: 600;
  font-size: 14px;
  outline: none;
}

.applicants-profile-btn button a:hover {
  background: #004c70;
  color: white;
}

/* applicants profile page */

.applicant-profile-page {
  width: 100%;
  height: auto;
  background-image: url('../images/homepage/jobseekerbg.jpg');
  padding: 30px 0px;
}

.applicant-profile-main {
  width: 100%;
  height: auto;
  padding: 30px 0px;
}

.applciant-profile-card {
  width: 100%;
  height: auto;
  background-color: #fff;
  border-radius: 10px;
}

.applciant-profile-header {
  width: 100%;
  height: 280px;
  display: flex;
  padding: 20px;
  border-bottom: 1px solid grey;
  margin-bottom: 20px;
}

.applciant-profile-header-left {
  width: 50%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.applciant-profile-header-left .applciant-profile-img {
  position: relative;
}

.applciant-profile-header-left .applciant-profile-img img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
}

.applciant-profile-header-right {
  width: 50%;
}

.applciant-profile-header-right .profile-header-right-btn-section {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.applciant-profile-img .profile-online-icon {
  position: absolute;
  bottom: 24px;
  right: 0px;
}

.applciant-profile-img .member-since-text {
  font-family: 'Lato-Black';
  color: grey;
  line-height: 25px;
  text-transform: capitalize;
  font-size: 14px;
  margin-top: 5px;
}

.applciant-profile-img .last-seen-text {
  font-family: 'Lato-Regular';
  color: grey;
  line-height: 25px;
  text-transform: capitalize;
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 0px;
}

.applciant-profile-header-right {
  width: 50%;
}

.applciant-profile-header-right .profile-header-right-btn-section {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.profile-header-right-btn-section .heart-icon {
  border: 1px solid grey;
  padding: 4px 8px;
  border-radius: 50%;
}

.profile-header-right-btn-section .see-more-icon {
  font-family: 'Lato-Black';
  margin-right: 1rem;
}

.profile-header-right-btn-section .dropdown .dropdown-menu {
  margin-top: 15px;
}

.send-job-btn button {
  font-family: 'Lato-Regular';
  font-weight: 500;
  color: #fff;
  background-color: #0093d1;
  line-height: 25px;
  text-transform: capitalize;
  margin-right: 1rem;
  border-radius: 25px;
}

.schedule-interview-button:focus {
  outline: none;
}

.schedule-interview-button {
  font-family: 'Lato-Regular';
  font-weight: 500;
  color: #fff;
  background-color: lightblue;
  line-height: 25px;
  text-transform: capitalize;
  margin-right: 1rem;
  border-radius: 25px;
  outline: none;
}

.send-job-btn button:hover {
  color: #fff;
}

.resume-down-btn button {
  background-color: #f4d00c;
  color: #004c70;
  margin-right: 1rem;
}

.audio-profile-section {
  width: 90%;
  margin: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 25px;
}

.audio-profile-section h5 {
  font-family: 'Lato-Bold';
  color: #000;
  line-height: 25px;
  text-transform: capitalize;
  margin-bottom: 10px;
}

.audio-profile-section .mr-16 {
  margin-right: 16px;
}

.audio-profile-section .play-audio-icon {
  width: 80px;
  height: 80px;
  background-color: grey;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.emp-status-section {
  width: 100%;
}

.emp-status-section p {
  font-family: 'Lato-Regular';
  color: #000;
  line-height: 25px;
  text-transform: capitalize;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 19px 10px 1rem;
}

.emp-status-section p span {
  font-family: 'Lato-Bold';
  color: #004c70;
  margin-left: 5px;
}

/* audio slider section */
.slidecontainer {
  width: 50%;
  position: relative;
  right: 15px;
  top: 20px;
  display: flex;
  align-items: center;
}

.slidecontainer input {
  margin-right: 15px;
}

.Audioslider {
  -webkit-appearance: none;
  width: 100%;
  height: 10px;
  border-radius: 5px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.Audioslider:hover {
  opacity: 1;
}

.Audioslider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #0093d1;
  cursor: pointer;
}

.Audioslider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #0093d1;
  cursor: pointer;
}

/* custom audio sldie  */
.applicant-profile-summary {
  width: 100%;
  height: auto;
  padding: 30px;
}

.applicant-profile-summary h4 {
  font-weight: bold;
  font-family: 'Lato-Bold';
  color: #000;
  line-height: 25px;
  text-transform: capitalize;
  margin-bottom: 10px;
}

.applicant-profile-summary p {
  font-family: 'Lato-Regular';
  color: grey;
  line-height: 25px;
  /* margin-left: 2rem; */
}

.applciant-profile-details h4 {
  color: #004c70;
}

.applciant-profile-details p {
  margin-bottom: 4px;
}

.applciant-profile-details p:nth-child(4) {
  margin-bottom: 16px;
}

/* hired staff */

.hired-staff-page {
  width: 100%;
  height: auto;
  background-color: #e7f6fd;
}

.hired-staff-banner {
  width: 100%;
  height: 250px;
  background-image: url('../images/homepage/jobpositionbanner.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  z-index: 3;
}

.banner-text-center {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hired-staff-main {
  width: 100%;
  height: auto;
  padding: 30px 0px;
}

.hired-staff-card {
  width: 100%;
  height: auto;
  padding: 20px;
  background-color: transparent;
  border-radius: 5px;
  /* border-left: 5px solid #004c70; */
}

.hired-staff-card-header {
  width: 100%;
  height: auto;
  display: flex;
}

.hired-staff-img img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
}

.hired-staff-name {
  margin-left: 20px;
}

.hired-staff-name h5 {
  font-weight: bold;
  font-family: 'Lato-Bold';
  color: #004c70;
  line-height: 25px;
  text-transform: capitalize;
  margin-bottom: 0px;
}

.hired-staff-name p {
  font-weight: 500;
  font-family: 'Lato-Regular';
  color: #000;
  line-height: 25px;
  text-transform: capitalize;
  margin-bottom: 0px;
}

.hired-staff-content {
  width: 100%;
  height: auto;
  margin-top: 20px;
}

.hired-staff-content p {
  font-weight: 500;
  font-family: 'Lato-Regular';
  color: #000;
  line-height: 25px;
  text-transform: capitalize;
  padding: 8px;
  text-align: center;
}

/* hired staff */
.hired-staff-main {
  width: 100%;
  height: auto;
}

.hired-staff-table-main {
  width: 96%;
  margin: 40px auto;
}

.usertable {
  width: 95%;
  margin: auto;
  border: 1px solid #0093d1;
}

.usertable thead tr>th:first-child {
  width: 3%;
}

.usertable thead tr>th {
  width: 14%;
  padding: 10px 8px;
  text-align: center;
}

.usertable thead tr>th:last-child {
  width: 25%;
}

.usertable thead {
  background: #0093d1;
  color: #fff;
}

.usertable tbody tr>td {
  padding: 10px 8px;
  text-align: center;
}

.usertable>tbody tr:nth-child(even) {
  background: #dedede;
}

.usertable>tbody tr:nth-child(odd) {
  background: #ededed;
}

.usertable .common-btn {
  padding: 5px 10px;
}

.table-view-btn {
  padding: 5px 15px;
  background-color: #004c70;
  font-family: Lato-bold;
  font-style: normal;
  line-height: 25px;
  color: #fff;
  font-size: 16px;
  text-transform: capitalize;
  border-radius: 25px;
}

.table-view-btn:hover {
  color: #fff;
}

.table-view-btn svg {
  margin-left: 3px;
}

/*---Tools page---*/
.tools-page-banner {
  width: 100%;
  height: 220px;
  background-image: url('../images/tools/toolsbanner.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.benefits-section {
  padding: 50px 0;
}

.benefits-row1-left {
  position: relative;
  padding: 10px;
}

.benefits-row1-left::after {
  content: '';
  position: absolute;
  top: 0;
  height: 100%;
  right: 0;
  background: #0093d1;
  width: 1pt;
}

.benefits-row1-left h4 {
  font-size: 24px;
  color: #004c70;
  text-transform: uppercase;
  font-family: 'Lato-Black';
}

.benefits-section-row2 .benefits-row1-left h4 {
  font-size: 24px;
  color: #0093d1;
  text-transform: uppercase;
  font-family: 'Lato-Black';
  padding-top: 0;
}

.benefits-section-row2 .benefits-row1-left hr {
  width: 10%;
  border: 0;
  background: #0093d1;
  height: 1pt;
  margin: 0 0 20px;
}

.benefits-section-row2 .benefits-row1-left::after {
  content: '';
  position: absolute;
  top: 0;
  height: 100%;
  left: 0;
  background: #004c70;
  width: 1pt;
  right: unset;
}

.benefits-section-row2 .benefits-row1-left {
  padding: 0 30px;
}

.benefits-section-row2 .benefits-row1-left ul li {
  margin-bottom: 19px;
  color: #004c70;
}

.benefits-section-row2 .benefits-row1-left ul li svg>path {
  fill: #004c70;
}

.benefits-row1-left hr {
  width: 10%;
  border: 0;
  background: #004c70;
  height: 1pt;
  margin: 0 0 20px;
}

.benefits-row1-left ul li {
  margin-bottom: 21px;
  color: #0093d1;
  font-family: 'Lato-Black';
}

.benefits-row1-left ul li svg>path {
  fill: #0093d1;
}

.benefits-section-row1 {
  margin-bottom: 60px;
}

.benefits-section-row2 {
  margin-bottom: 0;
}

.time-tracker {
  width: 100%;
  height: 600px;
  background-image: url('../images/tools/timetrackerbg.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  padding: 20px 0;
}

.time-tracker-vector img {
  width: 40%;
  margin: 0 auto;
  display: block;
}

.time-tracker-button {
  display: flex;
  justify-content: center;
}

/*---transaction page---*/
.transaction-page-banner {
  width: 100%;
  height: 220px;
  background-image: url('../images/transaction/transactionbanner.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.transaction-card-section {
  padding: 50px 0;
}

.transaction-table-card-filter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
}

.transaction-table-card-filter-left {
  display: flex;
}

.transaction-table-card {
  background: #fff;
  width: 90%;
  margin: 0 auto;
}

.transaction-table-card-heading {
  background: #004c70;
  color: #fff;
  padding: 15px;
  font-size: 24px;
  font-family: 'Lato-Black';
  margin-bottom: 20px;
}

.transaction-table table {
  width: 100%;
}

.Sin-up {
  background-color: white;
  border-top: 1px solid #b3b3b3;
  padding-top: 35px;
}

.sin-start img {
  height: 420px;
}

.Sin-up .sin-start {
  text-align: center;
}

.Sin-up .sin-start h2 {
  Font-style: Bold;
  Font-size: 60px;
  color: #004C70;
}

.Sin-up .sin-start p {
  Font-size: 30px;
  color: black;
  padding-bottom: 3rem;

}

.Sin-up .sin-start span {
  Font-size: 30px;
  color: #0093D1;
  text-decoration: underline;
}

.Sin-up .job-cards {
  border: SOLID 1PX #004C70;
  border-radius: 10PX;
  text-align: center;
  padding: 2rem 1.3rem;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  display: flex;
}

.Sin-up .job-cards h4 {
  Font-size: 30px;
  color: #004C70;
  margin: 12px 0px;
}

.Sin-up .job-cards button {
  background-color: #004C70;
  color: white;
  border-radius: 10px;
  border: none;
  font-size: 20px;
  padding: 10px 17px;
}

.Sin-up .job-cards ul {
  text-align: left;
  border-left: 2px solid #004C70;
  list-style: disc;
  padding-left: 2rem;
}

.Sin-up .job-cards .emp-det {
  padding-right: 1.5rem;
}

.emp-det img {
  width: 80px;
}

.emp-det li {
  font-size: 20px;
}

.job-det li {
  font-size: 20px;
}

.job-det img {
  width: 80px;
}

.emp-lst li {
  font-size: 20px;
}

.job-lst li {
  font-size: 20px;
}

.Sin-up .job-cards .job-det {
  padding-right: 1.5rem;
}

.Sin-up .job-cards .emp-lst ul {
  margin-top: 2.5rem;
}

.Sin-up .job-cards .job-lst ul {
  margin-top: 1.5rem;
}

.job-cards2 {
  padding-bottom: 18px !important;
}

.job-post-container {
  padding: 70px 0px;
}
.internal-findstaff-banner-heading {
  font-size: 35px;
  font-weight: 700;
  line-height: 57px;
  letter-spacing: 0em;
  color: #004C70;
}
.up-card-section ul {
  display: flex;
  align-content: space-around;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 90%;
  margin: auto;
  margin-top: 20px;
}

.up-card-section ul li {
  width: 280px;
  padding-bottom: 10px;
  padding-right: 15px;
  border: 1.5px solid #004C70 !important;
  padding: 2%;
  text-align: center;
  padding-top: 3%;
border-radius: 4px;
}

.up-card-section ul li strong {
  color: #004C70;
  font-weight: 900;
font-size: 25px;
line-height: 30px;
color: #004C70;
}
.up-card-section ul li div{
  font-weight: 400;
font-size: 25px;
line-height: 30px;
text-align: center;

color: #000000;
}
.up-card-section{
  border-bottom: 1px solid black;
}
.individual-skill_span--jobseeker{
  padding: 10px;
  border: 1px solid #535353;
  border-radius: 10px;
  margin-right: 9px;
  /* background: #ffffff; */
  color: #1A1A1A;
  margin-bottom: 5px;
}
.string-bg p span{
  background-color: transparent !important;
  background: transparent;
}
.job-post-container .apply_now{
  font-size: 20px;
  padding: 10px 14px;
}
.border_wrapper{
  border: 1px solid #000000;
border-radius: 16px;
padding-top: 14px;
}
.border_wrapper h3{
  margin-bottom: 12px !important;
}
.bottom-card-header h2{
  font-weight: 700;
font-size: 20px;
line-height: 19px;
text-align: center;
color: #004C70;
margin-left: 20px;
margin-bottom: 0px !important;
}
.invoice-table tbody tr td{
 border-right: 1px solid #8E8E8E;
}
.invoice-table tbody tr td:last-child{
  border-right: none;
 }
 .transaction_history_card .white-bg{
  padding: 0px !important;
  border-radius: 34px;
 }
 .apply_now{
  padding: 6px 16px;
  font-size: 18px;
  border-radius: 7px;
 }
 .heart_icon i{
  font-size: 22px;
  margin:auto 20px;
 }
 .report_text{
  font-weight: 700;
font-size: 20px;
line-height: 24px;
text-decoration: underline !important;
color: #004C70 !important;
 }
 .tabel_view_btn{
  padding: 8px 18px;
  background: #078AD3;
  border-radius: 5px;
  font-size: 12px !important;
  text-align: center;
  color: #FFFFFF !important;
  font-size: 14px;
 }
 .fnd-crd .card .calender {
  border: 1px solid rgb(0, 76, 112);
  background-color: rgb(0, 76, 112);
  border-radius: 5px;
  float: right;
  color: white;
  padding: 5px;
}
.fnd-crd .card .title button {
  border: 1px solid rgb(0, 76, 112);
  padding: 0px 16px;
  font-size: 12px;
  border-radius: 5px;
  color: white;
  background-color: rgb(0, 76, 112);
  font-weight: 700;
  margin-left: 6px;
  height: 3.5rem;
}
.find-job-input {
  outline: none;
  padding: 13px;
  border-radius: 5px 0px 0px 5px;
  background-color: rgb(216, 216, 216);
  border: none;
  width: 30rem;
  font-weight: 500;
}
.find-job-input {
    width: 30rem !important;
}
.blog-input-icon {
    padding: 13px !important;
    font-size: 15px;
}
.heart_icon{
  font-size: 25px;
}
.buisness_struggle p {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #2B2B2B;
  text-align: left;
}
.emp-pricing-footer .inner-divs>div p {
  color: black;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  margin-bottom: 16px;
}
.reviews h2 {
  font-weight: 700;
  font-size: 38px;
  line-height: 44px;
  text-align: center;
  color: #004C70;
  margin-bottom: 45px;
}
.sub-pricing .headings h3 {
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
  text-align: center;
  color: #616161;
  margin: 16px auto;
}
.sub-pricing .price-cards .cards .headings p {
  font-weight: 400;
  font-size: 16px;
  line-height: 17px;
  margin-bottom: 0px;
  color: white;
}
.sub-pricing .price-cards .cards .btn-div .brown-bg{
  width:"81%" ;
}
.virtual_protection{
  background-color: white;
}
.virtual_protection h2{
  font-weight: 700;
  font-size: 42px;
  line-height: 55px;
  color: #004C70;
  margin-bottom:20px ;
}
.virtual_protection p{
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  color: #000000;
  margin-bottom:14px ;
}
.protection_btn{
  background: #49B247;
  border-radius: 17px;
  color: white;
  font-weight: 700;
  font-size: 28px;
  line-height: 43px;
  border: 0px;
  padding: 4px 20px;
}
.protection_btn{
  font-size: 22px;
  padding: 0px 20px;
}
.protection_btn img{
  margin-bottom: 5px;
    width: 24px;
}
.protection_btn2 img{
  margin-bottom: 5px;
    width: 20px;
}
.protection_image_div{
  width: 100px;
}
.protected_text{
  display: flex;
  align-items: center;
  justify-content: center;
  color:#3FA73F;
  margin-bottom: 5px;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
}
.contract_text{
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #9D9D9D;
  margin-bottom: 5px;
}
.contract_text.yellow{
  color:#D0AE00;
}
.contract_popup_text{
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  cursor: pointer;
  text-decoration-line: underline;
  color: #004C70;
}
.protection_modalBtn{
  background: #3FA73F;
  border-radius: 8px;
  border: 0px;
  color: white;
  padding: 4px 20px;
  margin-bottom:12px;
  font-size: 15px;
  
}
.new_modal h2{
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  color: #004C70;
  margin-bottom:20px
}
.new_modal h3{
   font-size: 24px;
}
.new_modal span{
  color : #004C70;
  font-weight: bold;
}
.new_modal .modal-content{
  border-radius: 1.3rem;
}
.new_modal p{
  line-height: 20px;
}
.info_btn{
  background-color: #004C70;
  padding: 2px 8px;
    color: white;
    border-radius: 20px;
    font-size: 12px;
    cursor: pointer;

}
.info_view{
  width: 294px;
    position: absolute;
    top: 10px;
    left: 22px;
    text-align: left;
    font-weight: 400;
    font-size: 14px;
    line-height: 15px;
    color: #000000;
    background: #E4E4E4;
    padding: 10px;
    z-index: 9;
}
.close_modal_btn{
  position: absolute;
    right: 4px;
    top: 4px;
    padding: 1px 8px;
    border: 1px solid black;
    border-radius: 17px;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.signed_contract thead tr{
  background: #DBDBDB;
}
.contract{
  background: #FFFFFF;
  width: 85% !important;
  margin: auto;
  padding-top: 32px;
box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
border-radius: 34px;
}
.signed_contract>tbody>tr:nth-child(1)>td{
  padding-top: 40px;
}
/* .signed_contract>thead>tr>th{
  background: #DBDBDB;
}
.signed_contract>thead>tr>th:nth-child(1){
  border-top-left-radius: 34px !important;
}
.signed_contract>thead>tr>th:last-child{
  border-top-right-radius: 34px !important;
} */
.employer_signed_para{
  font-weight: 400;
  font-size: 28px;
  line-height: 36px;
  text-align: center;
  color: #000000;
}
.signed_contract th{
  font-weight: 700;
  font-size: 23px;
  line-height: 30px;
  text-align: center;
  color: #004C70 !important;
  border-right: 0px !important;
}
.signed_contract tbody tr td{
  border-right: 0px !important;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #666363;
}
.dashboard_parent .nda_table tbody tr td{
  text-align: left !important;
  padding-left: 6px;
  border: 0px !important;
}
.dashboard_parent .nda_table thead {
  background: #DBDBDB;
}
.dashboard_parent .nda_table thead th{
  color: #004C70 !important;
  text-align: left !important;
}
.dashboard_parent .nda_table tbody tr {
  background-color: transparent !important;
}
.nda_table>tbody>tr>td{
  border-bottom: 0px !important;
}
.contract_box{
  height: 70vh;
  overflow-y: scroll;
  width: 80%;
  margin: auto;
  border: 1px solid black;
padding: 30px 40px;
}
.contract_box h4, .agreement_footer h4{
  font-weight: 700;
font-size: 20px;
line-height: 22px;
color: #000000;
margin-bottom: 20px;
}
.contract_box{
  font-weight: 400;
font-size: 16px;
line-height: 22px;
color: #000000;
}
.contract_box p, .agreement_footer p{
  font-weight: 400;
font-size: 16px;
line-height: 22px;
color: #000000;
}
.agreement_action{
  text-align: center;
}
.agreement_action button{
  font-weight: 700;
  font-size: 26px;
  padding: 4px 16px;
  line-height: 36px;
  background: #49B247;
  border-radius: 17px;
  color: #FFFFFF;
  border: 0px;
  margin-bottom: 18px;
  margin-top: 20px;
}
.agreement_action p{
  font-weight: 400;
  font-size: 22px;
  line-height: 26px;
  color: #C10707;
}
.contarct_wraper{
  display: flex;
  width: 80%;
  margin: auto;
}
.Contractor_dropdown label{
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
}
.view_signed{
  font-weight: 400;
font-size: 20px;
line-height: 24px;
text-decoration-line: underline;
margin-left: 20px;
color: #004C70;
}
.agreement_footer{
  display: flex;
  justify-content: center;
}
.agreement_footer div{
  width: 30%;
  margin: auto;
}
.agreement_footer hr{
  border: 1px solid black;
}
.agreement_footer h4{
  margin-bottom: 10px;
}
.flex_res{
  display: flex;
  justify-content: space-between;
}
.info_btn3{
  position: absolute;
    height: 20px;
    right: 16px;
    display: flex;
    align-items: center;
    top: -2px;
    font-size: 9px;
    padding: 0px 8px;
}
.new_modal .modal-dialog {
  max-width: 800px;
}
.new_modal .new_modal_footer{
    background: rgb(0, 76, 112);
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
    position: relative;
    height: 110px;
}
.new_modal .new_modal_footer img{
    position: absolute;
    bottom: 0;
    left: 24%;
    width : 45%;
}
.thank_you_modal .modal-dialog{
  max-width: 700px !important;
}
.info_view2{
  width: 150px;
}
.res_img{
  margin: auto;
}
.flex_res .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl{
  width: 200px;
}
.contract_banner{
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #F2CB40;
  padding: 16px 24px;
}
.contract_banner div:first-child{
  /* width:70%; */
}
.contract_banner div:last-child{
  width:165px;
}
.contract_banner button{
  background: #004C70;
  border-radius: 6px;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #FFFFFF;
  border: 0px;
  padding: 8px 35px;
}
.contract_banner p{
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
  margin-bottom: 0px;
}
@media only screen and (max-width: 760px) {

  /* Force table to not be like tables anymore */
  table,
  thead,
  tbody,
  th,
  td,
  tr {
    display: block;
  }

  /* Hide table headers (but not display: none;, for accessibility) */
  thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  tr {
    margin: 5px 0;
  }

  td {
    /* Behave  like a "row" */
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 50%;
  }

  td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 0;
    left: 0;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
    background: #03031d;
    height: 100%;
    color: #fff;
    padding-top: 6px;
  }

  /*
	Label the data
	*/
  td:nth-of-type(1):before {
    content: attr(data-title);
  }

  td:nth-of-type(2):before {
    content: attr(data-title);
  }

  td:nth-of-type(3):before {
    content: attr(data-title);
  }

  td:nth-of-type(4):before {
    content: attr(data-title);
  }

  td:nth-of-type(5):before {
    content: attr(data-title);
  }

  td:nth-of-type(6):before {
    content: attr(data-title);
  }
}

.transaction-table-card-filter-left .form-group {
  margin-right: 15px;
  margin-bottom: 0;
}

.transaction-table-card-filter-left .form-group label {
  margin-right: 15px;
  margin-bottom: 0;
  color: #004c70;
  font-weight: bold;
  font-family: 'Lato-Black';
}

.transaction-table-card-filter-left .form-group input {
  background: #ededed;
  padding: 6px;
  height: 35px;
  border: 1pt solid #004c70;
}

.transaction-table {
  padding: 0 30px 30px;
}

.transaction-table table thead th {
  /* background: #0093d1; */
  color: #fff;
  font-size: 16px;
  padding: 5px;
  text-align: center;
}

.transaction-table table {
  border: 1pt solid #0093d1;
  margin-top: 25px;
}

.transaction-table table tbody tr:nth-child(odd) {
  background: #ededed;
}

.transaction-table table tbody tr:nth-child(even) {
  background: #dedede;
}

.transaction-table table tbody tr td {
  text-align: center;
  padding: 10px 5px;
  text-align: center;
  color: #004c70;
}
.transaction-table-card-filter-right-btn .next {
  background: #004C70;
  padding: 10px 20px;
  color: #fff;
  margin-left: 10px;
  font-size: 16px;
}
.transaction-table-card-filter-right-btn .action1 {
  background: #0093d1;
  border: 1pt solid #0093d1;
  border-radius: 25px;
  padding: 10px 20px;
  color: #fff;
  margin-left: 10px;
  font-size: 16px;
}

.action1 {
  background: #0093d1;
  border: 1pt solid #0093d1;
  border-radius: 25px;
  padding: 10px 20px;
  color: #fff;
  margin-left: 10px;
  font-size: 16px;
}

.bottom-card-header {
  display: flex;
  justify-content: space-between;
}

.transaction-table-card-filter-right-btn .action2 {
  background: transparent;
  border: 1pt solid #0093d1;
  border-radius: 25px;
  padding: 10px 20px;
  color: #0093d1;
  margin-right: 15px;
}

.transaction-history-card .nav {
  margin-bottom: 20px;
  background: #004c70;
}

.transaction-history-card .nav-pills .nav-link {
  border-radius: 0;
  color: #fff;
  font-family: 'Lato-Black';
}

.transaction-history-card .nav-pills .nav-link.active,
.transaction-history-card .nav-pills .show>.nav-link {
  color: #004c70;
  background-color: #f4d00c;
}

.transaction-history-card .transaction-table-card-filter-left .form-group select {
  background: #ededed;
  padding: 6px;
  height: 35px;
  border: 1pt solid #004c70;
}
.transaction_history_card .nav-pills .nav-link.active, .transaction-history-card .nav-pills .show>.nav-link{
  background-color: #004C70;
  color: white;
}
.transaction_history_card .nav-pills .nav-link{
  color: black;
}
/*---post-a-job---*/
.tabs-left {
  border-bottom: none;
}

.tabs-left>li {
  float: none;
  margin: 0px;
}

.tabs-left>li.active>a,
.tabs-left>li.active>a:hover,
.tabs-left>li.active>a:focus {
  border-bottom-color: #ddd;
  border-right-color: transparent;
  background: #f90;
  border: none;
  border-radius: 0px;
  margin: 0px;
  width: 100%;
  display: flex;
  position: relative;
}

.nav-tabs>li>a:hover {
  /* margin-right: 2px; */
  line-height: 1.42857143;
  border: 1px solid transparent;
  /* border-radius: 4px 4px 0 0; */
}

.tabs-left>li.active>a::after {
  content: '';
  position: absolute;
  top: 2px;
  right: -10px;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;

  border-left: 10px solid #f90;
  display: block;
  width: 0;
}

.post-a-job .nav-tabs {
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 15px;
}

.post-a-job {
  padding: 50px 0;
}

.nav-pills-custom .nav-link {
  color: #aaa;
  background: #fff;
  position: relative;
}

.nav-pills-custom .nav-link.active {
  color: #fff;
  background: #0093d1;
}

/* pricing css */
.grey-bgs {
  background-color: #717171 !important;
}

.blue-bg {
  background-color: #004C70 !important;
}

.green-bg {
  background-color: #008100 !important;
}

.brown-bg {
  background-color: #7D3208 !important;
}

.sub-pricing {
  padding-top: 3rem;
}

.sub-pricing .heading h1 {
  font-size: 65px;
  line-height: 78px;
  text-align: center;
  color: #004C70;
  font-weight: 700;
  width: 80%;
  margin: auto;
}

.sub-pricing .heading h3 {
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
  text-align: center;
  color: #616161;
  width: 75%;
  margin: 16px auto;
}

.sub-pricing .heading .options {
  display: flex;
  justify-content: center;
}

.pricing-faq p {
  text-align: left;
}

.sub-pricing .heading .options i {
  height: 1.5rem;
  width: 1.5rem;
  background: rgb(26, 191, 26);
  border-radius: 50%;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
}

.sub-pricing .heading .options>div {
  display: flex;
  align-items: center;
  margin: 10px 12px;
}

.sub-pricing .heading .options p {
  font-weight: 800;
  font-size: 24px;
  line-height: 29px;
  color: #004C70;
  margin-bottom: 0px;
}

.sub-pricing .price-cards .cards {
  background: #F5F7F7;
  width: 100%;
  margin: auto;
  height: 100%;
  /* padding-top: 30px; */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.space-div {
  height: 90px;
}

.space-div-b {
  height: 150px;
}
.sub-pricing .price-cards .cards .heading {
  clip-path: polygon(0 0, 86% 0, 100% 50%, 86% 100%, 0 100%);
  padding: 16px 10px;
  text-align: center;
  height: 100px;
}

.sub-pricing .price-cards .cards .heading h4 {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
}

.sub-pricing .price-cards .cards .heading p {
  font-weight: bold;
  font-size: 27px;
  color: white;
  margin-top: 15px;
}

.sub-pricing .price-cards .cards .price h6 {
  display: flex;
  align-items: baseline;
  justify-content: center;
  font-weight: 700;
  font-size: 51px;
  /* border-bottom: 1px solid #A4A4A4; */
  /* width: 80%; */
  margin: auto;
  margin-bottom: 24px;
}

.sub-pricing .price-cards .cards .price p {
  text-align: center;
  font-weight: 700;
  font-size: 22px;
  margin-bottom: 0px;
  text-decoration: line-through;
  text-decoration-color: red;
  color: black;
}

.sub-pricing .price-cards .cards .cards-para {
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  color: #272727;
  width: 92%;
  margin: 14px auto;
}

.sub-pricing .price-cards .cards .price h6 span {
  font-weight: 400;
  font-size: 25px;
  color: #000000;
}

.sub-pricing .price-cards .cards .features {
  width: 90%;
  margin: auto;
  border-top: 1px solid gainsboro;
}

.sub-pricing .price-cards .cards .features .fea {
  display: flex;
  align-items: start;
  margin-top: 14px;
  text-align: initial;
  
}


.tooltips {
  position: relative;

}

.tooltips:before,
.tooltips:after {
  display: block;
  opacity: 0;
  pointer-events: none;
  position: absolute;
}

.tooltips:after {
  border-right: 6px solid transparent;
  border-bottom: 6px solid rgba(0, 0, 0, .75);
  border-left: 6px solid transparent;
  content: '';
  height: 0;
  top: 20px;
  left: 20px;
  width: 0;
}

.tooltips:before {
  background: rgba(0, 0, 0, .75);
  border-radius: 2px;
  color: #fff;
  content: attr(data-title);
  font-size: 14px;
  padding: 6px 10px;
  top: 26px;
  /* white-space: nowrap; */
  width: 245px;
}

.tooltips.swing:before,
.tooltips.swing:after {
  transform: translate3d(0, 30px, 0) rotate3d(0, 0, 1, 60deg);
  transform-origin: 0 0;
  transition: transform .15s ease-in-out, opacity .2s;
  z-index: 99;
}

.tooltips.swing:after {
  transform: translate3d(0, 60px, 0);
  transition: transform .15s ease-in-out, opacity .2s;
}

.tooltips.swing:hover:before,
.tooltips.swing:hover:after {
  opacity: 1;
  transform: translate3d(0, 0, 0) rotate3d(1, 1, 1, 0deg);
}

.sub-pricing .price-cards .cards .features .des {
  color: #004C70;
  font-size: 14px;
  margin-left: 20px;
  text-align: left;
  font-weight: bold;
}

.sub-pricing .price-cards .cards .features .fea .i {
  margin-top: -12px;
  margin-left: 4px;
}

.sub-pricing .price-cards .cards .features p {
  margin-bottom: 0px;
  margin-top: -4px;
  font-weight: 400;
  font-size: 20px;
  margin-left: 4px;
  line-height: 24px;
  color: black;
}

.sub-pricing .price-cards .cards .btn-div {
  width: 100%;
  margin: auto;
  position: absolute;
  bottom: 0px;
  left:0px;
  display: flex;
}

.sub-pricing .price-cards .cards .btn-div .cards-btn {
  width: 84%;
  margin: auto;
  color: white;
  border: 0px;
  border-radius: 10px;
  padding: 10px 14px;
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 16px;
}

.enterprise-heading {
  background: #7D3208;
  text-align: center;
  padding: 4px;
  margin-bottom: 40px;

}

.enterprise-heading h4 {
  font-weight: 700;
  font-size: 30px;
  line-height: 36px;
}

.enterprise-heading p {
  font-weight: bold;
  font-size: 17px;
  line-height: 17px;
  color: white;
}

.review h2 {
  font-weight: 700;
  font-size: 46px;
  line-height: 55px;
  text-align: center;
  color: #004C70;
  margin-bottom: 45px;
}

.review-img {
  border-right: 1px solid black;
  width: 300px;
}

.review-card {
  width: 90%;
  margin: auto;
  background: #E3E3E3;
  border-radius: 40px;
  padding: 40px 30px;
  text-align: left;
}

.review-card p {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #1C1C1C;
}

.review-card h5 {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #161616;
  margin-left: 20px;
}

.small-cards {
  width: 100% !important;
}
.card_toggle_banner{
  width: 100%;
  font-size: 16px;
  background-color: #ffd659;
  color: black;
  position: absolute;
  top: -32px;
}
.card_toggle_banner p{
  margin-bottom: 0px;
  font-size: 21px;
  font-weight: 700;
}
.cards-banner{
  width: 105%;
  position: absolute;
  top: -25px;
  left: -7px;
}

.cards-banner img {
  width: 100%;
}

.enterprisee-card {
  width: 120% !important;
}

.enterprisee-card .btn-div {
  width: 100% !important;
  margin-left: 5px !important;
}

.slick-btn-div {
  display: flex;
  width: 224px;
  margin: auto;
  justify-content: space-between;
}

.slick-btn-div button {
  width: 26px;
  height: 26px;
  border-radius: 13px;
  border: 0px;
  color: white;
  font-size: 18px;
  background-color: #004C70;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
}

.slick-btn-div button:focus {
  outline: none;
}

.review .slick-dots {
  position: absolute;
  bottom: -33px;
  right: 40%;
  width: unset;
}

/* Add indicator arrow for the active tab */
@media (min-width: 992px) {
  .nav-pills-custom .nav-link::before {
    content: '';
    display: block;
    border-top: 8px solid transparent;
    border-left: 10px solid #0093d1;
    border-bottom: 8px solid transparent;
    position: absolute;
    top: 50%;
    right: -10px;
    transform: translateY(-50%);
    opacity: 0;
  }
}

@media (max-width:991px) {
  .up-card-section ul {
    width: 100%;
}
  .up-card-section ul li {
    width: 30%;
}
  .up-card-section ul li strong{
    font-size: 20px;
    line-height: 27px;
  }
  .up-card-section ul li div {
    font-size: 18px;
    line-height: 25px;
    margin-top: 0px !important;
}
  .job-cards2 {
    padding-bottom: 0.3rem !important;
  }

  .Sin-up .job-cards h4 {
    Font-size: 18px;
    color: #004C70;
  }

  .Sin-up .job-cards .emp-lst ul {
    margin-top: 1.5rem;
  }

  .Sin-up .job-cards button {

    font-size: 14px;
    padding: 10px;
  }

  .Sin-up .job-cards .job-det {
    padding-right: 1.5rem;
  }

  .Sin-up .job-cards {
    padding: 1.5rem;
    justify-content: center;
    align-items: center;
    width: 62%;
    margin: 20px auto;
  }
}

.nav-pills-custom .nav-link.active::before {
  opacity: 1;
}

.post-a-job .tab-content .tab-pane h4 {
  font-family: 'Lato-Black';
}

.post-a-job .tab-content .tab-pane {
  padding: 0 20px 30px;
}

.post-a-job .tab-content .tab-pane .custom-filter-section label {
  display: block;
  color: #004c70;
  text-align: left;
}

.postajob-banner .pricing-banner-content h1 {
  width: 60%;
  margin: 0 auto;
  text-align: center;
}

.post-a-job .tab-content .tab-pane .custom-filter-section input {
  width: 100%;
  border: 1pt solid #004c70;
  background: #eee;
  color: #004c70;
  margin: 5px 0;
}

.post-a-job .tab-content .tab-pane .custom-filter-section {
  margin-bottom: 25px;
}

.post-a-job .transaction-table-card-filter {
  padding: 0;
}
.job-post-title p{    width: 4rem; }
.job-post-title {
  /* border-bottom: 1pt solid #ededed;
  margin-bottom: 20px; */
  /* width:10rem; */
  display: flex;
    justify-content: center;
    margin-top: 25px;

}
.job-post-title span{
  background: #E1E1E1;
  padding: 16%;
  font-weight: bold;
  color: #004C70;
}
.job-post-title h3 {
  color: #0093d1;
  text-align: left;
  font-family: 'Lato-Black';
  margin-top: 0;
}

.post-a-job .transaction-table-card {
  width: 80.6%;
}

/* ******************* Selection Radio Item */

.selected-content {
  text-align: center;
  border-radius: 3px;
  box-shadow: 0 2px 4px 0 rgba(219, 215, 215, 0);
  border: solid 2px transparent;
  /* background: #fff; */
  max-width: 280px;
  height: auto;
  padding: 15px;
  display: grid;
  grid-gap: 15px;
  place-content: center;
  transition: 0.3s ease-in-out all;
}

.selected-content img {
  width: 230px;
  margin: 0 auto;
}

.selected-content h4 {
  font-size: 16px;
  letter-spacing: -0.24px;
  text-align: center;
  color: #1f2949;
}

.selected-content h5 {
  font-size: 14px;
  line-height: 1.4;
  text-align: center;
  color: #686d73;
}

.selected-label {
  position: relative;
}

.selected-label input {
  display: none;
}

.selected-label .icon {
  width: 20px;
  height: 20px;
  border: solid 2px #e3e3e3;
  border-radius: 50%;
  position: absolute;
  top: 15px;
  left: 15px;
  transition: 0.3s ease-in-out all;
  transform: scale(1);
  z-index: 1;
}

.selected-label .icon:before {
  /* content: '\f00c'; */
  position: absolute;
  width: 100%;
  height: 100%;
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  font-size: 12px;
  color: #000;
  text-align: center;
  opacity: 0;
  transition: 0.2s ease-in-out all;
  transform: scale(2);
}

.selected-label input:checked+.icon {
  background: #0093d1;
  /* border-color: #0093d1;
  transform: scale(1.2); */
}

.selected-label input:checked+.icon:before {
  color: #fff;
  opacity: 1;
  transform: scale(0.8);
}

.selected-label input:checked~.selected-content {
  box-shadow: 0 2px 4px 0 rgba(219, 215, 215, 0.5);
  border: solid 2px #0093d1;

  background: #fffbfb;
}

.custom-radio-btn-salary {
  display: flex;
  margin-top: 35px;
}

.selected-content .custom-filter-section input {
  display: block;
}

.selected-content svg {
  font-size: 30px;
  margin: 0 auto;
}

.quiz_card_area {
  position: relative;
  margin-bottom: 30px;
}

.single_quiz_card {
  background: white;
  border: 1px solid #004C70;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  -ms-transition: all 0.3s linear;
  -khtml-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.quiz_card_title {
  padding: 10px;
  text-align: center;
  /* background-color: #d6d6d6; */
}

.quiz_card_title h3 {
  font-size: 16px;
  font-weight: 400;
  color: #004c70;
  margin: 0;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  -ms-transition: all 0.3s linear;
  -khtml-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.quiz_card_title h3 i {
  opacity: 0;
}

.quiz_card_icon {
  max-width: 100%;
  min-height: 60px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.quiz_icon {
  width: 70px;
  height: 40px;
  position: relative;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: auto;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  -ms-transition: all 0.3s linear;
  -khtml-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.quiz_checkbox {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  cursor: pointer;
}

.quiz_checkbox:checked~.single_quiz_card {
  border: 1px solid #0093d1;
}

.quiz_checkbox:checked:hover~.single_quiz_card {
  border: 1px solid #0093d1;
}

.quiz_checkbox:checked~.single_quiz_card .quiz_card_content .quiz_card_title {
  background-color: #004C70;
  color: #ffffff;
}

.quiz_checkbox:checked~.single_quiz_card .quiz_card_content .quiz_card_title h3 {
  color: #ffffff;
}

.quiz_checkbox:checked~.single_quiz_card .quiz_card_content .quiz_card_title h3 i {
  opacity: 1;
}

.quiz_checkbox:checked:hover~.quiz_card_title {
  border: 1px solid #0093d1;
}

/*Icon Selector*/

.quiz_checkbox:checked~.single_quiz_card .quiz_card_content .quiz_card_icon {
  color: #0093d1;
}

.quiz_card_icon {
  font-size: 24px;
  color: #004c70;
}

.custom-checkbox-design {
  display: flex;
}

.custom-checkbox-design .quiz_card_area {
  width: 14%;
  margin-right: 1%;
}

.post-a-job .tab-content .tab-pane .custom-filter-section select {
  width: 50%;
  border: 1pt solid #004c70;
  background: #eee;
  color: #004c70;
}

.styled-checkbox {
  position: absolute;
  opacity: 0;
}

.styled-checkbox+label {
  position: relative;
  cursor: pointer;
  padding: 0;
}

.styled-checkbox+label:before {
  content: '';
  margin-right: 10px;
  display: inline-block;
  vertical-align: text-top;
  width: 20px;
  height: 20px;
  background: #ececec;
  border: 1pt solid #000;
}

.styled-checkbox:hover+label:before {
  background: #004c70;
}

.styled-checkbox:focus+label:before {
  box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
}

.styled-checkbox:checked+label:before {
  background: #004c70;
}

.styled-checkbox:disabled+label {
  color: #b8b8b8;
  cursor: auto;
}

.styled-checkbox:disabled+label:before {
  box-shadow: none;
  background: #ddd;
}

.styled-checkbox:checked+label:after {
  content: '' !important;
  position: absolute !important;
  top: 6px;
  left: 7px;
  background: white !important;
  width: 2px !important;
  height: 2px !important;
  box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white, 4px -6px 0 white,
    4px -8px 0 white;
  transform: rotate(45deg) !important;
}

.unstyled {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.custom-disabled-btn {
  background: #A2A2A2 !important;
  color: gray !important;
  border: 1pt solid #a8a8a8 !important;
  opacity: 0.8;
}

.transaction-page-banner {
  width: 100%;
  height: 220px;
  background-image: url('../images/post-A-job-employer/postajobbanner.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.review-page .custom-filter-section {
  width: 48%;
  background: #eee;
  padding: 15px;
  margin-right: 2%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.review-page {
  width: 100% ;
  display: flex;
  flex-wrap: wrap;
}

.review-page .custom-filter-section p {
  margin-bottom: 0;
}

.review-page-right button {
  background: #0093d1;
  color: #fff;
  border: 1pt solid #0093d1;
  padding: 5px;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.review-page-right button svg {
  font-size: 14px;
}

/* hired staff */

.hired-staff-page {
  width: 100%;
  height: auto;
  background-color: #e7f6fd;
}

.hired-staff-banner {
  width: 100%;
  height: 250px;
  background-image: url('../images/homepage/jobpositionbanner.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  z-index: 3;
}

.banner-text-center {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hired-staff-main {
  width: 100%;
  height: auto;
  padding: 30px 0px;
}

.hired-staff-card {
  width: 48%;
  margin-right: 2%;
  height: auto;
  padding: 20px;
  background-color: transparent;
  border-radius: 5px;
  /* border-left: 5px solid #004c70; */
}

.hired-staff-card-header {
  width: 100%;
  height: auto;
  display: flex;
}

.hired-staff-img img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
}

.hired-staff-name {
  margin-left: 20px;
}

.hired-staff-name h5 {
  font-weight: bold;
  font-family: 'Lato-Bold';
  color: #004c70;
  line-height: 25px;
  text-transform: capitalize;
  margin-bottom: 0px;
}

.hired-staff-name p {
  font-weight: 500;
  font-family: 'Lato-Regular';
  color: #000;
  line-height: 25px;
  text-transform: capitalize;
  margin-bottom: 0px;
}

.hired-staff-content {
  width: 100%;
  height: auto;
  margin-top: 20px;
}

.hired-staff-content p {
  font-weight: 500;
  font-family: 'Lato-Regular';
  color: #000;
  line-height: 25px;
  text-transform: capitalize;
  padding: 8px;
  text-align: center;
}

/* hired staff */
.hired-staff-main {
  width: 100%;
  height: auto;
}

.hired-staff-table-main {
  width: 100%;
}

.hired-staff-container {
  width: 90%;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
}

.hired-staff-card {
  min-height: 170px;
}

.hired-staff-view-profile {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.light-blue-button {
  background-color: #0093d1;
  color: #fff;
  font-weight: 500;
  font-family: 'Lato-Regular';
  line-height: 25px;
  text-transform: capitalize;

  border: 0;
  height: 2rem;
  border-radius: 10px;
  width: 9rem;
}

.hired-staff-view-profile button {
  background-color: #0093d1;
  color: #fff;
  font-weight: 500;
  font-family: 'Lato-Regular';
  line-height: 25px;
  text-transform: capitalize;
  margin-right: 10px;
  border: 0;
}

.type-status-section {
  width: 63%;
  display: flex;
  flex-direction: column;
  /* align-items: flex-end; */
}

.type-status-section .active-status {
  background-color: green;
  margin: 0px 5px;
  color: #fff;
  padding: 5px 10px;
}

.type-status-section .cancelled-status {
  background-color: red;
  /* margin: 0px 5px; */
  color: #fff;
  padding: 5px 10px;
}

.type-status-section .paused-status {
  background-color: yellow;
  /* margin: 0px 5px; */
  color: black;
  padding: 5px 10px;
}

.type-status-section p {
  color: #000;
  font-weight: 500;
  font-family: 'Lato-Regular';
  line-height: 25px;
  margin-top: 10px;
}

.viewappliants-input input {
  width: 100%;
  border: 1pt solid #004c70;
  background: #eee;
  color: #004c70;
  margin: 5px 0;
}

.viewappliants-input {
  border: 0;
  width: 33%;
}

.viewapplicants-filter {
  width: 33%;
  display: flex;
  align-items: flex-start;
}

.viewapplicants-filter .filter-menu {
  margin: 15px 20px 15px 0;
  position: relative;
}

.view-applicants-form {
  flex-direction: row !important;
  justify-content: space-between !important;
  align-items: flex-start !important;
}

.view-applicants-form .search-btn {
  margin-top: 0;
}

.range-input {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.range-input input {
  width: 100% !important;
}

.range-input p {
  margin-bottom: 0;
}

.working-hours-input {
  width: 42%;
  border: 0 !important;
  margin-bottom: 0 !important;
}

.working-hours-input input {
  width: 90% !important;
}

.view-all-btn {
  display: flex;
  justify-content: center;
}

.view-all-btn .login-text {
  padding: 10px 25px;
}

.top-skills-area {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.top-skills {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.outline-star {
  color: gold;
}

.filled-star {
  color: gold;
}

.top-skills p {
  font-weight: bold;
  margin-bottom: 10px;
}

.skills-border {
  border-bottom: 1px solid grey;
  padding-top: 0;
}

.hired-staff-filter-section .viewappliants-input {
  border: 0;
  width: 25%;
  padding-bottom: 0;
}

.hired-staff-filter-section .viewappliants-input label {
  display: block;
  text-align: left;
  color: #004c70;
}

.hired-staff-filter-section .custom-filter-section select {
  width: 300px !important;
  width: 100%;
  border: 1pt solid #004c70;
  background: #eee;
  color: #004c70;
}

.hired-staff-filter-section .view-applicants-form .search-btn {
  margin-top: 15px;
}

.job-post-title-text p {
  margin-bottom: 5px;
  color: #838383;
}

.job-post-title-text p:last-child {
  margin-bottom: 25px;
}

.from-to-select {
  display: flex;
}

.from-to-select select {
  width: 90px !important;
  margin-right: 20px;
}

.to-be-decide {
  display: flex;
  align-items: center;
}

.to-be-decide label {
  margin: 0;
}

.to-be-decide.filter-menu label:before {
  margin-top: -2px;
}

.to-be-decide span {
  line-height: 0;
  vertical-align: middle !important;
  font-size: 16px;
  margin-right: 8px;
}

.applicants-exp .slidecontainer {
  position: unset;
  width: 100%;
}

/*  Toggle Switch  */

.toggleSwitch span span {
  display: none;
}

@media only screen {
  .toggleSwitch {
    display: inline-block;
    height: 18px;
    position: relative;
    overflow: visible;
    padding: 0;
    margin-left: 0;
    cursor: pointer;
    width: 40px;
  }

  .toggleSwitch * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  .toggleSwitch label,
  .toggleSwitch>span {
    line-height: 20px;
    height: 20px;
    vertical-align: middle;
  }

  .toggleSwitch input:focus~a,
  .toggleSwitch input:focus+label {
    outline: none;
  }

  .toggleSwitch label {
    position: relative;
    z-index: 3;
    display: block;
    width: 100%;
  }

  .toggleSwitch input {
    position: absolute;
    opacity: 0;
    z-index: 5;
  }

  .toggleSwitch>span {
    position: absolute;
    left: -50px;
    width: 100%;
    margin: 0;
    padding-right: 50px;
    text-align: left;
    white-space: nowrap;
  }

  .toggleSwitch>span span {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
    display: block;
    width: 50%;
    margin-left: 40px;
    text-align: left;
    font-size: 0.9em;
    width: 100%;
    left: 15%;
    top: -4px;
    opacity: 0;
  }

  .toggleSwitch a {
    position: absolute;
    right: 50%;
    z-index: 4;
    display: block;
    height: 100%;
    padding: 0;
    left: 2px;
    width: 18px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  }

  .toggleSwitch>span span:first-of-type {
    color: #004c70;
    opacity: 1;
    left: 45%;
  }

  .toggleSwitch>span:before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 50px;
    top: -2px;
    background-color: #dbdbdb;
    border: 1px solid #ccc;
    border-radius: 30px;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
  }

  .toggleSwitch input:checked~a {
    border-color: #fff;
    left: 100%;
    margin-left: -8px;
  }

  .toggleSwitch input:checked~span:before {
    border-color: #0097d1;
    box-shadow: inset 0 0 0 30px #0097d1;
  }

  .toggleSwitch input:checked~span span:first-of-type {
    opacity: 0;
  }

  .toggleSwitch input:checked~span span:last-of-type {
    opacity: 1;
    color: #fff;
  }

  /* Switch Sizes */
  .toggleSwitch.large {
    width: 75px;
    height: 27px;
  }

  .toggleSwitch.large a {
    width: 24px;
    height: 24px;
  }

  .toggleSwitch.large>span {
    height: 29px;
    line-height: 28px;
  }

  .toggleSwitch.large input:checked~a {
    left: 56px;
  }

  .toggleSwitch.large>span span {
    font-size: 1.1em;
  }

  .toggleSwitch.large>span span:first-of-type {
    left: 50%;
  }

  .toggleSwitch.xlarge {
    width: 80px;
    height: 36px;
  }

  .toggleSwitch.xlarge a {
    width: 36px;
  }

  .toggleSwitch.xlarge>span {
    height: 38px;
    line-height: 37px;
  }

  .toggleSwitch.xlarge input:checked~a {
    left: 52px;
  }

  .toggleSwitch.xlarge>span span {
    font-size: 1.4em;
  }

  .toggleSwitch.xlarge>span span:first-of-type {
    left: 50%;
  }
}

/*  End Toggle Switch  */

.notification-heading h2 {
  color: #004c70;
  font-size: 56px;
}

.notification-heading .common-border {
  margin: 30px 0 0;
}

/* review job */

.review-job-page {
  width: 100%;
  height: auto;
  padding: 30px 0px;
  background-color: #f2f2f2;
}

.review-job-page-card {
  width: 100%;
  height: auto;
  background-color: white;
  border-radius: 5px;
  padding-bottom: 30px;
}

.review-job-card-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 30px;
  /* padding-top: 40px; */
  padding-bottom: 20px;
  border-bottom: 1px solid grey;
}

.review-job-page-card h4 {
  padding-top: 30px;
  font-family: 'Lato-Bold';
  line-height: 25px;
  font-style: normal;
  font-weight: bold;
  color: #004c70;
  text-align: center;
}

.review-job-card-body {
  width: calc(100% - 60px);
  height: auto;
  margin: 30px;
  background: aliceblue;
  border: 1pt solid #000;
}

.review-job-section {
  width: 100%;
  height: auto;
  padding-bottom: 20px;
}

.review-job-section h6 {
  font-family: 'Lato-Bold';
  line-height: 25px;
  font-style: normal;
  font-weight: bold;
  background-color: #004c70;
  color: #f4d00c;
  text-transform: capitalize;
  padding: 5px;
}

.review-job-section p {
  font-family: 'Lato-Regular';
  line-height: 25px;
  font-style: normal;
  color: grey;
  margin-left: 20px;
}

.review-job-section p span {
  font-family: 'Lato-Bold';
  font-weight: bold;
}

.review-job-section span {
  font-family: 'Lato-Bold';
  font-weight: bold;
}

.review-job-card-header .view-applicants-section {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.view-applicants-section p {
  font-family: 'Lato-Regular';
  line-height: 25px;
  font-style: normal;
  color: #004c70;
  font-size: 14px;
  margin-bottom: 0px;
}

.view-applicants-section span {
  /* padding: 5px 15px; */
  /* margin: 0px 10px; */
}

.view-applicants-section .view-applicants-btn {
  background: #0093d1;
  border-radius: 5px;
}

.view-applicants-section .view-applicants-btn a {
  color: white;
  font-family: 'Lato-Regular';
  line-height: 25px;
  font-style: normal;
}

.view-job-company-section {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.view-job-company-section img {
  width: 55px;
  height: 60px;
}

.view-job-company-section div h6 {
  font-family: 'Lato-Bold';
  font-weight: bold;
  color: #004c70;
  line-height: 25px;
  padding-top: 20px;
  margin-left: 20px;
  margin-bottom: 8px;
  text-transform: capitalize;
}

.view-job-company-section div p {
  font-family: 'Lato-Regular';
  font-style: normal;
  font-size: 15px;
  font-weight: 500;
  color: grey;
  line-height: 25px;
  text-transform: capitalize;
  margin-left: 20px;
}

.view-applicants-section .view-applicants-sub {
  margin: 0px 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.custom-green-text {
  color: green !important;
  font-weight: bold;
}

.custom-red-text {
  color: red !important;
  font-weight: bold;
}

.view-applicants-section .view-applicants-sub p {
  font-family: 'Lato-Bold';
  font-style: normal;
  font-size: 15px;
  text-transform: capitalize;
  font-weight: bold;
  color: #004c70;
}

.view-applicants-section .view-applicants-sub span {
  font-family: 'Lato-Regular';
  font-style: normal;
  font-size: 15px;
  text-transform: capitalize;
  color: #004c70;
}

.pay-invoice-row {
  position: relative;
}

.pay-invoice-bulletin {
  list-style: disc;
  padding-left: 35px;
}

/*---Messaging---*/
.common-page-title h2 {
  font-size: 28px;
  line-height: 33px;
  display: flex;
  align-items: center;
  color: #232266;
  margin-bottom: 21px;
}

.messagewholecard {
  display: flex;
}

.patient-details-left-card {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  border-radius: 8px;
  width: 100%;
}

.appointment-calendar-card {
  padding: 20px;
}

.messagecard2 {
  position: relative;
}

.messagerowsection {
  top: 0;
  overflow: auto;
  height: calc(100% - 140px);
  padding: 20px 30px;
  display: flex;
  flex-direction: column;
}

.messageleftrow {
  display: flex;
  align-items: flex-start;
}

.messageleftrowimage {
  margin-right: 17px;
  cursor: pointer;
}

.messageleftrowimage img {
  width: 40px;
  height: 40px;
}

.messageleftrowdoctordetails {
  margin-bottom: 5px;
  display: flex;
  align-items: flex-end;
}

.messageleftrowdoctordetails p {
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.1em;
  text-transform: capitalize;
  color: #004c70;
  margin: 0;
}

.messageleftrowdoctordetails.right-time {
  text-align: right;
  display: block !important;
}

.messageleftrowdoctordetails p>span {
  font-size: 11px;
  letter-spacing: 0.1em;
  text-transform: capitalize;
  color: #b8b6b6;
}

.messageleftrowtextdetails {
  background: #e7e7e7;
  border-radius: 0px 25px 25px 25px;
  padding: 16px;
}

.messageleftrowtextdetails p {
  font-size: 14px;
  color: #004c70;
  margin: 0;
}

.messagerow {
  max-width: 70%;
  margin: 15px 0 0 auto;
}

.message-large-width .messagerow {
  max-width: 85%;
}

.messagerightrow {
  justify-content: flex-end;
}

.messageleftrow {
  margin: initial;
  display: flex;
  align-items: flex-start;
}

.messagerightrowtextdetails {
  background: #004c70;
  border-radius: 25px 0px 25px 25px;
}

.messagerightrowtextdetails p {
  color: #fff;
}

.type-message-section {
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  background: #d9d9d9;
  padding: 10px;
  display: flex;
  flex-direction: row-reverse;
}

.type-message-sub-section {
  position: relative;
  flex: 12;
}

.type-message-sub-section input {
  border-radius: 40px;
  font-size: 15px;
  line-height: 21px;
  letter-spacing: 0.1em;
  color: #232266;
  width: 100%;
  outline: none;
  padding: 15px 25px;
  border: 0;
}

.type-message-section a {
  flex: 1;
  display: flex;
  justify-content: center;
  padding: 10px 10px;
}

.type-message-sub-section a {
  position: absolute;
  right: 54px;
  top: 17px;
}

.type-message-sub-section a:nth-child(3) {
  position: absolute;
  right: 17px;
  top: 17px;
}

.custom-message-spacing {
  margin-top: 20px;
}

.rightcardpropic {
  position: relative;
  padding: 20px;
  display: block;
  text-align: center;
}

.rightcardpropic img {
  width: 180px;
  height: 180px;
  border-radius: 180px;
}

.propicclosesection {
  position: absolute;
  right: 7px;
  top: 11px;
  cursor: pointer;
}

.propicdetails {
  background: rgba(255, 255, 255, 0.41);
  padding: 25px;
  width: 100%;
  position: absolute;
  bottom: 0;
  height: 72px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.propicdetails {
  background: rgba(255, 255, 255, 0.41);
  padding: 25px;
  width: 100%;
  position: absolute;
  bottom: 0;
  height: 72px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.propicdetails h3 {
  font-size: 26px;
  line-height: 30px;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #004c70;
}

.rightcard-basic-details-heading {
  margin-top: 30px;
}

.rightcard-basic-details-heading h2 {
  font-size: 30px;
  line-height: 35px;
  text-align: left;
  color: #232266;
  margin: 0;
}

.rightcard-basic-details-heading p {
  background: #c4c4c4;
  width: 45px;
  height: 1px;
  margin: 11px 0 34px;
}

.rightcard-basic-details {
  padding: 0 27px;
}

.rightcard-basic-details-row {
  margin-bottom: 36px;
}

.rightcard-basic-details-row h6 {
  font-size: 17px;
  line-height: 20px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #0093d1;
  margin-bottom: 18px;
  font-family: 'Lato-Black';
}

.rightcard-basic-details-row p {
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.1em;
  text-transform: capitalize;
  color: #232266;
}

.messagecard3 {
  padding: 0;
}

.message-section .notification-heading {
  margin-bottom: 30px;
}

div.dropdown {
  position: relative;
  width: 120px;
}

div.dropdown>div.caption {
  padding: 11px 24px;
  border-radius: 3px;
  cursor: pointer;
  border: 1pt solid #004c70;
  background: #eee;
  color: #004c70;
  display: flex;
  align-items: center;
}

div.dropdown>div.list {
  position: absolute;
  background-color: #f2f2f2;
  width: 100%;
  border-radius: 0 0 3px 3px;
  display: none;
  z-index: 1;
}

div.dropdown>div.list>div.item {
  padding: 11px 24px;
  cursor: pointer;
}

div.dropdown>div.list>div.item.selected {
  font-weight: bold;
}

div.dropdown>div.caption:hover,
div.dropdown>div.list>div.item:hover {
  background-color: rgb(83, 161, 214);
  color: #fff;
}

div.dropdown.open>div.caption {
  border-radius: 3px 3px 0 0;
  border-bottom: solid 1px #999;
}

div.dropdown.open>div.list {
  display: block;
}

.Open-bg {
  display: inline-block;
  height: 16px;
  width: 16px;
  background: green;
  border-radius: 50%;
  margin-right: 15px;
}

.post-a-job-step-4 .filter-menu {
  position: relative;
  margin-bottom: 20px;
}

.custom-mb-0 {
  margin-bottom: 0;
}

.audio-profile-section .applicants-contacts .applicants-exp a {
  width: 50%;
}

.custom-employer-modal .modal-header {
  background: #004c70;
  align-items: center;
  padding: 0.5em 1em;
}

.custom-employer-modal .modal-header h4 {
  padding: 0;
  font-size: 18px;
}

.custom-employer-modal .modal-header .close {
  color: #fff;
  opacity: 1;
}

.custom-employer-modal .modal-body .custom-filter-section label {
  display: block;
  color: #004c70;
  text-align: left;
}

.custom-employer-modal .modal-body .custom-filter-section input {
  width: 100%;
  border: 1pt solid #004c70;
  background: #eee;
  color: #004c70;
  margin: 5px 0;
}

.custom-btn-design {
  justify-content: center;
}

.custom-btn-design button {
  background: red;
  color: #fff;
}

.custom-btn-design .custom-send-btn {
  background: green;
  color: #fff;
}

.messagecard1 {
  padding: 0 !important;
}

.messagesearch-section {
  padding: 15px 20px;
  position: relative;
  border-bottom: 1px solid #c4c4c4;
}

.messagesearch-section span.search-icon {
  position: absolute;
  top: 23px;
  left: 90px;
  cursor: pointer;
}

.messagesearch-section input {
  font-size: 15px;
  letter-spacing: 0.05em;
  color: #333;
  width: 100%;
  border-radius: 25px;
  height: 40px;
  padding: 13px 20px 13px 20px;
  border: 1px solid #ccc;
  outline: none;
}

.messagecard1-leftrowdetails {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #c4c4c4;
  padding: 10px;
}

.messagecard1-leftrowdetails:not(.prevent-hover):hover {
  cursor: pointer;
  background-color: rgba(14, 125, 148, 0.1);
}

.messagecard1-leftrowdetails-img {
  margin-right: 15px;
}

.messagecard1-leftrowdetails-img img {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  overflow: hidden;
}

.messagecard1-leftrowdetails-text .title {
  font-size: 17px;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 0.03em;
  text-transform: capitalize;
  color: #232266;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-bottom: 0 !important;
  padding-bottom: 0rem !important;
}

.messagecard1-leftrowdetails-text p {
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.1em;
  text-transform: capitalize;
  color: #555;
  margin: 0;
  width: 250px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.messagecard1-leftrow-active {
  background: rgba(14, 125, 148, 0.1);
}

.pay-invoice-row {
  position: relative;
}

.text-green {
  color: green;
}

.pagination-button-disabled {
  opacity: 0.5;
}

.job-offer-bg {
  background: #004c70;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 15px;
}

.job-offer-bg h4 {
  margin: 0;
}

.job-offer-details {
  padding: 20px;
}

.job-offer-sub-para {
  font-size: 16px;
  color: #606060;
  margin-bottom: 15px;
}

.job-offer-sub-para span {
  color: #004c70;
}

.job-offer-details-heading {
  color: #004c70;
  font-weight: bold;
  font-size: 20px;
}

.job-offer-bold-text {
  color: #004c70;
  font-weight: bold;
  margin: 10px 0;
  display: flex;
  align-items: center;
}

.job-offer-para-text {
  font-size: 16px;
  color: #606060;

}

/* .job-offer-details-section {
  border-bottom: 1pt solid #606060;
  padding-bottom: 20px;
  margin-bottom: 20px;
} */

.edit-button {
  margin-left: 9px;
}

.job-offer-page .applicant-profile-main {
  width: 75%;
  height: auto;
  padding: 30px 0px;
  margin: 0 auto;
}

.job-offer-details-section .filter-menu {
  position: relative;
}

.job-offer-details-section .filter-menu input:checked+label:after {
  top: 5px;
}

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.btn {
  border: 2px solid #004c70;
  color: black;
  background-color: white;
  padding: 6px ​12px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: bold;
}

.upload-btn-wrapper input[type='file'] {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.upload-btn-wrapper .btn>svg {
  margin-right: 10px;
}

.upload-btn-wrapper label {
  margin-left: 15px;
  color: #004c70;
}

.job-offer-upload {
  display: flex;
  align-items: center;
}

.job-offer-upload label {
  margin-left: 15px;
}

.user-agreement-text {
  margin-top: 15px;
}

.user-agreement-text b {
  color: #004c70;
}

.send-job-btn-cancel {
  display: flex;
  align-items: center;
}

.cancel-job-btn button {
  border: 0;
}

.joboffer-input:disabled {
  border: none;
  outline: none;
  background-color: transparent !important;
  width: 4rem;
  font-weight: bold;
  padding: 0;
}

.circled-image {
  position: relative;
  width: 35%;
}

.circled-image a {
  position: absolute;
  right: 50px;
  top: 0;
  width: 2.6rem;
  height: 2.6rem;
  border-radius: 50%;
  border: 1px solid #ccc;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background: #f1f3fa;
  font-size: 20px;
  color: #004c70 !important;
}

.up-userimage img {
  width: 11rem;
  height: 11rem;
  border-radius: 50%;
  border: 2pt solid;

}

.up-user-image img {
  width: 11rem;
  height: 11rem;
  border-radius: 50%;
  border: 2pt solid;
}

.up-user-name>a {
  bottom: 10px;
  border-radius: 50%;
  border: 1px solid #ccc;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background: #f1f3fa;
  font-size: 20px;
  color: #004c70 !important;
}

.job-offer-client-img img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
}

.job-offer-client-details {
  display: flex;
}

.job-offer-client-img {
  margin-right: 50px;
}

.job-seeker-job-offer-section {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  border-bottom: 1pt solid #9a9a9a;
  padding-bottom: 20px;
}

.job-seeker-job-offer-section h3 {
  margin: 0;
  margin-right: 15px;
  padding: 0 !important;
  text-align: left;
  color: #145e82;
  font-size: 24px;
}

.job-seeker-job-offer-section p {
  margin: 0;
  padding: 18px;
  font-size: 1;
}

.job-offer-job-body .bj-content-body {
  padding: 0;
}

.job-seeker-job-offer-section h3>span {
  color: #626262;
  font-size: 12px;
}

.job-offer-btns a {
  background: #004c70 !important;
  border-radius: 5px;
}

.job-offer-client-img img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
}

.job-offer-client-details {
  display: flex;
}

.job-offer-client-img {
  margin-right: 50px;
}

.job-offer-client-text h4 {
  margin-bottom: 10px !important;
  display: block;
  padding: 0 !important;
  font-size: 24px;
}

.job-offer-client-text h6 {
  margin-bottom: 0;
  padding-bottom: 0 !important;
  font-size: 16px;
  font-weight: 600;
}

.job-offer-client-text p {
  margin-bottom: 0;
}

.job-offer-client-text .job-posted-bold-text {
  font-size: 16px;
  color: #06b900;
  margin-bottom: 10px;
}

.job-offer-client-text .member-since-text {
  font-size: 12px;
}

/* .custom-card-width{
    width: 50%;
    margin: 0 auto;
    } */
.boxmodal-interviewschedule {
  position: relative;
  top: 31%;
  left: 50%;
  right: 0;
  transform: translate(-50%, -50%);
  height: 58%;
  width: 41%;
  overflow-y: auto;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  z-index: 10;
}

/* for paymennt module */
/* Bootstrap styles*/

.panel {
  width: 70%;
  margin: 2em auto;
}

.panel-body {
  width: 90%;
  margin: 2em auto;
}

.helper-text {
  color: #e93143;
  font-size: 12px;
  margin-top: 5px;
  height: 12px;
  display: block;
}

/* Hosted Payment Fields styles*/
.hosted-field-focus {
  border: 1px solid #66afe9;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}

.hosted-field-invalid {
  border: 1px solid #e93143;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(233, 49, 67, 0.8);
}

.hosted-field-valid {
  border: 1px solid #14ba57;
}

.employer-active ul li {
  background: #fafafa;
  border-bottom: 1pt solid #004c70;
}

.left-dashboard-sidebar ul li {
  position: relative;
}

.custom-arrow-btn {
  position: absolute;
  right: 15px;
}

.payment-card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;

  padding: 2%;
}

.employer-dashboard-banner {
  height: 217px !important;
  width: 100%;
  position: relative;
}

.employer-dashboard-banner .search-bar .form-control {
  height: 37px;
  font-size: 16px;
}

.employer-dashboard-banner .search-bar>button {
  height: 37px;
  font-size: 16px;
}

.post-a-job-btn {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 15px;
}

.post-a-job-btn button {
  background: #004c70;
  color: #fff;
  border: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.post-a-job-btn button>i {
  margin-left: 10px;
}

.header .employer-nav.navbar-right .nav-item a {
  position: relative;
  display: inline-block;
  margin-bottom: 1rem;
  /*height: 40px;*/
  /*width: 40px;*/
}

.notification-header-num {
  background: red;
  color: #fff;
  font-size: 12px;
  height: 21px;
  width: 28px;
  position: absolute;
  right: 0;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

.notification-header-num-sidebar {
  background: red;
  color: #fff;
  font-size: 12px;
  height: 20px;
  width: 20px;
  position: absolute;
  top: 20px;
  right: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

.card-notification-num {
  background: red;
  width: 36px;
  padding: 5px;
  border-radius: 25px;
  height: 36px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 17px;
  margin: 0 auto;
}

.employer-dashboard-table .current-hired-staff-list-main {
  margin: 0;
  width: 100%;
}

.white-bg-card {
  background: #fff;
  padding: 15px 15px 20px;
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
  border-radius: 5px;
}

.no-position {
  position: unset !important;
  width: 100% !important;
  margin: 15px 0 0 !important;
}

.current-hired-staff-list-header h4 {
  font-family: 'Lato-Regular';
  font-style: normal;
  line-height: 25px;
  color: #004c70;
  text-align: left;
  text-transform: capitalize;
}

.employer-banner-title h4 {
  font-size: 17px;
  margin-bottom: 0;
}

.employer-banner-title {
  padding: 0;
}

.no-position .search-bar {
  padding: 1rem;
}

.employer-banner-bg {
  background-color: transparent;
  background: url(../images/dashboard-employer-banner1.jpg);
  height: 100%;
  width: 100%;
  background-size: cover;
  position: relative;
}

.employer-banner-bg-content {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgb(0 76 112 / 80%);
  left: 0;
  top: 0;
  border-radius: 5px;
  padding: 20px;
}

.employer-banner-bg-header h2 {
  color: #fff;
  font-weight: 600;
  font-size: 26px;
  letter-spacing: 1px;
}

.employer-banner-bg-line {
  border: 0;
  background: #fff;
  height: 3px;
  width: 10%;
  margin: 15px 0;
}

.employer-banner-bg-header p {
  color: #fff;
  margin: 30px 0;
  font-size: 20px;
}

.employer-banner-bg-header button {
  border: 1pt solid #0093d1;
  text-transform: capitalize;
  margin-bottom: 30px;
}

.employer-banner-bg-body p {
  color: #fff;
  margin: 0 ​0 20px;
  font-size: 18px;
  font-weight: 600;
  font-style: italic;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.employer-banner-bg-body input {
  width: 100%;
  height: 40px;
  margin-bottom: 15px;
  border-radius: 7px;
  border: 1pt solid #fff;
  padding: 5px 15px;
  color: #767676;
}

.employer-banner-bg-input {
  position: relative;
  width: 100%;
  height: 40px;
}

.employer-banner-bg-input i {
  position: absolute;
  right: 15px;
  top: 10px;
  color: #0093d1;
}

.employer-banner-bg-footer {
  display: flex;
  flex-wrap: wrap;
  margin: 10px 0 10px;
}

.employer-banner-bg-footer-links {
  color: #acacac;
  border: 1pt solid #acacac;
  padding: 3px 10px;
  font-size: 14px;
  border-radius: 5px;
  margin-right: 15px;
  cursor: pointer;
  text-transform: uppercase;
}

.employer-banner-bg-link {
  color: #fff;
  font-size: 16px;
  text-decoration: underline;
  cursor: pointer;
  letter-spacing: 1px;
}

.hiredemployer-section-three {
  margin: 40px 0;

  align-items: center;
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 1px 1px #eee;
}

.hiredemployer-section-two {
  margin: 20px auto 30px;
  position: relative;
  align-items: center;
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 1px 1px #eee;
  width: 92%;
}

.hiredemployer-section {
  display: flex;
  align-items: flex-start;
  padding-bottom: 30px;
}

.hiredemployer-img {
  margin-right: 50px;
  position: relative;
  margin-top: -50px;
}

.hiredemployer-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
  border: 3pt solid #3e3e3e;
}

.hired-text-links {
  display: flex;
  /* align-items: flex-start;  */
}

.hired-text-link1>b {
  color: #004c70;
}

.hired-text-link1>span {
  text-decoration: underline;
  cursor: pointer;
  margin-left: 15px;
  color: #0093d1;
}

.link1-span {
  text-decoration: underline;
  cursor: pointer;
  margin-left: 0;
  color: #0093d1;
}

.hiredemployer-title {
  color: #004c70;
  font-family: Lato-bold;
  margin-bottom: 20px;

  text-align: center;
}

.hiredemployer-title span {
  font-size: 16px;
  color: #8a8a8a;
}

.hired-text-link1 {
  margin-right: 15px;
  width: 31%;
}

.hired-text-link1:last-child {
  border: 0;
  padding: 0;
  margin: 0;
}

.blue-color-btn {
  color: #fff !important;
  background: #004c70 !important;
  border: 1pt solid #004c70 !important;
}

.hired-text-btn {
  margin: 25px 0;
}

.blue-color-btn2 {
  color: #fff !important;
  background: #0093d1 !important;
  border: 1pt solid #0093d1 !important;
}

.hired-text-location>i {
  color: #004c70;
  margin-right: 15px;
}

.hired-text-location>span {
  color: #004c70;
  font-family: Lato-bold;
}

.gov-id-verification-employer-guides .complete-profile-points-group i,
.gov-id-verification-employer-guides .complete-profile-points-group svg {
  font-size: 1rem !important;
  padding-top: 0.3rem;
  color: var(--light-color);
}

.gov-id-verification-employer-guides .complete-profile-points-group .profile-points {
  padding-bottom: 0;
}

.gov-id-verification-employer-guides .complete-profile-points-group .profile-points p {
  color: var(--light-color);
  padding-bottom: 0.3rem;
}

.sub-profile-points {
  margin-bottom: 0.5rem;
}

.gov-id-verification-employer-guides .complete-profile-points-group:hover {
  background: none;
}

.gov-id-verification-employer-guides p {
  font-size: 1rem !important;
  color: var(--light-color);
}

.invoice-modal {
  padding: 2%;
  position: relative;
  border-bottom: 1px solid #004c70;
}

.invoice-modal label {
  font-size: 21px;
  font-weight: bold;
  color: #004c70;
  width: 50%;
}

.invoice-modal span {
  font-size: 18px;
  font-weight: bold;
}

.hired-staff-view-profile_link {
  color: white;
  background: #004c70;
  padding: 8px;
  border-radius: 2px;
  font-size: 14px;
}

.hired-staff-view-profile_link:hover {
  color: white;
  background: #0093d1;
  padding: 8px;
  border-radius: 2px;
  font-size: 14px;
}

.hired-staff-card_element {
  font-size: 16px;
}

.applicant-questions {
  border: 1px solid gainsboro;
  border-radius: 10px;
}

.post-a-job-questions {
  border: 1pt solid #d2d2d2;
  border-radius: 15px;
  background: #fff;
  margin: 5%;
}

.questions-heading-section {
  background: #0093d1;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
}

.post-a-job-questions-close>svg {
  font-size: 20px;
  cursor: pointer;
}

.questions-dealbreaker-section {
  display: flex;
  justify-content: space-between;
  margin: 15px;
  border-bottom: 1pt solid #eee;
  align-items: center;
  padding-bottom: 15px;
}

.questions-dealbreaker-section h4 {
  margin: 0;
  padding: 0;
  font-size: 18px;
}

.questions-dealbreaker-section .filter-menu {
  position: relative;
}

.questions-dealbreaker-section .filter-menu>label {
  margin-bottom: 0;
  border: 1pt solid #1f6181;
  padding: 0px 5px;
  border-radius: 5px;
  cursor: pointer;
}

.questions-dealbreaker-section .filter-menu label:before {
  top: -1px;
}

.questions-dealbreaker-section .filter-menu input:checked+label:after {
  top: 6px;
  left: 11px;
}

.questions-options-section {
  padding: 0 15px;
}

.questions-options-section .custom-radio-btn-salary {
  flex-direction: column;
  margin: 15px 0 5px;
}

.questions-options-section .custom-radio-btn-salary .selected-label {
  width: 100%;
}

.questions-options-section .custom-radio-btn-salary .selection-wrapper {
  margin-bottom: 10px;
}

.questions-options-section .custom-radio-btn-salary .selected-content {
  width: 100%;
  max-width: 100%;
  display: block;
  border: 1pt solid #c4c4c4;
  border-radius: 3px;
  margin-left: 50px;
}

.questions-options-section .custom-radio-btn-salary .selected-content h4 {
  margin: 0;
  padding: 0;
  text-align: left;
  margin-left: 35px;
}
.work-schedule-div .selected-content{
  margin-left: 30px;
}
.questions-options-section .custom-radio-btn-salary .selected-label .icon {
  top: 20px;
}

.questions-options2-section select {
  width: 120px;
  border: 1pt solid #004c70;
  background: #eee;
  color: #004c70;
  height: 33px;
}

.questions-options2-section {
  display: flex;
  margin-bottom: 30px;
  align-items: center;
}

.questions-options2-section .custom-filter-section1 input {
  width: 390px;
  border: 1pt solid #004c70;
  background: #eee;
  color: #004c70;
  height: 33px;
}

.bold-text {
  font-weight: 600;
  margin: 0 10px;
}

.questions-options2-section .filter-menu {
  position: relative;
}

.questions-options2-section .filter-menu>label {
  margin-bottom: 0;
  border: 1pt solid #1f6181;
  padding: 0px 5px;
  border-radius: 5px;
  cursor: pointer;
}

.questions-options2-section .filter-menu label:before {
  top: -1px;
}

.questions-options2-section .filter-menu input:checked+label:after {
  top: 6px;
  left: 11px;
}

#helpdesk .modal-header .modal-title {
  color: #06668e;
  padding: 10px;
  font-weight: 600;
  padding: 0;
}

.modal-header .popupClose {
  background: #004c70;
  color: #fff;
  border-radius: 25px;
  width: 30px;
  border: 1pt solid #004c70;
  box-shadow: none;
  outline: none;
}

#helpdesk .commonBtn {
  background: #004c70;
  color: #fff;
  border-radius: 6px;
  border: none;
  outline: none !important;
  padding: 8px 20px;
  cursor: pointer;
}

#helpdesk sub {
  bottom: 2px;
  color: #004c70;
  font-weight: 600;
  font-size: 14px;
}

#helpdesk.modal-open.modal {
  overflow-x: hidden;
  overflow-y: hidden;
}

/* #helpdesk #form1 .modal-body{
overflow-y: scroll;
height: 400px;
} */
.helpdeskbtn {
  cursor: pointer;
}

#helpdesk .upload-name {
  color: black;
  margin-top: 8px;
  display: inline-block;
  font-size: 16px;
}

#helpdesk .modal-footer {
  justify-content: center;
}

#helpdesk #form2 {
  display: none;
  max-width: 550px;
}

#helpdesk #form2 .modal-body {
  overflow-y: auto;
  height: auto;
}

#helpdesk .ticket-text {
  font-size: 24px;
}

#helpdesk .modal-dialog {
  max-width: 800px;
  margin: 1.75rem auto;
}

#helpdesk .modalPopup {
  max-width: 100% !important;
}

.ticket-text p {
  font-size: 18px;
  margin-bottom: 20px;
  font-weight: 600;
}

.ticket-text p>b {
  color: #004c70;
}

.ticket-text2 {
  text-align: center;
}

.ticket-text2 p {
  font-size: 18px;
  font-weight: 600;
}

.ticket-text2 p:nth-child(2) {
  color: #004c70;
}

#helpdesk .modal-body .form-group {
  display: flex;
  align-items: center;
}

#helpdesk .modal-body .form-group label {
  width: 45%;
  color: #5e5e5e;
}

.commonBtn2 {
  cursor: pointer;
  background: #f4d00c;
  border: 1px solid #f4d00c;
  color: #464646;
  font-weight: 600;
  padding: 5px 15px;
  line-height: 1.5;
  border-radius: 0.25rem;
  cursor: pointer;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.helpdesk-leftcard-row1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.helpdesk-leftcard-row2 p {
  margin: 0;
  line-height: 21px;
  font-size: 14px;
}

.helpdesk-leftcard {
  padding: 10px 10px;
  background: #fff;
  box-shadow: 0px 0px 7px 0px #bcbcbc;
  border-radius: 8px;
}

.helpdesk-leftcard label {
  color: #004c70;
  font-weight: 600;
  font-size: 16px;
}

.helpdesk-leftcard span {
  margin-bottom: 15px;
  display: inline-block;
  font-size: 14px;
}

.helpdesk-heading h4 {
  color: #004c70;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 20px;
  text-transform: uppercase;
}

span.opened-bg {
  background: green;
  color: #fff;
  padding: 2px 10px;
  border-radius: 4px;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 0.5px;
  width: auto !important;
  height: 21px;
  line-height: 18px;
  margin-left: 4px;
}

span.closed-bg {
  background: red;
  color: #fff;
  padding: 2px 10px;
  border-radius: 4px;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 0.5px;
  width: auto !important;
  height: 21px;
  line-height: 18px;
  margin-left: 4px;
}

span.inprogress-bg {
  background: #cd770d;
  color: #fff;
  padding: 2px 10px;
  border-radius: 4px;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 0.5px;
  width: auto !important;
  height: 21px;
  line-height: 18px;
  margin-left: 4px;
}

.helpdesk-heading hr {
  border-top: 3px solid #004c70;
  margin-bottom: 30px !important;
}

.helpdesk-rightcard {
  background: #fff;
  box-shadow: 0px 0px 7px 0px #bcbcbc;
  border-radius: 8px;
}

.helpdesk-rightcard-heading {
  padding: 10px 10px;
  background-color: #004c70;
  color: #fff;
  text-align: center;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.helpdesk-rightcard-heading h5 {
  margin: 0;
  color: #fff;
  font-size: 16px;
}

.helpdesk-rightcard-details {
  padding: 10px 10px;
}

.helpdesk-rightcard-details-row label {
  color: #8d8d8d;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0;
  text-transform: uppercase;
  width: 30%;
}

.helpdesk-rightcard-details-row span {
  width: 70%;
  line-height: 1.5;
  font-size: 12px;
}

.helpdesk-rightcard-details-row hr {
  border-top: 3px solid #004c70;
  margin-bottom: 15px !important;
  margin-top: 15px !important;
}

.helpdesk-rightcard-details-row .commonBtn {
  font-size: 14px;
  padding: 8px 18px;
}

.svg-inline--fa.fa-w-14 {
  width: 0.875em;
}

.attach {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.helpdesk-leftcard {
  margin-bottom: 25px;
  width: 49%;
  cursor: pointer;
}

.helpdesk-tab-content .tab-menu-title ul li a p {
  font-size: 16px;
  text-transform: uppercase;
  margin-bottom: 0;
}

.helpdesk-tab-content .tab-menu-title ul li a {
  cursor: pointer;
  color: #707070;
}

.helpdesk-rightcard-details-row {
  display: flex;
  align-items: flex-start;
  margin-bottom: 7px;
  margin-top: 15px;
}

.helpdesk-rightcard-details-row-btn button {
  margin-right: 15px;
}

.helpdesk-rightcard-details-row-btn {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  justify-content: center;
}

#helpdesk .modal-header .modal-title,
#followup .modal-header .modal-title {
  color: #004c70;
  padding: 10px;
  font-weight: 600;
  padding: 0;
}

#helpdesk .commonBtn,
#followup .commonBtn {
  background: #004c70;
  color: #fff;
  border-radius: 6px;
  border: none;
  outline: none !important;
  padding: 8px 20px;
  cursor: pointer;
}

#helpdesk sub,
#followup sub {
  bottom: 2px;
  color: #004c70;
  font-weight: 600;
  font-size: 14px;
}

#helpdesk.modal-open.modal {
  overflow-x: hidden;
  overflow-y: hidden;
}

/* #helpdesk #form1 .modal-body{
overflow-y: scroll;
height: 400px;
} */
.helpdeskbtn {
  cursor: pointer;
}

#helpdesk .upload-name,
#followup .upload-name {
  color: black;
  margin-top: 8px;
  display: inline-block;
  font-size: 16px;
}

#helpdesk .modal-footer,
#followup .modal-footer {
  justify-content: center;
}

#helpdesk #form2 {
  display: none;
  max-width: 550px;
}

.tab-menu-title {
  display: flex;
}

.tab-menu-title ul li {
  margin: 0;
  padding: 0;
  list-style: none;
  display: inline-block;
  padding: 10px;
  border-bottom: 0px;
  min-width: 80px;
  text-align: center;
  border-bottom: 2px solid transparent;
}

.tab-menu-title ul li:hover,
.tab-menu-title ul li.active {
  border-bottom: 2px solid #004c70;
}

.tab-menu-title ul li:hover a,
.tab-menu-title ul li.active a {
  color: #004c70;
}

.tab-menu-content-section {
  padding: 15px;
  border-top: 2px solid #ddd;
  margin-top: -1px;
}

.tab-menu-title ul {
  margin: 0;
}

.commonBtn {
  background: #004c70;
  color: #fff;
  border-radius: 6px;
  border: none;
  outline: none !important;
  padding: 8px 20px;
  cursor: pointer;
  height: 40px;
}

.modal-header::before,
.modal-header::after {
  display: none;
}

.modal-header .popupClose {
  height: 30px;
}

.deleteMsg {
  font-size: 20px;
  text-align: center;
  margin-bottom: 25px;
}

.modal-content select {
  appearance: auto;
  height: 45px !important;
}

.modal-content .row:before,
.modal-content .row:after {
  display: none;
}

.modal-content .form-control {
  height: 40px !important;
}



.ts-dashboard-body-sec {
  width: 80%;
  min-height: 100vh;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.left-nav-menu {
  width: 87%;
}

.right-nav-menu {
  width: 13%;
}

.form_row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

.label_input {
  display: flex;
  width: 49%;
}

.label_textArea {
  display: flex;
  width: 97.5%;
}

.label_input input {
  width: 70%;
  margin-left: 20px;
}

.label_textArea textarea {
  width: 100%;
  margin-left: 17px;
  height: 70px !important;
}

.label_input label {
  display: flex;
  align-items: center;
  width: 20%;
}

.label_textArea label {
  display: flex;
  align-items: center;
  width: 12%;
}

.label_input_module {
  display: flex;
  width: 49%;
}

.label_input_module input {
  width: 70%;
  margin-left: 20px;
}

.label_input_module label {
  display: flex;
  justify-content: flex-start;
  width: 20%;
  margin-top: 7px;
}

.select_option {
  width: 70%;
  max-height: 40px;
  margin-left: 20px;
  border: lightgray 1px solid;
  border-radius: 4px;
}

.upload_div {
  width: 49%;
  display: flex;
  justify-content: space-between;
}

.upload-name {
  width: 66%;
}

.upload-name p {
  width: 100%;
  text-align: center;
  margin: 0;
}

.upload {
  width: 34%;
  margin-right: 20px;
  display: flex;
  justify-content: flex-end;
}

.no-ticket {
  width: 31%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.no-ticket p {
  width: 100%;
  color: #004c70;
  font-size: 22px;
  text-align: center;
}

@media print {
  body:before {
    content: 'VirtualStaff.ph';
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
    color: #0d745e;
    font-size: 100px;
    font-weight: 500px;
    display: grid;
    justify-content: center;
    align-content: center;
    opacity: 0.2;
    transform: rotate(-45deg);
  }
  body.print-body{
    visibility:visible !important;
  }
  body.print-body:before{
    visibility: visible !important;
    content: 'VirtualStaff.ph';
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
    color: #0d745e;
    font-size: 100px;
    font-weight: 500px;
    display: grid;
    justify-content: center;
    align-content: center;
    opacity: 0.2;
    transform: rotate(-45deg);
  }
}

.watermark {
  opacity: 0.1;
  font-size: 76px;
  position: absolute;
  cursor: default;
  user-select: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  left: auto;
  right: auto;
  text-align: center;
  width: 95%;
  top: 25%;
}

.invoice-update {
  padding: 2%;
  margin: 2%;
  border: 1px solid gainsboro;
  border-radius: 15px;
  background: lemonchiffon;
}

#tooltip {
  display: inline-block;
  /* position: relative;
  z-index: 1000; */
}

#tooltip:hover:after {
  display: flex;
  justify-content: center;
  background: #444;
  border-radius: 8px;
  color: #fff;
  content: attr(title);
  top: 35px;
  font-size: 16px;
  padding: 13px;
  width: 220px;
  position: absolute;
  z-index: 11;
}

#tooltip:hover:before {
  border: solid;
  border-color: #444 transparent;
  border-width: 0px 10px 10px 10px;
  content: '';
  left: 10%;
  top: 28px;
  position: absolute;
}

.invoice-tooltip-section {
  position: relative;
}

/* asdsa */

.select-filter-div {
  width: 18rem;
  border: 1px solid gainsboro;
  border-radius: 7px;
  background: whitesmoke;
}

.select-filter-div input {
  border: navajowhite;
  background: whitesmoke;
  outline: none;
}

.dropdown {
  position: relative;
}

.dropdownmenu {
  position: absolute;
  left: 0;
  background-color: white;
  padding: 0.75rem;
  border-radius: 0.25rem;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
  width: 18rem;
  transition: opacity 150ms ease-in-out;
  transform: translateY(2px);
  height: 8rem;
  overflow: scroll;
  z-index: 11;
}

.dropdown>input:focus+.dropdownmenu {
  transform: translateY(0);
  opacity: 1;
}

.dropdown-content {
  border-bottom: 1px solid gainsboro;
  padding: 1%;
}

.dropdown-content:hover {
  background-color: aliceblue;
}

.new-jobs-view-btn {
  font-size: 18px;
}

.table thead {
  background-color: #004c70;
}

.table thead tr {
  color: white;
}

.table thead tr th {
  color: white;
  text-align: center;
}

.table {
  border: 1px solid gainsboro;
}

.table tbody tr:nth-child(odd) {
  background-color: aliceblue;
}

.table tbody tr:nth-child(even) {
  background-color: whitesmoke;
}

.ticket-comment {
  box-shadow: 0px 0px 11px 0px rgb(0 0 0 / 50%);
  padding: 10px;
  height: 40vh;
  overflow-y: scroll;
  border-radius: 5px;
}

.ticket-comment-two {
  box-shadow: 0px 0px 11px 0px rgb(0 0 0 / 50%);
  padding: 10px;
  height: 10vh;
  overflow-y: scroll;
  border-radius: 5px;
}

.ticket-comment-two p {
  color: #8d8d8d;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0;
  text-transform: uppercase;
  text-align: center;
}

.comment-object {
  border-bottom: solid 2px #e9dede;
  /* padding-bottom: 7px; */
}

.hired-staff-heading {
  font-size: 20px;
}

.hired-staff-table-data>thead>tr>th {
  width: 50%;
  font-size: 18px;
  color: #425274;
}

.hired-staff-table-data {
  width: 100%;
}

.hired-staff-table-data>tbody>tr:hover {
  background: aliceblue;
}

.hired-staff-table-data>tbody>tr>td {
  font-size: 18px;
  padding: 15px 0;
  border-bottom: 1px solid #aaa;
}

.hired-staff-table-data>tbody>tr:last-child>td {
  border-bottom: 0;
}

.TS-heading {
  font-size: 30px;
  margin-bottom: 10px;
  color: #004c70;
  font-weight: bold;
}

.find-staff-search {
  width: 35%;
  margin: 0 auto;
  position: absolute;
  bottom: -20px;
}

.find-staff-search>input {
  width: 100%;
  height: 44px;
}

.find-staff-search>button {
  position: absolute;
  top: 0;
  right: 0;
  height: 44px;
  width: 44px;
  border: 1pt solid #004c70;
  background: #004c70;
  color: #fff;
}

.hiredemployer-section-bg {
  background: #004c70;
  height: 150px;
  width: 100%;
  display: flex;
}

.hiredemployer-img-section {
  width: 250px;
  height: 250px;
}

.hired-text-box {
  background: #249d00;
  padding: 7px 25px;
  display: inline-block;
  color: #fff;
  font-size: 18px;
  margin: 30px 0 20px;
}

.terminated-text-box {
  background: red;
  padding: 7px 25px;
  display: inline-block;
  color: #fff;
  font-size: 18px;
  margin: 30px 0 20px;
}

.hiredemployer-text {
  width: calc(100% - 300px);
}

.time-tracker-req-txt {
  margin-right: 15px;
  font-size: 14px;
}

.hired-pricing-text,
.hired-pricing-text input {
  font-size: 30px;
  font-weight: bold;
}

.hired-text-heading {
  font-family: 'Lato-Bold';
  color: #004c70;
  font-size: 18px;
}

.custom-margin-b-50 {
  margin-bottom: 10px;
}

.hired-btn-area {
  display: flex;
  flex-direction: column;
}

.hired-btn-area>div>a {
  margin-bottom: 20px;
  width: 75%;
  font-weight: bold;
  font-size: 20px;
}

/* .hired-btn-area > div> a{
  margin-bottom: 20px;
  width: 75%;
} */
.hired-btn-area>div>i {
  position: relative;
  top: -16px;
  font-size: 18px;
  cursor: pointer;
}

.hired-btn-area>div>button {
  margin-bottom: 20px;
  width: 75%;
  font-weight: bold;
  font-size: 20px;
}

.timing-text-section>p>i {
  margin-left: 10px;
  position: relative;
  font-size: 18px;
  cursor: pointer;
}

.hired-btn-section {
  position: relative;
}

.hired-btn-heading {
  margin-bottom: 10px;
  color: #004c70;
  font-family: 'Lato-Bold';
}

/* .hired-btn-section::after {
  width: 3px;
  height: 100%;
  background: #004c70;
  right: -12px;
  top: 0;
  position: absolute;
  content: '';
}
.hired-btn-section::before {
  width: 3px;
  height: 100%;
  background: #004c70;
  right: -12px;
  top: 0;
  position: absolute;
  content: '';
} */
.react-pagination {
  width: 25%;
  margin: 0 auto 26px;
}

.virtualstaff-academy-text {
  padding: 30px 0;
  text-align: center;
}

.virtualstaff-academy-text>p {
  color: #363636;
}

/* .applicant-profile-education-table {
  height: 100vh;
} */
.invoice-table {
  font-size: 18px;
  height: 100vh;
  overflow: auto;
}

.applicant-profile-education-table>table {
  width: 100%;
  border: 1pt solid #eee;
}

.applicant-profile-education-table>table>thead>tr>th,
.applicant-profile-education-table>table>tbody>tr>td {
  padding: 10px;
  text-align: center;
}

.applicant-profile-education-table>table>tbody>tr {
  height: 60px;
}

.applicant-profile-education-table>table>thead {
  background: #004c70;
}

.applicant-profile-education-table>table>thead>tr>th {
  color: #fff;
  border-right: 1px solid #949494;
}

.applicant-profile-education-table>table>tbody>tr>td {
  /* border-right: 1px solid #666363; */
  border-bottom: 1px solid #eee;
}

.applicant-profile-education-table>table>tbody>tr>td:last-child {
  border-right: 0;
}
.applicant-profile-education-table>table>tbody>tr>td:nth-child(7){
  border-right: 0px;
}
.applicant-profile-education-table>table>tbody>tr>td:nth-child(6){
  border-right: 0px;
}
.success-btn {
  border-radius: 16px;
  border: none;
  width: 9rem;
  height: 3rem;
  background: green;
  color: white;
}

.pending-btn {
  border-radius: 16px;
  border: none;
  width: 9rem;
  height: 3rem;
  background: yellow;
  color: black;
}

.failure-btn {
  border-radius: 16px;
  border: none;
  width: 7rem;
  height: 3rem;
  background: red;
  color: white;
}

.appicant-profile-banner {
  background: url('../images/banner.jpg');
  width: 100%;
  height: 250px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.appicant-profile-banner.pricing-page-banner::after {
  display: none;
}

.applicant-profile-card {
  background: #ffffff;
  box-shadow: 0px 0px 7px 1px rgba(0, 0, 0, 0.25);
  padding-bottom: 20px;
}

.applicant-profile-details {
  display: flex;
}

.applicant-profile-image {
  position: relative;

  margin-right: 64px;
}

.applicant-profile-pic {
  position: relative;
  margin-top: -55px;
  z-index: 111;
  width: 268px;
  height: 268px;
}

.applicant-profile-pic>img {
  border: 5px solid #3e3e3e;
  border-radius: 50%;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.applicant-profile-pic-title {
  font-family: 'Lato-Black';
  font-size: 36px;
  line-height: 43px;
  text-align: center;
  color: #514c4c;
  margin-top: 10px;
}

.applicant-profile-job-section {
  padding-top: 20px;
  width: calc(100% - 64px);
}

.applicant-profile-first-section {
  display: flex;
  justify-content: space-between;
}

.applicant-profile-job-title {
  font-size: 30px;
  line-height: 36px;
  font-family: 'Lato-Black';
  color: #33708d;
  margin: 0;
  margin-right: 10px;
}

.applicant-profile-job-title-area {
  display: flex;
  align-items: center;
}

.applicant-profile-postbtns>svg {
  margin-right: 10px;
  cursor: pointer;
}

.applicant-profile-sub-category {
  font-size: 16px;
  line-height: 19px;
  text-transform: capitalize;
  font-family: 'Lato-Black';
  color: #737373;
  font-style: italic;
}

.applicant-profile-location-icon {
  margin-bottom: 15px;
  display: flex;
}

.location_icon {
  width: 12%;
}

.location {
  width: 90%;
}

.down_btn {
  padding: 5px 10px;
  outline: none;
  border: solid 1px transparent;
  background-color: #004c70;
  border-radius: 56px;
  margin-bottom: 10px;
}

.location span {
  font-size: 16px;
  line-height: 19px;
  text-transform: capitalize;
  font-family: 'Lato-Black';
  color: #737373;
  width: 100%;
}

.applicant-profile-location-icon>svg {
  margin-right: 10px;
}

.applicant-profile-location-icon>span {
  font-size: 16px;
  line-height: 19px;
  text-transform: capitalize;
  font-family: 'Lato-Black';
  color: #737373;
}

.applicant-profile-location-icon:last-child>span {
  color: rgba(40, 177, 46, 0.6);
}

.applicant-profile-salary>label {
  font-size: 20px;
  line-height: 24px;
  font-family: 'Lato-Black';
  color: #737373;
  margin-bottom: 4px;
}

.box-pricing {
  font-size: 17px;
  line-height: 19px;
  font-family: 'Lato-Regular';
  color: #33708d;
  width: auto;
  height: 29px;
  display: inline-block;
  padding: 4px 0;
  font-weight: 700;
}

.employment-type-text {
  line-height: 24px;
  font-family: 'Lato-Regular';
  color: #33708d;
  font-weight: 700;
  font-size: 19px;
}

.applicant-profile-salary-audio-section {
  display: flex;
  justify-content: space-between;
  margin-bottom: 17px;
  align-items: flex-end;
  margin-top: 15px;
}

.applicant-profile-audio-section {
  width: 208px;
}

.applicant-profile-audio-title {
  font-size: 13px;
  font-weight: bold;
  line-height: 22px;
  margin-top: 10px;
  font-family: 'Lato-Bold';
}

.applicant-profile-download-section .btn {
  background: #004c70;
  /* border-radius: 30px; */
  border: 1pt solid #004c70;
  font-size: 16px;
  line-height: 19px;
  font-family: 'Lato-Bold';
  color: #ffffff;
  padding: 7px 15px;
  margin-right: 15px;
}

.applicant-profile-download-section .btn:hover {
  color: #fff;
  background: #004c70;
  border: 1pt solid #004c70;
}

.applicant-skills-details {
  padding: 45px 0;
}

.applicant-details-single-section {
  background: #eeeeee;
}

.applicant-details-single-title {
  background: #004c70;
  width: 100%;
  padding: 13px 11px;
  font-size: 24px;
  line-height: 29px;
  font-family: 'Lato-Black';
  color: #ffffff;
}

.applicant-details-single-content {
  padding: 15px;
}

.applicant-details-single-tags {
  display: flex;
  flex-wrap: wrap;
}

.applicant-details-tag {
  background: #0093d1;
  margin-right: 15px;
  font-size: 16px;
  line-height: 30px;
  text-transform: uppercase;
  font-family: 'Lato-Regular';
  color: #ffffff;
  padding: 4px 11px;
  cursor: pointer;
}

.applicant-details-single-content>p {
  margin-bottom: 0;
  font-size: 16px;
  line-height: 30px;
  font-family: 'Lato-Regular';
  color: #494949;
}

.applicant-details-single-content table {
  width: 100%;
}

.applicant-details-single-content table>thead>tr>th {
  font-size: 20px;
  line-height: 24px;
  font-family: 'Lato-Black';
  color: #737373;
  padding-bottom: 10px;
  border-bottom: 1px solid #737373;
  text-transform: capitalize;
}

.applicant-details-single-content table>tbody>tr>td {
  font-size: 18px;
  line-height: 22px;
  color: #737373;
  padding: 10px 0;
}

.applicant-profile-job-details {
  width: 65%;
}

.applicant-profile-location-details {
  width: 35%;
}

.job-seeker-row-section .job-seeker-job-offer-section {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  border: 0;
  width: 25%;
  padding-bottom: 0;
  margin-bottom: 0;
  padding: 15px;
  border-right: 1pt solid #bbb;
}

.job-seeker-row-section .job-seeker-job-offer-section:last-child {
  border-right: 0;
}

.job-seeker-row-section {
  display: flex;
  margin: 0 0 40px;
  border: 1pt solid #bbb;
}

.hired-staff-search {
  position: absolute;
  bottom: -15px;
  width: 35%;
}

.hired-staff-search .search-container {
  width: 100%;
  position: relative;
}

.search-container>input {
  height: 42px;
}

.search-container>.full-width input {
  height: 42px;
}

.search-container>button {
  position: absolute;
  right: 0;
  top: 0;
  height: 42px;
  width: 42px;
  background: #004c70;
  color: #fff;
  border: 1pt solid #004c70;
}

.job-seeker-row-section .job-seeker-job-offer-section h3 {
  font-size: 18px;
  margin-bottom: 15px;
  text-transform: uppercase;
}

.job-seeker-row-section .job-seeker-job-offer-section p {
  font-size: 14px;
}

.job-seeker-description-section .job-seeker-job-offer-section {
  border: 0;
  flex-direction: column;
  align-items: flex-start;
}

.time-tracker-text {
  font-size: 8px;
  margin-bottom: 30px;
}

.link1-span {
  margin-bottom: 90px;
}

.filter-checkbox:checked+label::before {
  background: #ececec !important;
}

.filter-checkbox:checked+label::after {
  background: none !important;
  box-shadow: none;
}

.sidebar-list {
  display: flex;
  flex-direction: column;
  font-size: 13px;
  color: #2784b2 !important;
  margin-left: 55px;
  padding-bottom: 10px;
}

.sidebar-list li {
  color: #2784b2 !important;
}

.terminated-btn {
  display: flex;
  align-items: center;
  /* width: 12rem; */
  text-align: center;
  justify-content: space-evenly;
}

.hired-btn,
.resigned-btn {
  display: flex;

  align-items: center;
  /* width: 12rem; */
  text-align: center;
  justify-content: space-around;
}

.resigned-btn span {
  height: 25px;
  width: 25px;
  background-color: yellow;
  border-radius: 50%;
  display: inline-block;
}

.terminated-btn span {
  height: 25px;
  width: 25px;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
}

.terminated-btn h4 {
  opacity: 0.7;
  margin-bottom: 0px;
}

.hired-btn span {
  height: 25px;
  width: 25px;
  background-color: rgb(9, 145, 9);
  border-radius: 50%;
  display: inline-block;

}

.hired-btn h4 {
  margin-bottom: 0px;
  font-weight: bold;
}

.view-more-btn {
  background: #004c70;

  border: 1pt solid #004c70;
  font-size: 16px;
  line-height: 19px;
  font-family: 'Lato-Bold';
  color: #ffffff;
  padding: 10px 15px;
  margin-right: 15px;
  text-transform: uppercase;
}

.job_filter {
  margin-right: 50px;
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-end;
}

.smaller-text {
  font-size: 10px;
  color: #acacac;
  display: block;
}

.edit-profile-btn-section .login-btn-section button {
  background: #004c70;
  color: #fff;
}

.edit-profile-banner {
  width: 100%;
  height: 320px;
  position: relative;
  background-image: url('../images/about/editprofile-bg.jpg');
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 3;
}

.edit-profile-banner::after {
  display: none;
}

.hire-employer-breadcumbs {
  width: 45%;
  margin: 0 auto;
  height: 100%;
  display: flex;
  align-items: flex-end;
}

.hirestaff-individual-name,
.hirestaff-slash {
  color: #fff;
  font-size: 18px;
  margin-right: 15px;
  cursor: pointer;
}

.increase-salary-dollar-text,
.increase-salary-whole-value,
.increase-salary-decimal,
.increase-salary-balance,
.increase-salary-hour-text {
  font-size: 42px;
  color: #004c70;
  font-family: 'Lato-Black';
}

.hirestaff-breadcumbs-list {
  display: flex;
}

.time-tracker-text>p {
  margin-bottom: 15px;
}

.time-tracker-text>p:last-child {
  margin-bottom: 0;
}

.increase-salary-space {
  width: 28px;
  display: inline-block;
  height: 25px;
}

.increase-salary-btn {
  background: transparent;
  border: 0;
  color: #004c70;
  font-size: 30px;
  width: 50px;
}

.custom-right-spacing {
  margin-right: 20px;
}

.custom-visibility-hidden {
  visibility: hidden;
}

.increase-salary-rate-section h5 {
  font-weight: 600;
}

.increase-salary-rate-section p {
  font-size: 14px;
}

.hired-employer-popup.modal.show .modal-dialog {
  max-width: 580px;
}

.increase-salary-popup {
  text-align: center;
}

.increase-salary-arrow-up-section {
  width: 87%;
}

.increase-salary-input-area {
  display: flex;
  justify-content: center;
  align-items: center;
}

.increase-salary-input-area input {
  border: none;
  width: 4rem;
  color: #004c70;
  border-bottom: 1px solid;
}

.currency-input {
  border: 1px solid #004C70 !important;
  display: flex;
  padding: 0px 0px 0px 15px;
  align-items: center;
  background: white !important;
}

.currency-input input {
  border: none !important;
  background: none !important;
}

.notification-div {
  display: flex;
  justify-content: space-between;
}

.find-staff-profile {
  width: 100%;
  height: 90px;
}

.find-staff-profile img {
  width: 90px;
  height: 90px;
  border-radius: 50%;
}

.find-staff-details {
  padding: 13px 0px;
  display: flex;
  justify-content: space-between;
}

.find-staff-details h5 {
  font-weight: bold;
  line-height: 26px;
  text-transform: capitalize;
  font-family: 'Lato-Bold';
  color: #004c70;
  margin-bottom: 0px;
  text-align: left;
  width: 50%;
}

.find-staff-icon-section {
  display: flex;

  padding: 10px 0px;
}

.find-staff-icon-section div {
  color: #000;
}

.find-staff-three {
  width: 68%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.find-staff-icon-section i {
  margin-right: 3px;
}

.find-staff-one i {
  color: black;
}

.find-staff-one span {
  font-weight: bold;
  color: black;
}

.find-staff-two i {
  color: green;
}

.find-staff-three i {
  color: blue;
}

.find-staff-summary {
  color: grey;
}

.find-staff-right-section {
  /* padding: 10px 24px; */
  display: flex;
  justify-content: end;
  align-items: center;
  width: 50%;
}

.find-staff-right-section button {
  border: navajowhite;
  background: deepskyblue;
  border-radius: 11px;
  padding: 5px 12px;
  margin-right: 5px;
}

.invoice-header {
  display: flex;
  justify-content: space-between;

  border-bottom: 1px solid #004c70;
  padding: 4px 87px;
}

.invoice-header h2 {
  font-size: 65px;
}

.invoice-left {
  padding: 10px 120px 10px 121px;
}

.invoice-box {
  display: flex;
  border: 1px solid #145e82;
  background: #145e82;
  color: white;
  padding: 8px 14px;
}

.invoice-box-one {
  text-align: center;
  border-right: 1px solid white;
  width: 50%;
}

.invoice-box span {
  color: #f4d00c;
}

.invoice-box-two {
  text-align: center;
  width: 50%;
}

.invoice-to-section {
  /* margin-top: 15px; */
  padding: 20px;
}

.invoice-right {
  text-align: right;
}

.auto-invoice-box {
  height: 3rem;
  background: aliceblue;
  text-align: center;
  padding: 10px 0px;
  font-weight: 800;
}

.invoice-buttons {
  align-items: center;
  align-items: center;
  margin-top: 30px;
  text-align: center;
  text-align: center;
}

.next-steps {
  padding: 10px 55px;

  font-style: italic;
  color: gray;
}

.job-card {
  border: solid 2px #0096d2;
  width: 90%;
  margin: auto;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 5px 10px #0096d2;
}

.job-card:hover {
  border: solid 2px #0096d2;
  width: 93%;
  transition: 0.6s;
  margin: auto;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 5px 10px #0096d2;
}

.job-card h3 {
  color: #0096d2 !important;
  font-size: 25px;
  margin: 0 !important;
  font-weight: bold;
  letter-spacing: 0.4px;
  font-family: "Source Sans Pro", sans-serif !important;
}

.salary h6 {
  font-size: 1.4rem;
  font-weight: 600 !important;
  color: black !important;
  margin: 0 !important;
  font-family: "Source Sans Pro", sans-serif !important;
}

.calendar h6 {
  font-size: 1.4rem;
  font-weight: 600 !important;
  color: black !important;
  margin: 0 !important;
  font-family: "Source Sans Pro", sans-serif !important;
}

.job-card-desc p {
  font-size: 1.4rem;
  font-family: "Source Sans Pro", sans-serif !important;
  margin-bottom: 5px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.invoicetooltip i {
  font-size: 12px;
  margin: 7px;
}

.profile-points ul li {
  list-style: disc;
  color: grey;

}

.profile-points ul {
  margin-left: 21px;
}

.schedule-modal-footer {
  padding: 3%;
}

.schedule-modal-footer ul li {
  list-style: disc;
}

.find_job {
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
  cursor: pointer;
  width: 90%;
  border-radius: 8px;
  /* border: solid 2px #0093d1; */
  padding: 20px;
  margin: 0 auto 30px;
  transition: 0.3s;
}

.find_job:hover {
  width: 93%;
}

.find_job h2 {
  font-size: 25px;
  color: #0093d1;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 600;
  width: 88%;
}

.find-job-p {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.find-job-p p {
  font-size: 15px;
  margin-right: 10px;
}

.find-job-sub-data {
  display: flex;
  justify-content: space-between;
}

.find_job h4 {
  font-size: 15px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.find_job h5 {
  font-size: 15px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.job-skills {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.invoice-note {
  padding: 3%;
  background: aliceblue;
}

.invoice-note ol {

  margin-left: 3%;
}

.invoice-note ol li {
  padding: 1%;
  list-style: disc;
}

.invoice-banner {
  background: url(../images/invoice/banner.jpg);
  width: 100%;
  height: 300px;
  background-size: 100%;
  background-repeat: no-repeat;
}

.invoice-heading {
  background: #004c70;
  padding: 40px 0;
}

.invoice-heading-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.weekly-invoice-heading {
  font-size: 40px;
  color: #fff;
}

.weekly-invoice-date {
  font-size: 18px;
  color: #fff;
}

.weekly-invoice-status {
  font-size: 18px;
  color: #fff;
}

.due-bg {
  background: #fff;
  color: #00d31e;
  padding: 5px 40px;
  font-family: "Lato-Bold";
  margin-left: 10px;
  border-radius: 7px;
  text-transform: uppercase;
}

.Amended {
  color: red;
}

.Due {
  color: orange;
}

.Auto-Billed {
  color: green;
}

.Disputed {
  color: red;
}

.staffname-label-section {
  display: flex;
  justify-content: space-between;
}

.staffname-section {
  padding: 40px 0 20px;
}

.staffname-label>label {
  color: #004c70;
  font-size: 20px;
  margin-bottom: 15px;
  font-family: "Lato-Black";
  min-width: 150px;
}

.staffname-label>label>i {
  cursor: pointer;
}

.staffname-label>span {
  color: #7a7a7a;
  font-size: 18px;
  font-family: "Lato-Bold";
}

.billing-period-date>span {
  color: #7a7a7a;
  font-size: 18px;
  font-family: "Lato-Bold";
}

.invoice-details-heading {
  background: #004c70;
  color: #fff;
  padding: 10px;
  text-align: center;
  font-size: 22px;
  font-family: "Lato-Black";
  margin-bottom: 15px;
}

.automatic-hours-sections .staffname-label>label {
  color: #8e8e8e;
  font-size: 14px;
  min-width: 250px;
}

.automatic-hours-sections .staffname-label>span {
  color: #8e8e8e;
  font-size: 14px;
}

.totalhours-section .staffname-label>label {
  min-width: 250px;
  margin-bottom: 0;
}

.manual-automatic-text>small {
  font-size: 12px;
  font-family: "Lato-Bold";
}

.invoice-line-separator {
  width: 100%;
  height: 6px;
  border-radius: 20px;
  background: #7a7a7a;
  margin: 10px 0 20px;
}

.amountdue-whole-section {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  padding: 0 20px;
}

.amountdue-section-left {
  display: flex;
}

.amountdue-section-right {
  display: flex;
  flex-direction: column;
}

.amountdue-section-left-text {
  min-width: 250px;
  color: #004c70;
  font-size: 36px;
  margin-bottom: 15px;
  font-family: "Lato-Black";
  line-height: 47px;
}

.amountdue-section-price {
  color: #008037;
  font-size: 36px;
  font-family: "Lato-Black";
}

.amountdue-section-right-price {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.amountdue-section-right>textarea {
  height: 120px;
  width: 350px;
  resize: none;
  border: 1pt solid #004c70;
  background: #fff;
  font-size: 16px;
  border-radius: 5px;
  padding: 0 10px;
}

.amountdue-section-right>label {
  color: #004c70;
  font-size: 20px;
  margin-bottom: 15px;
  font-family: "Lato-Black";
}

.pay-btn.btn {
  background: #008037;
  border: 1pt solid #008037;
  color: #fff;
  font-size: 16px;
  margin-right: 15px;
  padding: 5px 20px;
}

.Amend-btn.btn {
  background: #004c70;
  border: 1pt solid #004c70;
  color: #fff;
  font-size: 16px;
  padding: 5px 20px;
}

.totalhours-section .staffname-label-section-left:last-child .staffname-label>label {
  min-width: 175px;
}

.invoice-details-border {
  background: #fff;
  border: 1pt solid #cbcbcb;
  margin-bottom: 30px;
}

.staffname-label {
  padding: 0 20px;
  /* card loader */
  /* The loading Class */
}

.loading {
  position: relative;
  background-color: #e2e2e2;
}

.find-staff-profile.loading {
  border-radius: 50%;
}

.invoice-footer {
  text-align: center;
  font-size: 4px;
  border-top: 1px solid blue;
  margin: 0px 36px;
  padding: 1%;
  margin-top: 20px
}

.invoice-footer>p {
  font-size: 20px;
}

/* The moving element */
.loading::after {
  display: block;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  transform: translateX(-100%);
  background: -webkit-gradient(linear, left top,
      right top, from(transparent),
      color-stop(rgba(255, 255, 255, 0.2)),
      to(transparent));

  background: linear-gradient(90deg, transparent,
      rgba(255, 255, 255, 0.2), transparent);

  /* Adding animation */
  animation: loading 0.8s infinite;
}

/* Loading Animation */
@keyframes loading {
  100% {
    transform: translateX(100%);
  }
}

.dispute-div {
  width: 65%;
  background: gainsboro;
  padding: 2%;
  border-radius: 10px;
}

.dispute-div b {
  color: red;
}

.disputed-div span {
  color: red;
  font-size: 20px;
}

.invoice-right {

  text-align: center;
  background: gainsboro;
  padding: 1%;
  font-weight: bold;
  /* font-style: oblique; */
  font-family: 'Lato-Black';
}

.invoice-right-info-two {
  text-align-last: end;
  padding-right: 4px;
  flex-direction: column;
}

.invoice-right-info {
  text-transform: uppercase;
  font-size: 18px;
  flex-direction: column;
}

.invoice-right-info>span:nth-child(odd) {
  font-weight: bold;
}

.receipt-table {
  width: 100%;
  text-align: left;
  border: 1pt solid #aaa;
  border-spacing: 0;
}

.receipt-table>tr:nth-child(1) {
  background: gainsboro;
  padding: 1%;
}

.receipt-table>tr:nth-child(2)>td:nth-child(1) {
  width: 75%;
  padding: 10px;
  border-right: 1pt solid #aaa;
  border-bottom: 1pt solid #aaa;
}

.receipt-table>tr:nth-child(2)>td:nth-child(2) {
  width: 25%;
  text-align: end;
  padding: 10px;
  border-bottom: 1pt solid #aaa;
}

.receipt-table>tr:nth-child(3)>td:nth-child(1) {
  width: 75%;
  padding: 10px;
  border-right: 1pt solid #aaa;
  border-bottom: 1pt solid #aaa;
}

.receipt-table>tr:nth-child(3)>td:nth-child(2) {
  width: 25%;
  text-align: end;
  padding: 10px;
  border-bottom: 1pt solid #aaa;
}

.receipt-table>tr:nth-child(4)>td:nth-child(1) {
  width: 75%;
  padding: 10px;
  border-right: 1pt solid #aaa;
  border-bottom: 1pt solid #aaa;
}

.receipt-table>tr:nth-child(4)>td:nth-child(2) {
  width: 25%;
  text-align: end;
  padding: 10px;
  border-bottom: 1pt solid #aaa;
}

.hide {
  position: absolute !important;
  width: 67% !important;
  top: -9999px !important;
  left: -9999px !important;
}

.payment-options-card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);

  margin: auto;

  font-family: arial;
}

.payment-options-card-header {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid gainsboro;
  padding: 2rem;
  background: #f1f1f1;
}

.payment-options-card-header>div:nth-child(1) {
  font-size: 26px;
}

.payment-options-card-content {
  padding: 2rem;
  background: white;
}

.list-card {
  border: 1px solid gainsboro;
  align-items: center;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  padding: 1%;
}

.card-div {
  display: flex;
  justify-content: space-between;
  width: 91%;
  align-items: center;
  text-align: center;

  border-radius: 31px;

}

.card-div>span {
  width: 8rem;
}

.no-invoice-div {
  text-align: left;
  margin: 0px 432px;

}

.no-invoice-div>ul {
  list-style: disc;
  margin-left: 42px;
}

.format-desc ul {
  list-style: disc;
  margin-left: 65px;

}

.tablet-menu-button {
  display: none;
}

.user-preview-left-header {
  width: 50%;
}

.credit-card {
  border: 1px solid #eee;
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.02);
  padding: 20px;
  border-radius: 3px;
  font-size: 18px;
  /* 
  &.StripeElement--focus {
    border: 1px solid #ffcc00;
    box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.02);
  } */
}

.card-error {
  background: #ea4335;
  color: #fff;
  padding: 20px;
  border-radius: 3px;
  margin-top: 10px;
}

.bonus-modal>h1 {
  color: white;
  font-weight: bold;
}
.bonus-modal>h6 {
  color: white;
  
}
.currency-usd {
  width: 78% !important;
}

.currency-input>span {
  margin: 0px;
  height: 40px;
  width: 65px;
  font-size: 14px;
  background:#004C70;
  /* align-items: center; */
  font-weight: bold;
  text-align: center;
  padding-top: 10px;
  color: white;
}

.bonus-footer {
  display: flex;
  align-items: center;
  margin-top: 31px;
}

.bonus-footer>span {
  font-weight: bold;
  width: 10rem;
  /* background: #2b8fe7; */
  text-align: center;
  border-radius: 5px;
  margin-left: 21px;
  /* color: white; */
  border: 1px solid #004C70
}
.modal-footer{
  border-top:  1px solid #004C70 !important;
}

.card-default::after {
  content: "Default";
  left: 12px;
  position: relative;

  color: grey;
  display: flex;
  margin-left: 10px;
  margin-bottom: 14px;
  bottom: 3px;


}

.three_dot_menu {
  background-color: lightgrey;
  padding: 0 6px;
  display: flex;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
}

.dropdown_menus {
  background-color: #414a4c;
  position: absolute;
  margin-top: 46px;
  padding: 7px 5px;
  border-radius: 5px;
  box-shadow: 0 0 4px 0 #696969;
}

.dropdown_menus p {
  cursor: pointer;
  color: white;
  padding: 5px 23px 5px;
  margin: 0;
}

.dropdown_menus p:hover {
  background-color: lightgrey;
  border-radius: 5px;
  color: #004c70;
}

.password_modal_content p {
  margin-bottom: 0;
  text-align: center;
}

.schedule_interview_details {
  font-size: 16px;
}

.schedule_interview_details span {
  color: grey;
  font-weight: bold;
}

.time {
  font-size: 16px;
}

.time span {
  color: black;
  font-weight: bold;
}

.main_tracker_page {
  background-color: lightgray;
  padding: 30px 0;
}

.main_tracker_page h1 {
  color: rgb(51, 68, 77);
  font-weight: bold;
  margin-bottom: 4rem;
}

.tracker_install {
  display: flex;
  justify-content: space-around;
}

.first_card {
  background-color: #f2f2f2;
  width: 31%;
  padding: 30px 0;
}

.second_card {
  background-color: #f2f2f2;
  width: 31%;
  padding: 30px 0;
}

.third_card {
  background-color: #f2f2f2;
  width: 31%;
  padding: 30px 0;
}

.first_card_img_div {
  width: 28%;
  margin: auto;
}

.first_card_img_div img {
  width: 100%;
}

.second_card_img_div {
  width: 28%;
  margin: auto;
}

.second_card_img_div img {
  width: 100%;
}

.third_card_img_div {
  width: 28%;
  margin: auto;
}

.third_card_img_div img {
  width: 100%;
}

/* 08/03/22 */
/* findastaff - accordion left */
.custom-filter-section .collapse-state {
  margin-top: unset !important;
}

/* unverified */
.unverified-page {
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* employer page */
.up-user-image .circled-image {
  width: 10rem !important;
  height: 10rem !important;
}

.up-user-image .circled-image img {
  width: 100% !important;
  height: 100% !important;
}

/* job-dash */
header.jobseeker_header nav.navbar.navbar-expand-md {
  justify-content: space-between !important;
}

.job-dash table,
.applied-jobs table {
  display: table !important;
}

.job-dash thead th {
  color: #fff !important;
}

.job-dash tbody,
.job-dash thead,
.applied-jobs tbody,
.applied-jobs thead {
  display: table-row-group !important;
}

.job-dash thead tr,
.applied-jobs thead tr {
  position: unset !important;

}

.job-dash .hired-staff-table-data th,
.applied-jobs tr {
  color: #fff ;
}

.job-dash tr,
.applied-jobs tr {
  overflow: auto;
  display: unset !important;
  display: table-row !important;
}

.job-dash td,
.job-dash th,
.applied-jobs td,
.applied-jobs th {
  width: unset !important;
  display: table-cell !important;

}

.job-dash td::before,
.applied-jobs td::before {
  background: transparent !important;
  content: '' !important;
}

.applied-jobs-pagination .paination_link {
  margin: 10px auto;
}

.applied-jobs .react-pagination {
  width: 250px !important;
}

.interview-confirm {
  width: 60%;
}

.interview-confirm .job-details {
  font-size: 20px;
  font-weight: 600;
}

/* jobseeker/user-profile/preview */
.job-user-preview .up-user-image-preview::after {
  display: none !important;
}

.job-user-preview .img-wrapper {
  position: relative;
  width: 150px;
  height: 150px;
}

.job-user-preview .img-wrapper img {
  width: 100% !important;
  height: 100% !important;
}

.job-user-preview .img-wrapper a {
  position: absolute;
  right: 0;
  top: 0;
}

/* expertise level modal */

#expertiselevelModal .skills-level-box {
  border: 1px solid #ccc !important;
  border-radius: 5px;
  height: 150px !important;
  overflow: auto;
}

#expertiselevelModal .skills-level .row {
  margin: unset !important;
}

/* user-preview-employment modal */
#addEmployement .year-picker {
  width: 100% !important;
  height: 40px !important;
}

/* language modal */
#languageModal audio {
  width: 100% !important;
}

/* location modal */
#locationModal .form-control {
  width: 100% !important;
  min-width: 100% !important;
}

#delete .modal-dialog {
  min-width: 500px !important;
  margin: auto;
}

/* findjobs jobseeker */
.find-job-jobseeker-body .paination_link {
  width: 250px !important;
}

/* -----------------employer-15/03/22----------------------- */
.employer-dashboard-main .table thead th {
  color: #fff !important;
}

.employer-dashboard-main .table tbody td {
  padding: 15px !important;
}

/* view all applicants */
.employer-view-applicants-page .row.row-eq-height {
  margin: 0 !important;
}

/* employer enterprise */
.employer-enterprise .internal-enterprise-banner-right {
  background-size: cover !important;
}



/* job seeker dashboard - 18-03-2022 */

/* 22-03-2022 */

.message-group.paddingnone {
  padding: 0px !important;
}


/* 
.side-nav ul li:last-child{
  display: none; 
}  */
.padd-top {
  padding-top: 16px !important;
}

.mr-6 {
  margin-right: 6px !important;
}


/* noitifaction number */

.notification-header-num {
  background: red;
  color: #fff;
  font-size: 10px;
  height: 20px;
  width: 20px;
  width: 25px;
  position: absolute;
  position: relative;
  right: 0px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  bottom: 45px;
  justify-content: center;
  font-weight: bold;
}

.footer {
  border-top: 1px solid #e8e8e8;
  line-height: 38px;
  padding: 0 0px !important;
}

.react-pagination {
  width: 8% !important;
  margin: 0 auto 26px;
}

.mrgin-btm {
  margin-bottom: 12px;
}

.year-input {

  padding: 8px 8px !important;

}

#expertiselevelModal .skills-level-box {
  height: 230px !important;
}

.task-container-sass .footer-area {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-top: 30px !important;
}

.container.padding {
  width: 100% !important;
  padding-right: 15px !important;
  padding-left: 15px !important;
  margin-right: auto !important;
  margin-left: auto !important;
}

.applicant-profile-education-table>table>tbody>tr>td {
  border-bottom: 0px !important;
}

.terminated-btn.centalign {
  display: flex;
  align-items: center;
  /* width: 12rem; */
  text-align: center;
  justify-content: center !important;
}

.hired-btn.centalign {
  display: flex;

  align-items: center;

  text-align: center;
  justify-content: center;
}

.hired-btn.centalign span {
  margin-right: 8px !important;
}

.terminated-btn.centalign span {
  margin-right: 8px !important;
}

.mb-25 {
  margin-bottom: 24rem !important;
}

.mt-55 {
  margin-top: -15px !important;
  width: 197px;
}

.drop-zone.fntsize {
  font-size: 1.4rem !important;
}

.post-a-job .tab-content .tab-pane .custom-filter-section select.borderradius {

  border-radius: 0.25rem !important;
  height: 38px !important;
}

.fnt-12 {
  font-size: 14px !important;
}

.mt-28 {
  width: 78px;
}

.tablescroll {
  width: 100%;
  display: block;
  overflow: scroll;
  white-space: nowrap;
}

.m-0.mt-none p {
  margin-bottom: 0px !important;
}

.v-staff-admin-container .vesselstablebottom {

  height: auto !important;

}

#pending {
  background: yellow;
}

#verified {
  background: green;
  color: #fff
}

#rejected {
  background: red
}

.applicants-profile-img.round img {
  width: 100px !important;
  height: 100px !important;
  border-radius: 68px !important;
}

.short-desc-css {
  height: 67px;
  overflow: scroll;
  overflow: hidden;
  color: black;
  margin-bottom: 10px;
}

.default-css ol,
.default-css ol li,
.post-content-container ol,
.post-content-container ol li {
  list-style: auto;
  all: revert;
  line-height: 2;
}

.default-css ul,
.default-css ul li,
.post-content-container ul,
.post-content-container ul li {
  list-style: disc !important;
  all: revert;
  line-height: 2;
}

.default-css * {
  font-size: initial;
}

.default-css h1 {
  font-size: 3rem !important;
  color: #0093d1 !important;
  margin-bottom: 1rem;
}

.default-css h2 {
  font-size: 2.7rem !important;
  color: #0093d1 !important;
  margin-bottom: 1rem;
}

.default-css h3 {
  font-size: 2.2rem !important;
  color: #0093d1 !important;
  margin-bottom: 1rem;
}

.default-css h4:not(.big-typo) {
  font-size: 2.07rem !important;
  color: #0093d1 !important;
  margin-bottom: 1rem;
}

.default-css h5 {
  font-size: 2rem !important;
  color: #0093d1 !important;
  margin-bottom: 1rem;
}

.default-css h6 {
  font-size: 1.5rem !important;
  color: #0093d1 !important;
  margin-bottom: 1rem;
}

.applicants-profile-img {
  width: 30%;

}

.filter-menu input:checked+label:after {
  content: '';
  display: block;
  position: absolute;
  top: 6px !important;
  left: 7px !important;
  width: 6px !important;
  height: 12px !important;
  border: solid #004c70;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.paination_link {
  width: 12% !important;
}

.applicant-details-single-content table.color-white>thead>tr>th {

  color: #ffffff !important;

}
.custom-btn-style{
  width: max-content !important;
}

.dashboard-parent .custom-btn-style.thisbtn,
.landing-page .custom-btn-style {
  width: 18rem !important;
}


.newheader {
  font-size: 42px;
  font-weight: 900;
  margin-bottom: 0px !important;
}

.jobdescrbtnblue {
  background-color: #004c70 !important;
  border-color: #004c70 !important;
  border-radius: 12px !important;
  font-weight: 600 !important;
}

.stripe-modal .modal-content {
  border-radius: 18px !important;
  border: 2px solid white !important;
}


.jobdescrbtnheart {
  background-color: #eceaf7 !important;
  border-radius: 12px !important;
}

.hearticon {
  font-size: 24px !important;
}

.jobdescrbtnreport {
  background-color: #eceaf7 !important;
  border-radius: 12px !important;
  border-color: #eceaf7 !important;
  color: black !important;
  font-weight: 600 !important;
}

.landing-page h1.newheader::after {
  display: none !important;
}

.paddingtp-5 {
  padding-top: 5rem !important;
}

.paddingbtm-0.bookmarked-job-body {

  padding-bottom: 0rem !important;
}

.applyjob-section button.mrgright {
  margin-right: 15px;
}

.hire-staff-button {
  background: #3fa73f;
  border-radius: 7px;
  border: navajowhite;
  color: white;
  height: 2rem;
  width: 7rem;

}

.cancel-button {
  background: grey;
  border-radius: 7px;
  border: navajowhite;
  color: white;
  height: 2rem;
  width: 5rem;

}

.sidebarmobile {
  display: none;
}


.table.headsticky thead {
  position: sticky !important;
  z-index: 100 !important;
  top: 0 !important;
}


.tickadjust.styled-checkbox:checked+label:after {
  top: 8px !important;
  left: 5px !important;
}

.cancel-btn {
  color: #004c70;
  background: white;
  border: none;
}

@media (min-width:320px) and (max-width:767px) {
  .dashboard_view{
    left: 22px !important;
  }
  .dashboard_info{
    margin-left: 0px !important;
  }
  .employer-posted-job-list-page .job-list-right.flex-sear .edited-btn{
    margin-bottom: 4px !important;
}
.return{
  font-size: 20px;
}
  select.form-control.job_select_tag {
    width: 40% !important;
    font-size: 13px;
    padding-top: 0px;
    padding-bottom: 0px;
    height: 30px !important;
  }
  .Sin-up .container {
    width: 80%;
    margin: auto;
  }

  .Sin-up .job-cards {
    width: 100%;
  }

  .Sin-up .job-cards {
    margin-bottom: 2rem;
    display: flex;
    align-items: center;
    padding: 1rem;
    padding-bottom: 0.3rem;
  }

  .Sin-up .job-cards .job-det {
    padding-right: 0.3rem;
    width: 45% !important;
  }

  .Sin-up .job-cards .job-lst {
    width: 55% !important;
  }

  .Sin-up .job-cards .job-lst ul {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .Sin-up .job-cards img {
    width: 60px;
    height: 60px;
  }

  .Sin-up .job-cards h4 {
    font-size: 16px !important;
  }

  .Sin-up .job-cards button {

    font-size: 11px;
    padding: 7px 12px;
  }

  .Sin-up .job-cards ul li {
    font-size: 14px !important;
  }

  .Sin-up .sin-start h2 {
    Font-style: Bold;
    Font-size: 20px;
    color: #004C70;
  }

  .Sin-up .sin-start p {
    Font-size: 18px;
    color: black;
    padding-bottom: 2rem;

  }

  .Sin-up .sin-start span {
    font-size: 18px;
  }
}

/* signup page recheck */
@media (min-width:320px) and (max-width:1160px) {
  .d-flex.justify-content-end.mt-1.mr-1.closepos.x-bar-res {
    padding-top: 100px !important;
  }
}

@media (min-width:951px) and (max-width:1160px) {
  .d-flex.justify-content-end.mt-1.mr-1.closepos.x-bar-res {
    padding-top: 75px !important;
  }
}

@media (min-width:485px) and (max-width:770px) {
  .protected_text p{
    font-size: 13px;
  }
  select.form-control.job_select_tag {
    width: 78%;
}
  .d-flex.justify-content-end.mt-1.mr-1.closepos.x-bar-res {
    padding-top: 70px !important;
  }
}

@media (min-width:320px) and (max-width:484px) {
  .contract_banner p {
    font-size: 16px;
  }
  .contract_banner button {
    font-size: 15px;
    padding: 8px 12px;
}
.contract_banner div:last-child {
  width: 370px;
}
  .virtual_protection .row div:first-child{
    margin-bottom: 25px;
  }
  .flex_res .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl{
  width: 145px;
  height: 40px;
}
.info_btn3 {
    position: absolute;
    height: 13px;
    right: 2px;
    display: flex;
    align-items: center;
    top: 3px;
    font-size: 7px;
    padding: 0px 5px;
}
  .flex_res .MuiOutlinedInput-input {
    padding: 8.5px 9px;
  }
  .info_par{
    width: fit-content !important;
  }
  .flex_res{
    display: block;
  }
  .agreement_footer{
    display: block;
  }
  .agreement_footer div{
    width: 90%;
    margin: auto;
    margin-bottom: 30px;
  }
  .virtual_protection{
    padding-top: 1px;
    margin-top: 70px;
  }
  .res_img{
    margin-bottom: 25px !important;
  }
  .hide_image{
    display: none;
  }
  .virtual_protection h2{
    font-size: 34px;
    line-height: 42px;
  }
  .virtual_protection p{
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    margin-bottom: 11px;

    margin-left: 0px;
  }
  .contarct_wraper{
    display: block;
  width: 90%;

  }
  .contract_box{
    width: 90%;
  }
  .contract{
    width: 95% !important;
  }
  .employer_signed_para {
    font-size: 17px;
    line-height: 30px;

  
  }

  .Sin-up .job-cards .emp-det {
    padding-right: 0.3rem;
    width: 45% !important;
  }

  .Sin-up .job-cards .emp-lst {
    width: 55% !important;

  }

  .d-flex.justify-content-end.mt-1.mr-1.closepos.x-bar-res {
    padding-top: 50px !important;
  }
}

@media (min-width:950px) and (max-width:1160px) {
  .job-cards li {
    font-size: 16px !important;
  }

  .job-cards2 {
    padding-bottom: 33px !important;
  }

  .position-absolute.orposition.or-res {
    top: 43% !important;
  }

}

@media (min-width:771px) and (max-width:949px) {
  
  .position-absolute.orposition.or-res {
    top: 42% !important;
  }
}

@media (min-width:485px) and (max-width:770px) {
  .job-cards li {
    font-size: 14px !important;
  }

  .Sin-up .job-cards .emp-det {
    padding-right: 1rem;
    width: 50%;
  }

  .Sin-up .job-cards button {
    font-size: 12px;
    padding: 10px 8px;
  }

  .Sin-up .job-cards .job-det {
    padding-right: 1rem;
    width: 50%;
  }

  .job-cards2 {
    padding-bottom: 25px !important;
  }

  .position-absolute .orposition .or-res {
    top: 43% !important;
  }
}

@media (min-width:481px) and (max-width:484px) {
  .position-absolute.orposition.or-res {
    top: 46% !important;
  }
}

@media (min-width:320px) and (max-width:480px) {
  .employer-sidebar ul{
    background: #004C70;
  }
  .position-absolute.orposition.or-res {
    top: 47% !important;
  }

  .sin-start {
    width: 290px;
  }

  .sin-start img {
    width: 100%;
  }
}

.employer-dashboard-bg {
  background: #ece8e8;
  border-radius: 10px;
  padding: 2%;
}

.employer-dashboard-bg h2 {
  color: #004c70;
  font-weight: bold;
}

.employer-dashboard-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 1em;
}

.employer-dashboard-table tbody tr,
a {
  background: white;
  color: grey;
  cursor: pointer;
}

.employer-dashboard-table tbody tr td {
  padding: .5rem;
}

.payment-button {
  border: none;
  background: #1A932E2E;
  color: #1A932E;
  border-radius: 16px;
  padding: 7px 25px;
  font-weight: bold;
}

.employer-dashboard-table tbody tr td:nth-child(1) a {
  color: black !important;
  font-weight: bold;
}

.tracker-table table {
  width: 100%;
}

.tracker-table thead tr {
  border-top: 1px solid black;
  border-bottom: 1px solid black;
}

.google-login-button {
  background-color: #4285f4;
  background-image: none;
  color: #fff;
  color: white;
  border: none;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  width: 88%;
  border-radius: 50px;
}

.google-login-button span {
  font-size: 14px;
  line-height: 38px;
  letter-spacing: .22px;
  margin-left: 0;
  margin-right: 10px;
  vertical-align: top;
}

/* 
subscription-pricng */

.emp-pricing-header {

  height: max-content;
  text-align: center;
  color: white;
}

.emp-pricing-header .emp-pricing-header-section {
  background: #277CEA;
}

.emp-pricing-section .header-title h1 {
  font-size: 3.5rem;
  font-weight: bold;
}

.emp-pricing-section .header-title .header-description {
  justify-content: center;

}

.emp-pricing-section .header-title .header-description div {
  margin: 20px 10px 10px 10px;
}

.emp-pricing-section .header-title .header-description div span {
  height: 1.5rem;
  width: 1.5rem;
  background: white;
  border-radius: 50%;
  color: black;
}

.emp-pricing-section .header-title .header-description div p {
  font-size: 18px;
  margin-left: 15px;

}

.emp-pricing-toggle-btn {
  border-radius: 20px;
  background: #494848;

  width: max-content;
}

.emp-pricing-toggle-btn .active {
  padding: 3%;
  width: 10rem;
  border: none;
  border-radius: 18px;
  background: #1abf1a;
  color: white;
  font-weight: bold;
  letter-spacing: 1px;

}

.success-theme {
  background: #1abf1a;
  width: 14rem;
  border: none;
}

.info-theme {
  background: #494848;
  width: 14rem;
  border: none;
  border-radius: 5px;
}

.emp-pricing-toggle-btn .not-active {
  background: transparent;
  border: none;
  color: white;
  font-weight: bold;
  letter-spacing: 1px;
  width: 10rem;
  border-radius: 18px;
  padding:10px;
}

.emp-pricing-body {

  margin: 0 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  justify-items: center;
  height: calc(100% - 15px);
  margin-bottom: 15px;
}

.emp-pricing-body .cards {
  width: 420px;
  margin: 20px 10px 10px 10px;
  background: white;
  color: black;
  /* height: max-content; */
  padding: 10px 25px;
  ;
  border-radius: 5px;
}

.emp-pricing-body .cards .cards-header span {
  font-size: 20px;
  font-weight: bold;
}

.emp-pricing-body .cards .cards-header h1 {
  margin: 0;
  text-transform: uppercase;
  font-weight: bold;
  color: #004c70;
  font-size: 2rem;
}

.emp-pricing-body .cards .card-pricing {
  color: #004c70;
}

.emp-pricing-body .cards .card-pricing .pricing-tag {
  text-align: left;
  margin-left: 12px;
  font-family: emoji;
  height: 3rem;

}

.emp-pricing-body .cards .card-pricing .pricing-data>span:nth-child(1) {
  color: grey;
  text-decoration: line-through;
  text-decoration-color: #1dd61d;
}

.emp-pricing-body .enterprise-text {
  text-align: left;

  font-size: 18px;
  letter-spacing: 1px;
  width: 100%;

}

.emp-pricing-body .card-body {
  color: #004c70;
  /* height: calc(100% - 15px);
  margin-bottom: 15px; */
}

.iconBtn {
  height: 1.5rem;
  width: 1.5rem;
  background: #1abf1a;
  border-radius: 50%;
  color: white;
  /* border: 1px solid grey; */
}

.emp-pricing-body .card-body .card-content .card-row div {
  font-size: 20px;
  color: #004c70;
  height: 2.5rem;
}

.card-pricing-btn {
  background: #5534a0;
  margin-top: 10px;
  padding: 1%;
  color: white;
  border: none;
  border-radius: 5px;
}

.emp-profile-footer {
  height: 70vh;

}

.emp-profile-footer .sec-one div {
  background: linear-gradient(to bottom, #f8f6f6 0%, #f8f6f6 35%, #277CEA 35%, #277CEA 100%);
  width: 60%;
  height: 86%;
}

.emp-profile-footer .sec-three div {
  background: linear-gradient(to bottom, #f8f6f6 0%, #f8f6f6 35%, #277CEA 35%, #277CEA 100%);
  width: 60%;
  height: 86%;

}

.emp-profile-footer .sec-three {
  display: flex;
  justify-content: end;
}

.emp-pricing-footer .inner-div h2 {
  color: rgb(0, 76, 112);
  margin-left: 17px;
}

/* .emp-pricing-body .cards  .card-body .card-row{
 
  padding-left: 42px;
} */
#promo-card {
  padding-left: 42px;
}

.promo-head {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.dummy-div {
  background: #141414;
  height: 10px;
  width: 30%;
  position: absolute;
  top: -8px;
  left: 35%;
}

.dummy-div-two {
  background: #181818;

  width: 30%;
  position: absolute;

  left: 35%;

}

.emp-pricing-footer .inner-div h2 {
  color: rgb(0, 76, 112);
  font-weight: 800;
  font-size: 45px;
  line-height: 54px;
  text-align: center;
}

.emp-pricing-footer {
  width: 81%;
  margin: auto;
  display: flex;
  align-items: center;
  background: #E2F6FF;
  border-radius: 30px;
  padding: 0px 40px;
}

.emp-pricing-footer .inner-div>div p {
  color: black;
  font-weight: 400;
font-size: 23px;
line-height: 36px;
text-align: center;
margin-bottom: 35px;
}

.emp-pricing-footer .inner-div>div h3 {
  font-weight: 700;
  font-size: 30px;
  line-height: 60px;
}

.emp-pricing-footer img {
  height: 300px;
}

@media (max-width:500px) {
  .heart_icon{
    font-size: 15px;
  }
  .jobdescrbtnheart{
    padding: 0px 5px !important;
    height: 25px;
  }
  .img_wrapper{
    margin-bottom: 30px;
  }
  .job_seeker_details .img_wrapper{
    margin: auto !important;
    padding-right:0px !important;
  }
  .payments{
    justify-content: center;
  }
  .find-job-input {
    width: 25rem !important;
}
.blog-input-icon {
    padding: 13px !important;
    font-size: 15px;
}
  .search_div input{
    width: 270px;
  }
  .fnd-crd-row{
    margin: auto !important;
  }
  .find-job-input {
    padding: 7px;
    width: 13rem;
}
  .applicant-profile-education-table {
    margin-top: 10px !important;
  }
  .new_jobs_list{
    text-align: center;
    margin: 0px !important;
  }
  .job_offer_heading{
    font-size: 31px !important;
    text-align: center;
    line-height: 36px !important;
    margin-bottom: 0px !important;
    margin-top: 30px;
}
  .about_employer{
    text-align: center;
  }
  .job-offer-price-content{
    line-height: 23px !important;
  }
  .head-m{
    font-size: 34px !important;
    text-align: left !important;
  }
  .individual-skill_span--jobseeker {
    padding: 8px;
    font-size: 14px;
  }
  .mb-pt{
    padding: 0px !important;
  }
  .bj-content-body p > span, .bj-content-body li > span, .bj-content-body a > span{
    font-size: 14px !important;
  }
  .up-card-section ul li strong {
    font-size: 12px;
    line-height: 24px;
}
.up-card-section ul li div {
  font-size: 13px;
  line-height: 18px;
  text-align: left !important;
}
.up-card-section ul li{
  border: 0px !important;
  text-align: left !important;
}
.up-card-section ul {
  width: 90%;
  margin-top: 0px;
}
  .heart_icon i {
    font-size: 18px;
    margin: auto 16px;
    margin-bottom: 16px;

}
.report_text{
  margin-top: 10px;
  font-size: 14px;
}
  .job-post-container {
    padding: 0px 0px;
}
.job-post-container .apply_now {
  font-size: 12px;
    padding: 2px 8px;
        margin-bottom: 16px;
}
  .internal-findstaff-banner-heading {
    font-size: 14px;
    font-weight: 700;
    line-height: 25px;
    word-break: break-all;
    margin-bottom: 6px !important;
}
  .notification-heading h2 {
    font-size: 32px;
  }
  .lable-tag h5{
    font-size: 20px !important;
  }
  .lable-tag p{
    font-size: 14px !important;
  }
  .bookmarked_profile .view_applicants_banner{
    padding-top: 80px;
  }
  .bookmarked_profile .view_applicants_banner h1{
    font-size:2.1rem;
  }
  .notification-header-num{
    right: 10px;
    bottom: 10px;
  }
  .job-list{
    padding: 0px;
  }
  .job_list_right button{
    width: 24% !important;
  }
  .job_list_right a{
   font-size: 12px !important;
  }
  .job_list_right label{
    display: none;
  }
  .job_list_right select{
    width: 48%;
  }
  .job-list-left-title p{
    margin-bottom: 0px;
  }
  .job-list-left-mail p{
    margin-bottom: 0px;
  }
  .job-list-left h6{
    font-size: 16px;
    line-height: 22px;
  }
  .job-list-right .edit-btn a{
    font-size: 14px;
    padding:7px ;
    border-radius: 25px;
  }
  .job-list-right .edited-btn a{
    font-size: 14px;
    padding:7px ;
    border-radius: 25px;
  }
  .job-list-right .edit-btn i{
    margin-left: 2px;
  }
  .job-list-right {
    padding: 5px 0;
  }
  .job-list-right button{
    width: fit-content;
    padding: 0px !important;
  }
  .job-list-right label{
    display: none;
  }
  .job-list-right select{
    width: 50%;
  }
  .post-job span {
    font-size: 9px !important;
  }

  .mobile_nav a {
    font-size: 10px !important;
    padding: 0px !important;
    display: flex;
    align-items: center;
  }

  .employer_header .nav-item {
    display: flex;
    align-items: center;
  }

  .nav-item a i {
    font-size: 18px !important;
    display: flex;
    align-items: center;
  }

  .mobile_nav .post-job {
    padding: 3px !important;
  }

  .navbar {
    padding-bottom: 0px !important;
  }

  .iconButton {
    font-size: 18px;
    padding: 0px !important;
    width: 25px !important;
    height: 25px !important;
  }

  .nav {
    justify-content: space-between;
    width: 95%;
    margin: auto;
  }

  .contactus-left-card-input svg {
    right: 12px;
  }

  .sub-pricing .heading .options {
    display: block !important;
    padding: 10px;
    justify-content: center;
  }

  .cards-banner {
    width: 100% !important;
    position: absolute;
    top: -25px !important;
    left: -4px !important;
    overflow: hidden;
  }

  .sub-pricing .price-cards .cards .btn-div {
    width: 100% !important;
  }
  #get_now{
    width: 90% !important;
  }
  .price-cards > div{
    margin-top: 30px;
  }

  .cards {
    width: 85% !important;
  }

  .review-img {
    border-right: 0px;
    width: 320px;
  }

  .review-card {
    padding: 30px 26px;
    text-align: center;
  }

  .review-card p {
    font-size: 14px;
    line-height: 17px;
  }

  .review-card h5 {
    font-size: 15px;
    line-height: 17px;
    margin-left: 0px !important;
  }

  .review h2 {
    font-size: 33px;
    line-height: 40px;
    margin-bottom: 0px !important;
  }

  .emp-pricing-footer {
    width: 90% !important;
    display: block !important;
    padding: 0px 20px !important;
    padding-top: 30px !important;
  }

  .emp-pricing-footer .inner-div h2 {
    font-size: 22px !important;
    line-height: 30px !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .emp-pricing-footer .inner-div>div h3 {
    font-size: 24px !important;
    line-height: 38px !important;
    margin-top: 24px !important;
  }

  .emp-pricing-footer .inner-div>div p {
    font-size: 15px !important;
    line-height: 22px !important;
  }

  .sub-pricing .heading .options i {
    height: 23px;
    width: 25px;
    margin-top: 4px;
    margin-right: 5px;
  }

  .sub-pricing {
    margin-top: 5rem;
  }

  .sub-pricing .heading {
    margin-top: 8px;
  }

  .sub-pricing .heading h1 {
    font-size: 35px;
    line-height: 46px;
    width: 100%;
  }

  .sub-pricing .heading h3 {
    font-size: 26px;
    line-height: 35px;
    width: 100%;
  }

  .sub-pricing .heading .options>div {
    align-items: flex-start;
    justify-content: left !important;
  }

  .sub-pricing .heading .options p {
    font-size: 20px;
  }

  .dummy-div-two {


    width: 56% !important;


    left: 20% !important;

  }

  .counter-section .counter #one {

    font-size: 30px !important;
    letter-spacing: 0px !important;
    line-height: 27px !important;
  }

  .counter-section .counter #two {
    letter-spacing: 0px !important;
    font-size: 15px !important;

  }
  .find_job_search.find_job_search{
    width: 13rem !important;
    padding: 10px !important;
  }
  .find_staff .internal-howitworks-banner-page .internal-howitworks-banner-left .blog-input-icon{
    padding: 10px 14px !important;
  }
}

@media (max-width:901px) and (max-width:1000px) {
  .counter-section .counter #one {


    font-size: 30px !important;
    letter-spacing: 0px !important;
    line-height: 27px !important;

  }

  .counter-section .counter #two {
    letter-spacing: 0px !important;
    font-size: 15px !important;
    letter-spacing: 0px !important;

  }
}
@media (width < 500px){
  .hide-img{
    display: none;
  }
}
@media (max-width:900px) and (min-width: 500px) {
  #hal-two {
    display: none;
  }

  #hal-three {
    display: none;
  }

  .counter-section .counter #one {


    font-size: 30px !important;
    letter-spacing: 0px !important;
    line-height: 27px !important;

  }

  .counter-section .counter #two {
    letter-spacing: 0px !important;
    font-size: 15px !important;
    letter-spacing: 0px !important;

  }

  .inner-div div {
    width: 100% !important;
  }
}

@media (max-width:700px) {
  .subscription-modal-button {
    flex-direction: column;
  }

  .subscription-modal-button .second {
    margin-top: 10px;
  }

  .emp-pricing-body .card-body .card-content .card-row div {
    font-size: 15px;
  }

  .emp-pricing-section .header-title .header-description div {
    margin: 0 !important;
  }

  .emp-pricing-section .header-title .header-description div span {
    width: 2.5rem !important;
  }

  .emp-pricing-section .header-title .header-description div p {
    font-size: 15px !important;
    margin-left: 0 !important;
  }

  .emp-pricing-header .emp-pricing-header-section {
    margin-top: 50px;
  }

  .emp-pricing-section .header-title h1 {
    font-size: 1.5rem !important;
  }

  .tooltips:before {
    white-space: normal !important;
  }

  .faq-profile-button .button {
    padding: 0 !important;
    font-size: 8px !important;
    width: 3rem;
    margin-left: 16px;
    margin-top: 3px;

  }

  .timer-section .timer-div span {
    background: whitesmoke;
    /* border-radius: 5px; */
    height: 10px !important;
    width: 10px !important;
  }

  .timer-section .timer-div p {
    color: White;
    font-size: 5px !important;
  }

  .timer-section {
    height: 0 !important;
    width: 0 !important;
  }

  .halloween-popup .counter-sec {
    width: 70px !important;
    height: 39px !important;
    transform: translate(227px, 1px) !important;


  }

  .halloween-popup .first-div {
    transform: translate(342px, 621px) !important;

  }

  .counter-sec p {
    font-size: 8px !important;
  }

  .counter-sec .faq-profile-button {
    line-height: 8px;
    display: flex;
    flex-direction: column;
  }

  .close-btn {
    padding: 3px !important;
    font-size: 11px !important;
  }

  .counter-part {
    margin-bottom: 27px;
  }

  .promo-header .header-title .header-description div p {
    font-size: 14px !important;
  }

  .emp-pricing-faq #faq {
    width: 100% !important;
  }

  .emp-pricing-body .cards .card-body .card-row {
    margin-bottom: 10px;
    padding-left: 0 !important;
  }

  .promo-page {
    margin-top: 16%;
  }

  #hal-two {
    display: none;
  }

  #hal-three {
    display: none;
  }

  /* .halloween-popup img{
    width: 300px;
    height:40px;
  } */
  .halloween-popup {
    left: -1px;
  }

  .promo-page .promo-header .promo-headone {
    margin: 0 28px !important;
    width: 290px !important;
    font-size: 14px !important;
    text-align: center;
    line-height: 20px !important;
    align-items: center;
    justify-content: center;
    padding-top: 17px !important;

  }



  /* .emp-profile-footer {
    height : 38vh;
    
  } */
}

.emp-profile-footer .sec-two img {
  width: 10rem;
  position: relative;
  z-index: 1000;
  top: 19px;
}

.emp-profile-footer .sec-two {
  color: black;

}

.emp-profile-footer .sec-two .inner-div {
  background: #13142acf;
  position: absolute;
  padding: 5%;
  top: 140px;
  border-radius: 25px;
}

.emp-profile-footer .sec-two .inner-div h2 {
  color: #09b0f7;
  font-weight: bold;
}

.emp-profile-footer .sec-two .inner-div h3 {
  font-size: 2.5rem;
  font-style: italic;
  color: yellow;

}

.divider {
  border: none;
  border-top: 5px dashed grey;
  color: #fff;
  background-color: #fff;
  height: 1px;
  width: 50%;
}

.emp-pricing-faq #faq {
  width: 60%;
  text-align: left;
}

#faq .card .card-header .btn-header-link:after {
  content: "\f107";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  float: right;

}

.emp-pricing-modal {
  background-color: rgb(1, 60, 94);
  color: white;
  width: 540px;
}

.emp-pricing-modal .modal-heading h1:nth-child(1) {
  line-height: 19px;
  font-size: 3rem;
  letter-spacing: 4px;
}

.emp-pricing-modal .modal-heading h1:nth-child(2) {
  line-height: 61px;
  font-size: 3rem;
  letter-spacing: 4px;
}

.emp-pricing-modal .modal-heading h1:nth-child(3) {
  line-height: 19px;
}

/* .subscription-modal .modal-content{
  background-color:#474a4e;
  text-align: center;
  color:white;
} */

.warning-text {
  background: yellow;
  padding: 7px;
  border-radius: 5px;
  font-weight: bold;
  color: black;
}

.subscription-success-page {
  margin: 5%;
  text-align: center;
  height: 60vh;
  position: relative;
}

.subscription-success-page h1 {
  font-size: 7rem;
}

#svg {
  position: absolute;

  pointer-events: none;
  top: -50px;
  left: 25%;
}

.bold-font {
  font-family: 'Lato-Black';
}

.emp-sub-modal .modal-content {
  border-radius: 35px;
}

.text-under a {
  text-decoration: underline !important;
}

.text-underline {
  text-decoration: underline !important;
}

.bg-lighter {
  background: #c7ccd0;
}

.skill-section {
  display: flex;
  flex-direction: column;
  /* height: 50px; */
  font-size: 12px;
  color: grey;
}

.skill-section span {
  margin-left: 20px;
}

.halloween-popup {
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  cursor: pointer;

  z-index: 10;
}

.halloween-popup img {
  position: relative;
}

.halloween-popup .counter-sec {
  position: absolute;

  width: 190px;
  height: 100px;
  transform: translate(686px, 1px);
  border: none;
  padding-top: 5px;
  background: linear-gradient(to right, #30308ef7 0%, #0e0e3af7 35%, #0a0a3cf7 35%, #111152f7 100%);
}

.timer-section {
  display: flex;
  width: 100%;
  justify-content: space-around;
  height: 38px
}

.timer-section .timer-div {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.timer-section .timer-div span {
  background: whitesmoke;
  border-radius: 5px;
  height: 2rem;
  width: 2rem;
}

.timer-section .timer-div p {
  color: White;
  font-size: 10px;
}

.counter-sec p {
  color: white;
  text-align: center;
  font-size: 10px;
  margin: 0;
}

.faq-profile-button {
  text-align: center;
}

.faq-profile-button button,
.faq-profile-button .button {
  background: #f4d00c;
  border: 1pt solid #f4d00c;
  padding: 7px 15px;
  border-radius: 8px;
  font-size: 10px;
  color: #004c70;
  font-family: "Lato-Black";
}

.close-btn {
  background: #FFFFFF;
  border-radius: 50%;
  text-align: center;
  padding: 4px;
  font-size: 10px;
}

.halloween-popup .first-div {
  position: absolute;
  z-index: 10;
  transform: translate(844px, -3px);

}

.promo-page .promo-header {
  background: #004c70;

  padding: 5%;
}

.promo-page .promo-header .promo-headone {
  background: #FEC700;

  border-radius: 10px;
  font-family: Lato Regular;
  font-style: normal;

  font-size: 30px;
  line-height: 36px;
  text-align: center;


  padding: 2%;
  color: #0A2237;
}

.promo-header .header-title {
  color: white;
  text-align: center;
}

.promo-header .header-title .header-description {
  justify-content: center;

}

.promo-header .header-title .header-description div {
  margin: 20px 10px 10px 10px;

}

.promo-header .header-title .header-description div span {
  height: 1.5rem;
  width: 1.5rem;
  background: white;
  border-radius: 50%;
  color: black;
}

.promo-header .header-title .header-description div p {
  font-size: 18px;
  margin-left: 15px;

}

.promo-page .counter-part {
  text-align: center;
}

.promo-page .counter-part {
  color: white;
}

.counter-section .counter #one {
  font-family: Lato Regular;
  font-size: 90px;
  font-weight: 800;
  line-height: 108px;
  letter-spacing: 18px;
  text-align: center;
  color: #FEC700;
}

.counter-section .counter #two {
  font-family: Lato Regular;
  font-size: 30px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: center;

}

.counter-section .letter-div {
  display: flex;
  justify-content: space-around;
  margin: 0px 25%;
  /* text-align: center; */
  font-family: Lato;
  font-size: 30px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: center;
}

.discount-section {
  height: 74px;
  width: 87%;
  text-align: center;
  position: absolute;
  top: -18px;
  border-radius: 10px;
  background: #1ABF1A;
  color: white;
  padding: 2%;

}

.emp-pricing-footer {

  width: 81%;
  margin: auto;
  display: flex;
  align-items: center;
  background: #E2F6FF;
  border-radius: 30px;
  padding: 0px 40px;

}


.tooltips {
  position: relative;
}

.tooltips:before,
.tooltips:after {
  display: block;
  opacity: 0;
  pointer-events: none;
  position: absolute;
}

.tooltips:after {
  border-right: 6px solid transparent;
  border-bottom: 6px solid rgba(0, 0, 0, .75);
  border-left: 6px solid transparent;
  content: '';
  height: 0;
  top: 20px;
  left: 20px;
  width: 0;
}

.tooltips:before {
  background: rgba(0, 0, 0, .75);
  border-radius: 2px;
  color: #fff;
  content: attr(data-title);
  font-size: 14px;
  padding: 6px 10px;
  top: 26px;
  /* white-space: nowrap; */
}

.tooltips.swing:before,
.tooltips.swing:after {
  transform: translate3d(0, 30px, 0) rotate3d(0, 0, 1, 60deg);
  transform-origin: 0 0;
  transition: transform .15s ease-in-out, opacity .2s;
  z-index: 99;
}

.tooltips.swing:after {
  transform: translate3d(0, 60px, 0);
  transition: transform .15s ease-in-out, opacity .2s;
}

.tooltips.swing:hover:before,
.tooltips.swing:hover:after {
  opacity: 1;
  transform: translate3d(0, 0, 0) rotate3d(1, 1, 1, 0deg);
}


.blk-friday {
  background-image: url('../images/blackfriday.png');
}

.blk-friday .content {
  text-align: center;
  border: solid 3px #FF1F1F;
  padding: 1rem;
  margin: 2rem;
  position: relative;
}

.blk-friday .content h2 {
  color: #FFD600;
  Font-style: Bold;
  Font-size: 60px;
}

.blk-friday .content h4 {
  color: white;
  Font-size: 40px;
  padding: 1rem;
}

.blk-friday .content p {
  color: white;
  Font-size: 20px;
}

.blk-friday .content span {
  color: #FFD600;

}

.blk-friday .plan-crd {
  padding: 3rem;
}

.blk-friday .plan-crd .plan-1 {
  text-align: center;
  color:
    #004C70;
  background-color: white;
  border: solid 1px white;
  border-radius: 10px;
  padding: 1rem;
  position: relative;


}

.blk-friday .plan-crd .plan-1 .title-plan {

  padding-bottom: 1rem;
}

.blk-friday .plan-crd .plan-1 .title-plan h4 {
  color: white;
  background-color: #FF1F1F;
  font-size: 18px;
  border: solid 1px #FF1F1F;
  border-radius: 5px;
  justify-content: center;
  position: absolute;
  bottom: 94%;
  padding: 10px;

}

.blk-friday .plan-crd .plan-1 h5 {
  padding: 5px;
  font-size: 20px;
  color: black;
}

.blk-friday .plan-crd .plan-1 h3 {
  font-size: 25px;
  color: #004C70;
}

.blk-friday .plan-crd .plan-1 p {
  font-size: 18px;
  color: #004C70;

}

.blk-friday .plan-crd .plan-1 h2 {
  font-size: 50px;
  color: #004C70;
}

.blk-friday .plan-crd .plan-1 ul {
  text-align: left;
  list-style-type: none;
}

.blk-friday .plan-crd .plan-1 ul li i {
  color: #1ABF1A;
  font-size: 20px;
}

.blk-friday .plan-crd .plan-1 ul li span {
  color: black;
  font-size: 20px;
}

.blk-friday .plan-crd .plan-1 ul li {
  font-size: 20px;
  color: #004C70;
  padding-bottom: 5px;
}

.blk-friday .plan-crd .plan-1 button {
  font-size: 20px;
  background-color: #FF1F1F;
  color: white;
  border-radius: 10px;
  border: none;
  padding: 5px 2rem;
  width: 250px;

}

.blk-friday .plan-crd .plan-1 .card-body .card-content {
  padding: 0px 30px;
}


@media (min-width:990px) and (max-width:1200px) {
  .blk-friday .plan-crd .plan-1 .title-plan {
    padding-left: 0rem !important;
  }

  .blk-friday .plan-crd .plan-1 .title-plan h4 {

    padding: 5px !important;

  }
}

@media (min-width:721px) and (max-width:990px) {
  .internal-findstaff-banner-heading {
    font-size: 27px;
    line-height: 45px;
  }
  .job-post-container {
    padding: 20px 0px;
}

  /* .blk-friday .plan-crd .plan-1 .title-plan {
        padding-left: 6rem !important;
    } */
  .job-det {
    width: 40%;
  }

  .job-lst {
    width: 60%;
  }

  .emp-det {
    width: 40%;
  }

  .emp-lst {
    width: 60%;
  }

  .blk-friday .plan-crd .plan-1 {
    margin-bottom: 2rem !important;
  }
}

@media (min-width:530px) and (max-width:767px) {
  /* .blk-friday .plan-crd .plan-1 .title-plan {
        padding-left: 2rem !important;
    } */

  .blk-friday .plan-crd .plan-1 {
    margin-bottom: 2rem !important;
  }
}

@media (min-width:320px) and (max-width:530px) {
  .emp-pricing-toggle-btn button{
    font-size: 14px;
    width: 9rem !important;
    padding:8px 4px !important;
  }
  .sub-pricing .price-cards .cards .btn-div .brown-bg{
    width:65% !important;
  }
  .blk-friday .plan-crd .plan-1 .card-body .card-content {
    padding: 0px 10px;
  }

  /* .blk-friday .plan-crd .plan-1 .title-plan {
        padding-left: 1rem !important;
    } */

  .blk-friday .plan-crd .plan-1 {
    margin-bottom: 4rem !important;
    padding: 5px !important;
  }

  .blk-friday .plan-crd .plan-1 ul li {
    font-size: 16px;
  }

  .blk-friday .plan-crd .plan-1 ul li span {
    font-size: 16px;
  }

  .blk-friday .plan-crd .plan-1 h2 {
    font-size: 30px;
  }

  .blk-friday .plan-crd .plan-1 button {
    font-size: 16px;
    padding: 5px 1rem !important;
    width: 150px !important;
  }

  .blk-friday .plan-crd .plan-1 .title-plan h4 {
    font-size: 16px;
  }
}

@media (min-width:320px) and (max-width:720px) {
  .fnd-crd .card h2 {
    font-size: 16px !important;
    width: 50%;
}
  .fnd-crd .card .pro-img {
    width: 85px !important;
    height: 85px !important;
    object-fit: cover;
    float: right;
}
.pro-img img {
    height: 83px !important;
    width: 83px !important;
}
  .header .navbar-right {
    flex-direction: column !important;
  }

  .header .nav-item {
    margin: 0px;
  }

  .blk-friday .plan-crd {
    padding: 0px !important;
  }

  .blk-friday .content {
    text-align: center;
    border: solid 3px #FF1F1F;
    padding: 0px;
    margin-top: 80px !important;
  }

  .blk-friday .content h2 {
    font-size: 30px;
  }

  .blk-friday .content h4 {
    font-size: 20px;
  }

  .blk-friday .content p {
    font-size: 18px;
  }
}

/* pricing css */
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
  select.form-control.job_select_tag {
    width: 72%;
}
  .howitwoksbtn {
    font-size: 12px !important;
    display: flex;
    justify-content: center;
  }

  .padding-adjust {
    padding: 10px !important;
  }

  .review .testimonial {
    padding-top: 0px !important;
    margin-bottom: 20px;
  }

  .tooltips:before {
    white-space: normal !important;
  }

  .enterprisee-card .btn-div {
    width: 74% !important;
    margin-left: 0px !important;
  }

  .enterprisee-card {
    width: 80% !important;
  }

  .sub-pricing .price-cards .cards .btn-div {
    width: 90%;
  }

  .cards-banner {
    width: 115%;
    position: absolute;
    top: -25px;
    left: -17px;
  }

  .small-cards {
    width: 100% !important;
    margin: auto;
  }

  .emp-pricing-footer {
    width: 100% !important;
  }

  .emp-pricing-footer .inner-div h2 {
    font-size: 38px;
    line-height: 44px;
  }

  .emp-pricing-footer .inner-div>div p {
    font-size: 20px !important;
    line-height: 28px !important;
  }

  .emp-pricing-footer .inner-div>div h3 {
    font-size: 32px !important;
    margin-top: 0px !important;
  }

  .sub-pricing .heading h1 {
    font-size: 44px;
    line-height: 52px;
    width: 86%;
  }

  .sub-pricing .heading h3 {
    font-size: 28px;
    margin: 6px auto;
    line-height: 36px;
  }

  .sub-pricing .heading .options p {
    font-size: 18px;
  }

  .sub-pricing .heading .options i {
    height: 1.2rem;
    width: 1.2rem;
  }


}

@media only screen and (min-device-width : 850px) and (max-device-width : 1199px) {
  .review .slick-dots {
    bottom: -33px;
    right: 38%;
  }

}

@media (min-width:390px) and (max-width:450px) {
  .review .slick-dots {
    right: 30% !important;
  }

}

@media (min-width:370px) and (max-width:500px) {
.transaction_history_card .nav-pills .nav-link.active, .transaction-history-card .nav-pills .show>.nav-link{
  padding: 8px 14px;
}
.transaction_history_card .nav-pills .nav-link.active, .transaction-history-card .nav-pills .show>.nav-link{
  padding: 8px;
}
}
@media (min-width:320px) and (max-width:380px) {
  .notification-header-num {
    right: 10px;
    bottom: 10px;
  }
  .review .slick-dots {
    bottom: -33px;
    right: 29% !important;
  }
  .employer_header .nav-item{
    margin: 0px;
  }
  .nav{
    width: 100%;
  }
  .cards-banner {
    width: 100% !important;
    position: absolute;
    top: -25px !important;
    left: -4px !important;
    overflow: hidden;
  }
}

@media only screen and (min-device-width : 768px) and (max-device-width : 950px) {
  .tableresponsive {
    display: block !important;
    width: 100% !important;
    overflow-x: auto !important;
    -webkit-overflow-scrolling: touch;
    white-space: nowrap;
}
  .contactus-left-card-input svg {
    right: 17%;
  }

  .review-img {
    border-right: none;
    width: 300px;
    margin-bottom: 25px;
  }

  .cards-banner {
    width: 120%;
    position: absolute;
    top: -25px;
    left: -34px;
  }

  .review-img {
    border-right: none !important;
  }
}

@media (max-width: 750px) {
  .review-img {
    border-right: none !important;
  }

}

.enterprise-faq .faq .faq-title h2 {
  color: #004c70;
  font-size: 2.3rem;
  padding: 2rem 0rem;
  font-weight: 900 !important;
}

.enterprise-faq .faq .content {
  background-color: #d9d9d9;
  padding: 2rem 4rem;
  border-radius: 10px;

}

.enterprise-faq .faq .content h5 {
  border-bottom: 1px solid #c6c6c6;
  padding: 10px;
  font-size: 24px;
}

.enterprise-faq .faq .content h5 span {
  color: black;
  font-size: 14px;
}

.enterprise-accordian {
  background-color: #ebeced;
  padding: 2rem 4rem;
  border-radius: 10px;
}

.enterprise-accordian .card .card-header .btn-header-link {
  color: black !important;

}

.enterprise-accordian .card-body p {
  color: black !important;
  font-size: 15px;
  margin-top: 15px;
}

.enterprise-accordian .card-body ul li span {
  color: black !important;
  font-size: 15px !important;
}

@media (min-width:200px) and (max-width:319px) {
  .Sin-up .job-cards {
    width: 90%;
    font-size: 11px !important;
    padding: 10px;
  }

  .Sin-up .job-cards .job-det {
    padding-right: 0.5rem;
  }

  .Sin-up .job-cards h4 {
    Font-size: 16px;
  }

  .job-cards li {
    font-size: 14px !important;
  }

  .Sin-up .job-cards button {
    font-size: 10px;
    padding: 10px 8px;
  }

  .emp-det img {
    width: 60px;
  }

  .job-cards {
    margin-top: 20px !important;
  }

  .Sin-up .sin-start h2 {
    Font-size: 30px;
  }

  .Sin-up .job-cards .emp-det {
    padding-right: 0.5rem;
  }

  .sin-start {
    width: 100%;
  }

  .sin-start img {
    height: 195px;
  }

  .Sin-up .sin-start p {
    Font-size: 18px !important;
  }

  .Sin-up .sin-start span {
    Font-size: 18px !important;
  }
}

@media (min-width:300px) and (max-width:320px) {
  .nav {
    justify-content: flex-start;
  }

  .mobile_nav a {
    margin-bottom: 10px;
  }

  .employer_dashboard_table {
    margin-top: 66px !important;
  }
}

@media (min-width: 300px) and (min-width:360px){
  .contract_banner button {
    padding: 8px 6px;
}
  .emp-pricing-footer img {
    height: 238px;
}
  .emp-pricing-toggle-btn button {
    font-size: 12px;
    width: 8rem !important;
    padding: 8px 4px !important;
}
  .edit-profile-card-content form label{
    font-size: 13px;
  }
  .sub-pricing .heading .options p{
    font-size: 15px !important;
    text-align: left
  }
}

.work_hours_label label{
  display: none;
}
.new_radio .selected-label input:checked~.selected-content {
  box-shadow: none;
  border: none;
}
.new_radio h1{
  font-size: 16px;
}
.text-dec{
  text-decoration: underline !important; 
}
/* .signed_contract thead tr th{
    width: 20%;
} */
.blue-text{
  color: #0093D1;
}
.vs_protection h2{
  font-size: 46px;
  font-weight: 600;
    line-height: 55px;
    color: #004C70;
    margin-bottom: 20px;
}
.vs_protection .main_para{
  font-size: 20px;
  font-weight: 400;
  line-height: 26px;
  color: #000000;
  margin-bottom: 14px;
}
.new_section p{
  font-size: 20px !important;
  line-height: 32px !important;
  text-align: center;
  /* width: 75%; */
  margin: auto;
}
.step_wrapper{
  width: 85%;
  margin: auto;
  padding: 16px 30px;
  background-color: white;
  position: relative;
  min-height: 294px;
  padding-bottom: 4px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
border-radius: 5px;
}
.step_box{
  background: #105779;
  border-radius: 5px;
  color: white;
  font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    width: fit-content;
    padding: 6px 38px;
}
.step_wrapper .wraper_head{
  font-weight: 600;
font-size: 24px;
line-height: 29px;
color: #004C70;
text-align: center;
}
.vs_protection{
  background-color: #fff;
}
.vs_protection h2{
  font-size: 46px;
  font-weight: 600;
    line-height: 55px;
    color: #004C70;
    margin-bottom: 20px;
}
.vs_protection .main_para{
  font-size: 20px;
  font-weight: 400;
  line-height: 26px;
  color: #000000;
  margin-bottom: 14px;
}
.vs_protection h1{
  font-weight: 700;
font-size: 60px;
line-height: 72px;
text-align: center;
color: #004C70;
}
.pro_section p{
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  
  color: #000000;
}

.protection_btn{
  background: #49B247;
  border-radius: 17px;
  color: white;
  font-weight: 700;
  font-size: 28px;
  line-height: 43px;
  border: 0px;
  padding: 4px 20px;
}
.protection_btn{
  font-size: 22px;
  padding: 0px 20px;
}
.protection_btn img{
  margin-bottom: 5px;
    width: 24px;
}
.protection-circle{
  border-radius:0 50% 50% 0;
  height: 290px;
  width: 290px;
  background-color: #fff;
  display: flex;
    justify-content: center;
    align-items: center;
}
.pen-image{
  width: 420px;
  margin: auto;
}
.desktop{
  display: block;
}
.mobile{
 display: none;
}
.virtual_protection{
  margin-top: 20px;
  margin-bottom: 20px;
  padding-top: 20px;
}
@media (width < 500px) {
  
  .vs_protection{
    margin-top: 30px;
  }
  .vs_protection h1{
    font-size: 30px;
    line-height: 1.5;
  }
  .pen-image{
    width: 300px !important;
  }
  .reverse-row{
    flex-direction: column-reverse;
  }
  .space-responsive{
    margin-bottom: 30px;
  }
  .virtual_protection{
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    padding-top: 10px !important;
  }
  .protection-legal-mobile{
    margin-top: 100px !important;
  }
  .img-enterprise{
    display : none; 
  }
  .desktop{
    display: none;
  }
  .mobile{
   display: block;
  }
  .mobile-center{
    text-align: center;
    display: block;
    margin: auto;
    justify-content: center;
  }
  .salary_guide .guide .sub_heading{
    margin-left: -25px;
  }
  .download_div{
    display :flex !important;
    justify-content: center !important;
  }
  .hide-img{
    display: none;
  }
 

  .protection-legal{
    padding:10px;
    padding-top: 20px;
  }
  .protection-circle{
     border-radius:100%;
     height: 200px;
     width: 200px;
     margin: auto;
     margin-top: -100px;
  }
}

.link-overwrite-new{
  text-decoration:  underline !important;
  font-weight: bold;
}
.link-overwrite{
  text-decoration:  underline !important;
  color:#004C70 !important; 
  font-weight: bold;
}
.modal-90w{
  max-width: 90% !important;
}
.modal-90w .modal-content{
  background: #F9F9F9 !important;
border-radius: 32px !important;
}
.ai-model-grid{
  display: grid;
    grid-template-columns: repeat(3, 1fr);
  text-align: center;
  margin-top: 50px;
    margin-bottom: 30px;
}
.ai-model-card img{
  width: 102px;
  height: 100px;
  border-radius: 50%;

}
.ai-model-card h1{
  font-style: normal;
font-weight: 700;
font-size: 25px;
line-height: 30px;

color: #004C70;
}
.ai-model-card .inner-div{
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 4px 30px;
  align-items: center;
}
.ai-model-card .inner-div h2{
  font-style: normal;
font-weight: 700;
font-size: 20px;
line-height: 24px;

color: #004C70;
}
.ai-model-card .inner-div  button{
   background: inherit;
    border: 1px solid  #004C70;
    border-radius: 10px;
    color: #004C70;
    padding: 0px 10px 0px 10px;
    width: 8rem;
}
.invite-btn{
  border-radius: 30px;
    /* font-size: 16px; */
    /* line-height: 19px; */
    font-family: "Lato-Bold";
    padding: 7px 15px;
   
    border: 1pt solid #004c70;
 
    background: #004c70 !important;
    color: #fff !important;

}
.ai-model .head{
  font-style: normal;
font-weight: 400;
font-size: 26px;
line-height: 24px;
text-align: center;

color: #000000;
}
.ai-model-footer{
  display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

}
.ai-model-footer .but-1{
    background: #00701F;
    border: 1px solid #FFFFFF;
    border-radius: 17px;
    color: white;
    padding: 2px;
    font-size: 14px;
    width: 11rem;
}
.ai-model-footer .but-1 img{
  margin-right: 10px;
}
.ai-model-footer .but-1 p{
  font-size: 10px;
  margin: auto;
}
.ai-model-footer .but-2{
  background: #D9D9D9;
  border: 1px solid #000000;
  border-radius: 16px;
  width: 7rem;
  margin-top: 15px;
}


@media (width < 500px){
  .ai-model h1{
     font-size: 20px;
  }
  .ai-model .head{
    font-size: 15px;
  }
  .ai-model-grid{
   
      grid-template-columns: repeat(1, 1fr);

  }
  .ai-model-card{
    margin-bottom: 10px;
    padding : 10px;
  }
  .ai-model-card .inner-div{
    padding: unset;
  }
}
/* .loader{
  font-size: 48px;
  color: transparent;
  overflow:hidden;
  display: inline-block;
  font-family: Arial, Helvetica, sans-serif;
  text-shadow:0 0 #FFF, 5em 0 #FFF;
  animation:marquee 2s infinite ease;
}
.loader:before {
  content:"Loading...";
}

@keyframes marquee {to{text-shadow:-5em 0 #FFF, 0 0 #FFF}} */
.loader{
  text-align: center;
  margin: 30px;
}
.ai-model-card{
    padding:40px;
}

@media only screen and (min-device-width : 500px) and (max-device-width : 1200px) {
  .ai-model-grid {
   
    grid-template-columns: repeat(2, 1fr);

  }
}
.ai-textbox  li{
      list-style: initial !important;
      padding:initial !important;
      margin: initial !important;
}
.ai-textbox  ul{
  list-style: initial !important;
  padding:initial !important;
  margin: initial !important;
}

.ai-textbox  ol{
  list-style: initial !important;
  padding:initial !important;
  margin: initial !important;
}
.ai-textbox *{
   font-size: 14px !important;
}
.beta-btn{
  font-size: 19px;
    border-radius: 6px;
    padding: 0px 10px;
}
.terms .modal-dialog .modal-content .modal-header{
  background: #004C70;

}
.terms-body{
  height: 60vh;
    overflow: scroll;
    border: 1px solid skyblue;
    padding: 10px;
}
.round-carousel span{
    height: 10px;
    width: 10px;
    border-radius: 50%;
    border: 1px solid black;
    margin: 10px;
}
.terms-sectiontwo h3{
     font-size: 18px;
     font-weight: bold;
}
.terms-sectiontwo p{
    margin-left: 15px;
}
.pointer{
  cursor: pointer;
}
.price-cards{
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding: 25px;
  gap: 20px;
  grid-template-columns: 300px 300px 300px;
    justify-content: center;
}
.darkgreen-bg{
  background-color: #005128 !important;
}
.yellow-text{
  color : #FFD659;
}
.green-color{
  color: #005128;
}
.cards-text-div{
  color: black;
  padding-left: 15px;
  padding-right: 15px;
  text-align: left;
  margin-top: 10px;
  font-size: 18px;
}
.cards-link-div{
  color: #004C70 !important;
  text-decoration: underline !important;
  margin: auto; 
 display: flex;
  margin-left: 16px;
}
.cards-bottom-div{
  background: #D9D9D9;
  color: black;
  text-align: left;
  padding: 10px;
  margin-top: 20px;
}
@media only screen and (min-width : 600px)  and (max-width : 1200px) {
  .price-cards{
    grid-template-columns: repeat(2, 300px);
  }
  
}
@media (width <  500px){
  .price-cards{
    grid-template-columns: repeat(1, 350PX);
  }
}
.new-emp-toggle-btn{
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
}
.new-emp-toggle-btn .btn-one{
  width: 8rem;
  background: white;
  border-radius: 10px;
  border: 1px solid #004C70;
  color: black;
  font-size: 21px;
  font-weight: bold;
  height: 67px;
}
.new-emp-toggle-btn .active{
  border: none;
  background: #008700;
  color: white;
}
.position-fix{
  position : absolute;
}
.margin-height-adjust{
  margin-top: 111px !important;
}
.protection_btn3{
  width: 500px;
}
.protection_btn4 .new-span{
   font-size: 15px;
}
.for-font{
  font-size: 24px;
}
@media (width < 500px){
  .mbl-header{
    flex-direction: column;
  }
  .for-font{
    font-size: 14px;
  }
  .protection_btn4 .new-span{
   line-height: 18px;
   font-size: 13px;
 }
  .protection_btn4{
    width: 300px;
    padding: 10px;
    font-size: 16px;
  }
  .protection_btn3{
    width: initial;
  }
  .protection-list{
    padding: initial;
  }
  .selected-content h4{
    font-size: 13px;
  }
  .margin-height-adjust{
    margin-top: auto !important;
  }
  .position-fix{
    position : initial;
  }
  .new-emp-toggle-btn{

    padding: 10px;
    gap: 10px;
  }
  .cancel-img{
  display: none;
  }
  .direction-fix{
    flex-direction: column;
  }
 
}
.cancel-img{
  width:100%;
}

.protection-list ul{
  list-style: revert;
    font-size: 20px;
    font-weight: 500;
}
.protection-list ul li{
  padding-bottom: 20px;
}
.postjob-tab{
  background: #F5FBFF;
  padding: 30px;
  border-radius: 30px;
}
.link-tab-btn{
  color: #F5D44C;
  text-decoration: underline !important;
}
.new-msg-btn{
    border: none;
    background: #E7F4FA;
    padding: 8px 14px;
    color: #004C70;
    font-weight: bold;
    border-radius: 20px;
    font-size: 14px;
}
.form-control{
  border: 1px solid #004C70 !important;
  color: black;
  font-weight: bold;
}
.common-blue-button{
  background: #004C70;
    color: white;
    border-radius: 16px;
    padding: 1px 12px;
    border: none;
    height: 2rem;
}
.new-back-button{
  background: white;
  height: 56px;
  width: 56px;
  border-radius: 50%;
  font-size: 35px;
}
.how-it-works-toggle{
  border-radius: 20px;
  background: white;
  display: flex;
  width: max-content;
  margin-top: -63px;
  margin-bottom: 40px;
  background: transparent;
 
}
.how-it-works-toggle .parent-div{
     height: max-content;
    width: max-content;
    background: #004C70;
    border-top-left-radius: 18px;
    border-bottom-left-radius: 18px;
   
}
.how-it-works-toggle .div-one{
  background: white;
  border: 1px solid #004C70;
  color: #004C70;
  font-weight: bold;
  letter-spacing: 1px;
  width: 6rem;
  border-radius: 18px;
  padding: 10px;
  text-align: center;
  font-size: 18px;
  height: max-content;
}
.how-it-works-toggle .div-two{
    padding: 10px;
    width: 10rem;
    border: none;
    border-radius: 0px 18px 18px 0px;
    background: #004C70;
    color: white;
    font-weight: bold;
    letter-spacing: 1px;
    text-align: left;
    font-size: 18px;
    width: auto;
}
.how-it-works-container{
  box-shadow: 0px 0px 4px 0px #00000040;
  position: relative;
  padding: 40px;
  border-radius: 25px;
}
.list-unset{
  list-style: disc;
  margin-left: 20px;
}
.how-it-works-container  img{
  width: 90%;
}
.how-it-works-steps-div{
     background: #99D4ED33;
    padding: 20px;
    text-align: center;
    border-radius: 20px;
    height: 100%;
}
.how-it-works-steps-div p{
  text-align: left;
}
.how-it-works-final-div{
  border: 1px solid #000000;
  border-radius: 25px;
  padding: 25px;
}
.how-it-works-final-footer{
  box-shadow: 0px 0px 4px 0px #00000040;
    border-radius: 35px;
    padding: 30px;
}
.how-it-works-final-div  img{
  width: 90%;
}
.how-it-works-final-footer  img{
  width: 90%;
}
.margin-top-adjust{
  margin-top:20px;
}
@media (width <  500px){
  .common-blue-button{
    height: inherit;
  }
  .custom_card_container{
    
    grid-template-columns: repeat(1,1fr); /* Adjust as needed */
  
  }
  .how-it-works{
    margin-top: 50px;
  }
  .how-it-works-toggle{
    width: initial;
  }
  .how-it-works-toggle .div-two{
    max-width: 90%;
  }
  .row-gap{
    gap : 20px;
  }
  .margin-top-adjust{
    margin-top: 25px;
  }
  .how-it-works-steps-div p{
    text-align: center;
  }
  .mobile-direction-fix{
    flex-direction: column-reverse;
  }
  .how-it-works-toggle .parent-div{
    display: flex;
    align-items: center;
    height: initial;
  }
  .how-it-works-toggle .div-one{
    height: 3rem;
  }
  .how-it-works-toggle .div-two{
    font-size: 15px;
    /* line-height: 19px; */
  }
}

.min-width-130{
  min-width: 130px !important;
}
.jobseeker-custom-card{
    margin: 0 10px;
   
    background: #fff;
    box-shadow: 0px 0px 20px 0px #00000040;

    border-radius: 14px;
    padding: 20px 18px;
}
.jobseeker-custom-card .description-section{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 80px;
}
.jobseeker-custom-card .description-section{
  font-size: 15px;
}
.btn-outline{
    border: 1px solid #004C70 !important;
    font-size: 15px !important;
    color: #004C70 !important;
}
.new-currency-input{
  border: 1px solid #004C70;
  display: flex;
  padding: 10px;
  border-radius:5px;
}
.custom-blue-button{
  background: #004C70;
  color: white;

}
.custom_card_container{
  display: grid;
  grid-template-columns: repeat(3,1fr); /* Adjust as needed */
  grid-gap: 10px; /* Add spacing between cards */
}
.protection-options{
  display: flex;
  justify-content: space-evenly;
}
.protection-options div{
   display: flex;
   align-items: center;
}
.protection-options div i{
  height: 1.5rem;
    width: 1.5rem;
    background: rgb(26, 191, 26);
    border-radius: 50%;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 8px;
}
 .slanted-word {
    /* Adjust font size as needed */
    position: relative;
    display: inline-block;
  }

  .slanted-word::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 4px; /* Adjust the thickness of the line */
    background: red; /* Adjust the color of the line */
    transform: rotate(-45deg); /* Adjust the angle of the line */
  }
  .vs_protection .header h1{
    font-size: 40px;
    line-height: 1.3;
  }
  .vs_protection .protection-header p{
    font-size: 25px;
    line-height: 1.3;
  }
.protection-options p{
   margin: auto ;
}
.virtual_protection h2{
  font-size: 30;
  margin: auto;
  line-height: 1.5;
}
@media (width < 500px){
  .row-reverse{
    display: flex;
    flex-direction: column-reverse;
  }
  .display-none{
    display: none;
  }
  .protection-options{
    flex-direction: column;
    gap: 20px;
    padding: 7px;
  }
  .virtual_protection p{
    margin-bottom: 0px;
    margin: 10px;
  }
  .virtual_protection h2{
    font-size: 24px !important;
    
  }
  .virtual_protection p{
    font-size: 18px;
    line-height:26px;
  }
  .margin-only-p{
    margin-top: 20px !important;

  }
}
.virtual_protection h2 {
  font-weight: 700;
  font-size: 30px;
  /* line-height: 55px; */
  color: #004C70;
  margin-bottom: 20px;
}
.a-tag-fix{
  text-decoration: underline !important;
  font-size: 14px; 
}
.view-applicants-page .small_label{
  font-weight: 700;
  font-size: 25px;
  line-height: 42px;
  color: #004C70;
  min-width: 100px;
  text-align: right;
}
.virtual_protection .vs_steps .vs_steps_section .number_section{
  border-radius: 50%;
  background: #004C70;
  height: 60px;
  color: white;
  width: 60px;
  display: flex;
  align-items: center;
  /* text-align: center; */
  justify-content: center;
  z-index: 999;
}
.virtual_protection .vs_steps .vs_steps_section .content__section h2{
  font-size: 20px;
  margin: auto;
}
.virtual_protection .vs_steps .vs_steps_section .content__section {
  width: 200px;
}
.virtual_protection .vs_steps .vs_steps_section .content__section p{
  margin:auto;
  font-size: 18px;
}
.virtual_protection .protection_info{
  border: 3px solid #004C70;
    padding: 20px;
}
.virtual_protection .protection_info h3{
  font-size: 21px;
  color: #004C70;
}
.virtual_protection .protection_info p{
  font-size: 18px;
}
.virtual_protection .points_padding_fix{
  padding : 0 10%;
}
.enterprise-card-section {
  display: grid;
  grid-template-columns: 330px 400px 330px;
  gap: 20px;
}
.enterprise-card-section .card {
  border: 1px solid #0E3944;
  border-radius: 10px;
  text-align: center;
  padding: 20px;
}
.enterprise-card-section .card .card-info .card-name {
  font-weight: bold;
}
.enterprise-card-section .card .card-info .card-description {
  margin-top: 15px;
}
.virtual_protection .horizontal_line{
  width: 77%;
background: #004C70;
   
transform: translate(0px, 46px);
}
.new_margin_adjust{
  margin-top: 3rem;
}
@media (width  <  500px){
  .new_margin_adjust{
    margin-top: 0rem;
  }
  .virtual_protection .horizontal_line{
    display: none;
  }
  .img-responsive-adjust {
    width: 100%;
}
  .enterprise-card-section {
    grid-template-columns: 1fr;
}
  .vs_steps{
    flex-direction: column;
    gap: 20px;
}
.virtual_protection .vs_steps .vs_steps_section .content__section{
  width:inherit;
}
.virtual_protection .points_padding_fix{
  padding: 0 4%;
}
.vs_protection .protection-header p{
  font-size: 18px;
  margin: 0 35px;
    line-height: normal;
}
.virtual_protection .row div:first-child{
  margin-bottom: 0px !important;
}
.gap__adjust{
  gap:20px;
}
}
@media (width < 380px){
  .vs_protection .protection-header p{
    font-size: 18px;
    margin: 0 15px;
      line-height: normal;
  }
}