body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
html {
    scroll-behavior: smooth;
}

.bg-light-gray{
    background-color: rgb(51 51 51 / 14%);
}
.disabled-tab{
    background-color:#356785 !important;
}
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.blur-disabled{
    opacity: 0.5;
    background-color:#eee;
}
.flex{
    display: flex;
}
.iconButton{
    color: #0093d1;
    font-size: 18px;
    padding:8px;
    border-radius:50px;
    height:40px;
    width:40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.text-16{
    font-size: 16px !important;
}
.text-gray{
    color:#ccc !important;
}
.font-h1{
    font-size:30px !important;
}
.min-400{
    min-height:400px;
}
.min-350{
    min-height:350px;
}
.bg-theme{
    background-color:#0093d1;
}
.bg-theme-hover:hover{
    color:#0093d1 !important;
}
.button-theme{
    background-color: #004c70;
}
.white-bg{
    background-color: white;
}
.fit-cover{
    width:100%;
    height:100% !important;
    object-fit: cover !important;
}
.reduce-pad a {
    padding-left:20px !important;
    padding-right: 10px !important;
}
.iconButton:hover{
    background-color:#eee;
    cursor:pointer;
}
.bj-content-body p > span,.bj-content-body li > span,.bj-content-body a > span {
    font-size: 16px !important;
    /* line-height:2; */
}
.editor-wrapper{
    border: 1px solid #aaa;
    border-radius:10px;
}
.editor-toolbar,.rdw-option-wrapper{
    background-color:#efefef !important;
}
.editor-toolbar{
    border-radius:10px 10px 0px 0px !important;
}
.editor-textbox{
    padding:10px 40px;
    min-height:300px;
}
.font-fixer{
    font-size:16px !important;
}
.full-width{
    width: 100% !important;
    display: block;
}
.hover-li{
    transition: all 0.1s ease-in;
}
.pad-top{
    padding:10px 0px;
}
.centering{
    margin: auto;
    margin-left:30%;
}
.hover-li:hover{
    background-color:#eee;
    cursor:pointer;
    border-radius:30px;
    padding-left: 10px;
}
.hover-li-active{
    background-color:#eee;
    cursor:pointer;
    border-radius:30px;
    padding-left: 10px; 
}
.pointer{
    cursor:pointer;
}
.sizer{
    padding:10px 15px;
    border-bottom:0.5px solid #ccc;
}
.loading-button-progress{
    margin-top: 5px;
}
.loading-button{
    position: relative;
    width: 18%;
    display: block;
    margin:auto;
    text-align: center;
}
.helper-text{
    color: red;
}
.input-group-a{
    top: 10% !important;
}
.App{
    display: flex;
    align-items: center;
    justify-content: center;
}

.centerElem {
    position: fixed;
    width:100%;
    height:100%;
    top:0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.visibleBox {
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: space-around;
}
.visibleBox img{
    margin-bottom: 20px;
}
.centerElemOverlay {
    position: fixed;
    width:100%;
    height:100%;
    top:0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color:#fff;
    opacity:0.93;
}

.hover-gray:hover{
    background-color:#eee;
}

.mobbutn{
    display: none !important;
}

.mobcoll.collapse:not(.show) {
    display: block !important;
}

.invoice-module .invoice-module-menus{
    width: 100% !important;
}

.job-description p {
   
    height: auto !important;
  
}

.hired-staff-table-data > tbody > tr > td.marfintable{
    padding: 0px 8px;
}

.width50{
    width: 50% !important;
}

.maxwidth{
   max-width: 800px !important;
}
.toastr-box, .toastr-box p{
    margin-bottom: 0;
    white-space:wrap;
    word-break: break-word;
}

@media print {
    body {
      visibility: hidden;
    }
    .contract_box{
        height: auto;
    }
    .print-area {
      visibility: visible;
      position: absolute;
      left: 0;
      top: 0;
      height: auto;
      overflow: visible;
    }
    .print-box{
        visibility: visible;
    }
    .contract_box{
        width: 90%;
    }
  }