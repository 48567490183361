.chat-sidebar {
    flex: 3.5;
    display: flex;
    max-width: 350px;
}

.chat-content {
    flex: 8;
    display: flex;
}

.chat-profile {
    flex: 3;
    display: flex;
}

.chat-container {
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    position:absolute;
    width:100%;
    height:100%;
    top:0;
    left:0;
    overflow:hidden;
}
.chat-center{
    display:block;
    position: sticky;
    margin:auto;
    top: 20px;
    background-color: #eee;
    padding: 5px 20px;
    border-radius: 25px;
    font-size: 14px;
    margin-top: 20px;
}

.chat-text-box{
    padding: 12px 10px;
    background-color:#fff;
    word-break: break-all;
    min-height:40px;
    max-height: 150px;
    margin-top:3px;
    font-size:16px;
    color:#1d2129;
    border-radius:2rem;
    padding-left: 30px;
    overflow:auto;
}
[placeholder]:empty::before {
    content: attr(placeholder);
    color: #ccc;
}

.new-message p{
    font-weight: bold !important;
}
.chat-text-box:focus {
    box-shadow: none;
}
.chat-list{
    height:calc(100% - 120px);
    overflow: auto;
}
.msg-count-badge{
    color: #fff;
    height: 25px;
    min-width: 25px;
    font-size: 10px;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: -10px;
    background-color:#dc3545;
}

.rightcard-basic-details-row-fixed h6{
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #0093d1;
    font-weight:bold;
    margin-bottom: 0;
}
.rightcard-basic-details-row-fixed p{
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.1em;
    text-transform: capitalize;
    color: #232266;
    margin-bottom: 2rem;
}



.chat-header {
    position: relative;
    border-bottom: 1px solid #C4C4C4;
    padding-left: 15px;
}

.chat-header a {
    top: 17px;
    left: 42px;
    cursor: pointer;
}

.chat-header .messagecard1-leftrowdetails{
    border-bottom:none;
    flex-direction:row;
    justify-content: space-between;
}
.chat-header .messagecard1-leftrowdetails-text h4{
    font-weight: bold;
}
.chat-header .messagecard1-leftrowdetails-text p{
    color: #232266;
}

/*video call*/
.video-content{
    flex:9;
    display: flex;
    background-color: #0c5460;
}
.video-chat-content{
    flex: 3;
    display: flex;
}

#jitsi-iframe{
    position: static;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
}
#jitsi-iframe iframe{
    height: 100% !important;
}
.call-container{
    top:0;
    left:0;
    width:100%;
    z-index: 1000;
    box-shadow: -7px 0rem 6rem 20px rgb(0 0 0 / 8%) !important;
    border-radius:10px 10px 0px 0px;
}
.prevent-after::after{
    content:"";
    display:none;
}
.share-video{
    position: absolute;
    width: 114px;
    height: 55px;
    background: #333;
    margin: 10px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.chat-link{
    text-decoration: underline !important;
    word-break: break-word;
}
.color-white{
    color: white !important;
}
.share-button:hover{
    background-color: #555 !important;
}

::-webkit-scrollbar {
    width: 2px;
    height: 12px;
}
::-webkit-scrollbar-thumb {
    background: #566871;
    border-radius: 14px;
}
::-webkit-scrollbar-track {
    background: 0 0;
}


.chat-tabs{
    border-bottom: 1px solid #ccc;
}


.chat-tabs .tab:hover{
  background-color: #efefef;
}

.chat-tabs .tab.active{
    background-color: #cfcfcf;
}

.blocked .title{
    color:#777777 !important;
}
.mute-bell{
    left:10px;
    margin-top:27px;
    position: absolute;
    border-radius:100%;
    padding:7px;
    background-color: #ffc107;
    color: #fff;
    font-size: 11px;
}
.msg-attach-view{
    position: absolute;
    right: 0;
    bottom: 70px;
    height:300px;
    background: #fff;
    width: 350px;
    box-shadow: 1px -1px 20px 0px #ccc;
    border: none;

}
.msg-attach-view img{
    object-fit: contain;
    position: absolute;
}
.msg-attach-view .image{
    width: 100%;
    height: 100%;
}
.msg-attach-view .other{
    left: 35%;
    top: 25%;
}
.msg-attach-view .text{
    width: 100%;
    left: 0%;
    top:60%;
    text-align: center;
}
.rounded-circle{
    border-radius:50%;
    overflow:hidden;
}
.chat-send-button{
    background-color: #fff;
}
.top-50{
    top: 50px;
}
.chat-alert-box{
    height:50px;
    background:#efefef;
    text-align:center;
    display: flex;
    justify-content:center;
    align-items:center;
    padding-left: 50px;
    flex-grow: 1;
    position: fixed;
    width: 100%;
    z-index: 100;
}
.center-loading-chat-list {
    display: flex;
    justify-content: center;
    padding: 19px;
    position: absolute;
    left: 100px;
    bottom: 5px;
}
.center-loading-chat-list .chat-list-load{
    background-color: #fff;
    padding:10px;
    border-radius: 50%;
}