* {
    box-sizing: border-box;
}

html.jobseeker {
    font-size: 62.5%;
}

body {
    /* colors */
    --alert-color: #e74c3c;
    --light-color: #6c757d;
    --theme-darkblue: #004c70;
    --theme-blue: #0093d1;
    --white-color: #fff;
    --btn-primary-hover: #145e82;
    --theme-success: #00701F;
    /* sizes */
    --general-size: 1.6rem;
    --placeholder-size: 1.4rem;
}

.text-black {
    color: black !important
}

.dashboard-parent {
    width: 100%;
    background: #f1f3fa;
}


.dashboard-parent h1,
.dashboard-parent h2,
.dashboard-parent h3,
.dashboard-parent h4,
.dashboard-parent h5,
.dashboard-parent h6,
.landing-page h1,
.landing-page h2,
.landing-page h3,
.landing-page h4,
.landing-page h5,
.landing-page h6 {
    color: var(--theme-darkblue);
    display: inline-block;
    padding-bottom: 0.5rem !important;
    margin-bottom: 2rem;
    position: relative;
}

.dashboard-parent h2::after,
.landing-page h1::after {
    content: '';
    position: absolute;
    // width: 4rem;
    height: 0.4rem;
    background: var(--theme-darkblue);
    bottom: -0.8rem;
    left: 0;
}

.landing-page h1::after {}

.dashboard-parent .custom-btn-style,
.landing-page .custom-btn-style {
    font-size: var(--general-size);
    color: var(--white-color) !important;
    padding: 0.9rem 3.6rem;
    margin: 2rem 0 1rem;
    width: 20rem;
    border-radius: 2.5rem;
}

.landing-page .custom-btn-style {
    width: auto;
    padding: 0.4rem 2.6rem;
    margin: 0;
}

.dashboard-parent .btn-outline-primary,
.landing-page .btn-outline-primary {
    border-color: var(--theme-darkblue);
    background: var(--theme-darkblue);
}

.dashboard-parent .btn-outline-primary:hover,
.landing-page .btn-outline-primary:hover {
    border-color: var(--theme-darkblue);
    color: var(--white-color) !important;
    box-shadow: 0 2px 6px 0 var(--btn-primary-hover);
    background: var(--btn-primary-hover);
}

.dashboard-parent .btn-danger:hover {
    color: var(--white-color) !important;
    box-shadow: 0 2px 6px 0 #c82333;
}

// .dashboard-parent a {
//     color: white !important;
// }
.dashboard-parent a #select{
    color: white;
}

.dashboard-parent {
    /* width: 100%;
    float: left; */
    display: flex;
}

.nav-notifications a {
    cursor: pointer;
}

.nav-notifications {
    position: relative;
}

.nav-notifications .show {
    display: block !important;
}

.nav-notification-options {
    position: absolute;
    // display: none;
    top: 5rem;
    width: 35rem;
    left: -31rem;
    height: 50vh;
    overflow: scroll;
    background: white;
    padding: 1.5rem;
    z-index: 4;
    border: 1px solid gainsboro;
    background: white;

}

.find-job-para {
    color: white;
    font-size: 18px;
}

.nav-notification-options .dropdown-options:hover {
    background: aliceblue;
}

.dropdownoptions {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.dropdownoptions small.txt-sm {
    font-size: 14px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
    line-height: 20px;
}

.dropdownoptions:hover {
    background: aliceblue;
}

.nav-notification-options .dropdown-options {
    padding: 0.55rem 0;
}

.nav-notification-options .dropdownoptions p {
    font-size: var(--general-size);
    color: var(--theme-darkblue);
}

.nav-notification-options .dropdownoptions small {
    font-size: var(--placeholder-size);
    color: var(--theme-darkblue);
}

.side-nav {
    width: 20%;
    float: left;
    background: #004C70;
    min-height: 100vh;
}

.dashboard-body-sec {
    width: 80%;
    min-height: 100vh;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.dashboard-body-offer {
    width: 100%;
    min-height: 100vh;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.side-nav .nav-item .nav-link {
    height: 3rem;
    margin: 0.6rem auto;
    font-size: var(--placeholder-size);
}

.side-nav .nav-item .nav-link i,
.side-nav .nav-item .nav-link svg {
    font-size: 0.6rem;
}

.side-nav ul a i,
.side-nav ul a svg {
    width: 2rem !important;
    font-size: 1.8rem;
    color: white;
}

.side-nav ul a span {
    color: white;
}

.side-nav ul a .fa-chevron-right {
    font-size: 1.5rem;
    transition: 0.2s ease all;
}

.side-nav ul .rotate .fa-chevron-right {
    transform: rotate(90deg);
}

.side-nav ul a {
    display: flex;
    align-items: center;
    color: #004c70;
    /* margin : 2.5rem auto; */
    padding: 1.5rem 0rem 1.5rem 3.5rem;
    font-size: 16px;
    font-weight: bold;
    position: relative;
}

.side-nav .fadeIn a {
    padding: 0.4rem 0.5rem 0.4rem 1.2rem !important;
}

.side-nav ul a p {
    margin-bottom: 0;
    margin-left: 2.5rem;
    font-size: 1.6rem;
    margin: auto;
}

.side-nav .active a,
.side-nav .active p {
    color: #fff;
}

/********* UPWORK LIKE CSS STARTS *********/

/* COMMON CSS FOR UPWORK LIKE DESIGN */
.body-sec {
    width: 70%;
    margin: 0 auto;
    min-height: 100vh;
    margin-top: 4rem;
    margin-bottom: 4rem;
}

.nav-section {
    width: 25%;
}

.content-section {
    width: 75%;
    padding-right: 3rem;
    padding-left: 3rem;
}

.content-section .btn-outline-primary {
    border-color: var(--theme-darkblue);
    background: none;
}

.content-section .custom-btn-style {
    font-size: var(--general-size);
    padding: 0.6rem 3.6rem;
    margin: 2rem 0 1rem;
    width: unset;
    border-radius: 2.5rem;
    color: var(--theme-darkblue) !important;
}

.content-section .next-btn {
    background: var(--theme-darkblue);
    color: #fff !important;
}

.content-section h3 {
    margin-bottom: unset;
}

/* NAV SECTION */
.nav-section ul .active {
    background: #fff;
    border-left: 4px solid var(--theme-blue);
    box-shadow: 0 0.1rem 0.4rem 0 rgb(50 50 50 / 50%);
}

.nav-section ul .active p,
.nav-section ul .active .nav-itm i,
.nav-section ul .active .nav-itm svg,
.nav-section ul .completed .nav-badge i,
.nav-section ul .completed .nav-badge svg {
    color: var(--theme-blue);
    font-weight: bold;
}

.nav-section ul a {
    padding: 1rem 2rem;
}

.nav-section ul i,
.nav-section ul svg {
    font-size: var(--placeholder-size);
    color: var(--light-color);
    width: 2rem !important;
}

.nav-section ul p {
    font-size: var(--general-size);
    color: var(--light-color);
    padding-left: 1rem;
}

/* BODY SECTION  */

/* INTRODUCTION PAGE */
.introduction .introduction-card-body {
    padding: 0 !important;
}

.intro-banner {
    background-image: url('../images/jobseeker/simplebg.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    height: 26rem;
}

.introduction .intro-content-body {
    padding: 3rem 3.25rem;
}

.introduction .intro-footer {
    font-size: var(--general-size);
}

/* NEW CATEGORY PAGE */
.content-section p,
.content-section label {
    font-size: var(--general-size);
    color: var(--light-color);
}

.content-section small {
    font-size: var(--placeholder-size);
}

.content-section label {
    font-weight: bold;
}

.content-section .card {
    border-radius: 0.1rem;
    box-shadow: 0 0.1rem 0.4rem 0 rgb(50 50 50 / 50%);
}

.content-section .card-header,
.content-section .card .card-body {
    padding: 3rem 3.25rem;
}

.content-section .card .card-body h2 {
    margin-bottom: 3rem;
}

.content-section .card .card-footer {
    padding: 2rem 3.25rem;
}

.content-section input,
.content-section select {
    height: 3.8rem;
    font-size: var(--general-size);
    color: var(--light-color);
}

.content-section textarea {
    font-size: var(--general-size);
    color: var(--light-color);
}

/* NEW SKILLS PAGE */
.skills-box p {
    font-size: var(--placeholder-size);
    background: #eee;
    padding: 0.3rem 1rem;
    border-radius: 2.5rem;
    margin: 0.4rem;
    display: inline-block;
}

.skills-box a {
    font-size: var(--placeholder-size);
    background: #eee;
    padding: 0.3rem 1rem;
    border-radius: 2.5rem;
    margin: 0.4rem;
    display: inline-block;
}

.skills-box i,
.skills-box svg {
    font-size: 1.2rem;
}

#select {
    background-color: #145e82;
    color: white;
}

.skills-box .selected {
    background: var(--theme-darkblue);
    color: var(--white-color);
}

/* NEW SKILLS lEVEL PAGE */
.skills-level-box {
    // height: 16rem;
    position: relative;
    border-radius: 0.5rem;
}

.card-header-input {
    display: flex;
    align-items: center;
    width: 59%;
    justify-content: space-between;
    // margin: auto;
}

.card-bodyContent {
    height: 26.5vh;
}

.card-header-input h3 {
    margin: 0 !important;
    padding: 0 !important;
}

.skills-level-box p {
    text-align: center;
    font-size: var(--placeholder-size);
}

.skills-level-box .form-check input[type=radio] {
    width: 1.5rem;
    /* height: 4rem; */
}

.skills-level-box .form-check {
    position: absolute;
    top: -0.4rem;
    right: 1.7rem;
}

.skills-level-box .form-check input:hover {
    cursor: pointer;
}

/* EDUCATION PAGE */
.education .card-body,
.employment .card-body {
    min-height: 40rem;
}

.education .modal-body .form-group,
.employment .modal-body .form-group {
    margin-bottom: 2.3rem;
}

.education .modal-header .close,
.employment .modal-header .close {
    font-size: 2.75rem;
    line-height: unset !important;
}

.education .modal-footer a,
.employment .modal-footer a {
    margin: 0 0.8rem;
}

.education .modal-footer .cancel-btn,
.employment .modal-footer .cancel-btn {
    font-size: var(--general-size);
}

.education .modal-footer .cancel-btn:hover,
.employment .modal-footer .cancel-btn:hover {
    cursor: pointer;
}

.education-info-content-update a,
.employment-info-content-update a {
    border: 1px solid var(--theme-darkblue);
    border-radius: 50%;
    width: 3rem;
    height: 3rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 0 0.4rem;
}

.education-info-content h3,
.employment-info-content h3 {
    margin-bottom: 0;
    padding-bottom: 0 !important;
}

/* LANGUAGES PAGE */
.languages ul,
.resume ul,
.getstarted ul {
    list-style: unset;
    margin-left: 1.5rem;
}

.languages ol {
    list-style: number;
    margin-left: 1.5rem;
}

.languages ul li,
.languages ol li,
.resume ul li,
.profile-photo ul li,
.getstarted ul li {
    color: var(--light-color);
    font-size: var(--general-size);
}

.modal fade modal-md show {
    display: block;
}

.dropdown-options-box {
    background: #fff;
    box-shadow: 0 0.1rem 0.4rem 0 rgb(50 50 50 / 50%);
    padding: 1rem;
    position: absolute;
    z-index: 1;
    width: 92%;
    font-size: 16px;

}

.languages .show {
    display: block !important;
}

.dropdown-options:hover {
    cursor: pointer;
}

/* RATE PAGE */
.rate-box {
    flex: 1;
    padding: 4rem 0;
    border-color: #ccc;
    align-items: center;
}

.rate-definition {
    flex: .7;
}

.rate-price {
    flex: .3;
}

.rate-definition h3 {
    padding-bottom: 0 !important;
}

.rate-definition p {
    font-size: var(--placeholder-size);
}

.rate .rate-price .input-group {
    width: 14rem;
    float: right;
}

.rate .rate-price input {
    text-align: right;
    border-left: none;
}

.rate .rate-price i,
.rate .rate-price svg {
    color: var(--theme-blue);
    font-size: var(--general-size);
}

.rate .rate-price .input-group-append span {
    font-size: var(--general-size);
}

/* RESUME PAGE */
.resume .upload-btn {
    font-size: var(--general-size);
    padding: 0.4rem 2.6rem;
    color: #fff;
    background: #004c70;
}

/* PROFILE PHOTO PAGE */
.profile-photo ul {
    list-style: unset;
    margin-left: 2.5rem;
}

.profile-photo-img .drop-zone {
    width: 200px;
    height: 200px;
    padding: 0.3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-family: "Quicksand", sans-serif;
    font-weight: 500;
    font-size: 20px;
    cursor: pointer;
    color: #cccccc;
    border: 0.2rem dashed var(--theme-darkblue);
    border-radius: 1rem;
    margin-bottom: 20px;
}

.profile-photo-img .drop-zone--over {
    border-style: solid;
}

.profile-photo-img .drop-zone__input {
    display: none;
}

.profile-photo-img .drop-zone__thumb {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    overflow: hidden;
    background-color: #cccccc;
    background-size: cover;
    position: relative;
}

.profile-photo-img .drop-zone__thumb::after {
    content: attr(data-label);
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 5px 0;
    color: #ffffff;
    background: rgba(0, 0, 0, 0.75);
    font-size: 14px;
    text-align: center;
}

.profile-photo .card-body {
    position: relative;
}

.profile-photo .skip-btn {
    position: absolute;
    bottom: 0.8rem;
}

.profile-photo .skip-btn a {
    font-weight: bold;
    font-size: var(--placeholder-size);
}

/* LOCATION PAGE */

.location .location-heading h3 {
    padding-bottom: 0 !important;
}

/* BOOK MARKED PAGE CSS STARTS */
.bookmarked-job .banner {
    background: url('../images/homepage/banner.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 35rem;
    padding: 5rem 0rem;
    justify-content: center;
}

.bookmarked-job .banner h1 {
    color: var(--white-color);
    font-size: 5rem;
    font-weight: bold;
    letter-spacing: 0.3rem;
    margin-bottom: unset;
}

.bookmarked-job .bj-banner-content p {
    font-size: var(--general-size);
    color: rgb(255 255 255 / 75%);
    padding: 1.5rem 0;
    width: 50%;
}

.bookmarked-job .bj-banner-content #bookmark-option {
    color: var(--white-color);
}

.bookmarked-job .bj-banner-content #bookmark-option:hover {
    cursor: pointer;
}

.bookmarked-job .search-bar {
    background: rgba(0, 0, 0, 0.3);
    padding: 1.5rem;
    display: inline-block;
    color: #fff;
    font-size: var(--placeholder-size);
}

.bookmarked-job .banner h1::after {
    content: unset;
}

.bookmarked-job-body {
    padding-top: 5rem;
    padding-bottom: 5rem;
}

.bj-content-header {
    flex: 1;
}

.bj-content-header-image {
    flex: .2;
}

.bj-content-header-image img {
    height: 11rem;
    width: 100%;
}

.bj-content-header-content {
    flex: .8;
}

.bj-content-header-content h3 {
    padding-bottom: 0 !important;
    color: var(--light-color);
}

.bj-content-header-content p,
.bj-content-body p {
    color: var(--light-color);
    font-size: var(--general-size);
    padding: 0.8rem 0;
}

.bj-content-body {
    padding: 4rem 0;
}

.bj-content-body ul {
    margin-left: 19px;
    list-style: unset;
}

.bj-content-body ul li {
    color: var(--light-color);
    font-size: var(--general-size);
    padding-bottom: 0.9rem;
}

.bj-content-right-section p {
    font-size: var(--general-size);
    padding: 1rem 2rem !important;
    display: inline-block;
    color: var(--theme-darkblue);
    border-radius: 0.5rem;
    border: 0.1rem solid var(--theme-darkblue);
    margin-right: 1.5rem;
}

.bj-content-right-section .social-media-icons a {
    color: var(--theme-darkblue);
    font-size: var(--general-size);
    padding: 1rem 2rem;
    border: 1px solid var(--theme-darkblue);
    border-radius: 0.5rem;
    margin-right: 2rem;
    display: inline-block;
}

/* Balance Details page */
.balance-info .balance-detail-box {
    height: 14rem;
    position: relative;
    border-radius: 0.5rem;
}

.balance-info .balance-detail-box h3 {
    font-weight: bold;
    margin-bottom: 1rem;
}

.balance-info .balance-detail-box p {
    text-align: center;
    font-size: var(--general-size);
    font-weight: bold;
    color: var(--light-color);
}

.balance-info .balance-detail-box small {
    font-size: 1rem;
    color: var(--light-color);
}

.table thead th {
    font-weight: bold;

}

.savedbtn {
    font-size: 15px;
    border-radius: 16px;
    border: none;
    width: 9rem;
    height: 5rem;
    background: #c7c721;
    color: black;
}

.successbtn {
    margin-right: 10px;
    font-size: 15px;
    border-radius: 16px;
    border: none;
    width: 9rem;
    height: 5rem;
    background: green;
    color: white;
}

.table thead th,
.table tbody td {
    font-size: var(--placeholder-size);
    padding: 1.5rem 0.75rem;
    color: var(--light-color);
    text-align: center;
}

/********* UPWORK LIKE CSS ENDS *********/

.interview-checkbox {
    width: 4rem;
    display: flex;
    justify-content: space-between;
    font-size: 10px;
    align-items: center;
}

.interview-checkbox>label {
    font-size: 14px;
}

.white-bg {
    background: #fff;
    box-shadow: 0 0 3.5rem 0 rgb(154 161 171 / 15%);
    border-radius: 0.5rem;
    padding: 1.5rem 3.5rem;
    // height: 52%;
}

.db-widgets-parent-row {
    height: 100%;
}

.db-widgets {
    height: 50%;
}

.db-widgets-box {
    padding: 1.5rem 2.5rem;
}

.db-widgets-box-icon i,
.db-widgets-box-icon svg {
    color: var(--theme-darkblue);
}

.db-widgets-applicant-status-inactive {
    color: var(--light-color);
    opacity: 0.3;
}

.db-widgets-time-log h3 {
    line-height: 2.6rem;
}

#animations-example-4 {
    height: 30rem;
    width: 100%;
    margin: 0 auto;
}

#animations-example-4 tbody td {
    transform-origin: bottom;
    animation: revealing-bars 4s linear;
}

@keyframes revealing-bars {
    0% {
        transform: scaleY(0);
    }

    15% {
        transform: scaleY(1);
    }
}

.charts-css {
    --color-1: rgba(0, 76, 112, 0.75) !important;
    --color-2: rgba(0, 76, 112, 0.75) !important;
    --color-3: rgba(0, 76, 112, 0.75) !important;
    --color-4: rgba(0, 76, 112, 0.75) !important;
    --color-5: rgba(0, 76, 112, 0.75) !important;
}

.dashboard-parent .dashboard-body-sec .btn-outline-primary {
    background: none;
}

.dashboard-parent .dashboard-body-sec .custom-btn-style {
    width: unset;
    padding: 0.6rem 3.6rem;
    color: var(--theme-darkblue) !important;
}

.complete-profile-points-group {
    display: flex;
    align-items: flex-start;
}

.complete-profile-points-group:hover {
    background: #f1f3fa;
    cursor: pointer;
}

.complete-profile-points-group i,
.complete-profile-points-group svg {
    margin-top: 0.5rem;
    font-size: 1.6rem;
    color: var(--alert-color);
    opacity: 0.8;
}

.complete-profile-points-group .profile-points {
    margin-left: 1.5rem;
    margin-bottom: 0.8rem;
    border-bottom: 1px solid #eee;
    width: 100%;
    padding-bottom: 0.4rem;
}

.complete-profile-points-group .profile-points p {
    font-size: 1.6rem;
    margin-bottom: 0;
    color: var(--alert-color);
    opacity: 0.8;
}

.complete-profile-points-group .profile-points a {
    font-size: 1.4rem;
    opacity: 0.8;
}

/* NOTIFICATION SETTINGS PAGE CSS STARTS */
.lable-tag {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.lable-tag h5 {
    font-weight: bold;
}

.notification-settings .lable-tag h3 {
    padding-bottom: 0 !important;
    font-weight: bold;
}

.notification-settings .switch label {
    margin-bottom: 0;
}

/* SWITCH BUTTON CSS */
.switcher:hover {
    cursor: pointer;
}

.switcher label {
    margin-bottom: 0;
}

div.checkbox.switcher label,
div.radio.switcher label {
    padding: 0;
}

div.checkbox.switcher label *,
div.radio.switcher label * {
    vertical-align: middle;
}

div.checkbox.switcher label input,
div.radio.switcher label input {
    display: none;
}

div.checkbox.switcher label input+span,
div.radio.switcher label input+span {
    position: relative;
    display: inline-block;
    /* margin-right: 10px; */
    width: 56px;
    height: 26px;
    background: #f2f2f2;
    border: 1px solid #eee;
    border-radius: 50px;
    transition: all 0.3s ease-in-out;
}

div.checkbox.switcher label input+span small,
div.radio.switcher label input+span small {
    position: absolute;
    display: block;
    width: 50%;
    height: 100%;
    background: #fff;
    border-radius: 50%;
    transition: all 0.3s ease-in-out;
    left: 0;
}

div.checkbox.switcher label input:checked+span,
div.radio.switcher label input:checked+span {
    /* background: #269bff;
    border-color: #269bff; */
    background: var(--theme-blue);
    border-color: var(--theme-blue);
}

div.checkbox.switcher label input:checked+span small,
div.radio.switcher label input:checked+span small {
    left: 50%;
}

body div.container {
    /* padding: 5% 0; */
}

/* INVOICE MOUDLE PAGE CSS STARTS */
.invoice-module .invoice-module-menus {
    padding-right: 1rem;
    padding-left: 1rem;
    width: 25%;
    text-align: center;
}

.invoice-module .invoice-module-menus .white-bg {
    padding: 1.5rem 1rem;
    border: 0.3rem solid transparent;
}

.invoice-module .invoice-module-menus h3 {
    display: block;
    margin-bottom: 1rem;
}

.invoice-module .invoice-module-menus i,
.invoice-module .invoice-module-menus svg {
    font-size: 3.8rem;
}

.invoice-module .nav {
    flex-wrap: unset;
}

.invoice-module-contents-parent {
    padding-right: 1rem;
    padding-left: 1rem;
}

.invoice-module-contents-parent .manage-account form label {
    margin-bottom: 0.5rem;
}

.invoice-module-contents-parent .manage-account form input,
.invoice-module-contents-parent .manage-account form select {
    border: 1px solid #ced4da;
    padding: 0.375rem 0.75rem;
}

.invoice-module-contents-parent .manage-account form label {
    color: var(--light-color);
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    /*border : 0.3rem solid var(--theme-darkblue);*/
    background: var(--white-color);
    color: var(--theme-darkblue);
}

/* view Detailed Payment page css starts*/
.viewDetailedPayment .unset-after h2 {
    margin-bottom: 1rem;
}



/* dashboard-messages */
.dashboard-messages p {
    color: var(--light-color);
    font-size: var(--placeholder-size);
}

.message-group {
    padding: 1.3rem 0;
    border-top: 1px solid #eee;
}

.message-reply,
.message-delete {
    font-size: var(--placeholder-size);
    color: var(--light-color);
    width: 5%
}

.message-reply:hover,
.message-delete:hover,
.message-title:hover {
    cursor: pointer;
}

.message-group:hover {
    background: #f1f3fa;
}

.message-checkbox,
.message-save-icon {
    width: 5%;
}

.custom-job-recieved .message-save-icon {
    width: 2%;
    margin: 0 10px;
}

.message-username {
    width: 20%;
}

.message-title {
    width: 55%;
}

.message-time {
    width: 15%;
    text-align: right;
}

.message-checkbox input {
    position: unset;
    margin: 0;
}

/*  dashboard-bookmarked-jobs */
.redirection-table table tbody tr:hover {
    cursor: pointer;
}

.job-table .table th {
    border-top: none;
    text-align: center;
}

.job-table .table th,
.job-table .table td {
    font-size: var(--general-size);
    // color : var(--light-color);
    padding: 1.5rem 0;
    text-align: center;
}

.sidebar-para {
    padding: 0px 26px;

    font-size: 13px;
    color: #2784b2
}

/* MANAGE ACCOUNT SECTION - COMMON CSS */
.manage-account .form-group {
    margin-bottom: 1.8rem;
}

.manage-account .form-group-left {
    padding-right: 1.6rem;
}

.manage-account .form-group-right {
    padding-left: 1.6rem;
}

.manage-account form label {
    font-size: var(--general-size);
    color: var(--theme-blue);
    font-weight: bold;
    margin-bottom: 0;
}

.manage-account form input,
.manage-account form select {
    height: 3.8rem;
    font-size: var(--general-size);
    color: var(--light-color);
    border: none;
    border-bottom: 0.1rem solid #ccc !important;
    padding-left: 0;
    border-radius: 0.1rem;
}

.manage-account .form-control:valid:focus {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

.manage-account textarea {
    resize: none;
    font-size: var(--general-size);
    color: var(--light-color);
    border: none;
    border-bottom: 0.1rem solid #ccc !important;
    padding-left: 0;
}

.manage-account ::-webkit-input-placeholder {
    font-size: var(--placeholder-size);
}

.manage-account ::-moz-placeholder {
    font-size: var(--placeholder-size);
}

.manage-account :-ms-input-placeholder {
    font-size: var(--placeholder-size);
}

.manage-account :-moz-placeholder {
    font-size: var(--placeholder-size);
}

/* EDIT RESUME PAGE - CSS STARTS */
.job-seeker-edu .input-group-prepend SVG {
    color: var(--theme-blue);
}


/* VERIFY ACCOUNT PAGE - CSS STARTS */
.gov-id-verification-guides .complete-profile-points-group i,
.gov-id-verification-guides .complete-profile-points-group svg {
    font-size: 1.3rem;
    padding-top: 0.3rem;
    color: var(--light-color);
}

.gov-id-verification-guides .complete-profile-points-group .profile-points {
    padding-bottom: 0;
}

.gov-id-verification-guides .complete-profile-points-group .profile-points p {
    color: var(--light-color);
    padding-bottom: 0.3rem;
}

.sub-profile-points {
    margin-bottom: 0.5rem;
}

.gov-id-verification-guides .complete-profile-points-group:hover {
    background: none;
}

.gov-id-verification-guides p {
    font-size: var(--general-size);
    color: var(--light-color);
}

/* UPLOAD IMAGES  */
.drop-zone {
    /* max-width: 200px; */
    height: 20rem;
    padding: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-family: "Quicksand", sans-serif;
    font-weight: 500;
    font-size: 1.5rem;
    cursor: pointer;
    color: #cccccc;
    border: 0.2rem dashed var(--theme-darkblue);
    border-radius: 10px;
}

.drop-zone--over {
    border-style: solid;
}

.drop-zone__input {
    display: none;
}

.drop-zone__propmt {
    width: 100%;
    height: 100%;
}

.drop-zone__thumb {
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    overflow: hidden;
    background-color: #cccccc;
    background-size: cover;
    position: relative;
}

.drop-zone__thumb::after {
    content: attr(data-label);
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 0.5rem 0;
    color: #ffffff;
    background: rgba(0, 0, 0, 0.75);
    font-size: 1.4rem;
    text-align: center;
}

/* SKILL RATING PAGE - CSS STARTS */
.skill-rating p {
    font-size: var(--general-size);
    color: var(--light-color);
    margin-bottom: 2rem;
}

.skill-rating .complete-profile-points-group {
    border-bottom: 1px solid #eee;
    margin-top: 0.9rem;
}

.skill-rating .form-check-input {
    margin-left: unset;
    margin-top: 0.6rem;
}

.skill-rating .complete-profile-points-group .profile-points {
    border-bottom: unset;
}

.skill-rating .complete-profile-points-group .profile-points p {
    margin-left: 1.5rem;
    color: var(--theme-darkblue);
}

.skill-rating .complete-profile-points-group:hover {
    background: unset;
    cursor: unset;
}

.skill-box p,
.skill-box input {
    font-size: var(--placeholder-size);
    color: var(--light-color);
}

.skill-box li .inputbox {
    width: 20%;
}

.skill-box input[type=number]::-webkit-inner-spin-button,
.skill-box input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}

.skill-box li {
    font-size: var(--general-size);
    color: var(--light-color);
    padding: 0.3rem 0;
}

.grey-bg {
    background: #717171 !important;
    border-radius: 0.1rem;
    padding: 0.8rem;
    // height: 100%;
    // background-color: #717171 !important;
}

.pp-lft-content {
    width: 90%;
}

.pp-rgt-content {
    width: 10%;
}

.up-title-price span {
    font-size: 16px;
}

.pp-rgt-content i,
.pp-rgt-content svg {
    color: var(--theme-darkblue);
}

.pp-leftbox-spacing {
    padding-left: 0rem;
    padding-right: 0.75rem;
    margin-bottom: 1.5rem;
}

.pp-rightbox-spacing {
    padding-right: 0rem;
    padding-left: 0.75rem;
    margin-bottom: 1.5rem;
}

/* NEW JOBS LIST PAGE CSS STARTS */
.new-jobs-list {
    width: 100%;
}

.new-jobs-view-btn {
    background: var(--theme-darkblue);
    color: var(--white-color) !important;
    padding: 0.5rem 1.5rem;
    border-radius: 2.5rem;
    cursor: pointer;
}

/* USER PREVIEW PROFILE PAGE CSS STARTS */
.user-preview {
    width: 100%;
}

.user-preview-left-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.up-user-image-preview {
    display: flex;
    justify-content: end;
    align-items: center;
}
.common-edit{
    font-size: 12px;
    position: absolute;
    /* margin-left: -36px; */
    background: #004C70;
    padding: 6px 6px;
    border-radius: 50%;
    color: white;
    border: solid 1px lightgray;
}
.up-user-image-preview a {
    font-size: 16px;
    position: absolute;
    margin-left: -36px;
    background: #004C70;
    padding: 1px 5px;
    border-radius: 50%;
    color: white;
    border: solid 1px lightgray;
}

.up-user-name {
    margin-left: 20px;
    padding: 20px;
}

.user-preview-right-header {
    width: 30%;
}

// .up-user-image-preview::after {
//     content: '';
//     position: absolute;
//     top: 0;
//     right: 22px;
//     height: 100%;
//     width: 2px;
//     background: #acacac;
//   }
.up-user-image-preview img {
    width: 11rem;
    height: 11rem;
    border-radius: 50%;
    object-fit: cover;
}

.up-user-name h2::after,
.unset-after h2::after {
    content: unset;
}

.unset-after a, .edit-icon{
    border-radius: 50%;
    width: 2.6rem;
    height: 2.6rem;
   
    display: inline-flex;
    justify-content: center;
    align-items: center;
   
    font-size: 22px;
}

.unset-after svg {
    font-size: 1.2rem;
}

.up-user-name p {
    font-size: 15px;
    color:black;
}
.user-preview-user-title .up-title-content a{
    font-size: 15px;
    font-weight: 300 !important;
}
.user-preview-user-title .up-title-content p,
.general-size-light-color {
    font-size: var(--general-size);
    color: black;
}

.user-preview .modal-header,
.user-preview .modal-footer {
    padding: 1rem 3rem;
}

.user-preview .modal-body {
    padding: 2rem 3rem;
}

.user-preview input {
    // height: 2.3rem;
    border-radius: 0.1rem;
    font-size: var(--general-size);
}

.user-preview .user-preview-education p a {
    color: var(--theme-darkblue);
}

.user-preview .user-preview-employement p a {
    color: var(--theme-darkblue);
}

.modal-footer .btn-outline-primary {
    font-size: var(--general-size);
    border-radius: 2.5rem;
    padding: 0.6rem 1.9rem;
    background: var(--theme-darkblue);
    color: var(--white) !important;
    margin: 1rem 0;
}

.modal-footer .btn-outline-success {
    font-size: var(--general-size);
    border-radius: 2.5rem;
    padding: 0.6rem 1.9rem;
    background: var(--theme-success);
    color: var(--white) !important;
    margin: 1rem 0;
}
.btn-outline-success {
    font-size: var(--general-size);
    border-radius: 2.5rem;
    padding: 0.6rem 1.9rem;
    background: var(--theme-success);
    color: var(--white) !important;
    margin: 1rem 0;
}
.btn-outline-primary {
    font-size: var(--general-size);
    border-radius: 2.5rem;
    padding: 0.6rem 1.9rem;
    background: var(--theme-darkblue);
    color: var(--white) ;
    margin: 1rem 0;
    opacity: 0.8;
    margin-left: 10px;
}

/* FIND A JOB PAGE -CSSS STARTS */

.find-job .banner {
    background: url('../images/homepage/banner.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 35rem;
}

.find-job .banner h1 {
    color: var(--white-color);
}

.find-job .banner h1::after {
    content: unset;
}

.search-bar {
    margin: 0 auto;
    background: rgba(0, 0, 0, 0.1);
    padding: 2.5rem;
}

.search-bar .input-group svg {
    color: var(--theme-blue);
}

.search-bar input,
.search-bar select {
    height: 3.7rem;
    border-radius: 0.1rem;
    width: 33rem;
    margin-right: -7rem;
    font-size: var(--general-size);
}

.search-bar button {
    background: var(--theme-blue) !important;
    border-color: var(--theme-blue) !important;
    border-radius: 0.1rem;
    width: 20rem;
    color: var(--white-color);
    font-size: var(--general-size);
}

.search-bar button:hover {
    box-shadow: 0 2px 6px 0 var(--theme-blue) !important;
}

.find-job-body .find-job-left-nav {
    width: 25%;
}

.find-job-body .find-job-left-nav ul li h1 {
    font-size: 2.2rem;
}

.find-job-body .find-job-left-nav ul .parent-nav {
    padding: 1rem 0;
}

.find-job-body .find-job-left-nav ul .parent-nav a {
    color: var(--theme-darkblue);
    font-size: var(--general-size);
}

.find-job-body .find-job-left-nav ul .parent-nav .nav-link {
    font-size: var(--placeholder-size);
    padding-bottom: 1rem;
}

.find-job-body .find-job-left-nav ul .parent-nav .nav-link svg {
    font-size: 0.6rem;
    margin-bottom: 0.25rem;
}

.find-job-body .find-job-left-nav ul .parent-nav p {
    margin-left: 2rem;
}

.find-job-body .find-job-left-nav .white-bg {
    height: unset;
}

.find-job-body .find-job-right-content {
    width: 75%;
}

.find-job-body .headings {
    margin: 6.5rem 0;
}

.find-job-body .headings h1::after {
    left: 50%;
    transform: translateX(-50%);
}

.find-job-body .headings p {
    font-size: var(--placeholder-size);
    color: var(--light-color);
}

.job-description h2 {
    border-bottom: 1px solid var(--theme-darkblue);
}

.job-description p {
    font-size: var(--general-size);
    color: var(--light-color);
    margin: 1rem 0;
}

.job-description .table td {
    color: var(--light-color);
    font-size: var(--placeholder-size);
}

.find-job-pagination .pagination-left p,
.find-job-pagination .pagination-right span {
    color: var(--light-color);
    font-size: var(--placeholder-size);
}

.find-job-pagination .pagination-right span {
    padding: 0 0.6rem;
    width: 3rem;
    height: 3rem;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.find-job-pagination .pagination-right span:hover {
    cursor: pointer;
}

.find-job-pagination .pagination-right .pagination-active {
    background: var(--theme-darkblue);
    color: var(--white-color);
    border-radius: 50%;
}

#imgone {
    display: none;
}

#imgtwo {
    display: none;
}

#imgthree {
    display: none;
}

.user-preview-header a {
    margin: 2rem auto !important;
}


.boxmodal {
    position: relative;
    top: 50%;
    left: 50%;
    right: 0;
    transform: translate(-50%, -50%);
    height: 78%;
    width: 46%;
    overflow-y: auto;
    background-color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    z-index: 10;



}

.boxmodal-joboffer {
    position: relative;
    top: 31%;
    left: 50%;
    right: 0;
    transform: translate(-50%, -50%);
    height: 54%;
    width: 54%;
    overflow-y: auto;
    background-color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    z-index: 10;


}

.modal-wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
}

.modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
    background-color: rgba(0, 0, 0, 0.3);

}

.circled-image {
    position: relative;
    // width: 35%;
    margin: 0 auto;
}

.circled-image a {
    position: absolute;
    right: 14px;
    width: 2.6rem;
    height: 2.6rem;
    border-radius: 50%;
    border: 1px solid #ccc;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background: #f1f3fa;
    font-size: 16px;
    color: #004c70 !important;
}

.up-user-name>a {
    bottom: 10px;
    border-radius: 50%;
    border: 1px solid #ccc;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background: #f1f3fa;
    font-size: 20px;
    color: #004c70 !important;
}

.user-preview-header .up-user-name a {
    font-size: 16px;
}

.profile-photo-body .row .col-sm-12 label {
    width: 232px;
}

div#profilePicModal .modal-footer a {
    position: unset;
}

div#profilePicModal .modal-footer a {
    padding: 10px !important;
    position: unset;
    height: auto;
}

.checkedGreen {
    color: green !important;
}

/*---Messaging---*/
.common-page-title h2 {
    font-size: 28px;
    line-height: 33px;
    display: flex;
    align-items: center;
    color: #232266;
    margin-bottom: 21px;
}

.messagewholecard {
    display: flex;
}

.patient-details-left-card {
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    border-radius: 8px;
    width: 100%;
}

.appointment-calendar-card {
    padding: 20px;
    margin-bottom: 30px;
}

/*.messagecard2 {*/
/*  margin-right: 15px;*/
/*  position: relative;*/
/*}*/
/*.messagerowsection {*/
/*  margin-bottom: 100px;*/
/*}*/
/*.messageleftrow {*/
/*  display: flex;*/
/*  align-items: flex-start;*/
/*  margin-bottom: 35px;*/
/*}*/
/*.messageleftrowimage {*/
/*  margin-right: 17px;*/
/*  cursor: pointer;*/
/*}*/
/*.messageleftrowimage img {*/
/*  width: 60px;*/
/*  height: 58px;*/
/*  max-width: unset;*/
/*}*/
/*.messageleftrowdoctordetails {*/
/*  margin-bottom: 5px;*/
/*  display: flex;*/
/*  align-items: center;*/
/*}*/
/*.messageleftrowdoctordetails p {*/
/*  font-size: 16px;*/
/*  line-height: 19px;*/
/*  letter-spacing: 0.1em;*/
/*  text-transform: capitalize;*/
/*  color: #004c70;*/
/*  margin: 0;*/
/*}*/
/*.messageleftrowdoctordetails p > span {*/
/*  font-size: 12px;*/
/*  line-height: 14px;*/
/*  letter-spacing: 0.1em;*/
/*  text-transform: capitalize;*/
/*  color: #B8B6B6;*/
/*}*/
/*.messageleftrowtextdetails {*/
/*  background: #E7E7E7;*/
/*  border-radius: 0px 25px 25px 25px;*/
/*  padding: 16px;*/
/*}*/
/*.messageleftrowtextdetails p {*/
/*  font-size: 14px;*/
/*  line-height: 16px;*/
/*  letter-spacing: 0.1em;*/
/*  text-transform: capitalize;*/
/*  color: #004c70;*/
/*  margin: 0;*/
/*}*/
/*.messagerightrow {*/
/*  width: 90%;*/
/*  justify-content: flex-end;*/
/*  margin: 0 0 0 auto;*/
/*}*/
/*.messageleftrow {*/
/*  display: flex;*/
/*  align-items: flex-start;*/
/*  margin-bottom: 35px;*/
/*}*/
/*.messagerightrowtextdetails {*/
/*  background: #004c70;*/
/*  border-radius: 25px 0px 25px 25px;*/
/*}*/
/*.messagerightrowtextdetails p {*/
/*  color: #fff;*/
/*}*/
/*.type-message-section {*/
/*  position: absolute;*/
/*  bottom: 0;*/
/*  width: 100%;*/
/*  left: 0;*/
/*}*/
/*.type-message-sub-section {*/
/*  position: relative;*/
/*}*/
/*.type-message-sub-section input {*/
/*  background: #D9D9D9;*/
/*  border-radius: 0px 0px 8px 8px;*/
/*  font-size: 18px;*/
/*  line-height: 21px;*/
/*  letter-spacing: 0.1em;*/
/*  text-transform: capitalize;*/
/*  color: #232266;*/
/*  height: 68px;*/
/*  width: 100%;*/
/*  padding: 23px 60px 23px 23px;*/
/*  border: 0;*/
/*}*/
/*.type-message-sub-section a{*/
/*  position: absolute;*/
/*  right: 54px;*/
/*  top: 17px;*/
/*}*/
/*.type-message-sub-section a:nth-child(3) {*/
/*  position: absolute;*/
/*  right: 17px;*/
/*  top: 17px;*/
/*}*/
/*.custom-message-spacing {*/
/*  margin-top: 20px;*/
/*}*/
.rightcardpropic {
    position: relative;
}

.rightcardpropic-img img {
    width: 100%;
}

.propicclosesection {
    position: absolute;
    right: 7px;
    top: 11px;
    cursor: pointer;
}

.propicdetails {
    background: rgba(255, 255, 255, 0.41);
    padding: 25px;
    width: 100%;
    position: absolute;
    bottom: 0;
    height: 72px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.propicdetails {
    background: rgba(255, 255, 255, 0.41);
    padding: 25px;
    width: 100%;
    position: absolute;
    bottom: 0;
    height: 72px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.propicdetails h3 {
    font-size: 26px;
    line-height: 30px;
    text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #004c70;
}

.rightcard-basic-details-heading {
    margin-top: 30px;
}

.rightcard-basic-details-heading h2 {
    font-size: 25px;
    line-height: 35px;
    text-align: left;
    color: #232266;
    margin: 0;
}

.rightcard-basic-details-heading p {
    background: #C4C4C4;
    width: 45px;
    height: 1px;
    margin: 11px 0 34px;
}

.rightcard-basic-details {
    padding: 0 27px;
    overflow: auto;
    height: calc(100% - 230px);
}

.rightcard-basic-details-row {
    margin-bottom: 36px;
}

.rightcard-basic-details-row h6 {
    font-size: 17px;
    line-height: 20px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #0093d1;
    margin-bottom: 18px;
}

.rightcard-basic-details-row p {
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0.1em;
    text-transform: capitalize;
    color: #232266;
}

.picker-panel {
    z-index: 1;
    left: -3px !important;
    top: 48px !important;
}

.current-year {
    display: none !important;
}

.modal-label {
    font-size: 16px;
    color: var(--theme-darkblue);
}

.modal-radio {
    width: 16px;
    // color: var(--theme-darkblue);
}

.modal-body>form>.form-group>label {
    font-size: 16px;
    color: var(--theme-darkblue);
}

.modal-body>form>.form-row>.form-group>label {
    font-size: 16px;
    color: var(--theme-darkblue);
}

.user-preview select {
    height: 3.7rem;
    border-radius: 0.1rem;
    margin-right: 1rem;
    font-size: var(--general-size);
}

.resume_preview_button {
    display: inline-block;
    cursor: pointer;
    font-size: 15px;
    color: #004c70
}

.resume_preview_button:hover {
    cursor: pointer;
    font-size: 15px;
    color: #0093d1
}

.model-fieldset {
    margin-top: 1.5rem;
}

.modal-legend {
    font-size: 16px;
    color: var(--theme-darkblue);
}

.modal-aboutMe-textArea {
    font-size: 16px;
}

.character-left-textArea {
    font-size: 10px;
}

.job_type_select {
    font-size: 14px !important;
}

.job-seeker-job-offer-section {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    border-bottom: 1pt solid #9a9a9a;
    padding-bottom: 20px;
}

.job-seeker-job-offer-section h3 {
    margin: 0;
    margin-right: 15px;
    padding: 0 !important;
    text-align: left;
}

.job-seeker-job-offer-section p {
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
}

.job-offer-job-body .bj-content-body {
    padding: 0;
}

.job-seeker-job-offer-section h3>span {
    color: #626262;
    font-size: 12px;
}

.job-offer-btns a {
    background: #004c70 !important;
    border-radius: 5px;
}

.job-offer-client-img img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
}

.job-offer-client-details {
    display: flex;
}

.job-offer-client-img {
    margin-right: 50px;
}

.job-offer-client-text h4 {
    margin-bottom: 10px !important;
    display: block;
    padding: 0 !important;
    font-size: 24px;
}

.job-offer-client-text h6 {
    margin-bottom: 0;
    padding-bottom: 0 !important;
    font-size: 16px;
    font-weight: 600;
}

.job-offer-client-text p {
    margin-bottom: 0;
    padding: 0 !important;
}

.job-offer-client-text .job-posted-bold-text {
    font-size: 16px;
    color: #06b900;
    margin-bottom: 10px;
}

.job-offer-client-text .member-since-text {
    font-size: 12px;
}

.custom-card-width {
    margin: 0 auto;
}

.custom-arrow-btn {
    position: absolute;
    right: 15px;
    color: white;
}

.sub-menu ul li {
    border-bottom: 1pt solid #fff;
}

.sub-menu ul li:last-child {
    border: 0;
}

.employer-active ul li {
    background: #fafafa;
    //border-bottom: 1pt solid #004c70;
}

.side-nav ul li {
    position: relative;
}

.no-jobs {
    display: flex;
    font-size: 26px;
    justify-content: center;
    margin: 100px;
}

.job-seeker-dashboard-banner {
    height: 248px;
    width: 100%;
    position: relative;
}

.job-seeker-dashboard-banner-content {
    position: absolute;
    bottom: 0;
    left: 2.5%;
    width: 95%;
}

.job-seeker-dashboard-banner-content .search-bar button {
    position: relative;
}

.job-seeker-dashboard-banner-content .search-bar .input-group {
    flex-wrap: nowrap;
}

.job-seeker-search {
    top: 23%;
}

.job-seeker-dashboard-banner-title h1 {
    color: #fff;
    font-weight: bold;
    font-size: 14px;
    letter-spacing: 1px;
}

.job-seeker-dashboard-banner-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.view-all-jobs-section {
    font-size: 14px;
    text-decoration: underline !important;
    color: #fff !important;
    cursor: pointer;
}

.view-all-jobs-section:hover {
    color: #fff !important;
    text-decoration: underline !important;
}

.job-seeker-dashboard-banner-content .search-bar {
    margin: 0 auto;
    background: rgba(0, 76, 112, 0.3);
}

.job-seeker-dashboard-banner-content .input-group-prepend {
    width: 37px;
    height: 37px;
}

.job-seeker-dashboard-banner-content .input-group-prepend>div {
    width: 100%;
}

.job-seeker-dashboard-banner-content .input-group-text {
    width: 37px;
}

.job-seeker-dashboard-banner-content .input-group-text>svg {
    width: 37px;
    font-size: 0em;
}

.job-seeker-search1 {
    width: 45%;
    left: 30%;
    top: unset;
}

.custom-height-auto {
    height: auto;
}

.custom-link-text {
    font-size: 16px;
    position: relative;
    bottom: 8px;
    cursor: pointer;
}

.no_data {
    color: rgb(66, 72, 78) !important;
    display: flex;
    justify-content: center;
    font-size: 22px !important;
    min-height: 330px;
    align-items: center;
}

/* VERIFY ACCOUNT PAGE - CSS STARTS */
.gov-id-verification-employer-guides .complete-profile-points-group i,
.gov-id-verification-employer-guides .complete-profile-points-group svg {
    font-size: 1rem !important;
    ;
    padding-top: 0.3rem;
    color: var(--light-color);
}

.gov-id-verification-employer-guides .complete-profile-points-group .profile-points {
    padding-bottom: 0;
}

.gov-id-verification-employer-guides .complete-profile-points-group .profile-points p {
    color: var(--light-color);
    padding-bottom: 0.3rem;
}

.sub-profile-points {
    margin-bottom: 0.5rem;
}

.gov-id-verification-employer-guides .complete-profile-points-group:hover {
    background: none;
}

.gov-id-verification-employer-guides p {
    font-size: 1rem !important;
    color: var(--light-color);
}

.job_seeker_update_icon {
    background: #145e82;
    border-radius: 6px;
    color: white;
    padding: 10px 25px;
    cursor: pointer;
}

.verification_status_pending {
    background: #f29339;
    color: #fff;
    border-radius: 3px;
    padding: 3px;
}

.verification_status_rejected {
    background: #a82e2e;
    color: #fff;
    border-radius: 3px;
    padding: 3px;
}

.modal_id_width .modal-content {
    width: 150%;
}

.search-input-bar,
.search-input-bar>div {
    width: 100%;
}

.search-bar input {
    width: 100%;
}

.verification_status_rejected {
    background: #a82e2e;
    color: #fff;
    border-radius: 3px;
    padding: 3px;
}

.modal_id_width .modal-content {
    width: 150%;
}

.job-offer-banner {
    background: url('../images/jobseeker/jobofferbanner.jpg');
    width: 100%;
    height: 250px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    justify-content: center;
}

.job-offer-status-content {
    font-size: 16px;
}

.job-offer-banner .bj-banner-content h1 {
    width: 60%;
    margin: 0 auto;
    text-align: center;
    font-family: "Lato-Bold";
    font-style: normal;
    font-weight: bold;
    color: #ffff;
    line-height: 40px;
    font-size: 3.5rem;

}

.job-offer-card {
    padding: 33px 0;
}

.job-offer-card-bg {
    background: #FFFFFF;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    width: 100%;
}

.job-offer-card-bg {
    display: flex;
}

.job-offer-card-left {
    width: 70%;
    border-right: 1px solid #737373;
    padding: 20px;
}

.job-offer-card-right {
    width: 30%;
}

.job-offer-card-head-title {
    font-size: 24px;
    line-height: 29px;
    margin-bottom: 12px;
    color: #004C70;
    font-family: "Lato-Black";
}

.job-offer-card-head-line {
    width: 53px;
    height: 2px;
    background: #004C70;
    margin-bottom: 30px;
}

.job-offer-card-content-section {
    margin-bottom: 14px;
}

.job-offer-card-content-section>label {
    font-size: 20px;
    line-height: 24px;
    font-family: "Lato-Black";
    color: #004C70;
}

.job-offer-card-content-section>span {
    font-size: 20px;
    line-height: 24px;
    margin-left: 10px;
    color: #737373;
    font-family: "Lato-Regular";
}

.job-offer-card-content-section>p {

    font-size: 20px;
    line-height: 24px;
    font-family: "Lato-Regular";
    color: #737373;
}

.job-offer-card-content-section2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.job-offer-card-content-section2>label>span {
    font-size: 16px;
    line-height: 19px;

    color: #737373
}

.job-offer-card-content-section2 {
    margin-bottom: 10px;
    // border-bottom: 1px solid #004C70;
    padding-bottom: 10px;
}

.padding_left_right {
    padding-left: 10px;
    padding-right: 10px;
}

.padding_left_right a {
    text-decoration: underline !important;
}

.border_Section {
    margin-top: 10px;
    // border-bottom: 1px solid #004C70;
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    border: 2px solid #004c70cf;
    background: #F1F1F1;
}

.job-offer-card-content-section2:last-child {
    border-bottom: 0;
}

.job-offer-card-content-section2 svg {
    position: relative;
    top: -3px;
}

.job-offer-card-right-top {
    padding: 22px 50px;
    border-bottom: 1px solid #737373;
}

.job-offer-card-right-image {
    margin-bottom: 22px;
}

.job-offer-card-employer-text {
    font-size: 20px;
    line-height: 24px;
    font-family: "Lato-Black";
    color: #004C70;
    margin-bottom: 15px;
}

.job-offer-card-employer-name {
    font-size: 22px;
    line-height: 26px;
    font-family: "Lato-Bold";
    color: #737373;
    margin-bottom: 15px;
}

.job-offer-card-company-name {
    font-size: 18px;
    line-height: 22px;
    font-family: "Lato-Bold";
    color: #A8A6A6;
    margin-bottom: 15px;
}

.job-offer-card-company-link {
    font-size: 18px;
    line-height: 22px;
    text-decoration-line: underline;
    margin-bottom: 15px;
    color: #407893;
}

.job-offer-card-company-payment {
    font-size: 14px;
    line-height: 17px;
    color: #A8A6A6;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
}

.job-offer-card-company-payment>svg {
    margin-right: 5px;
}

.job-offer-card-company-member {
    font-size: 16px;
    line-height: 19px;

    color: #A8A6A6;
}

.job-offer-card-right-bottom {
    padding: 50px 60px;
    text-align: center;
}

.job-offer-status-heading {
    font-size: 20px;
    line-height: 24px;
    font-family: "Lato-Black";
    color: #004C70;
    margin-bottom: 7px;
}

.active-box {
    background: #013C5E;
    border-radius: 3px;
    width: 114px;
    height: 29px;
    margin: 0 auto;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    text-transform: capitalize;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFFFFF;
}

.job-offer-status-section {
    margin-bottom: 10px;
}

.job-offer-btn-section {
    margin-top: 20px;
}

.job-offer-btn-section .btn {
    margin-bottom: 15px;
    width: 80%;
    height: 34px;
    border-radius: 30px;
    color: #004C70;
}

.accept-job-offer-btn {
    background: #249D00;
    border: 1px solid #249D00;
    color: #fff !important;
}

.gender_div {
    display: flex;
    justify-content: center;
    align-items: center;

}

.gender_div label {
    margin: 3px;
}

.name_label {
    font-weight: bold;
    font-size: var(--general-size);
    color: var(--light-color);
}

.img-preview {
    overflow: hidden;
}

.cropper-image .cropper-wrap-box .cropper-canvas>img {
    border-radius: 0;
    height: auto !important;
    width: 100% !important;
}

.cropper-image>img {
    border-radius: 0;
    height: auto;
    width: 100%;
}

.cropper-view-box>img {
    border-radius: 0;
}

.expertise_skill_span {
    padding: 5px 25px;
    border-radius: 9px;
    background: white;
    color: #004c70;
    margin-right: 5px;
    margin-top: 0.5rem;
    border: 1px solid #004c70;
    white-space: nowrap;
    display: inline-block;
}

.expertise_skill_p {
    margin-top: 18px;
    // display: flex;
    // flex-direction: row;
    // flex-wrap: wrap;
}

.search-bar .input-group {
    flex-wrap: nowrap;
}

.zero-top-padding {
    padding: 0;
}

.job-seeker-description-section .job-seeker-job-offer-section h3 {
    margin-bottom: 15px;
}

.pagination_center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.job_offer_type_selector {
    margin-bottom: 10px;
    font-size: 20px;
}

.job_offer_type_selector .select {
    font-size: 16px;
    width: 220px;
    height: 35px !important;

}

.job_seeker--account_dob {
    width: 40%;
    border: 1px solid #ced4da;
}

.up-user-image>label {
    text-align: center;
    display: block;
    margin: 15px 0 20px;
    font-size: 14px !important;
}

.btn-size {
    width: max-content;
    height: 5rem;
    font-size: 16px;
}

.applyjob-section {
    display: flex;

    width: 40%;
    margin-top: 15px;
}

.applyjob-section button {
    margin-right: 10px;
}

.incomplete-profile {
    width: 100%;
}

.incomplete-profile em {
    font-size: 30px;
    color: #145e82;
}

.profile-details {
    padding: 1%;
    font-size: 16px;
}

.profile-details-div h2 {
    text-align: center;
    font-size: 25px;
    color: #0093d1;
}

.profile-details-div p {
    text-align: center;
    font-size: 17px;
}

.profile-details ol li {
    color: red;
}

.profile-details ol li span {
    font-weight: bold;
    margin-right: 5px;
}

.incomplete-button {
    width: 100%;
    display: flex;
    justify-content: space-around;
    font-size: 16px;
}

.verification_selector--jobseeker {
    height: 40px !important;
    width: 300px;
}

.individual_skill_span--jobseeker {
    padding: 10px;
    border: 1px solid;
    border-radius: 20px;
    margin-right: 9px;
    background: #ffffff;
    color: #004c70;
    margin-bottom: 5px;
}

.alert-footer {
    text-align: center;
    display: flex;
    justify-content: center;
    padding: 2%;
}

.invoice_note {
    padding: 3%;
    background-color: aliceblue;
}

.text-black {
    color: black !important
}

.Amended {
    color: red;
}

.Due {
    color: orange;
}

.amountdue-section-left-one {
    width: 100%;
}

.amountdue-section-left-one>span {
    font-size: 16px;
}

.amount-due-salary-breakdown {
    display: flex;
    justify-content: space-between;
    width: 50%;
    margin-bottom: 12px;
}

.amount-due-salary-breakdown>div {
    flex-direction: column;
}

.salarybreakdown>span:nth-child(1) {
    font-size: 15px;
    font-weight: 800;
}

.salarybreakdown>span:nth-child(2) {
    font-size: 12px;
    margin: 3px 0px 10px 47px;
    color: black;
}

.salarybreakdownright>span:nth-child(1) {
    font-size: 16px;
    font-weight: 800;
}

.salarybreakdownright>span:nth-child(2) {
    text-align-last: end;
    font-size: 14px;
    font-weight: 700;
    color: #145e82;
}

.amended-section>span:nth-child(1) {
    color: red;
    font-weight: 700;
    font-size: 20px;
}

.autobilled-section>span:nth-child(1) {
    color: rgb(31, 177, 31);
    font-weight: 700;
    font-size: 20px;
}

.amendedbreakdown>span:nth-child(1) {
    font-size: 15px;
    font-weight: 800;
}

.amendedbreakdown>span:nth-child(2) {
    font-size: 12px;
    font-weight: 800;

}

.amendedbreakdownright>span:nth-child(1) {
    color: red;
    font-weight: 700;
    font-size: 20px;
}

.disputed-div span {
    color: red;
    font-size: 20px;
}

.dispute-section text {
    font-size: 16px;
    font-weight: bold;
}

.dispute-section p {
    font-weight: bold;
    color: #004c70;
    font-size: 20px;
}

.last-step-icon>i {
    font-size: 30px;
    color: #004c70;
}

.public-visibility-box {
    cursor: pointer;
    padding: 3em !important;
}

.public-visibility-box>p {
    text-align: left;
}



// .profile-visibility-div>div:nth-child(1) {
//     display: flex;
//     flex-direction: column;
//     text-align: center;
//     font-size: 16px;
// }

// .profile-visibility-div>div:nth-child(1)>span:nth-child(2) {
//     color: #004C70;
//     text-transform: uppercase;
// }

.billings-section {
    background: whitesmoke;
    margin: 10px 12%;
    height: 100vh;
}

.billings-header h1 {
    font-size: 31px;
}

.billings-header span {
    color: grey;
    font-size: 16px;
}

.billings-body {
    background: white;
    border: 1px solid gainsboro;

    margin-top: 10px;
    border-radius: 5px
}

.billings-body-header {
    display: flex;
    justify-content: space-between;
    padding: 1% 6%;
}

.billing-body-content {
    border-top: 1px solid gainsboro;
}

.jobs-section {

    width: 100%;
    /* margin: 0px 10px; */
    text-align: center;
    // margin-left: 40%;
    justify-content: center;
}

.invoice-module-contents-parent .fade:not(.show) {
    opacity: 1;
}

.processing-fee {
    padding: 2%;
    color: green;

}

.cursor-pointer {
    cursor: pointer;
}

.year-div {
    border: 1px solid gainsboro;
    height: 4rem;
    width: 23rem;
}

.year-div>input {
    border: none !important;
}

.hired_alert {
    background-color: #DBDBDB;
    width: 97.5%;
    margin: auto;
    border-radius: 10px;
    padding: 1rem;
    color: white;
}

.otp-field {
    height: 4rem;
    width: 4rem;
    margin-left: 5px;
    border: navajowhite;
    /* border-color: #191818; */
    /* align-items: center; */
    font-size: 24px;
    /* text-align: center; */
    /* display: flex; */
    border-bottom: 1px solid black;
}

.image-loading-progress {
    position: absolute;
    /* left: 13rem; */
    height: 170px;
    width: 181px;
    /* top: 5rem; */
    align-items: center;
    text-align: center;
    color: white;
    display: flex;
    background: rgb(240 227 227 / 80%);
    justify-content: center;
}

.flex_rep {
    display: flex;
}

@media (width < 500px) {
    .gender_lable {
        width: 100% !important;
        justify-content: space-between !important;
    }

    .jobseeker-side-nav {
        width: 250px !important;
        height: 100vh;
        transition: all .2s;
        position: relative;
        left: 0;
    }

    .flex_rep {
        display: block;
        margin-top: 10px;
        width: 100% !important;
    }

    .nav-section ul .active {
        border-left: 9px solid var(--theme-blue);
    }

    .nav-section {
        width: 100%;
        overflow: auto;
        background: #F1F3FA;
        -ms-overflow-style: none;
        scrollbar-width: none;
    }

    .nav-section::-webkit-scrollbar {
        display: none;
    }

    .nav-section ul {
        display: flex;
        margin-bottom: 0px;
    }

    .getstarted_bg {
        background-color: white;
    }

    .content-section {
        width: 100%;
        margin-top: 20px;
    }

    .nav-section ul p {
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        color: #000000;
    }

    .nav-badge {
        display: none;
    }

    .nav-section ul a {
        min-width: fit-content;
        padding: 2rem 1.5rem;
    }
}

.find-job-input {
    outline: none;
    padding: 13px;
    border-radius: 5px 0px 0px 5px;
    background-color: rgb(216, 216, 216);
    border: none;
    width: 30rem;
    font-weight: 500;
}

.blog-input-icon {
    background-color: #004C70;
    padding: 15px;
    color: white;
    border-radius: 5px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}

.ai-model {
    text-align: center;
    align-items: center;
    h2 {
      
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        /* identical to box height */


        color: #004C70;
    }

}
.ai-textbox{
    height: 300px;
    background: #ECECEC;
    margin: 20px;
    padding: 20px;
    overflow: scroll;
}
.ai-footer{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.profile-visibility-section{
    display: flex;
    justify-content: end;
    .profile-visibility-div {
        align-items: center;
       justify-content: space-between;
       width: 195px;
       span{
         font-size:15px;
       }
    //    .react-switch-bg{
    //      background: rgb(0, 136, 0) !important;
    //    }
    }
}
.user-preview-content{
    box-shadow: 0px 0px 20px 0px #00000040;
   border-radius: 20px;
   padding: 20px;
   margin-top: 20px;
   margin-bottom: 25px;
}
.blue{
    color : #004C70
}
.user-preview-body{
  padding-left: 15%;
}
@media (width < 500px){
    .up-user-image-preview{
        justify-content: center;
    }
    .profile-preview-container h2{
        font-size:14px;
    }
    .user-preview-user-title h1{
        font-size:14px; 
    }
}
.accept-job-offer-footer{
    box-shadow: 0px 0px 4px 0px #00000040;
    padding: 10px;
    margin-bottom: 20px;
}
@media (width > 900px){
    .accept-job-offer-header{
        padding-right: 100px;
    } 
}
.protection-modal{
    .modal-content{
        width: 0% !important;
        border : none !important;
        img{
            height: 451px;
            width: 896px;
            position: relative;
        }
        button{
            background: #33835C;
            color: white;
            border: none;
            font-weight: bold;
            width: 13rem;
            padding: 10px;
            position: absolute;
            bottom: 26px;
            left: 176px;
            border-radius: 10px;
        }
        h1{
            width: max-content;
    position: absolute;
    z-index: 1000;
    font-family: Lato;
    font-size: 25px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    top: 24px;
    color: #004C70;
    left: 40px;
        }
    }
}
@media ( width <  500px){
    .protection-modal{
        .modal-content{
            img{
              
                width:380px;
                position: relative;
            }
            h1{
                font-size: 18px;
            }
            button{
                left: 89px;
            }
        }
    }
}